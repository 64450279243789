import {AbstractControl, ValidationErrors,} from '@angular/forms';
import {DateTime} from 'luxon';

export class DateValidator {
  static isoFormat(control: AbstractControl): ValidationErrors | null {
    const validDate = /^\d{4}-\d{1,2}-\d{1,2}$/;

    if (control.value == null) {
      return null;
    }

    if (typeof control.value !== 'string') {
      if (control.value.day && control.value.month && control.value.year) {
        return null;
      }
      return {noIsoFormat: true};
    }

    if (!control.value.match(validDate) && DateTime.fromISO(control.value).isValid == false) {
      return {noIsoFormat: true};
    }

    console.log('return null');
    return null;
  }
}
