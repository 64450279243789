<link rel="stylesheet" href="clients-comparator-table.component.scss">
<ag-grid-angular
  [style.height.px]="45 + 30 * 10 + 11"
  [class]="'ag-theme-alpine'"
  [rowData]="rowData"
  [columnDefs]="colDefs"
  [gridOptions]="gridOptions"
  [context]="context"
  (gridReady)="gridApi = $event.api"
  (columnResized)="columnResize$.emit()"
/>

<div class="flex" style="flex-direction: column; gap: 0.5rem; padding-top: 0.5rem;">
  <button mat-raised-button color="accent"
          [disabled]="context.selectedHeader == null || context.selectedHeader.getColDef().pinned === 'left'"
          style="width: fit-content"
          (click)="onTakeData()"
  >
    Daten übernehmen
  </button>

  <button
    mat-raised-button color="accent"
    style="width: fit-content"
    (click)="getMoreClients()"
  >
    mehr anzeigen
  </button>
</div>
