import {Injectable} from '@angular/core';
import {AbstractControl, AsyncValidator, AsyncValidatorFn, ValidationErrors} from '@angular/forms';
import {catchError} from 'rxjs/operators';
import {Observable, of, switchMap} from 'rxjs';
import {CoreService} from 'frontier/nucleus';

// now we need to decorate this class with Injectable
@Injectable({
  providedIn: 'root',
})
export class CartNumberValidator implements AsyncValidator {
  instanceId: string;
  constructor(private coreService: CoreService) {
  }

  validate(control: AbstractControl): Observable<ValidationErrors|null> {
    const {value} = control;
    return this.coreService.Cartlist.validcartnumber(this.instanceId, value)
      .pipe(
        switchMap((val) => {
          return val === false ? of({invalidCartNumber: true}) : of(null)
        }),
        catchError(() => {
          return of({failedCartNumberCheck: true})
        })
      );
  };
}

// Factory function to create an AsyncValidatorFn with parameters
export function cartNumberValidatorFactory(coreService: CoreService, instanceId: string): AsyncValidatorFn {
  return (control: AbstractControl): Observable<ValidationErrors | null> => {
    const validator = new CartNumberValidator(coreService);
    validator.instanceId = instanceId;
    return validator.validate(control);
  };
}
