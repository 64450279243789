import {Component, Inject, Injectable, OnDestroy, OnInit} from '@angular/core';
import {MAT_SNACK_BAR_DATA, MatSnackBar} from '@angular/material/snack-bar';
import {Observable, Subject, Subscription, takeWhile, timer} from 'rxjs';
import {debounceTime} from 'rxjs/operators';

interface IError {
  text: string;
  duration?: number;
}

@Injectable({
  providedIn: 'root',
})
export class FeedbackService implements OnDestroy {
  error: Subject<IError> = new Subject<IError>();
  delayedError = this.error.pipe(debounceTime(300));
  subscription = new Subscription();

  constructor(private snackBar: MatSnackBar) {
    this.subscription.add(
      this.delayedError.pipe().subscribe((err: IError) => {
        this.snackBar.openFromComponent(CustomSnackbarComponent, {
          data: err,
          verticalPosition: 'top', announcementMessage: 'ok'
        });
      })
    );
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  setNotification(notification: string, duration = 3000) {
    this.snackBar.open(notification, null, {duration});
  }

  setError(error: string, duration?: number) {
    this.error.next({text: error, duration});
  }

  hide() {
    this.snackBar.dismiss();
  }
}

@Component({
  selector: 'custom-snackbar',
  template:
    `
           <div class="wrapper">
             <span class="text-wrapper">
               {{data.text}}
             </span>
             <button color="accent" mat-stroked-button (click)="snackBar.dismiss()">ok</button>
     <!--        <mat-icon class="centered" style="display: flex" svgIcon="{{EsvgFiles.correct}}"></mat-icon>-->
           </div>
           <div style="display: flex; justify-content: center; flex-direction: column; align-items: center">
             <mat-progress-bar mode="determinate" [value]="val" *ngIf="data.duration"></mat-progress-bar>
             <button mat-icon-button class="icon-button-centered" *ngIf="data.duration">
               <mat-icon class="blue-icon" svgIcon="pause" (click)="onPause()"></mat-icon>
             </button>
           </div>
         `,
  styles: [
    `
           .wrapper {
             display: flex;
             align-items: center;
             justify-content: space-between;
           }
           .text-wrapper {
             display: -webkit-box;
             max-height: 6em;
             -webkit-box-orient: vertical;
             overflow: auto;
           }

           button {
             max-height: 2rem;
           }
         `,
  ],
})
export class CustomSnackbarComponent implements OnInit {
  timer: Observable<number>;
  timerSub: Subscription;

  val: number;
  private maxDuration: number;

  constructor(@Inject(MAT_SNACK_BAR_DATA) public data: IError, public snackBar: MatSnackBar) {

  }

  ngOnInit() {
    if (this.data.duration == null) return;

    this.maxDuration = this.data.duration;
    this.timer = timer(0, 10);

    this.timerSub = this.timer.pipe(takeWhile(val => val * 10 <= this.data.duration)).subscribe(v => {
      this.val = v * 10 / this.maxDuration * 100;
    }, () => {
    }, () => {
      this.val = 100;
      this.snackBar.dismiss();
    })
  }

  onPause() {
    this.timerSub.unsubscribe();
    this.data.duration = null;
  }
}
