import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {MatButtonModule} from '@angular/material/button';
import {MatIconModule} from '@angular/material/icon';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {CancelIconButtonComponent} from './cancel-icon-button/cancel-icon-button.component';
import {ReloadIconButtonComponent} from './reload-icon-button/reload-icon-button.component';
import {InteractableIconDirective} from '../directives/interactable-icon.directive';
import {ConfirmIconButtonComponent} from './confirm-icon-button/confirm-icon-button.component';
import {SaveIconButtonComponent} from './save-icon-button/save-icon-button.component';
import {HighlightPipe} from '../pipes/highlight.pipe';
import {DialogHeaderComponent} from './dialog-header/dialog-header.component';
import {DragDropModule} from '@angular/cdk/drag-drop';
import {MatDialogModule} from '@angular/material/dialog';
import {ReactiveFormsModule} from '@angular/forms';
import {SectionTitleComponent} from './section-title/section-title.component';
import {MatListModule} from '@angular/material/list';
import {DynamicFormModule} from "../control/form-control/dynamic-form/dynamic-form.module";

@NgModule({
  declarations: [
    SaveIconButtonComponent,
    CancelIconButtonComponent,
    ReloadIconButtonComponent,
    InteractableIconDirective,
    ConfirmIconButtonComponent,
    HighlightPipe,
    DialogHeaderComponent,
    SectionTitleComponent],
  imports: [
    CommonModule,
    BrowserAnimationsModule,
    MatButtonModule,
    MatIconModule,
    DragDropModule,
    MatDialogModule,
    ReactiveFormsModule,
    MatListModule,
    DynamicFormModule,
  ],
  exports: [
    SaveIconButtonComponent,
    CancelIconButtonComponent,
    ReloadIconButtonComponent,
    InteractableIconDirective,
    ConfirmIconButtonComponent,
    HighlightPipe,
    DialogHeaderComponent,
    SectionTitleComponent,
  ],
  providers: []
})
export class SharedComponentsModule {
}
