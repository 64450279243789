import {Observable} from 'rxjs';
import {ApiConfigService} from '../api-config.service';
import {HttpHeaders} from '@angular/common/http';
import {IControlObject} from '../../interfaces/control-object.interface';

export class Tablesynctask {
  http;
  url;
  httpOptions;
  constructor(protected appConfig: ApiConfigService) {
    this.http = appConfig.http;
    this.url = appConfig.url;
    this.httpOptions = appConfig.httpOptions;
  }


  importsynctask(InstanceId: string, json: any) : Observable<string> {
   return this.http.post(
      this.url + '/intern/api/control/ExecuteAction/' + InstanceId + '/importsynctask/', {"json": json}, this.httpOptions
    ) as unknown as Observable<string>;
  }

  exportsynctask(InstanceId: string, task: IControlObject) : Observable<any> {
   return this.http.post(
      this.url + '/intern/api/control/ExecuteAction/' + InstanceId + '/exportsynctask/', {"task": task}, this.httpOptions
    ) as unknown as Observable<any>;
  }

  restartimport(InstanceId: string, task: IControlObject) : Observable<string> {
   return this.http.post(
      this.url + '/intern/api/control/ExecuteAction/' + InstanceId + '/restartimport/', {"task": task}, this.httpOptions
    ) as unknown as Observable<string>;
  }

  startsynctask(InstanceId: string, task: IControlObject) : Observable<string> {
   return this.http.post(
      this.url + '/intern/api/control/ExecuteAction/' + InstanceId + '/startsynctask/', {"task": task}, this.httpOptions
    ) as unknown as Observable<string>;
  }

}
