<app-dialog-header
  [title]="'Wählen Sie ein Datum der Tarifänderung aus'"
  [dialogRef]="dialogRef"
  (close)="dialogRef.close()"
></app-dialog-header>

<mat-dialog-content>
  <kpi4me-date-element [formControlElement]="this.dateForm"></kpi4me-date-element>
</mat-dialog-content>

<div mat-dialog-actions style="margin-top:1rem;">
  <app-confirm-icon-button (customClick)="confirm()" [disabled]="dateForm.invalid"></app-confirm-icon-button>
</div>
