import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {FormBuilder, FormControl, FormGroup, Validators,} from '@angular/forms';
import {BaseDialogComponent} from '../../../base-components/base-dialog/base-dialog.component';
import {IDialogData} from '../../../base-components/base-dialog/dialog-data.interface';
import {DialogService} from '../../../dialogs/dialog.service';

interface INodeEditorDialogData extends IDialogData {
  title: string;
  name: string;
}

@Component({
  selector: 'app-node-editor-dialog',
  templateUrl: './node-editor-dialog.component.html',
  styleUrls: ['./node-editor-dialog.component.scss'],
})
export class NodeEditorDialogComponent extends BaseDialogComponent implements OnInit {
  constructor(
    public dialogRef: MatDialogRef<any>,
    @Inject(MAT_DIALOG_DATA) public data: INodeEditorDialogData,
    protected fb: FormBuilder,
    protected dialogService: DialogService
  ) {
    super(dialogRef, data, fb, dialogService);
  }

  form = new FormGroup({
    name: new FormControl('', [Validators.required, Validators.minLength(3)]),
  });

  ngOnInit(): void {
    if (this.data && this.data.name) {
      this.form.patchValue({name: this.data.name});
    }
  }

  onConfirm() {
    this.close();
  }

  protected getDialogOutput(): INodeEditorDialogData {
    return {
      ...this.data,
      ...this.form.value,
    };
  }

  protected isValid(): boolean {
    return this.form.valid;
  }

  protected loadForm(storedData: INodeEditorDialogData): void {
    this.form.patchValue(storedData);
  }

  protected getForm(): any {
    return this.form.value as Partial<{ name: string }>;
  }
}
