import {ChangeDetectorRef, Component, EventEmitter, Input, Output, ViewEncapsulation} from '@angular/core';
import {BaseControlComponent, CoreService, DefaultApiAdapter, IControlObject, StoreService} from 'frontier/nucleus';
import {Observable, tap} from 'rxjs';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'crud-control',
  templateUrl: './crud-control.component.html',
  styleUrls: ['./crud-control.component.scss'],
  encapsulation: ViewEncapsulation.Emulated,
})
export class CrudControlComponent extends BaseControlComponent {
  private objectData: any;

  @Input() set crudObject(o: IControlObject) {
    if (o == null) return;

    // read the data of the object
    this.read(o).pipe(
      tap(data => {
        this.objectData = data;
        this.dataChange.emit(data);
      })
    );
  }

  // if new crud data is read Output it.
  @Output() dataChange = new EventEmitter();

  apiAdapter = new DefaultApiAdapter();

  constructor(
    protected coreService: CoreService,
    protected store: StoreService,
    public cdr: ChangeDetectorRef
  ) {
    super(coreService, store, cdr);
  }

  create(o: any): Observable<any> {
    return this.coreService.CrudControl.createobject(
      this.apiInstance.instanceid,
      o
    )
  }

  read(o: IControlObject): Observable<any> {
    return this.coreService.CrudControl.getobject(
      this.apiInstance.instanceid,
      o
    )
  }

  update(o: IControlObject, newcontent: any): Observable<any> {
    return this.coreService.CrudControl.changeobject(
      this.apiInstance.instanceid,
      o,
      newcontent,
    )
  }

  delete(o: IControlObject): Observable<any> {
    return this.coreService.CrudControl.getobject(
      this.apiInstance.instanceid,
      o
    )
  }
}
