import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {EsvgFiles, KPI4meColor} from 'frontier/nucleus';

@Component({
  selector: 'app-section-title',
  templateUrl: './section-title.component.html',
  styleUrls: ['./section-title.component.scss'],
})
export class SectionTitleComponent implements OnInit {
  @Input() title: string;
  @Input() color: KPI4meColor;
  @Input() icon: string;
  @Input() showIcons = true;
  @Input() categoryIsClosed = true;
  @Input() state: 'full-width' | 'small-width' | 'closed' = 'small-width';

  @Output() openClosedStateToggled = new EventEmitter<boolean>();

  constructor() {
  }

  ngOnInit(): void {
    return;
  }

  getHeaderTextStyle() {
    let color: string;

    switch (this.color) {
      case 'blue':
        color = 'var(--kpi-blue)';
        break;
      case 'green':
        color = 'var(--kpi-green)';
        break;
      case 'red':
        color = 'var(--kpi-red)';
        break;
      default:
        color = 'var(--kpi-blue)';
    }
  }

  getOpenCloseIcon(): string {
    if (this.categoryIsClosed) {
      return EsvgFiles.arrow_down;
    } else {
      return EsvgFiles.arrow_up;
    }
  }

  toggleOpenClosed(): void {
    this.categoryIsClosed = !this.categoryIsClosed;
    this.openClosedStateToggled.emit(this.categoryIsClosed);
  }
}
