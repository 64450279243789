import {Component, OnInit} from '@angular/core';
import {CustomCellComponent} from '../custom-cell/custom-cell.component';
import {EsvgFiles} from 'frontier/nucleus';

@Component({
  selector: 'app-reference-cell-renderer',
  templateUrl: './reference-cell-renderer.component.html',
  styleUrls: ['../custom-cell/custom-cell.component.scss'],
})
export class ReferenceCellRendererComponent extends CustomCellComponent implements OnInit {
  protected readonly EsvgFiles = EsvgFiles;

  constructor() {
    super();
  }

  ngOnInit(): void {
    return;
  }

  cellEditable() {
    return this.params && this.params.value ? this.params.value.editable : true;
  }
}
