<div class="form-element-icon-wrapper form-field-no-padding"
     [style.height]="formData.styling?.height"
     [ngStyle]="getFlexStyle()"
     [matTooltip]="formData.tooltip ? formData.tooltip : null"
>
  <mat-icon
    *ngIf="formData.styling && formData.styling.icon"
    style="flex-shrink: 0; margin-right: 0.5rem; display: flex"
    class="blue-icon centered"
    [svgIcon]="formData.styling.icon"
  ></mat-icon>

  <ng-template #hostElement></ng-template>
</div>
