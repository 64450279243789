export
enum Reason {
  changeInsuranceCompany,
  changeInvoiceAddress,
  submitAuthorization,
  other
}

export interface TClientData {
  Action?: Reason;
  Note?: string;
  Email?: string;
  Phone?: string;
  InvoiceNumber?: string;
  ClientHasPayer?: boolean;
  ClientIdentifierNumber?: string;
  ClientPayerName?: string;
  ClientPayerNumber?: string;
  ClientFirstName?: string;
  ClientLastName?: string;
  ClientStreet?: string;
  ClientPostCode?: string;
  ClientCity?: string;
  ClientCountryCode?: string;
  ActionDescription?: string;
  DocumentAssigned?: boolean;
  DisplayAction?: boolean;
}

