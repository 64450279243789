import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnDestroy,
  Output,
  Renderer2,
  ViewChild,
  ViewEncapsulation
} from '@angular/core';
import {BaseFormElementComponent} from '../base-form-element.class';
import {NgbDateAdapter, NgbDateParserFormatter, NgbInputDatepicker} from '@ng-bootstrap/ng-bootstrap';
import {DateValidator, EsvgFiles} from 'frontier/nucleus';
import {IDateFormElement} from '../form-data.interface';
import {
  GermanDatepickerAdapter,
  GermanDatepickerFormatter
} from 'frontier/browserkit/src/lib/bootstrap-datepicker-adapter/german-datepicker-adapter.service';
import {filter, tap} from 'rxjs/operators';
import {AbstractControl, FormControl} from '@angular/forms';
import {skip, Subscription} from 'rxjs';

@Component({
  selector: 'kpi4me-date-element',
  templateUrl: './date-element.component.html',
  styleUrls: ['./date-element.component.scss', '../form-element.component.scss'],
  encapsulation: ViewEncapsulation.Emulated,
  providers: [
    {provide: NgbDateAdapter, useClass: GermanDatepickerAdapter},
    {provide: NgbDateParserFormatter, useClass: GermanDatepickerFormatter},
  ],
})
export class DateElementComponent extends BaseFormElementComponent implements OnDestroy {
  @ViewChild(NgbInputDatepicker) datePickerRef: NgbInputDatepicker;

  @ViewChild('inputRef', {static: true}) inputRef: ElementRef;
  private insertDots: boolean;
  private subs = new Subscription();
  protected readonly EsvgFiles = EsvgFiles;

  _formControlElement: AbstractControl;
  @Input() set formControlElement(c: AbstractControl) {
    if (c) {
      this._formControlElement = c;
      this.subs.add(this._formControlElement.valueChanges.pipe(
        skip(1),
        tap(() => console.log('Form control value changed')),
        filter(() => this._formControlElement.valid)
      ).subscribe(
        () => {
          this.focusOutEvent.emit(this.formControlElement)
        }
      ))
    }
  };

  get formControlElement(): FormControl {
    return this._formControlElement as FormControl;
  }

  constructor(private cdr: ChangeDetectorRef,
              private renderer: Renderer2) {
    super();
  }

  @Input() set data(form: IDateFormElement) {
    this.insertDots = form.insertDelimiter;

    this.getFormControl().addValidators(DateValidator.isoFormat)
    super.data = form;

    this.getFormControl().updateValueAndValidity();
  };

  @Output() selectionChange = new EventEmitter();

  ngOnDestroy() {
    this.subs.unsubscribe();
  }

  onKeyDown(evt: KeyboardEvent, d: NgbInputDatepicker) {
    if (this.insertDots !== true) {
      return;
    }
    if (evt.key === 'Backspace') {
      return;
    }
    evt.preventDefault();
    const onlyDigits = evt.key.match(/^[0-9]*$/);
    if (!onlyDigits) {
      return;
    }
    const formValue = this.inputRef.nativeElement.value;
    if (formValue.length >= 10) {
      return;
    }
    this.cdr.detach();
    let outputValue;
    if (formValue != null) {
      const valueWithoutDots = formValue.replace(/\./g, '');
      outputValue = valueWithoutDots + evt.key;
    } else {
      outputValue = '' + evt.key;
    }
    let outputValueWithDots = '';
    for (let i = 0; i < outputValue.length; i++) {
      if (i == 1 || i == 3) {
        outputValueWithDots += outputValue[i] + '.';
      } else {
        outputValueWithDots += outputValue[i];
      }
    }

    // this.formControlElement.patchValue(outputValueWithDots, {emitEvent: true});
    this.renderer.setProperty(this.inputRef.nativeElement, 'value', outputValueWithDots);
    this.inputRef.nativeElement.dispatchEvent(new Event('change'), {bubbles: true});
    this.formControlElement.updateValueAndValidity();

    this.cdr.reattach();

    this.cdr.detectChanges();
    console.log(outputValue, evt);

  }

  getErrorMessage(): string[] {
    const formControl = this.getFormControl();
    const errorMessage: string[] = [];
    if (formControl.hasError('required')) {
      errorMessage.push('Bitte geben Sie ein Datum an.');
    }
    if (formControl.hasError('noIsoFormat')) {
      errorMessage.push('Der eingegebene Wert ist kein Gültiges Datum im Format tt.mm.jjjj.')
    }

    return errorMessage;
  }

  onFocusOut(evt: FocusEvent) {
    return;
  }

}
