import {IControlObject} from 'frontier/nucleus';

export enum EFormControlType {
  text,
  select,
  checkbox,
  radio,
  search,
  date,
  file,
  color,
  treeSelect,
}

export type TIconPosition = 'left' | 'top' | 'right' | 'bottom';
export type TTextInputType = 'text' | 'textarea' | 'number' | 'tel' | 'url' | 'email' | 'password' | 'iban';
export
type TAnyFormElement = (
  IFormElement |
  ISelectFormElement |
  ICheckboxFormElement |
  IDateFormElement |
  IFileUploadFormElement |
  ITextFormElement |
  ITreeSelectFormElement
  );


export interface IForm {
  confirmable: boolean;
  formGroups: IFormGroup[];
}

export interface IFormGroup {
  closed?: boolean; // Frontend only
  maxCol?: string; // Frontend only
  header: string;
  closable?: boolean;
  elements: TAnyFormElement[];
}

export interface IFormElement {
  value?: any;
  key?: string;
  label?: string;
  required?: boolean;
  formControlType?: EFormControlType;
  styling?: IFormElementStyle;
  tooltip?: string;
  options?: any[];
  imported?: string;
  tableLabel?: string;
}

export interface IRadioOption {
  name: string;
  layer_id?: number;
  value?: any; // Always use either layer_id or value
  description?: string;
}

export interface IRadioElement extends IFormElement {
  options: IRadioOption[];
}

export interface IFormElementStyle {
  rowStart?: number;
  rowEnd?: number;
  columnStart?: number;
  columnEnd?: number;
  height?: string;
  width?: string;
  maxWidth?: string;
  icon?: string;
  iconPosition?: TIconPosition;
  cssClass?: string;
  textAreaInitialRowCount?: number;
}

export interface ITextFormElement extends IFormElement {
  min?: number;
  max?: number;
  maxLength?: number;
  inputType?: TTextInputType;
  rolling?: boolean;
}

export interface ISelectFormElement extends IFormElement {
  arrowPosition?: "left" | "right";
  multiple?: boolean;
  createNewEnabled?: boolean;
  deleteElementEnabled?: boolean;
  editElementEnabled?: boolean;
  clearEnabled?: boolean;
  options?: ISelectFormOption[];
  disabled?: boolean;
  confirmButton?: boolean;
  emptyOptionAdded?: boolean;
}

export interface ISelectFormOption extends IControlObject {
  name: string;
  value: any;
}


export interface IFileUploadFormElement extends IFormElement {
  readAs: 'ArrayBuffer' | 'Text';
  maxSize: number;
  selectType: 'upload' | 'serverFiles',
  fileTypes: string; // i.e. = '.jpg, .jpeg, .png'
}

export interface IDateFormElement extends IFormElement {
  insertDelimiter: boolean;
}

export interface ICheckboxFormElement extends IFormElement {
  displayAs: 'checkbox' | 'toggle';
}

export interface ISearchFormElement extends IFormElement {
  multiple: boolean;
}

export interface ITreeSelectFormElement extends ISelectFormElement {
  nodes: ITreeSelectNode[];
  createRootEnabled?: boolean;
}

export interface ITreeSelectNode extends IControlObject {
  name: string;
  children: any[]

}

export interface IKeyToStyleMap {
  [key: string]: IFormElementStyle
}
