import {Observable} from 'rxjs';
import {ApiConfigService} from '../api-config.service';
import {HttpHeaders} from '@angular/common/http';

export class Sysconfig {
  http;
  url;
  httpOptions;
  constructor(protected appConfig: ApiConfigService) {
    this.http = appConfig.http;
    this.url = appConfig.url;
    this.httpOptions = appConfig.httpOptions;
  }


  BackupList() : Observable<any[]> {
    return this.http.get(
      this.url + '/intern/api/sysconfig/BackupList/', this.httpOptions
    ) as unknown as Observable<any[]>;
  }

  SystemRestore(filename: string) : Observable<boolean> {
    return this.http.post(
      this.url + '/intern/api/sysconfig/SystemRestore/',
      {_parameters: [filename]}, this.httpOptions
    ) as unknown as Observable<boolean>;
  }

  SaveBackup(name: string) : Observable<boolean> {
    return this.http.post(
      this.url + '/intern/api/sysconfig/SaveBackup/',
      {_parameters: [name]}, this.httpOptions
    ) as unknown as Observable<boolean>;
  }

}
