import {Component, Inject, OnInit, ViewChild} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef,} from '@angular/material/dialog';
import {ISelectableEntity} from './selectable-entity.interface';
import {MatSelectionList} from '@angular/material/list';
import {IEntitySelectorDialogData} from './entity-selector-data.interface';

@Component({
  selector: 'app-entity-selector',
  templateUrl: './entity-selector.component.html',
  styleUrls: ['./entity-selector.component.scss'],
})
export class EntitySelectorComponent implements OnInit {
  @ViewChild(MatSelectionList) matSelectionList: MatSelectionList;
  selectedEntities: ISelectableEntity[] = [];
  multiple: boolean = false;

  constructor(
    public dialog: MatDialog,
    public dialogRef: MatDialogRef<EntitySelectorComponent>,
    @Inject(MAT_DIALOG_DATA) public data: IEntitySelectorDialogData
  ) {
  }

  ngOnInit(): void {
    console.log(this.data);
  }

  onConfirm() {
    this.dialogRef.close(this.selectedEntities);
  }
}
