import {Component, EventEmitter, Input, Output, ViewEncapsulation} from '@angular/core';
import {BaseFormElementComponent} from "../base-form-element.class";
import {COMMA, ENTER} from "@angular/cdk/keycodes";
import {MatChipInputEvent} from "@angular/material/chips";
import {FormControl, Validators} from "@angular/forms";
import {EsvgFiles} from 'frontier/nucleus';
import {FeedbackService} from '../../../../../user-feedback/feedback.service';

@Component({
  selector: 'kpi4me-multiple-email-element',
  templateUrl: './multiple-email-element.component.html',
  styleUrls: ['./multiple-email-element.component.scss'],
  encapsulation: ViewEncapsulation.Emulated,
})
export class MultipleEmailElementComponent extends BaseFormElementComponent {
  @Input() mails: string[] = [];
  // @Input() matFormFieldFormControlName: string;
  @Output() receiverChanged = new EventEmitter<string[]>();
  addOnBlur = true;
  readonly separatorKeysCodes = [ENTER, COMMA] as const;
  textForm = new FormControl('', [Validators.email]);

  constructor(private feedbackService: FeedbackService,) {
    super();
  }

  /**
   * Adds a mail to the list of mails if valid.
   * @param {MatChipInputEvent} event - The new mail address that is requested to be added.
   * @emits {string[]} receiverChanged - Emits the updated list of mails after the addition.
   */
  add(event: MatChipInputEvent): void {
    const value = (event.value || '').trim();

    if (this.textForm.valid == false) {
      this.feedbackService.setError('Die eingebene E-Mail-Adresse ist nicht korrekt. Bitte überprüfen Sie Ihre Eingabe.', 5000);
    }
    if (this.textForm.valid == false || value === '') return;
    if (value) {
      this.mails.push(value);
    }

    this.receiverChanged.emit(this.mails);

    // Clear the input value
    event.chipInput.clear();
  }

  /**
   * Removes a mail from the list of mails.
   * @param {string} mail - The mail to be removed.
   * @emits {string[]} receiverChanged - Emits the updated list of mails after the removal.
   */
  remove(mail: string): void {
    const index = this.mails.indexOf(mail);

    if (index >= 0) {
      this.mails.splice(index, 1);
    }

    this.receiverChanged.emit(this.mails);
  }

  protected readonly EsvgFiles = EsvgFiles;
}
