import {
  ChangeDetectorRef,
  Component,
  Input,
  OnChanges,
  SimpleChanges,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import {EcalculusControlGUID, IApiControl, IControlObject} from 'frontier/nucleus';
import {ControlManagerService, TableVerticalControlComponent} from 'frontier/browserkit';
import {CalculusStateService} from "../../Services/calculus-state.service";


@Component({
  selector: 'kpi4me-transport-detail',
  templateUrl: './transport-detail.component.html',
  styleUrls: ['./transport-detail.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class TransportDetailComponent implements OnChanges {
  @Input() parent: IControlObject;
  @Input() showImports = true;
  @ViewChild('form') formRef: TableVerticalControlComponent;
  formApiInstance: IApiControl;
  transportDetailsGUID = EcalculusControlGUID.TransportList;

  constructor(
    protected cdr: ChangeDetectorRef,
    protected controlManager: ControlManagerService,
    protected calculusStateService: CalculusStateService,
  ) {
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.parent && this.formRef) {
      console.log('Parent of Transport changed to: ', changes.parent);
      this.filterAndFetch(this.formApiInstance);
    }
  }

  protected onResize(): void {
  }

  filterAndFetch(apiInstance: IApiControl): void {
    if (this.formRef) {
      this.formApiInstance = {
        ...apiInstance,
        filter: {
          ...this.formApiInstance?.filter,
          parent: this.parent,
          typename: 'cart',
        },
      };

      this.cdr.detectChanges();
      this.formRef.changeAndFetch().subscribe();
    }
  }
}
