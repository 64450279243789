import {
  GuidFormComponent,
  GuidTableComponent,
  IGoldenLayoutDynamicComponent,
  ISideNavGlCategory,
} from 'frontier/browserkit';
import {ECalculusComponentNames, ECalculusLabels, ECalculusViewCategory} from './golden-layout.component';
import {EcalculusControlGUID, EsvgFiles} from 'frontier/nucleus';
import {ProcessOverviewComponent} from './process-overview/process-overview.component';
import {GoToTrapoComponent} from './go-to-trapo/go-to-trapo.component';
import {DunningConfigComponent} from "./dunning-config/dunning-config.component";
import {AlternativePayerComponent} from './alternative-payer/alternative-payer.component';
import {TariffChangesComponent} from './tariff-changes/tariff-changes.component';
import {TariffOptionsComponent} from './tariff-changes/tariff-options/tariff-options.component';
import {InvoiceTemplatesConfigComponent} from './invoice-templates-config/invoice-templates-config.component';
import {TemplateEditorComponent} from './template-editor/template-editor.component';

export const detailComponents: IGoldenLayoutDynamicComponent[] = [
  {
    angularComponentType: GuidFormComponent,
    componentName: ECalculusComponentNames.dynamicForm,
    type: 'component',
    title: ECalculusLabels.dynamicForm,
    controlGuids: [ECalculusComponentNames.dynamicForm],
    allowMultipleInstances: true,
  },
  {
    angularComponentType: GuidTableComponent,
    componentName: ECalculusComponentNames.dynamicTable,
    type: 'component',
    title: ECalculusLabels.dynamicTable,
    controlGuids: [ECalculusComponentNames.dynamicTable],
    allowMultipleInstances: true,
  },
  {
    angularComponentType: DunningConfigComponent,
    componentName: ECalculusComponentNames.dunningConfig,
    type: 'component',
    title: ECalculusLabels.dunningConfig,
    controlGuids: [EcalculusControlGUID.DunningConfigView, EcalculusControlGUID.DunningConfigView],
    allowMultipleInstances: false,
  },
  {
    angularComponentType: InvoiceTemplatesConfigComponent,
    componentName: ECalculusComponentNames.invoiceTemplates,
    type: 'component',
    title: ECalculusLabels.invoiceTemplates,
    controlGuids: ['mockGUID'],
    allowMultipleInstances: false,
  },
  {
    angularComponentType: AlternativePayerComponent,
    componentName: ECalculusComponentNames.alternativePayer,
    type: 'component',
    title: ECalculusLabels.alternativePayer,
    controlGuids: [EcalculusControlGUID.AlternativePayerView],
    allowMultipleInstances: false,
  },
  {
    angularComponentType: TariffChangesComponent,
    componentName: ECalculusComponentNames.tariffChanges,
    type: 'component',
    title: ECalculusLabels.tariffChanges,
    controlGuids: [EcalculusControlGUID.ItemPriceHistoryView],
    allowMultipleInstances: false,
  },
  {
    angularComponentType: TariffOptionsComponent,
    componentName: ECalculusComponentNames.tariffOptions,
    type: 'component',
    title: ECalculusLabels.tariffOptions,
    controlGuids: [EcalculusControlGUID.ItemGroupView, EcalculusControlGUID.ItemTypeView],
    allowMultipleInstances: false,
  },
  {
    angularComponentType: TemplateEditorComponent,
    componentName: ECalculusComponentNames.templateEditor,
    type: 'component',
    title: ECalculusLabels.tariffOptions,
    controlGuids: [EcalculusControlGUID.TemplateConfigurationControl],
    allowMultipleInstances: false,
  }
];

export const components: ISideNavGlCategory[] =
  [
    {
      title: ECalculusViewCategory.calculus,
      icon: EsvgFiles.money,
      components: [
        {
          angularComponentType: ProcessOverviewComponent,
          componentName: ECalculusComponentNames.processOverview,
          type: 'component',
          title: ECalculusLabels.processOverview,
        },
        {
          angularComponentType: GoToTrapoComponent,
          componentName: ECalculusComponentNames.goToTrapo,
          type: 'component',
          title: ECalculusLabels.goToTrapo,
        },
      ],
    },
  ];
