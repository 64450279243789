import {ChangeDetectionStrategy, Component, OnInit} from '@angular/core';
import {ICellRendererAngularComp} from 'ag-grid-angular';
import {ICellRendererParams} from 'ag-grid-community';
import {ICustomColDef} from '../../interfaces/custom-col-def.interface';

@Component({
  selector: 'app-custom-cell',
  templateUrl: './custom-cell.component.html',
  styleUrls: ['./custom-cell.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CustomCellComponent implements OnInit, ICellRendererAngularComp {
  params: ICellRendererParams;

  constructor() {
  }

  ngOnInit(): void {
    return;
  }

  agInit(params: ICellRendererParams): void {
    this.params = params;
  }

  refresh(params: ICellRendererParams): boolean {
    return false;
  }

  onContextMenu($event: MouseEvent) {
    console.log(this.params.context);
    $event.preventDefault();
  }

  // Helper method to check if a cell is of type double / float
  isFloatValue(): boolean {
    return (
      (this.params.colDef as ICustomColDef).apiCol.attributetype === 'Double'
    );
  }

  startEdit(evt: MouseEvent) {
    evt.stopPropagation();
    this.params.api.stopEditing(true);
    this.params.api.startEditingCell({rowIndex: this.params.rowIndex, colKey: this.params.column});
  }
}
