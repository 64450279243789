<div
  style="
    display: flex;
    flex-direction: row;
    height: 100%;
  "
>
  <app-table-control
    #groups
    [GUID]="groupsGuid"
    [gridOptions]="groupsGridOptions"
    [(apiInstance)]="groupsApiInstance"
    [actions]="groupsActions"
    (apiInstanceInitialize)="groupsApiInstanceInitialized$.next($event)"
  ></app-table-control>
  <app-table-control
    #types
    [GUID]="typesGuid"
    [gridOptions]="typesGridOptions"
    [(apiInstance)]="typesApiInstance"
    [actions]="typesActions"
    (apiInstanceInitialize)="typesApiInstanceInitialized$.next($event)"
  ></app-table-control>
</div>
