import {Component, Inject, Input, inject} from '@angular/core';
import {PDFSource, PdfViewerModule} from 'ng2-pdf-viewer';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {DomSanitizer} from '@angular/platform-browser';

@Component({
  selector: 'lib-pdf-dialog',
  templateUrl: './pdf-dialog.component.html',
  styleUrl: './pdf-dialog.component.css'
})
export class PdfDialogComponent {

  private sanitizer = inject(DomSanitizer);

  pdfSrc: string | Uint8Array | PDFSource;

  constructor(
    public dialogRef: MatDialogRef<any>,
    @Inject(MAT_DIALOG_DATA) public data: Blob
  ) {
    const url = URL.createObjectURL(data);
    this.pdfSrc = this.sanitizer.bypassSecurityTrustResourceUrl(url);
  }
}
