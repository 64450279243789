<div class="diagram-container">
	<button *ngFor="let bar of _evaluationData; let i = index"
					[class.selected]="
    i === 0 && selectedStates?.includes(process.errorId) ||
    i === 1 && selectedStates?.includes(process.warningId) ||
    i === 2 && selectedStates?.includes(process.okId)
"
					[class]="'bar-container'"
					(click)="barClicked.emit({state: getStateByIndex(i), event: $event});">
		<div
				class="bar-wrapper">
			<div class="bar"
					 [style.height.%]="bar">
			</div>
		</div>
		<div class="label">
			{{showPercentages ? evaluationDisplayPercentages[i] : evaluationDisplayAbsolute[i]}}
		</div>
	</button>
</div>
