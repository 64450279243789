import {
  AfterViewInit,
  Component,
  ComponentFactory,
  ComponentFactoryResolver,
  ComponentRef,
  ElementRef,
  EmbeddedViewRef,
  EventEmitter,
  Inject,
  Input,
  OnDestroy,
  Output,
  QueryList,
  Renderer2,
  ViewChild,
  ViewChildren,
  ViewContainerRef,
} from '@angular/core';
import {ControlManagerService} from '../control-manager.service';
import {GoldenLayoutComponent} from '@kpi4me/golden-angular-layout';
import {DOCUMENT} from '@angular/common';
import {NewLayoutDialogComponent} from './new-layout-dialog/new-layout-dialog.component';
import {LoadLayoutDialogComponent} from './load-layout-dialog/load-layout-dialog.component';
import {BehaviorSubject, Subscription} from 'rxjs';
import {DialogService} from '../../dialogs/dialog.service';
import {filter, take, tap} from 'rxjs/operators';
import {IGoldenLayoutDynamicComponent, ISideNavGlCategory} from './golden-layout-dynamic-components.interface';
import {DragButtonComponent} from './drag-button/drag-button.component';
import {ISideNavItem} from "../../mat-drawer-content/side-nav-view.interface";
import {SectionTitleComponent} from "../../shared-components/section-title/section-title.component";
import {MatMenu, MatMenuTrigger} from "@angular/material/menu";
import {IControl} from "../../control/table-control/interfaces/displayed-columns.interface";
import {EsvgFiles} from 'frontier/nucleus';

@Component({
  selector: 'app-control-manager',
  templateUrl: './control-manager.component.html',
  styleUrls: ['./control-manager.component.scss'],
})
export class ControlManagerComponent implements AfterViewInit, OnDestroy {
  // Parent element for the dynamically creatable elements.
  @ViewChild('menuContainer', {static: true}) menuContainer: ElementRef;
  @ViewChildren('categoryPlaceholder', {read: ViewContainerRef}) categoriesPlaceholder: QueryList<ViewContainerRef>;
  @ViewChild('saveLayoutReference') saveLayoutReference: ElementRef;
  @ViewChild(SectionTitleComponent) title: SectionTitleComponent;
  // reference to the MatMenuTrigger in the DOM
  @ViewChild(MatMenuTrigger, {static: true}) matMenuTrigger: MatMenuTrigger;
  @ViewChild(MatMenu) matMenu: MatMenu;

  // we create an object that contains coordinates
  menuTopLeftPosition: { x: string, y: string } = {x: '0', y: '0'}

  // Components that are creatable by drag and drop by the user.
  @Input() categories: ISideNavGlCategory[] = [];
  @Input() detailComponents: IGoldenLayoutDynamicComponent[];
  @Input() showIcons = true;
  @Input() state: 'full-width' | 'small-width' = 'full-width';
  @Input() states = ['full-width', 'small-width'];

  @Output() resetLayout = new EventEmitter();
  @Output() stateChanged = new EventEmitter();
  private _layout = new BehaviorSubject<GoldenLayoutComponent>(null);
  private subs = new Subscription();
  private viewContainerRefs: ViewContainerRef[] = [];
  private subscription = new Subscription();
  private componentRefs: ComponentRef<any>[] = [];
  isEnterMatMenuOpen = false;
  timerRunning = false;

  constructor(
    public controlManagerService: ControlManagerService,
    private renderer: Renderer2,
    @Inject(DOCUMENT) private document: Document,
    private dialogService: DialogService,
    private elementRef: ElementRef,
    private resolver: ComponentFactoryResolver,
  ) {
    this.subscription.add(
      this.resetLayout.subscribe(() => {
        this.controlManagerService.resetLayout();
        this.controlManagerService.openLayoutId = null;
      })
    );
  }

  @Input() set goldenLayoutInstance(layout: GoldenLayoutComponent) {
    if (layout) {
      this._layout.next(layout);
      // this.createDragElements();
    }
  }

  ngAfterViewInit() {
    this.subs.add(
      this.controlManagerService.componentToBeOpened$.subscribe((obj: { control: IControl, parent: any }) => {
        if (obj) {
          this.openDetailComponent(obj.control, obj.parent);
        }
      })
    )

    this.subs.add(this.controlManagerService.viewToRemoveFromLayout$.subscribe((componentName: string) => {
      if (componentName) {
        const layout = this._layout.getValue();
        Object.values(layout.getComponents()).forEach((c: any) => {
          if (c.componentName == componentName) {
            c.close();
          }
        });
      }
    }))

    this.subs.add(
      this.controlManagerService.openComponent$.subscribe(componentName => {
        this.openComponent(componentName)
      })
    )
  }

  ngOnDestroy() {
    this.subs.unsubscribe();
  }

  /**
   * Creates a component dynamically.
   * @param c: The component that gets created at the current selected tab.
   */
  createComponent(c: IGoldenLayoutDynamicComponent) {
    const selectedTab =
      this.goldenLayoutInstance.getGoldenLayoutInstance().selectedItem;
    selectedTab.addChild(c);
  }

  onSaveLayout() {
    this.controlManagerService.overwriteLoadedLayout();
  }

  /**
   * Opens the dialog for loading a layout. After loading a layout, the drag elements have to be recreated to make them work.
   */
  onLoadLayout() {
    this.dialogService.dialog
      .open(LoadLayoutDialogComponent, {
        data: {
          layouts: this.controlManagerService.layouts,
          inEditMode: false,
        },
      })
      .afterClosed()
      .subscribe((result) => {
        if (result) {
          console.log(result);
          this.controlManagerService.loadView(result);
        }
      });
  }

  onEditLayouts() {
    this.dialogService.dialog
      .open(LoadLayoutDialogComponent, {
        data: {
          layouts: this.controlManagerService.layouts,
          inEditMode: true,
        },
      })
      .afterClosed()
      .subscribe((data) => {
        console.log(data);
        this.controlManagerService.saveLayoutsLocally(data.layouts.getValue());
      });
  }

  /**
   * Opens the dialog for creating and saving a new layout. The result of the dialog is a form with a name attribute, for the title of the layout.
   */
  onAddNewReport() {
    this.dialogService.dialog
      .open(NewLayoutDialogComponent)
      .afterClosed()
      .subscribe((result) => {
        if (result) {
          this.controlManagerService.createLayout(result.get('name').value);
        }
      });
  }

  /**
   * Creates drag elements for the control components. For each control, a button is created with a text and added to the
   * container element (menuContainer) of the template.
   * @private
   */
  private createDragElements() {
    this.categories.forEach((category: ISideNavGlCategory, i) => {
      // Todo: Before using this function again, one should implement behavior for the ISideNavItems
      (category.components as IGoldenLayoutDynamicComponent[]).forEach((c: IGoldenLayoutDynamicComponent) => {
        // check if c is IGoldenLayoutDynamicComponent or ISideNavItem
        const container: ViewContainerRef =
          this.categoriesPlaceholder.toArray()[i];

        // save the containers is an array to later clear them all in the recreation method.
        this.viewContainerRefs.push(container);

        const dragButtonComponentRef: ComponentRef<DragButtonComponent> =
          this.createAngularComponent(c, container);
        // get the html element of the button
        const domElem = (
          dragButtonComponentRef.hostView as EmbeddedViewRef<any>
        ).rootNodes[0] as HTMLElement;

        if (this.controlManagerService.openComponents[c.componentName]) {
          dragButtonComponentRef.instance.instanstiated = true;
        } else {
          dragButtonComponentRef.instance.instanstiated = false;
        }

        // Event listener for closing the instances of one type.
        dragButtonComponentRef.instance.closeInstances
          .pipe(
            tap(() => {
              const layout = this._layout.getValue();
              Object.values(layout.getComponents()).forEach((c: any) => {
                console.log(c);
                if (
                  c.componentName ==
                  dragButtonComponentRef.instance.data.componentName
                ) {
                  c.close();
                }
              });
            })
          )
          .subscribe();

        dragButtonComponentRef.instance.click
          .pipe(
            tap(() => {
              const layout = this._layout.getValue();
              layout
                .createNewComponent(dragButtonComponentRef.instance.data)
                .then((r) => this.controlManagerService.setOpenedComponents());
            })
          )
          .subscribe();

        this._layout
          .pipe(
            filter((v) => v != null),
            take(1),
            tap((layout) => {
              const dragSource = layout
                .getGoldenLayoutInstance()
                .createDragSource(domElem, c);
            })
          )
          .subscribe();
      });
    });
  }

  private createAngularComponent(
    c: IGoldenLayoutDynamicComponent,
    container: ViewContainerRef,
  ): ComponentRef<DragButtonComponent> {
    const factory: ComponentFactory<DragButtonComponent> =
      this.resolver.resolveComponentFactory(DragButtonComponent);
    const componentRef: ComponentRef<DragButtonComponent> =
      container.createComponent(factory);
    componentRef.instance.data = c;
    this.componentRefs.push(componentRef);
    return componentRef;
  }

  private clearContainerRefs() {
    this.viewContainerRefs.forEach((conRef) => {
      conRef.clear();
    });
  }

  onComponentListItemClick(event: MouseEvent, comp: IGoldenLayoutDynamicComponent | ISideNavItem, parent?: any) {
    if (this.isGoldenLayoutComponent(comp)) {
      if (parent) {
        (<IGoldenLayoutDynamicComponent>comp).componentState = {
          parent
        }
      }
      const component = <IGoldenLayoutDynamicComponent>comp;
      this._layout.pipe(
        filter(layout => layout != null),
        take(1),
        tap(layout => {
          if (Object.values(layout.getComponents()).some((c: any) => {
            return c.componentName == component.componentName;
          }) && !component.allowMultipleInstances) {
            const tabToFocus = Object.values(layout.getComponents()).find((c: any) => {
              return c.componentName == component.componentName;
            });
            tabToFocus.parent.setActiveContentItem(tabToFocus);
          } else {
            layout
              .createNewComponent(<IGoldenLayoutDynamicComponent>comp)
              .then((r) => this.controlManagerService.setOpenedComponents());
          }
        })
      ).subscribe();
    }
  }

  closeAllComponentsOfType(event: MouseEvent, comp: IGoldenLayoutDynamicComponent | ISideNavItem) {
    const component = <IGoldenLayoutDynamicComponent>comp;
    event.stopImmediatePropagation();
    event.stopPropagation();
    let componentsGetClosed = true;
    let layout = this._layout.getValue();
    while (componentsGetClosed) {
      componentsGetClosed = false;
      layout = this._layout.getValue();
      Object.values(layout.getComponents()).forEach((c: any) => {
        if (
          c.componentName ==
          component.componentName
        ) {
          c.close();
          componentsGetClosed = true;
        }
      });
    }
  }

  getIconColor(color: "blue" | "red" | "green") {
    return `${color}-icon`;
  }

  onIconClick() {
    this.stateChanged.emit();
  }

  isGoldenLayoutComponent(comp: IGoldenLayoutDynamicComponent | ISideNavItem): boolean {
    if (comp) {
      return "componentName" in comp;
    }
    return false;
  }

  getOpenCloseIcon(): string {
    if (this.state === 'full-width') {
      // image of an arrow tip pointing to the left
      return EsvgFiles.arrow_left;
    } else {
      // image of an arrow tip pointing to the right
      return EsvgFiles.arrow_right;
    }
  }

  updateCategoryState($event: boolean, toggledCategory: ISideNavGlCategory): void {
    this.categories = this.categories.map((category: ISideNavGlCategory) => {
      if (category.title !== toggledCategory.title) {
        return category;
      } else {
        return {...category, closed: $event};
      }
    });
  }

  openContextMenu(evt: MouseEvent, category: ISideNavGlCategory): void {
    if (!this.timerRunning) {
      this.timerRunning = true;

      // we record the mouse position in our object
      this.menuTopLeftPosition.x = '50px';
      this.menuTopLeftPosition.y = String(Number(evt.clientY) + 22) + 'px';

      // we pass to the menu the information about our object
      this.matMenuTrigger.menuData = {item: category}

      // we open the menu
      this.matMenuTrigger.openMenu();
      this.isEnterMatMenuOpen = false;

      setTimeout(() => {
        if (!this.isEnterMatMenuOpen) {
          this.matMenuTrigger.closeMenu();
          console.log('Closed per Timeout')
        }
        this.timerRunning = false;
      }, 1000);
    }

  }

  closeHoverMenu() {
    // this.matMenuTrigger.closeMenu();
    console.log('Closed per Menu Left')
  }

  onMouseEnter() {
    this.isEnterMatMenuOpen = true;
  }

  preventSelection($event: any) {
    $event.preventDefault();
  }

  openDetailComponent(control: IControl, parent: any): void {
    const componentToOpen = this.detailComponents.find((component: IGoldenLayoutDynamicComponent) => {
      return component.controlGuids.includes(control.guid);
    });

    if (parent) {
      this.onComponentListItemClick(null, componentToOpen, parent);
    } else {
      this.onComponentListItemClick(null, componentToOpen);
    }
  }

  openComponent(componentName: string) {
    try {
      let componentToOpen: IGoldenLayoutDynamicComponent;
      this.categories.forEach(category => {
        componentToOpen = category.components.find((component: IGoldenLayoutDynamicComponent) => component.componentName === componentName);
      })
      this.onComponentListItemClick(null, componentToOpen);
    } catch (e) {
      console.error('Could not find componentName in details components', e);
    }
  }

  getRoute(comp: IGoldenLayoutDynamicComponent | ISideNavItem): string {
    if (this.isGoldenLayoutComponent(comp)) {
      return null;
    } else {
      return (comp as ISideNavItem).route;
    }
  }

  protected readonly EsvgFiles = EsvgFiles;
}
