<div
  style="
    display: flex;
    flex-direction: column;
    height: 100%;
  "
>
  <app-table-control
    #config
    [GUID]="guid"
    [gridOptions]="gridOptions"
    [(apiInstance)]="apiInstance"
    [actions]="configTableActions"
    (apiInstanceInitialize)="apiInstanceInitialized$.next($event)"
  ></app-table-control>
</div>
