import {Component, ViewEncapsulation} from '@angular/core';
import {CustomCellComponent} from '../custom-cell/custom-cell.component';

@Component({
  selector: 'kpi4me-autocomplete-cell-renderer',
  templateUrl: './autocomplete-cell-renderer.component.html',
  styleUrls: ['./autocomplete-cell-renderer.component.scss'],
  encapsulation: ViewEncapsulation.Emulated,
})
export class AutocompleteCellRendererComponent extends CustomCellComponent {

  getDisplayValue() {
    if (this.params.value == null) return;

    if (typeof this.params.value.value === 'string') {
      return this.params.value.value
    } else {
      return this.params.value.value.name;
    }
  }
}
