import {ModuleWithProviders, NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {ControlManagerComponent} from './control-manager/control-manager.component';
import {ControlManagerService} from './control-manager.service';
import {NewLayoutDialogComponent} from './control-manager/new-layout-dialog/new-layout-dialog.component';
import {LoadLayoutDialogComponent} from './control-manager/load-layout-dialog/load-layout-dialog.component';
import {MatDialogModule} from '@angular/material/dialog';
import {ReactiveFormsModule} from '@angular/forms';
import {SharedComponentsModule} from '../shared-components/shared-components.module';
import {CustomMaterialModule} from 'frontier/nucleus';
import {DragButtonComponent} from './control-manager/drag-button/drag-button.component';
import {AdminSettingsComponent} from "../shared-components/admin-settings/admin-settings.component";
import {DynamicFormModule} from "../control/form-control/dynamic-form/dynamic-form.module";
import {GoldenLayoutModule} from '@kpi4me/golden-angular-layout';
import {RouterModule} from "@angular/router";

@NgModule({
  declarations: [
    ControlManagerComponent,
    NewLayoutDialogComponent,
    LoadLayoutDialogComponent,
    DragButtonComponent,
    AdminSettingsComponent
  ],
  exports: [
    ControlManagerComponent
  ],
  imports: [
    CommonModule,
    RouterModule,
    MatDialogModule,
    CustomMaterialModule,
    ReactiveFormsModule,
    DynamicFormModule,
    SharedComponentsModule,
    GoldenLayoutModule,
  ]
})
export class ControlManagerModule {
  static forRoot(): ModuleWithProviders<ControlManagerModule> {
    return {
      ngModule: ControlManagerModule,
      providers: [ControlManagerService]
    }
  }
}
