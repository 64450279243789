<div class="buttonwrapper">
  <button
    mat-button
    [color]="'accent'"
    (click)="addTimestamp()"
  >
    Zeitstempel einfügen
  </button>
</div>

<kpi4me-dynamic-form-control
  #controlRef
  [GUID]="internalNoteFormGUID"
  [selectedElement]="parent"
  [apiAdapter]="internalNoteApiAdapter"
  (apiInstanceInitialize)="filterAndFetch($event, this.parent)"
  [(apiInstance)]="formApiInstance"
  [displayHeaders]="false"
  [standardForm]="true"
  (formChange)="updateFormData($event)"
></kpi4me-dynamic-form-control>
