<div class="upload-wrapper" appDropUpload (fileDropped)="onFileDrop($event)" tabindex="1" (blur)="onTouched()">
  <div class="centered" style="flex-direction: column; gap: 0.25rem">
    <div class="centered">
      <mat-icon class="blue-icon upload-icon" svgIcon="{{EsvgFiles.upload}}"></mat-icon>
      <div>Datei hier ablegen oder</div>
    </div>
    <mat-chip-listbox aria-label="File selection">
      <mat-chip-option
        style="cursor: pointer"
        color="primary"
        selected
        (click)="onUploadClick()"
      >Durchsuchen
      </mat-chip-option
      >
    </mat-chip-listbox>
    <mat-progress-bar mode="indeterminate" style="width: 100%; height: 0.5rem; margin-top: 0.5rem;"
                      *ngIf="uploading; else fileNameRef">

    </mat-progress-bar>
    <ng-template #fileNameRef>
      <div *ngIf="fileName"
        class="file-name"
      >
      <span>
        {{ fileName }}
      </span>
        <button mat-icon-button class="red-icon" (click)="onDeleteImage()">
          <mat-icon svgIcon="{{EsvgFiles.delete}}"></mat-icon>
        </button>
      </div>
    </ng-template>

  </div>
</div>
<input
  #fileUpload
  style="display: none"
  type="file"
  [accept]="accept"
  (change)="addAttachment($event)"
/>
