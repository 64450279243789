<div
  style="
    display: flex;
    flex-direction: column;
    height: 100%;"
>
  <app-table-control
    style="flex: 1"
    #invoiceTable
    [(apiInstance)]="invoiceTableApiInstance"
    (apiInstanceInitialize)="filterAndFetch($event, 'invoice')"
    [GUID]="invoiceDetailsTableGUID"
    [actions]="invoiceTableActions"
    [gridOptions]="invoiceGridOptions"
    (customAction)="onCustomInvoiceAction($event)"
  ></app-table-control>

  <app-table-control
    style="flex: 1.25"
    [hidden]="isDunningInfoHidden"
    #dunningInfoTable
    [(apiInstance)]="dunningInfoTableApiInstance"
    (apiInstanceInitialize)="filterAndFetch($event, 'dunningInfo')"
    [GUID]="dunningInfoTableGUID"
    [actions]="dunningInfoTableActions"
    [gridOptions]="dunningInfoGridOptions"
  ></app-table-control>

  <app-table-control
    style="flex: 2"
    #cartItemsTable
    [(apiInstance)]="cartItemsTableApiInstance"
    (apiInstanceInitialize)="filterAndFetch($event, 'cartItems')"
    [GUID]="cartItemsTableGUID"
    [actions]="cartItemsTableActions"
    [gridOptions]="cartItemsGridOptions"
  ></app-table-control>
</div>
