import {Observable} from 'rxjs';
import {ApiConfigService} from '../api-config.service';
import {HttpHeaders} from '@angular/common/http';

export class Style {
  http;
  url;
  httpOptions;
  constructor(protected appConfig: ApiConfigService) {
    this.http = appConfig.http;
    this.url = appConfig.url;
    this.httpOptions = appConfig.httpOptions;
  }


  GetLastLoglines() : Observable<string> {
    return this.http.get(
      this.url + '/resource/api/style/GetLastLoglines/', this.httpOptions
    ) as unknown as Observable<string>;
  }

  getReferer() : Observable<string> {
    return this.http.get(
      this.url + '/resource/api/style/getReferer/', this.httpOptions
    ) as unknown as Observable<string>;
  }

  AddLog(LogMsg: string, LogLvl: number) : Observable<boolean> {
    return this.http.post(
      this.url + '/resource/api/style/AddLog/',
      {_parameters: [LogMsg, LogLvl]}, this.httpOptions
    ) as unknown as Observable<boolean>;
  }

  version() : Observable<string> {
    return this.http.get(
      this.url + '/resource/api/style/version/', this.httpOptions
    ) as unknown as Observable<string>;
  }

  VerifyVersion(BuildNumber: number, BuildHash: string) : Observable<boolean> {
    return this.http.post(
      this.url + '/resource/api/style/VerifyVersion/',
      {_parameters: [BuildNumber, BuildHash]}, this.httpOptions
    ) as unknown as Observable<boolean>;
  }

}
