import {
  Component,
  EventEmitter,
  inject,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild
} from '@angular/core';
import {IClientsTile, IClientsTileApi} from '../conspicuity-detail/clients-tile.interface';
import {ColDef, GridApi, GridOptions} from 'ag-grid-community';
import {DatePipe, DecimalPipe} from '@angular/common';
import {Column} from 'ag-grid-community/dist/lib/entities/column';
import {CustomHeaderComponent} from './custom-header/custom-header.component';
import {AgGridAngular} from 'ag-grid-angular';
import {debounceTime, Subscription} from "rxjs";

type TField =
  'firstName' |
  'lastName' |
  'birthday' |
  'payer' |
  'identifiernumber' |
  'numberOfCarts' |
  'streetAndHouseNumber' |
  'city' |
  'score' |
  'postcode'

interface Row {
  field: string;

  [clientRowId: string]: any
}

interface FieldMapping {
  field: TField;
  value: any;
}

interface IClientData extends IClientsTile {
  signature: string;
}

@Component({
  selector: 'app-clients-comparator-table',
  templateUrl: './clients-comparator-table.component.html',
  styleUrl: './clients-comparator-table.component.scss'
})
export class ClientsComparatorTableComponent implements OnInit, OnDestroy {
  subs = new Subscription();

  @ViewChild(AgGridAngular) agGridRef: AgGridAngular;
  @Output() takeData = new EventEmitter<IClientsTileApi>();
  @Output() getAnotherClient = new EventEmitter<number>();

  datePipe = inject(DatePipe);
  numberPipe = inject(DecimalPipe);
  selectedColumn: Column;
  gridOptions: GridOptions = {
    rowHeight: 30,
    headerHeight: 45,
    defaultColDef: {
      resizable: true,
      onCellClicked: (params) => {
        this.selectedColumn = params.column;
      },
      suppressMovable: true,
      cellClass: (params) => {
        if (this.selectedColumn?.getColId() === params?.column?.getColId()) {
          return 'selected-column'
        }
        return '';
      },
      headerComponent: CustomHeaderComponent,
    },
  }

  rowData: Row[];
  colDefs: ColDef[] = [];
  context: {
    selectedHeader: Column,
    onHeaderClick: (column: Column) => void,
    onHeaderCtrlClick: (column: Column) => void;
  } = {
    selectedHeader: null,
    onHeaderClick: (column: Column) => {
      console.log(column)
      this.context.selectedHeader = column;
      this.gridApi?.refreshHeader();
    },
    onHeaderCtrlClick: (column: Column) => {
      if (column.getInstanceId() === this.context.selectedHeader?.getInstanceId()) {
        this.context.selectedHeader = null;
      } else {
        this.context.selectedHeader = column;
      }
      this.gridApi?.refreshHeader();
    },
  };
  gridApi: GridApi<any>;

  private mappedClients: IClientData[];
  private fields: { fieldName: TField, displayName: string }[] = [
    {fieldName: 'score', displayName: 'Übereinstimmung'},
    {fieldName: 'firstName', displayName: 'Vorname'},
    {fieldName: 'lastName', displayName: 'Nachname'},
    {fieldName: 'birthday', displayName: 'Geburtstag'},
    {fieldName: 'payer', displayName: 'Krankenkasse'},
    {fieldName: 'identifiernumber', displayName: 'Versichertennummer'},
    {fieldName: 'numberOfCarts', displayName: 'Anzahl Fahrten'},
    {fieldName: 'streetAndHouseNumber', displayName: 'Straße & Hausnummer'},
    {fieldName: 'postcode', displayName: 'Postleitzahl'},
    {fieldName: 'city', displayName: 'Ort'},
  ];
  private clientsApiData: IClientsTileApi[];
  columnResize$ = new EventEmitter();

  @Input() set clients(clients: IClientsTileApi[]) {
    if (clients == null) return;

    this.clientsApiData = clients;

    this.mappedClients = clients.map(clientData => {
      return {
        firstName: clientData.client.firstname,
        lastName: clientData.client.lastname,
        birthday: this.datePipe.transform(clientData?.client.birthday),
        payer: clientData.client.payerreadonly,
        identifiernumber: clientData.client.identifiernumber,
        numberOfCarts: clientData.client.cartcounter,
        city: clientData.client.houseaddress?.city,
        streetAndHouseNumber: clientData.client.houseaddress?.street,
        postcode: clientData.client.houseaddress?.postcode,
        signature: clientData.client.signature,
        score: this.numberPipe.transform(clientData.score * 100) + '%',
      }
    })
    this.colDefs = [
      {
        headerName: '',
        field: 'field',
        pinned: 'left',
        cellClass: 'attributes',
        width: 205,
      },
      ...this.mappedClients.map(c => {
        return this.mapClientToCol(c)
      })
    ];

    // Base comparator (selected patient)
    this.colDefs[1].cellClass = 'original';
    this.colDefs[1].headerClass = 'original';
    this.colDefs[1].headerName = ' (Vergleichsbasis) \n ' + this.colDefs[1].headerName;
    this.colDefs[1].pinned = 'left';

    const rows = [];
    this.fields.forEach(field => {
      const row: Row = {
        field: field.displayName
      };
      this.colDefs.slice(1).forEach(colDef => {
        const client = this.mappedClients.find(mpc => mpc.signature === colDef.field);
        row[colDef.field] = client[field.fieldName];
      })
      rows.push(row);
    })

    this.rowData = rows;
    console.log(this.rowData);
  };
  ngOnInit() {
    this.subs.add(
      this.columnResize$.pipe(
        (debounceTime(300)),
      ).subscribe(() => this.recalculateTooltipThreshold()),
    );
  }

  ngOnDestroy() {
    this.subs.unsubscribe();
  }

  private mapClientToCol(c: IClientData): ColDef {
    return {
      headerName: c.firstName + ' ' + c.lastName,
      colId: c.signature,
      field: c.signature,
      editable: false,
      tooltipValueGetter: (params) => {
        // Calculate estimated cell width based on column width
        const columnWidth = params?.column?.getActualWidth(); // Get the width of the column
        if (!columnWidth) return '';

        const threshold = (columnWidth - 34) / 12; // Set a threshold relative to the column width
        return params.value?.length > threshold ? params.value : ''
      }
    } as ColDef;
  }

  onTakeData() {
    this.takeData.emit(this.clientsApiData.find(c => c.client.signature === this.context.selectedHeader.getColId()));
  }

  getMoreClients() {
    this.getAnotherClient.emit(this.mappedClients.length);
  }

  scrollRight() {
    setTimeout(() => {
      this.gridApi.ensureColumnVisible(this.colDefs[this.colDefs.length - 1].colId, 'auto');
    })
  }

  recalculateTooltipThreshold() {
    const columnDefs = this.colDefs;
    console.log(columnDefs);
    const columnApi = this.gridOptions.columnApi;

    // Loop through each column
    this.colDefs = this.colDefs.map((column, index) => {
      return {
        ...column,
        tooltipValueGetter: (params) => {
          // Calculate estimated cell width based on column width
          const columnWidth = params?.column?.getActualWidth(); // Get the width of the column
          if (!columnWidth) return '';

          const threshold = (columnWidth - 34) / 12; // Set a threshold relative to the column width
          return params.value?.length > threshold ? params.value : ''
        }
      }
    })
  }
}
