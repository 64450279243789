import {Component, EventEmitter, HostListener, Input, Output,} from '@angular/core';
import {EsvgFiles} from 'frontier/nucleus';

@Component({
  selector: 'app-save-icon-button',
  templateUrl: './save-icon-button.component.html',
  styleUrls: ['./save-icon-button.component.css'],
})
export class SaveIconButtonComponent {
  @Input() disabled: boolean;
  @Output() customClick = new EventEmitter();

  protected readonly EsvgFiles = EsvgFiles;

  @HostListener('window:keyup.Enter', ['$event'])
  onDialogClick(event: KeyboardEvent): void {
    // this.customClick.emit(event)
  }

  @HostListener('click', ['$event'])
  onClick(btn: MouseEvent) {
    if (this.disabled) {
      btn.preventDefault();
      btn.stopPropagation();
      btn.stopImmediatePropagation();
    } else {
      this.customClick.emit(btn);
    }
  }

  constructor() {
  }
}
