import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {TextElementComponent} from './form-element/text-element/text-element.component';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {DynamicFormComponent} from './dynamic-form.component';
import {FormElementComponent} from './form-element/form-element.component';
import {BaseFormElementComponent} from './form-element/base-form-element.class';
import {SelectElementComponent} from './form-element/select-element/select-element.component';
import {CheckboxElementComponent} from './form-element/checkbox-element/checkbox-element.component';
import {RadioElementComponent} from './form-element/radio-element/radio-element.component';
import {SearchElementComponent} from './form-element/search-element/search-element.component';
import {DateElementComponent} from './form-element/date-element/date-element.component';
import {TextAreaElementComponent} from './form-element/text-area-element/text-area-element.component';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {MatSlideToggleModule} from '@angular/material/slide-toggle';
import {TextFieldModule} from '@angular/cdk/text-field';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatInputModule} from '@angular/material/input';
import {MatSelectModule} from '@angular/material/select';
import {MatRadioModule} from '@angular/material/radio';
import {MatDividerModule} from '@angular/material/divider';
import {MatButtonModule} from '@angular/material/button';
import {MatIconModule} from '@angular/material/icon';
import {NgbDatepickerModule} from '@ng-bootstrap/ng-bootstrap';
import {GermanDatepickerAdapterModule} from '../../../bootstrap-datepicker-adapter/german-datepicker-adapter.module';
import {RemoveDatepickerValidationDirective} from '../../../directives/remove-datepicker-validation.directive';
import {FileUploadElementComponent} from './form-element/file-upload-element/file-upload-element.component';
import {MatChipsModule} from '@angular/material/chips';
import {MatProgressBarModule} from '@angular/material/progress-bar';
import {DropUploadDirective} from '../../../upload-wrapper/drop-upload.directive';
import {MatTooltipModule} from '@angular/material/tooltip';
import {MatAutocompleteModule} from '@angular/material/autocomplete';
import {TreeSelectComponent} from './form-element/tree-select/tree-select.component';
import {TreeModule} from '@ali-hm/angular-tree-component';

import {OverlayModule} from '@angular/cdk/overlay';
import {SearchInputComponent} from './search-input/search-input.component';
import {TreeSelectFormDirective} from './form-element/tree-select/tree-select-form.directive';
import {MatMenuModule} from '@angular/material/menu';
import {DynamicFormControlComponent} from './dynamic-form-control/dynamic-form-control.component';
import {FormWrapperComponent} from './form-wrapper/form-wrapper.component';
import {CreateNewEntityComponent} from '../../../shared-components/create-new-entity/create-new-entity.component';
import {A11yModule} from '@angular/cdk/a11y';
import {MultipleEmailElementComponent} from './form-element/multiple-email-element/multiple-email-element.component';
import {ClearEntityComponent} from "../../../shared-components/clear-entity/clear-entity.component";

@NgModule({
  declarations: [
    DynamicFormComponent,
    FormElementComponent,
    BaseFormElementComponent,
    TextElementComponent,
    SelectElementComponent,
    CheckboxElementComponent,
    RadioElementComponent,
    SearchElementComponent,
    DateElementComponent,
    TextAreaElementComponent,
    FileUploadElementComponent,
    TreeSelectComponent,
    SearchInputComponent,
    TreeSelectFormDirective,
    DynamicFormControlComponent,
    FormWrapperComponent,
    MultipleEmailElementComponent,
  ],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    MatCheckboxModule,
    MatSlideToggleModule,
    TextFieldModule,
    MatFormFieldModule,
    MatInputModule,
    MatSelectModule,
    MatRadioModule,
    MatDividerModule,
    MatButtonModule,
    MatIconModule,
    MatChipsModule,
    NgbDatepickerModule,
    FormsModule,
    GermanDatepickerAdapterModule,
    RemoveDatepickerValidationDirective,
    MatChipsModule,
    MatProgressBarModule,
    DropUploadDirective,
    MatTooltipModule,
    MatAutocompleteModule,
    TreeModule,
    OverlayModule,
    MatMenuModule,
    CreateNewEntityComponent,
    A11yModule,
    ClearEntityComponent,
  ],
  exports: [
    DynamicFormComponent,
    TextElementComponent,
    DynamicFormControlComponent,
    SelectElementComponent,
    FormWrapperComponent,
    CheckboxElementComponent,
    TreeSelectComponent,
    RadioElementComponent,
    DateElementComponent,
    SearchElementComponent,
    MultipleEmailElementComponent,
    FileUploadElementComponent,
    FormElementComponent,
  ]
})
export class DynamicFormModule {
}
