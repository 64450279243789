import {Observable} from 'rxjs';
import {ApiConfigService} from '../api-config.service';
import {IControlObject} from '../../interfaces/control-object.interface';

export class Templateinfo {
  http;
  url;
  httpOptions;

  constructor(protected appConfig: ApiConfigService) {
    this.http = appConfig.http;
    this.url = appConfig.url;
    this.httpOptions = appConfig.httpOptions;
  }


  createmapping(InstanceId: string, template: IControlObject): Observable<boolean> {
    return this.http.post(
      this.url + '/intern/api/control/ExecuteAction/' + InstanceId + '/createmapping/', {"template": template}, this.httpOptions
    ) as unknown as Observable<boolean>;
  }

}
