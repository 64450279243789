import {ChangeDetectorRef, Component, EventEmitter, Inject} from '@angular/core';
import {FormControl} from '@angular/forms';
import {debounceTime} from 'rxjs';
import {GoldenLayoutComponent, GoldenLayoutComponentHost, GoldenLayoutContainer} from '@kpi4me/golden-angular-layout';
import {GoldenLayoutContentComponent} from '../../golden-layout/golden-layout-content/golden-layout-content.component';
import {ControlManagerService} from '../../control-manager/control-manager.service';
import {ITextFormElement} from '../../control/form-control/dynamic-form/form-element/form-data.interface';
import GoldenLayout from '@kpi4me/golden-layout';

@Component({
  selector: 'kpi4me-guid-table',
  templateUrl: './guid-table.component.html',
  styleUrls: ['./guid-table.component.scss'],
})
export class GuidTableComponent extends GoldenLayoutContentComponent {
  tableGuid = new FormControl('');
  tableGuidFormData: ITextFormElement = {
    label: 'Tabellen selectionGUID',
    inputType: 'text',
    required: false
  };
  render = false;
  private tableGuidChange$ = new EventEmitter();

  constructor(
    @Inject(GoldenLayoutComponentHost) protected goldenLayout: GoldenLayoutComponent,
    @Inject(GoldenLayoutContainer) protected container: GoldenLayout.Container,
    protected cdr: ChangeDetectorRef,
    protected controlManager: ControlManagerService
  ) {
    super(goldenLayout, container, cdr, controlManager);

    this.subs.add(
      this.tableGuidChange$.pipe(debounceTime(2000)).subscribe((guid) => {
        this.render = false;
        this.cdr.detectChanges();
        this.render = true;
        this.cdr.detectChanges();
        console.log('guid change', guid);
      })
    )
  }

  protected onResize(): void {
  }

  onTableGuidChange(guid: string) {
    this.tableGuidChange$.emit(guid)
  }
}
