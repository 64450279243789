<button
  (click)="onClick()"
  [class.instantiated]="instanstiated == true"
  mat-stroked-button
>
  <span>{{ data.title }}</span>
  <mat-icon
    (click)="onCloseInstances($event)"
    [matTooltip]="'Alle Instanzen schließen'"
    [svgIcon]="EsvgFiles.close"
  ></mat-icon>
</button>
