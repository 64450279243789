import {Component} from '@angular/core';
import {CustomCellComponent} from '../custom-cell/custom-cell.component';
import {
  ColorCellEditorComponent
} from 'frontier/browserkit/src/lib/control/table-control/editors/color-cell-editor/color-cell-editor.component';

@Component({
  selector: 'app-color-cell-renderer',
  templateUrl: './color-cell-renderer.component.html',
  styleUrls: ['./color-cell-renderer.component.scss'],
})
export class ColorCellRendererComponent extends CustomCellComponent {
  onDoubleClick() {
    this.params.api.startEditingCell({
      rowIndex: this.params.rowIndex,
      colKey: this.params.column,
    });
    const cellEditor = this.params.api.getCellEditorInstances({
      rowNodes: [this.params.node],
      columns: [this.params.column],
    });
    (cellEditor[0] as ColorCellEditorComponent)?.colorPickerComponent.openDialog();
  }
}
