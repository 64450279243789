<div
  class="mat-h4 header"
  [ngClass]="state == 'full-width' ? 'label' : 'hoverable-icon'"
  style="display: flex; align-items: center"
>
  <span
    class="icon-wrapper"
    [style.width.px]="showIcons ? 24 : 2"
  >
    <ng-container *ngIf="showIcons && icon">
      <mat-icon
        style="display: flex"
        [ngClass]="state == 'full-width' ? 'sidebar-icon' : 'hoverable-icon'"
        [svgIcon]="icon"
      ></mat-icon>
    </ng-container>
  </span>

  <span class="title">
    {{title}}
  </span>

  <button
    *ngIf="state === 'full-width'"
    mat-icon-button
    class="icon-wrapper"
    style="cursor: pointer; margin-left: auto;"
    [style.width.px]="showIcons ? 40 : 2"
    (click)="toggleOpenClosed()"
  >
    <mat-icon
      class="centered"
      [svgIcon]="getOpenCloseIcon()"
    ></mat-icon>
  </button>
</div>
