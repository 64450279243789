import {InjectionToken} from '@angular/core';
import {IEnvironment} from './interfaces/environment.interface';

export interface IApiConfig {
  url: string;
  httpOptions: any;
  environment?: IEnvironment;
}

export const API_CONFIG_TOKEN = new InjectionToken<IApiConfig>('api-config');
