import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {FormControl} from "@angular/forms";

@Component({
  selector: 'app-save-changes-dialog',
  templateUrl: './confirmation-dialog.component.html',
  styleUrls: ['../shared-dialogs.scss'],
})
export class ConfirmationDialogComponent implements OnInit {
  title: string;
  description: string;
  confirmButtonText: string;
  cancelButtonText: string;
  email: string;
  showCustomCheckBox: boolean;
  checkBoxText: string = '';
  checkBoxValue = new FormControl(false);

  constructor(
    public dialogRef: MatDialogRef<ConfirmationDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: ConfirmationDialogComponent
  ) {
  }

  ngOnInit() {
    return;
  }

  onConfirm() {
    if (this.data.showCustomCheckBox === true) {
      this.dialogRef.close({result: true, checkBox: this.checkBoxValue.value});
    } else {
      this.dialogRef.close(true);
    }
  }

  onCancel() {
    if (this.data.showCustomCheckBox === true) {
      this.dialogRef.close({result: false, checkBox: this.checkBoxValue.value});
    } else {
      this.dialogRef.close(false);
    }
  }
}
