<div
  style="
    display: flex;
    flex-direction: column;
    height: 100%;
  "
>
  <app-table-control
    #table
    [(apiInstance)]="tableApiInstance"
    (apiInstanceInitialize)="tableApiInstance$.next($event)"
    [GUID]="clientGUID"
    [actions]="tableActions"
    [gridOptions]="gridOptions"
    [fixedPageSize]="fixedPageSize"
    (rowSelected)="clientDetails($event)"
  ></app-table-control>

</div>
