<!--
<mat-form-field appearance="outline" class="form-field-no-padding mat-form-field-appearance-outline" >
  <div style="display: flex">
<input matInput
  #input="ngbDatepicker"
  (dateSelect)="onDateChange($event)"
  [startDate]="startDate"
  [formControl]="formControl"
  ngbDatepicker
  [type]="'text'"
  placeholder="TT.MM.JJJJ"

/>
<span style="height: 12px;
top: -5px;
position: relative;" fxLayout="row">
  <mat-icon
    (click)="input.toggle()"
    svgIcon="{{EsvgFiles.calendar}}"
    class="blue-icon"
  ></mat-icon>
  <mat-icon *ngIf="formControl.status !== 'VALID'"
            svgIcon="warning" class="red-icon"
            [matTooltip]="'Kein gültiges Datum im Format TT.MM.JJJJ'"
  >
  </mat-icon>
</span>
  </div>
</mat-form-field>
-->
<kpi4me-date-element class="form-field-no-padding"
                     [formControlElement]="formControl"
>

</kpi4me-date-element>


