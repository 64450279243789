<mat-toolbar class="primary-toolbar" *ngIf="state">
  <!-- Span for logo, product name, and version number -->
  <span class="left">
    <ng-container *ngIf="productLogoPath == null; else productLogo">
      <img
        style="height: 100%;"
        [src]="themeService.themeClass === 'drk-theme' ? 'assets/images/drk-logo.png' : companyLogoPath"
        alt="Firmenlogo"
      >

      <span class="label-wrapper">
        <span
          class="product-label"
        >
          {{ productName }}
        </span>

        <span class="version-label">
          {{ version }}
        </span>
      </span>
    </ng-container>

    <ng-template #productLogo>
        <img
          class="product-logo"
          [src]="productLogoPath"
          alt="Produktlogo"
        >
    </ng-template>

    <div
      class="centered"
      style="padding: 0 10px"
    >
      <!-- Calendar Type selection -->
      <mat-button-toggle-group
        class="calendar-type-wrapper"
        [(value)]="state.calendarType"
        (change)="onCalenderTypeChange($event)"
      >
        <mat-button-toggle [value]="ECalenderType.classic">
          Kalender
        </mat-button-toggle>

        <mat-button-toggle [value]="ECalenderType.new">
          Zeitbereiche
        </mat-button-toggle>
      </mat-button-toggle-group>
    </div>

    <div class="calendar-wrapper">
      <kpi4me-date-picker
        (configurationChange)="onEasyDatePickerChange($event)"
        *ngIf="state.calendarType === ECalenderType.new; else classicCalender"
        [(configuration)]="state.easyDatePicker"
      ></kpi4me-date-picker>

      <ng-template #classicCalender>
        <kpi4me-calendar
          (dateChange)="onDateChange($event)"
          [config]="state.classicalCalendar"
          [selectedGranularity]="state.classicalCalendar.granularity"
          [lowestGranularity]="lowestGranularity"
          [showGranularityButtons]="true"
        ></kpi4me-calendar>
      </ng-template>
    </div>

    <div class="text-filter-form">
        <span style="margin-right: 0.5rem; margin-left: auto; display: flex; align-items: center">
             <mat-icon
               style="display: flex; justify-content: center"
               [svgIcon]="EsvgFiles.search"
               matPrefix
             ></mat-icon>
        </span>

        <span>
             <input
               [formControl]="textFilterControl"
               type="text"
               placeholder="Volltextfilter"
               name="textFilterForm"
               (focus)="onFocusFullTextFilter(textFilterControl.value)"
               (blur)="checkForFullTextFilterChanges(textFilterControl.value)"
               (keydown.enter)="checkForFullTextFilterChanges(textFilterControl.value)"
             >
        </span>


        <button style="display: flex;" class="unstyled-button centered" mat-ripple (click)="onClear()">
                    <mat-icon
                      [svgIcon]="EsvgFiles.close"
                      style="display: flex; justify-content: center; cursor: pointer;"
                      matSuffix>
                    </mat-icon>
        </button>

    </div>
  </span>

  <!-- Span for custom actions -->
  <span *ngIf="customToolbarActions" class="middle">
    <div>
      <ng-container *ngFor="let action of customToolbarActions">
        <mat-icon
          (click)="customToolbarActionClicked.emit(action.id)"
          class="light-icon"
          [svgIcon]="action.icon"
          [matTooltip]="action.tooltip"
        ></mat-icon>

        <span
          class="product-label"
          style="color: var(--toolbar-text); font-family: Poppins, sans-serif;font-weight: 400;font-size: 10px;vertical-align: middle;"
        >
          {{ action.label }}
        </span>
      </ng-container>
    </div>
  </span>

  <!-- Span for menu -->
  <span class="right">
      <kpi4me-select-element
        style="height: 30px; width: 160px; background-color: var(--select-background)"
        [createNewEnabled]="true"
        [copyEnabled]="true"
        [singleStringArray]="true"
        [multiple]="false"
        [arrowPosition]="'right'"
        [deleteTooltip]="'Layout löschen'"
        [editTooltip]="'Layout umbenennen'"
        [copyTooltip]="'Layout duplizieren'"
        [emptyOptionAdded]="false"
        [newButtonLabel]="'Layout erstellen'"
        [formControlElement]="layoutSelectFormControl"
        [options]="options"
        [icon]="EsvgFiles.copy"
        (newElementClick)="onNew($event)"
        (deleteElementClick)="onDelete($event)"
        (changeElementClick)="onChange($event)"
        (copyElementClick)="onCopy($event)"
        (modelChange)="onModelChange($event)"
      ></kpi4me-select-element>

      <div class="icon-button-wrapper">
        <mat-icon
          (click)="onResetLayout()"
          matTooltip="Setzt alle globalen und lokalen Filter auf ihren Anfangsstatus zurück und stellt das urspüngliche
          Tab-Layout wiederher."
          class="light-icon"
          svgIcon="{{EsvgFiles.refresh}}"
        ></mat-icon>

        <mat-icon
          (click)="onSaveLayout()"
          class="light-icon"
          svgIcon="{{EsvgFiles.save}}"
        ></mat-icon>
      </div>

    <!--            <div class="icon-button-wrapper">-->
    <!--              <mat-icon-->
    <!--                      (click)="toggleSecondaryToolbar()"-->
    <!--                      class="light-icon"-->
    <!--                      svgIcon="{{EsvgFiles.pin}}"-->
    <!--              ></mat-icon>-->
    <!--            </div>-->

    <span class="label-wrapper">
      <span class="product-label">
        {{ tenant }}
      </span>

      <span class="version-label" *ngIf="userMenu.user">
        {{ userMenu.user.firstname + ' ' + userMenu.user.lastname }}
      </span>
    </span>

    <button class="user-icon" color="primary"
      mat-icon-button
      [matMenuTriggerFor]="userMenu?.menuRef"
    >
      <mat-icon svgIcon="{{EsvgFiles.user}}"></mat-icon>
    </button>
  </span>
</mat-toolbar>

<mat-toolbar *ngIf="state"
             [hidden]="!showSecondaryToolbar"
             class="secondary-toolbar"
>
  <div class="centered">
    <!-- Calendar Type selection -->
    <mat-button-toggle-group
      class="calendar-type-wrapper"
      [(value)]="state.calendarType"
      (change)="onCalenderTypeChange($event)"
    >
      <mat-button-toggle [value]="ECalenderType.classic">
        Kalender
      </mat-button-toggle>

      <mat-button-toggle [value]="ECalenderType.new">
        Zeitbereiche
      </mat-button-toggle>
    </mat-button-toggle-group>
  </div>

  <div class="calendar-wrapper">
    <kpi4me-date-picker
      (configurationChange)="onEasyDatePickerChange($event)"
      *ngIf="state.calendarType === ECalenderType.new; else classicCalender"
      [(configuration)]="state.easyDatePicker"
    ></kpi4me-date-picker>

    <ng-template #classicCalender>
      <kpi4me-calendar
        (dateChange)="onDateChange($event)"
        [config]="state.classicalCalendar"
        [selectedGranularity]="state.classicalCalendar.granularity"
        [lowestGranularity]="lowestGranularity"
        [showGranularityButtons]="true"
      ></kpi4me-calendar>
    </ng-template>
  </div>

  <div class="text-filter-form">
    <mat-icon svgIcon="{{EsvgFiles.search}}"></mat-icon>
    <input
      [formControl]="textFilterControl"
      type="text"
      placeholder="Volltextfilter"
      name="textFilterForm"
      (focusout)="updateTextFilter(textFilterControl.value)"
      (keydown.enter)="updateTextFilter(textFilterControl.value)">
  </div>
</mat-toolbar>

<app-user-menu
  [showTenantMenu]="showTenantMenu"
  [menuOptions]="menuOptions"
  [helpUrl]="helpUrl"
  [moduleFilters]="moduleFilters"
  (optionTriggered)="optionTriggered.emit($event)"
  (logout)="logout.emit()"
  (themeToggle)="themeToggle.emit()"
  (applyTheme)="applyTheme.emit($event)"
></app-user-menu>
