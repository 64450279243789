import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {NgbDateAdapter, NgbDateParserFormatter,} from '@ng-bootstrap/ng-bootstrap';
import {GermanDatepickerAdapter, GermanDatepickerFormatter,} from './german-datepicker-adapter.service';

@NgModule({
  declarations: [],
  imports: [CommonModule],
  providers: [
    {provide: NgbDateAdapter, useClass: GermanDatepickerAdapter},
    {provide: NgbDateParserFormatter, useClass: GermanDatepickerFormatter},
  ],
})
export class GermanDatepickerAdapterModule {
}
