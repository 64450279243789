import {
  ChangeDetectorRef,
  Component,
  Input,
  OnChanges,
  SimpleChanges,
  ViewChild,
  ViewEncapsulation
} from '@angular/core';
import {
  ControlManagerService,
  IApiRow,
  IApiTableData,
  ITableVerticalDataRow,
  TableVerticalApiAdapter,
  TableVerticalControlComponent
} from 'frontier/browserkit';
import {CoreService, EcalculusControlGUID, IApiAdapter, IControlObject} from 'frontier/nucleus';

class PersonFormApiAdapter extends TableVerticalApiAdapter {
  from(apiData: IApiTableData): IApiTableData {
    return {
      ...apiData,
      displayedColumns: apiData.displayedColumns.slice(0, 4),
    };
  }
}

class AddressFormApiAdapter extends TableVerticalApiAdapter {
  from(apiData: IApiTableData): IApiTableData {
    return {
      ...apiData,
      displayedColumns: apiData.displayedColumns.slice(4, 8),
    };
  }
}

class InsuranceFormApiAdapter extends TableVerticalApiAdapter {
  from(apiData: IApiTableData): IApiTableData {
    return {
      ...apiData,
      displayedColumns: apiData.displayedColumns.slice(8),
    };
  }
}

@Component({
  selector: 'kpi4me-client-detail',
  templateUrl: './client-detail.component.html',
  styleUrls: ['./client-detail.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ClientDetailComponent implements OnChanges {
  @Input() parent: IControlObject;
  @ViewChild('personForm') personFormRef: TableVerticalControlComponent;
  @ViewChild('addressForm') addressFormRef: TableVerticalControlComponent;
  @ViewChild('insuranceForm') insuranceFormRef: TableVerticalControlComponent;
  personFormApiInstance: any;
  addressFormApiInstance: any;
  insuranceFormApiInstance: any;
  clientListGUID = EcalculusControlGUID.ClientList;
  personFormApiAdapter: IApiAdapter = new PersonFormApiAdapter();
  addressFormApiAdapter: IApiAdapter = new AddressFormApiAdapter();
  insuranceFormApiAdapter: IApiAdapter = new InsuranceFormApiAdapter();
  @Input() showImports = true;

  constructor(
    protected cdr: ChangeDetectorRef,
    protected controlManager: ControlManagerService,
    protected coreService: CoreService,
  ) {
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.parent) {
      if (this.personFormRef) {
        this.filterAndFetch(this.personFormApiInstance, 'person');
      }
      if (this.addressFormRef) {
        this.filterAndFetch(this.addressFormApiInstance, 'address');
      }
      if (this.insuranceFormRef) {
        this.filterAndFetch(this.insuranceFormApiInstance, 'insurance');
      }
    }
  }

  filterAndFetch(apiInstance: any, id: string): void {
    if (this.parent) {
      switch (id) {
        case 'person':
          this.personFormApiInstance = {
            ...apiInstance,
            filter: {
              ...this.personFormApiInstance?.filter,
              parent: this.parent,
              typename: 'cart',
            },
          };
          this.cdr.detectChanges();
          this.personFormRef.changeAndFetch().subscribe();
          break;
        case 'address':
          this.addressFormApiInstance = {
            ...apiInstance,
            filter: {
              ...this.addressFormApiInstance?.filter,
              parent: this.parent,
              typename: 'cart',
            },
          };
          this.cdr.detectChanges();
          this.addressFormRef.changeAndFetch().subscribe();
          break;
        case 'insurance':
          this.insuranceFormApiInstance = {
            ...apiInstance,
            filter: {
              ...this.insuranceFormApiInstance?.filter,
              parent: this.parent,
              typename: 'cart',
            },
          };
          this.cdr.detectChanges();
          this.insuranceFormRef.changeAndFetch().subscribe();
          break;
      }
    }
  }

  protected onResize(): void {
  }

  onCellChanged(evt: { apiRow: IApiRow; verticalRow: ITableVerticalDataRow }) {
    if (evt.verticalRow.attribute === "PayerType") {
      let columnToChangeRepository: ITableVerticalDataRow;
      let indexOfColumnToChangeRepository: number;
      this.insuranceFormRef.rows.forEach((col, index) => {
        if (col.attribute === "DynamicAlternativePayer") {
          indexOfColumnToChangeRepository = index;
          columnToChangeRepository = col;
        }
      });
      if (columnToChangeRepository) {
        this.coreService.Clientlist.getrepository(
          this.insuranceFormRef.apiInstance.instanceid,
          "DynamicAlternativePayer",
          columnToChangeRepository.attributeIndex,
        ).subscribe((res) => {
          this.insuranceFormRef.rows[indexOfColumnToChangeRepository] = {
            ...this.insuranceFormRef.rows[indexOfColumnToChangeRepository],
            repository: res,
          };
        });
      }
    }
  }
}
