import { Component } from '@angular/core';
import {EvaluationComponent} from '../evaluation.component';
import { ECalculusStateFilter } from '../../config/state-filter.enum';

@Component({
  selector: 'kpi4me-client-access',
  templateUrl: './client-access.component.html',
  styleUrl: '../evaluation.component.scss'
})
export class ClientAccessComponent extends EvaluationComponent {

  protected _evaluationData: [number];
  protected evaluationDisplayPercentages: [string];
  protected evaluationDisplayAbsolute: [string];
  protected readonly ECalculusStateFilter = ECalculusStateFilter;

}
