<input type="text" style="width: 100%;"
       #input
       placeholder="Suche"
       [formControl]="textFormControl"
       [matAutocomplete]="auto"
>
<mat-autocomplete #auto="matAutocomplete" (optionSelected)="onOptionSelected()" [displayWith]="displayWith">
  <mat-option *ngFor="let entity of filteredEntities | async" [value]="entity.apiRow"
              [matTooltip]="entity.apiRow.name"
              matTooltipPosition="left"
  >
    {{entity.apiRow.name}}
  </mat-option>
</mat-autocomplete>
