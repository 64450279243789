<div
  style="
    display: flex;
    flex-direction: column;
    height: 100%;
  "
>
  <app-table-control
    #config
    [GUID]="configGuid"
    [gridOptions]="configGridOptions"
    [(apiInstance)]="configTableApiInstance"
    [actions]="configTableActions"
    (apiInstanceInitialize)="config.changeAndFetch().subscribe()"
    (rowSelected)="filterDunningLevels($event)"
    (rowDeselected)="unfilterDunningLevels()"
  ></app-table-control>

  <app-table-control
    #level
    [hidden]="!(this._dunningConfigSelectedSource | async)"
    [GUID]="levelGuid"
    [gridOptions]="levelGridOptions"
    [(apiInstance)]="levelTableApiInstance"
    (apiInstanceInitialize)="level.changeAndFetch().subscribe()"
  ></app-table-control>
</div>
