import {ChangeDetectorRef, Component, EventEmitter, Inject, ViewChild} from '@angular/core';
import {CoreService, DefaultApiAdapter, EControlActions, IApiControl, IControlObject,} from 'frontier/nucleus';
import {BehaviorSubject, combineLatest, debounceTime, filter, mergeMap, of, tap} from 'rxjs';
import {GoldenLayoutComponent, GoldenLayoutComponentHost, GoldenLayoutContainer,} from '@kpi4me/golden-angular-layout';
import {FormControl} from '@angular/forms';
import GoldenLayout from '@kpi4me/golden-layout';
import {GoldenLayoutContentComponent} from '../../golden-layout/golden-layout-content/golden-layout-content.component';
import {ControlManagerService} from '../../control-manager/control-manager.service';
import {
  DynamicFormControlComponent,
  ICheckboxFormElement,
  ISelectFormElement,
  ITextFormElement
} from 'frontier/browserkit';

@Component({
  selector: 'kpi4me-guid-form',
  templateUrl: './guid-form.component.html',
  styleUrls: ['./guid-form.component.scss'],
})
export class GuidFormComponent extends GoldenLayoutContentComponent {
  @ViewChild('controlRef', {static: false}) formRef: DynamicFormControlComponent;
  hasRepo = new FormControl(false);
  formGuid = new FormControl('');
  repoSelectedElement = new FormControl(null);

  textElementData: ITextFormElement = {
    label: 'Formular selectionGUID',
    inputType: 'text',
    required: true
  };
  render = false;
  checkboxData: ICheckboxFormElement = {
    label: 'Mit Repository',
    value: true,
    displayAs: 'toggle'
  };
  repoSelectionData: ISelectFormElement = {
    label: 'Repository',
    options: [],
    createNewEnabled: true,
    multiple: false,
  };

  hasRepo$ = new BehaviorSubject(this.hasRepo.value);
  initializedFormControl$ = new BehaviorSubject<any>(null);
  private formGuidChange$ = new EventEmitter<string>();
  apiAdapter = new DefaultApiAdapter();

  constructor(
    @Inject(GoldenLayoutComponentHost) protected goldenLayout: GoldenLayoutComponent,
    @Inject(GoldenLayoutContainer) protected container: GoldenLayout.Container,
    protected cdr: ChangeDetectorRef,
    protected controlManager: ControlManagerService,
    private coreService: CoreService
  ) {
    super(goldenLayout, container, cdr, controlManager);

    this.subs.add(
      combineLatest([
        this.hasRepo$.pipe(filter(v => v == true)),
        this.initializedFormControl$.pipe(filter(v => v != null))
      ]).pipe(
        mergeMap(() => this.updateRepo(null))
      ).subscribe()
    );

    this.subs.add(
      this.initializedFormControl$.pipe(filter(() => this.hasRepo.value == false)).subscribe(() => {
        this.formRef?.changeAndFetch().subscribe();
      })
    )

    this.subs.add(
      this.formGuidChange$.pipe(debounceTime(2000)).subscribe((guid) => {
        this.render = false;
        this.cdr.detectChanges();
        this.render = true;
        this.cdr.detectChanges();
        console.log('guid change', guid);
      })
    )

    this.subs.add(
      this.repoSelectedElement.valueChanges.subscribe(v => {
        if (v) {
          this.formRef.apiInstance.filter = {...this.formRef.apiInstance.filter, parent: v};
          this.formRef.changeAndFetch().subscribe();
        }
        console.log(v);
      })
    )
  }

  protected onResize(): void {
  }


  onFormGuidChange(guid: string) {
    this.formGuidChange$.emit(guid);
  }

  onHasRepoChanged(b: boolean) {
    console.log('has repo changed', b);
    this.hasRepo$.next(b);
  }

  updateRepo(action: EControlActions) {
    console.log('updating repo');
    if (this.hasRepo.value == false) {
      return of(null);
    } else {
      return this.coreService.ActionForm.getrepository(this.formRef.apiInstance.instanceid).pipe(
        tap((repository) => {
          this.repoSelectionData = {
            ...this.repoSelectionData,
            ...repository,
            options: repository.options
          };
          if (action) {
            this.coreService.controlDataChanged.emit({GUID: this.formGuid.value, changeType: action});
          }
        })
      )
    }
  }

  onInizializedDynamicFormControl(event: IApiControl) {
    console.log('Inizialized dynamic form control');
    this.initializedFormControl$.next(event)
  }

  onNewElement() {
    this.coreService.ActionForm.newelement(this.formRef.apiInstance.instanceid, null, null)
      .subscribe(res => {
        this.updateRepo(EControlActions.create).subscribe(() => {
          this.repoSelectedElement.patchValue(res);
        })
      })
  }

  onDeleteElement(evt: IControlObject) {
    this.coreService.ActionForm.deleteelement(this.formRef.apiInstance.instanceid, evt)
      .subscribe(res => {
        this.updateRepo(EControlActions.delete).subscribe(() => {
          if (res) {
            this.repoSelectedElement.patchValue(null);
          }
        })
      })
  }
}
