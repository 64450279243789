<div class="mat-h3">
  Rechnungsdatenkorrektur durch den Patienten
  <div class="mat-caption">
    Der Patient hat Daten korrigiert.
  </div>
</div>
@if (rowData()?.length > 0) {
  <ag-grid-angular [style.height.px]="30 * 8"
                   [gridOptions]="gridOptions"
                   [rowData]="rowData()"
                   [columnDefs]="colDefs"
                   (gridReady)="this.gridApi = $event.api"
                   class="ag-theme-alpine hide-header"
  ></ag-grid-angular>
}
@if (displayAction()) {
  <div>
    <div>
      Aktionsbeschreibung
    </div>
    <div style="white-space: pre-line;">
      {{actionDescription()}}
    </div>
  </div>
  <div class="flex" style="gap: 0.5rem; padding-top: 0.5rem">
    <!-- Resolve automatically -->
    <button mat-raised-button color="accent" (click)="onResolveAutomatically()">
      Ausführen
    </button>

    <!-- Resolve manually -->
    <button mat-raised-button color="accent" (click)="onResolveManually()">
      Manuell Bearbeiten / Ignorieren
    </button>
  </div>
}

@if (documentAssigned()) {
  <div style="padding-top: 0.5rem;">
    <button mat-raised-button color="primary" (click)="onShowDocument()">
      Dokument anzeigen
    </button>
  </div>
}

