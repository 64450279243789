import {Component, EventEmitter, Input, OnDestroy, OnInit, ViewChild, ViewEncapsulation} from '@angular/core';
import {IControlObject} from 'frontier/nucleus';
import {MatTabGroup} from "@angular/material/tabs";
import {debounceTime, Subscription} from "rxjs";
import {CalculusStateService} from '../../Services/calculus-state.service';
import {tap} from 'rxjs/operators';
import {IClientApi} from '../conspicuity-detail/clients-tile.interface';
import {ConspicuityDetailComponent} from '../conspicuity-detail/conspicuity-detail.component';

@Component({
  selector: 'kpi4me-cart-details',
  templateUrl: './cart-details.component.html',
  styleUrls: ['./cart-details.component.scss'],
  encapsulation: ViewEncapsulation.Emulated
})
export class CartDetailsComponent implements OnInit, OnDestroy {
  @Input() cartParent: IControlObject;
  @Input() clientParent: IControlObject;
  @ViewChild('tabGroup') tabRef: MatTabGroup;
  @ViewChild(ConspicuityDetailComponent, {static: false}) conspicuityDetail: ConspicuityDetailComponent;
  showImports = true;
  subs = new Subscription();
  private sizeChanged = new EventEmitter();

  constructor(
    private calculusStateService: CalculusStateService
  ) {}

  ngOnInit(): void {
    this.subs.add(
      this.sizeChanged.pipe(
        debounceTime(300),
      ).subscribe(() => this.tabRef.updatePagination()),
    );

    this.subs.add(
      this.calculusStateService.showImportedValues$.pipe(tap((v) => {
        this.showImports = v;
      })).subscribe()
    )
  }

  ngOnDestroy() {
    this.subs.unsubscribe();
  }

  updatePagination() {
    this.sizeChanged.emit();
  }

  onClientReplaced(event: { client: IClientApi; cart: IControlObject }) {
    this.calculusStateService.replacedClientForCart$.next(event);
  }
}
