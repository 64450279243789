import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {ControlRendererComponent} from './control-renderer.component';

@NgModule({
  declarations: [ControlRendererComponent],
  exports: [ControlRendererComponent],
  imports: [CommonModule],
})
export class ControlRendererModule {
}
