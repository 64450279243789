<app-dialog-header [title]="'Einsatz erstellen'" (close)="dialogRef.close()"></app-dialog-header>
<div mat-dialog-content>
  <div>
    <p>Was ist die Einsatznummer?</p>
    <kpi4me-text-element
      [customValidatorMessage]="customValidatorErrorMessages"
      (keydown.backspace)="onBackspace($event)"
      [formControlElement]="form.controls.missionNumber" [data]="missionNumberData"
      [patternErrorMessage]="'Die Transportnummer muss im Format \<Jahr\>|\<AuftragsNr\> eingegeben werden'"
    ></kpi4me-text-element>
  </div>
  <div>
    <p>Datum des Einsatzes</p>
    <kpi4me-date-element [formControlElement]="form.controls.date" [data]="dateData"></kpi4me-date-element>
  </div>
  <div>
    <p>Zu welchem Geschäftsfeld gehört der Einsatz?</p>
    <kpi4me-select-element [formControlElement]="form.controls.businessAreaSignature"
                           [data]="selectBusinessAreaData"></kpi4me-select-element>
  </div>
</div>
<div mat-dialog-actions>
  <app-confirm-icon-button [disabled]="form.invalid || form.pending"
                           (customClick)="dialogRef.close(form.value)"></app-confirm-icon-button>
</div>
