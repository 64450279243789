import {
  ChangeDetectorRef,
  Component,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
  ViewChild,
  ViewEncapsulation
} from '@angular/core';
import {CoreService, EcalculusControlGUID, IApiControl, IControlObject, IUser} from 'frontier/nucleus';
import {DynamicFormControlComponent, FormApiAdapter, IKeyToStyleMap, UserMenuComponent} from 'frontier/browserkit';
import {DatePipe} from "@angular/common";
import {filter, take, tap} from "rxjs/operators";

class InternalNoteApiAdapter extends FormApiAdapter {
  styles: IKeyToStyleMap = {
    ['InternalNotes;-1']: {
      textAreaInitialRowCount: 14,
    }
  }
}

@Component({
  selector: 'kpi4me-internal-note-detail',
  templateUrl: './internal-note-detail.component.html',
  styleUrls: ['./internal-note-detail.component.scss'],
  encapsulation: ViewEncapsulation.Emulated
})
export class InternalNoteDetailComponent implements OnInit, OnChanges {

  @Input() parent: IControlObject;
  @ViewChild('controlRef') formRef: DynamicFormControlComponent;
  @ViewChild(UserMenuComponent, {static: true}) userMenu: UserMenuComponent;
  user: IUser;

  internalNoteFormGUID = EcalculusControlGUID.InternalNotes;
  internalNoteApiAdapter = new InternalNoteApiAdapter();
  formApiInstance: IApiControl;
  formData: any;

  constructor(
    protected cdr: ChangeDetectorRef,
    protected coreService: CoreService,
    private datePipe: DatePipe
  ) {
  }

  ngOnInit(): void {
    this.coreService.User.GetLoginData().subscribe((user: IUser) => {
      this.user = user;
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.parent && this.formRef) {
      this.formRef.isFormChanging$.pipe(
        filter(v => {
          return v === false
        }),
        take(1),
        tap(() => {
          this.filterAndFetch(this.formApiInstance, changes.parent.currentValue);
        })
      ).subscribe()
    }
  }

  filterAndFetch(apiInstance: IApiControl, parent: any): void {
    if (parent) {
      this.formApiInstance = {
        ...apiInstance,
        filter: {
          ...this.formApiInstance?.filter,
          parent: parent,
          typename: 'cart',
        },
      };
      this.cdr.detectChanges();
      this.formRef.changeAndFetch().subscribe((res) => {
        this.formData = res;
      });
    }
  }

  addTimestamp() {
    const formGroup = this.formData.formGroups[0];
    const elements = formGroup.elements;
    elements[0].value = elements[0].value + '\n' + this.datePipe.transform(new Date(), 'dd.MM.yyyy hh:mm') + ' ' +
      this.user.firstname + ' ' + this.user.lastname + ':\n';
    this.formRef.onFormChange({elements: elements, formGroup: formGroup});
  }

  updateFormData(evt: any) {
    this.formData = evt;
  }
}
