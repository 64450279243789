import {AfterViewInit, Component, ViewChild} from '@angular/core';
import {CustomCellEditorComponent} from '../custom-cell-editor/custom-cell-editor.component';
import {ColorPickerDirective} from 'ngx-color-picker';

@Component({
  selector: 'app-color-cell-editor',
  templateUrl: './color-cell-editor.component.html',
  styleUrls: ['./color-cell-editor.component.scss'],
})
export class ColorCellEditorComponent extends CustomCellEditorComponent implements AfterViewInit {
  @ViewChild(ColorPickerDirective) colorPickerComponent: ColorPickerDirective;
  toggle: boolean;

  ngAfterViewInit() {
    // focus on the input
    setTimeout(() => {
      this.colorPickerComponent.openDialog();
    });
  }

  isPopup(): boolean {
    return true;
  }

  onColorPickerClosed($event: string) {
    if ($event !== this.textFormControl.value) {
      this.textFormControl.patchValue($event);
      this.saveValue();
    }
    this.params.stopEditing();
  }
}
