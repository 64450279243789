import {ChangeDetectorRef, Component, Inject, ViewChild} from '@angular/core';
import {
  CalendarService,
  ControlManagerService, CSV_EXPORT_ACTION,
  DEFAULT_TABLE_ACTIONS,
  DialogService,
  FeedbackService,
  GoldenLayoutContentComponent,
  IAction,
  SharedGridOptions,
  TableControlComponent,
} from 'frontier/browserkit';
import {CoreService, EcalculusControlGUID, EControlActions, EsvgFiles, IApiControl} from 'frontier/nucleus';
import {GridOptions} from 'ag-grid-community';
import {GoldenLayoutComponent, GoldenLayoutComponentHost, GoldenLayoutContainer} from '@kpi4me/golden-angular-layout';
import GoldenLayout from '@kpi4me/golden-layout';
import {
  DatePickerDialogComponent
} from 'frontier/browserkit/src/lib/dialogs/date-picker-dialog/date-picker-dialog.component';
import {combineLatest, concatMap, debounceTime, Observable, of, Subject, switchMap} from 'rxjs';
import {filter, tap} from 'rxjs/operators';

@Component({
  selector: 'app-tariff-changes',
  templateUrl: './tariff-changes.component.html',
  styleUrl: './tariff-changes.component.scss'
})
export class TariffChangesComponent extends GoldenLayoutContentComponent{
  @ViewChild(TableControlComponent) tableRef: TableControlComponent;
  apiInstance: IApiControl;
  guid = EcalculusControlGUID.ItemPriceHistoryView;
  gridOptions: GridOptions = {
    ...SharedGridOptions,
    rowSelection: 'multiple',
  };
  actions: IAction[] = [
    DEFAULT_TABLE_ACTIONS[1],
    {
      displayName: 'Tarifänderung',
      controlAction: EControlActions.tariffChange,
      disabledIfNoSelection: false,
      disabledIfMultiSelection: false,
      color: 'accent',
      icon: EsvgFiles.settings,
      action: () => this.tariffChanges(),
    },
    {
      displayName: 'Preise Rückwirkend Anwenden',
      controlAction: EControlActions.recalculateItemPriceHistory,
      disabledIfNoSelection: false,
      disabledIfMultiSelection: false,
      color: 'accent',
      icon: EsvgFiles.refresh,
      action: () => this.recalculateItemPriceHistory(),
    },
    {
      displayName: 'Tarifoptionen',
      controlAction: EControlActions.tariffOption,
      disabledIfNoSelection: false,
      disabledIfMultiSelection: false,
      color: 'primary',
      icon: EsvgFiles.settings,
      action: () => this.openTariffOptions(),
    },
    CSV_EXPORT_ACTION
  ];

  apiInstanceInitialized$: Subject<IApiControl> = new Subject();

  protected readonly EControlActions = EControlActions;

  constructor(
    @Inject(GoldenLayoutComponentHost) protected goldenLayout: GoldenLayoutComponent,
    @Inject(GoldenLayoutContainer) protected container: GoldenLayout.Container,
    protected cdr: ChangeDetectorRef,
    protected controlManager: ControlManagerService,
    protected coreService: CoreService,
    private dialogService: DialogService,
    private calendarService: CalendarService,
    private feedbackService: FeedbackService
  ) {
    super(goldenLayout, container, cdr, controlManager);

    this.subs.add(
      combineLatest([
        this.calendarService.globalForkedEvents$.pipe(filter(v => v != null)),
        this.apiInstanceInitialized$
      ]).pipe(
        debounceTime(100),
        switchMap(([filter, apiInstance]) => {
          // Update the apiInstance and trigger the fetching of the data with the new filter.
          return this.tableRef.setApiInstanceAndFetch({
            ...apiInstance,
            filter: {
              ...apiInstance?.filter,
              ...filter
            },
          });
        }),
      ).subscribe()
    );
  }

  protected onResize(): void {
  }

  private tariffChanges(): Observable<any> {
    return this.dialogService.dialog.open(DatePickerDialogComponent, {
      autoFocus: 'dialog'
    }).afterClosed().pipe(
      filter(date => date != null),
      concatMap(date => {
        return this.coreService.Itempricehistoryview.updateitempricehistory(this.apiInstance.instanceid, this.tableRef.gridApi.getSelectedNodes().map(r => r.data.apiRow.obj), date)
      }),
      concatMap(() => {
        return this.tableRef.changeAndFetch()
      })
    );
  }

  private recalculateItemPriceHistory(): Observable<any> {
    return this.coreService.Itempricehistoryview.recalculateitempricehistory(this.apiInstance.instanceid).pipe(
      tap(() => {
        this.feedbackService.setNotification('Die Preisliste wurde erfolgreich auf die offenen Fahrten angewendet.')
      })
    )
  }

  private openTariffOptions() {
    this.controlManager.openLayoutComponent({
      guid: EcalculusControlGUID.ItemGroupView,
    }, null);
    return of(null);
  }
}
