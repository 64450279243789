import {ChangeDetectorRef, Component} from '@angular/core';
import {IHeaderParams} from 'ag-grid-community';
import {IHeaderAngularComp} from 'ag-grid-angular';

@Component({
  selector: 'app-day-column-header',
  templateUrl: './day-column-header.component.html',
  styleUrls: ['./day-column-header.component.scss'],
})
export class DayColumnHeaderComponent implements IHeaderAngularComp {
  params: IHeaderParams;
  holiday: string;

  constructor(private cdr: ChangeDetectorRef) {
  }

  agInit(params: any): void {
    this.params = params;
    if (params.column.colDef.apiCol.holiday != undefined) {
      this.holiday = params.column.colDef.apiCol.holiday;
      this.cdr.detectChanges();
    }
  }

  refresh(params: IHeaderParams): boolean {
    this.params = params;
    return false;
  }
}
