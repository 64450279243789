import {Observable} from 'rxjs';
import {ApiConfigService} from '../api-config.service';

export class Demomanagement {
  http;
  url;
  httpOptions;

  constructor(protected appConfig: ApiConfigService) {
    this.http = appConfig.http;
    this.url = appConfig.url;
    this.httpOptions = appConfig.httpOptions;
  }


  LoadDemo(Name: string): Observable<boolean> {
    return this.http.get(
      this.url + '/intern/api/Demomanagement/LoadDemo/' + encodeURI(Name) + '/', this.httpOptions
    ) as unknown as Observable<boolean>;
  }

  CreateDemo(Name: string): Observable<boolean> {
    return this.http.get(
      this.url + '/intern/api/Demomanagement/CreateDemo/' + encodeURI(Name) + '/', this.httpOptions
    ) as unknown as Observable<boolean>;
  }

  GetDemos(): Observable<any[]> {
    return this.http.get(
      this.url + '/intern/api/Demomanagement/GetDemos/', this.httpOptions
    ) as unknown as Observable<any[]>;
  }

}
