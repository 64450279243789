// custom-header.component.ts
import {Component} from '@angular/core';
import {IHeaderAngularComp} from 'ag-grid-angular';
import {IHeaderParams} from 'ag-grid-community';

@Component({
  selector: 'app-custom-header',
  template: `
    <div
      [class.selected-header]="params.context?.selectedHeader?.getColId() === params.column?.getColId()"
      style="width: 100%; height: 100%;"
      (click)="selectColumn($event)"
      tabindex="1"
    >
      {{ params.displayName }}
    </div>
  `,
  styleUrl: 'custom-header.component.scss'
})
export class CustomHeaderComponent implements IHeaderAngularComp {
  params: IHeaderParams;

  agInit(params: IHeaderParams): void {
    this.params = params;
  }

  selectColumn(evt: MouseEvent): void {
    if (evt.ctrlKey) {
      this.params.context.onHeaderCtrlClick(this.params.column);
    } else {
      this.params.context.onHeaderClick(this.params.column);
    }
  }

  refresh(params: IHeaderParams): boolean {
    return false;
  }
}
