import {ProcessOverviewComponent} from "./process-overview/process-overview.component";
import {GoToTrapoComponent} from "./go-to-trapo/go-to-trapo.component";
import {GuidFormComponent, GuidTableComponent} from 'frontier/browserkit';
import {DunningConfigComponent} from "./dunning-config/dunning-config.component";
import {AlternativePayerComponent} from './alternative-payer/alternative-payer.component';
import {TariffChangesComponent} from './tariff-changes/tariff-changes.component';
import {TariffOptionsComponent} from './tariff-changes/tariff-options/tariff-options.component';
import {InvoiceTemplatesConfigComponent} from './invoice-templates-config/invoice-templates-config.component';
import {TemplateEditorComponent} from './template-editor/template-editor.component';

export enum ECalculusViewCategory {
  provisoria = 'Provisoria',
  calculus = 'Abrechnung',
}

export enum ECalculusComponentNames {
  dynamicForm = 'dynamcicForm',
  dynamicTable = 'dynamicTable',
  processOverview = 'processOverview',
  goToTrapo = 'goToTrapo',
  dunningConfig = 'dunningConfig',
  alternativePayer = 'alternativePayer',
  tariffChanges = 'tariffChanges',
  tariffOptions = 'tariffOptions',
  invoiceTemplates = 'invoiceTemplates',
  templateEditor = 'templateEditor',
}

export enum ECalculusLabels {
  dynamicForm = 'dynamisches Formular',
  dynamicTable = 'dynamische Tabelle',
  processOverview = 'Prozessübersicht',
  goToTrapo = 'Trapo-Upload',
  dunningConfig = 'Mahnstufen-Einstellungen',
  alternativePayer = 'Alternative Kostenträger',
  tariffChanges = 'Tarif-Einstellungen',
  tariffOptions = 'Tarif-Optionen',
  invoiceTemplates = 'Rechnungs-Templates-Konfiguration',
  templateEditor = 'Rechnungsvorlagen',
}

/**
 * Array of all golden layout components that can be declared
 * in a golden layout instance.
 */

export const GL_COMPONENTS: any[] = [
  {
    name: ECalculusComponentNames.dynamicForm,
    type: GuidFormComponent,
    title: ECalculusLabels.dynamicForm,
    instantiable: true,
  },
  {
    name: ECalculusComponentNames.dynamicTable,
    type: GuidTableComponent,
    title: ECalculusLabels.dynamicTable,
    instantiable: true,
  },
  {
    name: ECalculusComponentNames.processOverview,
    type: ProcessOverviewComponent,
    title: ECalculusLabels.processOverview,
    instantiable: true,
  },
  {
    name: ECalculusComponentNames.goToTrapo,
    type: GoToTrapoComponent,
    title: ECalculusLabels.goToTrapo,
    instantiable: true,
  },
  {
    name: ECalculusComponentNames.dunningConfig,
    type: DunningConfigComponent,
    title: ECalculusLabels.dunningConfig,
    instantiable: true,
  },
  {
    name: ECalculusComponentNames.invoiceTemplates,
    type: InvoiceTemplatesConfigComponent,
    title: ECalculusLabels.invoiceTemplates,
    instantiable: true,
  },
  {
    name: ECalculusComponentNames.alternativePayer,
    type: AlternativePayerComponent,
    title: ECalculusLabels.alternativePayer,
    instantiable: true,
  },
  {
    name: ECalculusComponentNames.tariffChanges,
    type: TariffChangesComponent,
    title: ECalculusLabels.tariffChanges,
    instantiable: true,
  },
  {
    name: ECalculusComponentNames.tariffOptions,
    type: TariffOptionsComponent,
    title: ECalculusLabels.tariffOptions,
    instantiable: true,
  },
  {
    name: ECalculusComponentNames.templateEditor,
    type: TemplateEditorComponent,
    title: ECalculusLabels.templateEditor,
    instantiable: true,
  }
]
