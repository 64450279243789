<div
  style="
    display: flex;
    flex-direction: column;
    height: 100%;"
>
  <kpi4me-table-vertical-control
    #form
    [showImports]="showImports"
    [hasImports]="false"
    [(apiInstance)]="formApiInstance"
    [GUID]="transportDetailsGUID"
    (apiInstanceInitialize)="filterAndFetch($event)"
  ></kpi4me-table-vertical-control>
</div>

<!--<kpi4me-dynamic-form-control-->
<!--		#form-->
<!--		[GUID]="transportDetailsGUID"-->
<!--		[selectedElement]="parent"-->
<!--		(apiInstanceInitialize)="filterAndFetch($event)"-->
<!--		[(apiInstance)]="formApiInstance"-->
<!--		[displayHeaders]="false"-->
<!--		[standardForm]="false"-->
<!--		[showImports]="showImports"-->
<!--&gt;</kpi4me-dynamic-form-control>-->
