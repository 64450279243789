import {Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild} from '@angular/core';
import {
  CalendarService, CSV_EXPORT_ACTION,
  DialogService,
  EntitySelectorComponent,
  IAction,
  IEntitySelectorDialogData,
  SharedGridOptions,
  TableControlComponent
} from "frontier/browserkit";
import {CoreService, EcalculusControlGUID, EControlActions, EsvgFiles, IApiControl} from "frontier/nucleus";
import {
  BehaviorSubject,
  combineLatest,
  concatMap,
  debounceTime,
  distinctUntilChanged,
  forkJoin,
  merge,
  Observable,
  of,
  ReplaySubject,
  scan,
  startWith,
  Subscription,
  switchMap,
  tap
} from "rxjs";
import {GridOptions, PaginationChangedEvent, RowNode} from "ag-grid-community";
import {CalculusStateService} from "../../Services/calculus-state.service";
import {catchError, filter, finalize, map} from 'rxjs/operators';
import {ICustomGridRow} from 'frontier/browserkit/src/lib/control/table-control/interfaces/custom-grid-row.interface';
import {IProcessOverviewState} from '../config/category-state-selection.interface';
import {ECalculusStateFilter} from '../config/state-filter.enum';
import {IPdfBlobEvent} from 'frontier/browserkit/src/lib/control/table-control/pdf-blob-event.interface';

enum EJobType {
  manual,
  epost
}

@Component({
  selector: 'kpi4me-document-outbox',
  templateUrl: './document-outbox.component.html',
  styleUrls: ['./document-outbox.component.scss']
})
export class DocumentOutboxComponent implements OnInit, OnDestroy {
  subs = new Subscription();

  @Input() fixedPageSize: number;

  @Output() resetPagination = new EventEmitter<number>();

  @ViewChild('document-preview', {static: false}) documentPreviewRef: ElementRef;
  @ViewChild('table') tableRef: TableControlComponent;
  tableApiInstance: IApiControl;
  tableApiInstance$ = new ReplaySubject(1);
  gridOptions: GridOptions = {
    ...SharedGridOptions,
    rowSelection: 'single',
    pagination: true,
  };
  documentOutboxTableGUID = EcalculusControlGUID.OutboxBook;
  processOverViewState: IProcessOverviewState;

  tableActions: IAction[] = [
    {
      displayName: 'Alle anordnen',
      controlAction: EControlActions.arrangeAll,
      disabledIfNoSelection: false,
      disabledIfMultiSelection: false,
      color: 'primary',
      icon: EsvgFiles.mail,
      tooltip: 'Alle Dokumente anordnen.',
      tooltipDelay: 1000,
      action: () => this.arrangeAll(),
      isHidden: () => {
        return this.processOverViewState?.stateSelection?.states.includes(ECalculusStateFilter.arrangeInvoiceOk)
      }
    },
    {
      displayName: 'DTA erneut anordnen',
      controlAction: EControlActions.arrange,
      disabledIfNoSelection: true,
      disabledIfMultiSelection: false,
      color: 'primary',
      icon: EsvgFiles.mail,
      tooltip: "Erstelle und versende alle ausgewählten DTA's erneut.",
      tooltipDelay: 1000,
      action: () => this.rearrangeDta(),
      isHidden: () => {
        return !this.processOverViewState?.stateSelection?.states.includes(ECalculusStateFilter.arrangeInvoiceOk)
      }
    },
    {
      displayName: 'Dokument erneut versenden',
      controlAction: EControlActions.export,
      disabledIfNoSelection: true,
      disabledIfMultiSelection: false,
      color: 'primary',
      icon: EsvgFiles.mail,
      tooltip: 'Versende alle ausgewählten versendbaren Dokumente.',
      tooltipDelay: 1000,
      action: () => this.sendSelection(),
      isHidden: () => {
        return !this.processOverViewState?.stateSelection?.states.includes(ECalculusStateFilter.arrangeInvoiceOk)
      }
    },
    {
      displayName: 'Rechnung anzeigen',
      controlAction: EControlActions.invoicePreview,
      disabledIfNoSelection: true,
      disabledIfMultiSelection: false,
      color: 'primary',
      icon: EsvgFiles.search,
      tooltip: 'Blende das Rechnungsdokument ein',
      tooltipDelay: 1000,
      action: () => of(null).pipe(tap(() => this.pdfInvoiceToggle$.next(true))),
      isDisabled: () => {
        return !this.isPdfOverlayLoading$.getValue();
      }
    },
    CSV_EXPORT_ACTION
  ];

  selectedRow$ = new ReplaySubject<RowNode>(1);
  paginationChange$ = new EventEmitter<PaginationChangedEvent>();

  paginationSelectedRow$ = merge(
    this.paginationChange$.pipe(
      map((paginationChange) => {
        // only if the page size is one, the first row should be selected
        if (this.gridOptions.paginationPageSize === 1) {
          const rowIndex = paginationChange.api.paginationGetCurrentPage();
          paginationChange.api.setFocusedCell(rowIndex, "0");
          const firstRow = paginationChange.api.getDisplayedRowAtIndex(rowIndex);
          firstRow.setSelected(true, true);
          return firstRow;
        }
        return null;
      }),
      filter(row => row != null)),
    this.selectedRow$
  ).pipe(
    // Node api data should be set
    filter(node => node.data != null),
    // check if row has changed
    distinctUntilChanged((previous: RowNode, current: RowNode) =>
      (previous?.data as ICustomGridRow)?.apiRow?.obj?.signature === (current.data as ICustomGridRow)?.apiRow?.obj?.signature
    ),
    tap((row) => console.log('selected row changed', row))
  )

  pdfInvoiceToggle$ = new EventEmitter<boolean>();
  isPdfOverlayLoading$ = new BehaviorSubject(false);
  pdfShowOverlay$: Observable<boolean> = this.pdfInvoiceToggle$.pipe(
    scan((oldOverlay: boolean, newOverlay: boolean) => {
      if (newOverlay === oldOverlay) {
        this.onHideOverlay();
        return false;
      }
      this.onShowOverlay();
      return true;
    }, null),
    startWith(false));

  pdfBlob$: Observable<IPdfBlobEvent> = combineLatest([
      this.pdfShowOverlay$,
      this.paginationSelectedRow$
    ]
  ).pipe(
    tap(() => this.isPdfOverlayLoading$.next(true)),
    switchMap(([showOverlay, selectedRow]) => {
      // If no current overlay is shown and the selected pagination row has changed select it.
      if (showOverlay) {
        return this.coreService.Outboxbook.downloadcurrentinvoice(
          this.tableApiInstance.instanceid,
          selectedRow.data.apiRow.obj
        ).pipe(
          catchError(() => of(null)),
          map(v => ({blob: v}))
        ) as Observable<IPdfBlobEvent>
      } else {
        // null case
        return of(null)
      }
    }),
    finalize(() => {
        this.tableRef?.gridApi?.hideOverlay();
        this.isPdfOverlayLoading$.next(false);
      }
    ));

  constructor(
    protected coreService: CoreService,
    protected calculusStateService: CalculusStateService,
    private dialogService: DialogService,
    private calendarService: CalendarService
  ) {
    this.subs.add(this.calculusStateService.customPageSize$.subscribe((res) => {
      this.resetPagination.emit(res);
    }));
    this.subs.add(this.calculusStateService.processOverviewState$.subscribe(res => {
      this.processOverViewState = res;
    }));

    this.subs.add(
      this.coreService.controlDataChanged.pipe(
        filter(evt => {
          return new Map([
            [EcalculusControlGUID.OutboxBook,
              new Set([EControlActions.arrange, EControlActions.arrangeAll, EControlActions.export])],
          ]).get(evt.GUID as EcalculusControlGUID)?.has(evt.changeType);
        }),
        tap(() => {
          this.tableRef.refreshInfiniteCache()
        })).subscribe(),
    );
  }

  ngOnInit(): void {
    this.subs.add(
      combineLatest([
        this.calendarService.globalForkedEvents$.pipe(filter(v => v != null)),
        this.tableApiInstance$,
        this.calculusStateService.processOverviewState$.pipe(
          tap(() => {
            this.tableRef?.gridApi?.deselectAll();
          })
        )
      ]).pipe(
        debounceTime(50),
        switchMap(([filter, , processOverviewState]) => {
          const {states, categories} = processOverviewState.stateSelection;
          // Update the apiInstance and trigger the fetching of the data with the new filter.
          return this.tableRef.setApiInstanceAndFetch({
            ...this.tableApiInstance,
            filter: {
              ...this.tableApiInstance?.filter,
              ...filter,
              valuelist: states.length > 0 ? states : categories,
              typename: processOverviewState.invoice?.invoiceId || null,
            },
          });
        }),
      ).subscribe()
    );
  }

  ngOnDestroy() {
    this.subs.unsubscribe();
  }

  private arrangeAll(): Observable<any> {
    return this.coreService.Outboxbook.arrangeallinvoices(this.tableApiInstance.instanceid).pipe(
      tap(data => {
        // Create download URL
        const downloadURL = URL.createObjectURL(data);

        const link = document.createElement('a');
        link.href = downloadURL;
        link.download = 'invoices';
        link.click();
      }),
      finalize(() => this.tableRef?.gridApi?.hideOverlay())
    )
  }

  private sendSelection(): Observable<any> {
    const dialogData: IEntitySelectorDialogData = {
      title: 'Möchten Sie das Dokument elektronisch per E-Mail versenden oder selbständig für den Versand herunterladen?',
      entities: [
        {displayName: 'E-Post', id: EJobType.epost},
        {displayName: 'Manueller Versand', id: EJobType.manual},
      ]
    }
    return this.dialogService.dialog.open(
      EntitySelectorComponent, {
        data: dialogData,
        maxWidth: '30rem'
      }
    ).afterClosed().pipe(
      concatMap((result: number[]) => {
        return this.coreService.Outboxbook.rearrangedocument(this.tableApiInstance.instanceid,
          this.tableRef.gridApi.getSelectedRows()[0].apiRow.obj,
          result[0] as EJobType
        )
      }),
      finalize(() => this.tableRef?.gridApi?.hideOverlay())
    )
  }



  private rearrangeDta(): Observable<any> {
    return forkJoin(
      this.tableRef.gridApi.getSelectedRows().map(r => {
        return this.coreService.Outboxbook.rearrangedta(
          this.tableApiInstance.instanceid,
          r.apiRow.obj
        )
      }))
      .pipe(
        finalize(() => this.tableRef?.gridApi?.hideOverlay())
      );
  }

  private onHideOverlay() {
    this.replaceDisplayNameOfAction(EControlActions.invoicePreview, 'Rechnung anzeigen')
  }

  private onShowOverlay() {
    this.replaceDisplayNameOfAction(EControlActions.invoicePreview, 'Rechnung ausblenden')
  }

  replaceDisplayNameOfAction(action: EControlActions, displayName: string) {
    this.tableActions = this.tableActions.map((tableAction) => {
      if (tableAction.controlAction !== action) {
        return tableAction;
      } else {
        return {
          ...tableAction,
          displayName,
        };
      }
    });
  }
}
