<!--<input-->
<!--  #input-->
<!--  (keydown)="onKeyDown($event)"-->
<!--  (ngModelChange)="onModelChange($event)"-->
<!--  [(ngModel)]="params.value.value"-->
<!--  style="width: 100%"-->
<!--  type="text"-->
<!--/>-->
<kpi4me-text-element
  class="form-field-no-padding"
  *ngIf="textFormControl" #input
  style="width: 100%"
  [formControlElement]="textFormControl"
  [data]="textElementData"
  (modelChange)="onModelChange($event)"
>

</kpi4me-text-element>
