import {Component, Input, OnChanges, SimpleChanges, ViewChild, ViewEncapsulation} from '@angular/core';
import {CoreService, EcalculusControlGUID, IControlObject} from 'frontier/nucleus';
import {CrudControlComponent} from 'frontier/browserkit';

@Component({
  selector: 'kpi4me-cart-additional-detail',
  templateUrl: './cart-additional-detail.component.html',
  styleUrls: ['./cart-additional-detail.component.scss'],
  encapsulation: ViewEncapsulation.Emulated
})
export class CartAdditionalDetailComponent implements OnChanges {
  cartAdditionalDataGuid = EcalculusControlGUID.CrudCartAdditionaldata;
  @ViewChild(CrudControlComponent) crudControl: CrudControlComponent;
  @Input() parent: IControlObject;
  labels: string[];

  constructor(private coreService: CoreService) {
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.parent && this.crudControl) {
      this.filterAndFetch();
    }
  }

  onApiInstanceInitialize() {
    this.labels = [
      'Text:',
      '',
      'Notfallart:',
      '',
      'Rückfahrt:',
      '',
      'NEF nachgefordert:',
      '',
      'Fehlfahrt:',
      '',
      'Versuchter Transport:',
      '',
    ];

    this.filterAndFetch();
  }

  private filterAndFetch() {
    if (this.parent) {
      this.coreService.CrudControl.getobject(this.crudControl.apiInstance.instanceid, this.parent)
        .subscribe((res) => {
          console.log(res);
          if (res) {
            this.labels[1] = res.text;
            this.labels[3] = res.stat1;
            this.labels[5] = res.hinrueck === 'F' ? 'nein' : res.hinrueck === 'T' ? 'ja' : '';
            this.labels[7] = res.mitnef === 'F' ? 'nein' : res.mitnef === 'T' ? 'ja' : '';
            this.labels[9] = res.fehlfahrt === 'F' ? 'nein' : res.fehlfahrt === 'T' ? 'ja' : '';
            this.labels[11] = res.versuchttransport === 'F' ? 'nein' : res.versuchttransport === 'T' ? 'ja' : '';
          } else {
            this.labels[1] = 'keine Daten';
            this.labels[3] = 'keine Daten';
            this.labels[5] = 'keine Daten';
            this.labels[7] = 'keine Daten';
            this.labels[9] = 'keine Daten';
            this.labels[11] = 'keine Daten';
          }
          this.crudControl.cdr.detectChanges();
        });
    }
  }
}
