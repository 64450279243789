import {AfterViewInit, Component, ViewChild} from '@angular/core';
import {CustomCellEditorComponent} from '../custom-cell-editor/custom-cell-editor.component';
import {MatSlideToggle} from '@angular/material/slide-toggle';

@Component({
  selector: 'app-boolean-cell-editor',
  templateUrl: './boolean-cell-editor.component.html',
  styleUrls: ['./boolean-cell-editor.component.scss'],
})
export class BooleanCellEditorComponent extends CustomCellEditorComponent implements AfterViewInit {
  @ViewChild('checkbox') checkboxRef: MatSlideToggle;

  ngAfterViewInit() {
    setTimeout(() => {
      this.checkboxRef.focus();
    });
  }

  isPopup(): boolean {
    return false;
  }

  onEnter() {
    this.textFormControl.patchValue(!this.textFormControl.value);
    this.saveValue();
  }

  protected setPastedValue(pasted: string) {
    const bool = JSON.parse(pasted);
    this.textFormControl.patchValue(bool);
    this.params.value.value = bool;
    this.params.api.stopEditing();
  }
}
