import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewEncapsulation
} from '@angular/core';
import {CoreService, DefaultApiAdapter, StoreService} from 'frontier/nucleus';
import {CalculusStateService} from "../../Services/calculus-state.service";
import {Subscription} from 'rxjs';
import {IBarClickedEvent} from './bar-click-event.interface';
import {
  ICartStatus,
  ICartStatusClientAccess,
  ICartStatusOnHold,
  StatusTuple
} from '../config/cart-status-data.interface';
import {ECalculusStateFilter} from '../config/state-filter.enum';

@Component({
  selector: 'kpi4me-evaluation',
  templateUrl: './evaluation.component.html',
  styleUrls: ['./evaluation.component.scss'],
  encapsulation: ViewEncapsulation.Emulated
})
export class EvaluationComponent implements OnInit, OnDestroy {
  @Input() selectedStates: ECalculusStateFilter[];
  @Input() process: ICartStatus | ICartStatusOnHold | ICartStatusClientAccess;
  @Output() barClicked = new EventEmitter<IBarClickedEvent>();
  apiAdapter = new DefaultApiAdapter();
  protected _evaluationData: [number] | StatusTuple;
  protected evaluationDisplayPercentages: [string] | [string, string, string];
  protected evaluationDisplayAbsolute: [string] | [string, string, string];
  showPercentages = false;

  private subs = new Subscription();

  @Input() set evaluationData(data: StatusTuple | [number]) {
    if (!data?.length) return;

    const nonNegativeData = data.map((value: number): number => {
      if (value < 0) {
        console.error('Received invalid data: negative value');
        return 0;
      }
      return value;
    });

    const inputSum: number = nonNegativeData.reduce((previousValue, currentValue) => previousValue + currentValue, 0);
    if (inputSum > 0) {
      const average = nonNegativeData.map((value: number) => {
        return Math.round(value / inputSum * 100);
      });
      this._evaluationData = average.length === 1 ? average as [number] : average as StatusTuple;
    } else {
      this._evaluationData = data.length === 1 ? [0] : [0, 0, 0];
    }

    this.evaluationDisplayPercentages = this._evaluationData.map((num) => {
      return num.toString() + '%';
    }) as [string, string, string];
    this.evaluationDisplayAbsolute = nonNegativeData.map((value: number) => {
      return this.generateDisplay(value);
    }) as [string, string, string];
  }

  constructor(
    protected coreService: CoreService,
    protected store: StoreService,
    public cdr: ChangeDetectorRef,
    protected detailFormService: CalculusStateService,
  ) {
  }

  ngOnInit() {
    this.subs.add(
      this.detailFormService.showPercentages$.subscribe((res: boolean) => {
        console.log('change transmitted', res)
        this.showPercentages = res;
      })
    )
  }

  ngOnDestroy() {
    this.subs.unsubscribe();
  }

  private generateDisplay(value: number): string {
    if (value < 1000) {
      return value.toString();
    } else if (value < 10000) {
      return (Math.round(value / 100) / 10).toString() + 'k';
    } else if (value < 1000000) {
      return (Math.round(value / 1000)).toString() + 'k';
    } else if (value < 10000000) {
      return (Math.round(value / 100000) / 10).toString() + 'M';
    } else {
      return (Math.round(value / 1000000)).toString() + 'M';
    }
  }

  getStateByIndex(i: number) {
    switch (i) {
      case 0:
        return this.process.errorId;
      case 1:
        return this.process.warningId;
      case 2:
        return this.process.okId;
      default:
        throw new Error("Unexpected state index")
    }
  }
}
