<div class="padder">
  <div>
    <div class="left-border"></div>

    <div class="label-radio-wrapper">
      <label [id]="key">{{label}}</label>
      <mat-radio-group
        aria-label="Select an option"
        [formControl]="getFormControl()"
        (ngModelChange)="modelChange.emit($event)"
      >
        <mat-radio-button
          *ngFor="let o of options"
          [value]="o.value"
        >
          {{o.name}}
        </mat-radio-button>
      </mat-radio-group>

      <mat-error>
        <ng-container *ngFor="let error of this.getErrorMessage()">
          {{ error }} <br>
        </ng-container>
      </mat-error>
    </div>

    <div class="right-border"></div>
  </div>
</div>
