import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  HostListener,
  Inject,
  OnDestroy,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import {ProcessHeaderComponent} from "../process-header/process-header.component";
import {EsvgFiles, IApiControl, IControlObject} from 'frontier/nucleus';
import {CartsComponent} from "../carts/carts.component";
import {ClientsComponent} from "../clients/clients.component";
import {CalculusStateService, TSwitchablePhases} from "../../Services/calculus-state.service";
import {combineLatest, distinctUntilChanged, Observable, Subscription,} from "rxjs";
import {
  ControlManagerService,
  GoldenLayoutContentComponent,
  IControl,
  TableControlComponent,
} from 'frontier/browserkit';
import {ECalculusComponentNames} from "../golden-layout.component";
import {OpenItemsComponent} from "../open-items/open-items.component";
import {
  IActiveClient,
  ICart,
  ICategoryStateSelection,
  IProcessOverviewState
} from '../config/category-state-selection.interface';
import {ECalculusStateFilter} from '../config/state-filter.enum';
import {filter, map, take, tap} from "rxjs/operators";
import {DocumentOutboxComponent} from "../document-outbox/document-outbox.component";
import {TShownTables} from './shown-tables.type';
import {ITableToggler} from './table-toggler/table-toggler.interface';
import {CartDetailsComponent} from "../cart-details/cart-details.component";
import {GoldenLayoutComponent, GoldenLayoutComponentHost, GoldenLayoutContainer} from '@kpi4me/golden-angular-layout';
import GoldenLayout from '@kpi4me/golden-layout';
import {areSetsEqual} from 'frontier/nucleus/src/lib/utils/equal-sets.func';
import {KeyboardEvent} from 'react';
import {areArraysEqual} from 'frontier/nucleus/src/lib/utils/equal-arrays.func';

interface IView {
  activeTables: Set<TShownTables>,
  processOverviewState: IProcessOverviewState
}

@Component({
  selector: 'kpi4me-process-overview',
  templateUrl: './process-overview.component.html',
  styleUrls: ['./process-overview.component.scss'],
  encapsulation: ViewEncapsulation.Emulated
})
export class ProcessOverviewComponent extends GoldenLayoutContentComponent implements AfterViewInit, OnDestroy {

  @ViewChild('clients', {static: false}) clientsRef: ClientsComponent;
  @ViewChild('carts', {static: false}) cartsRef: CartsComponent;
  @ViewChild('openItems', {static: false}) openItemsRef: OpenItemsComponent;
  @ViewChild('documentOutbox', {static: false}) documentOutboxRef: DocumentOutboxComponent;
  @ViewChild(ProcessHeaderComponent) processHeaderRef: ProcessHeaderComponent;
  @ViewChild('details') detailsRef: CartDetailsComponent;

  subs = new Subscription();

  processOverviewState$ = this.calculusStateService.processOverviewState$;
  activeCartFilterObjClicked$ = new EventEmitter();
  stateClicked$ = new EventEmitter();
  activeTables = new Set<TShownTables>;
  view$: Observable<IView> =
    combineLatest(
      [
        this.calculusStateService.activeTables$.pipe(
          tap((tables) => {
            this.activeTables = tables;
            this.cdr.detectChanges();
          })
        ),
        this.processOverviewState$
      ]).pipe(map(([activeTables, processOverviewState]) => {
        return {activeTables, processOverviewState}
      }
    ));
  view: IView;

  apiInstance: IApiControl;
  cartParent: IControlObject = null;
  clientParent: IControlObject = null;
  preventUpdate = false;
  fixedPageSize$ = this.calculusStateService.customPageSize$;

  protected readonly EsvgFiles = EsvgFiles;
  protected readonly ECalculusStateFilter = ECalculusStateFilter;

  arrangeClientToggleData$: Observable<ITableToggler> = this.calculusStateService.showCartsTable$.pipe(map(value => {
    return {
      leftIcon: EsvgFiles.elderly,
      rightIcon: EsvgFiles.car,
      initialValue: value?.ETLnMapping || false,
    };
  }));

  arrangeInvoiceToggleData$: Observable<ITableToggler> = this.calculusStateService.showCartsTable$.pipe(map(value => {
    return {
      leftIcon: EsvgFiles.document,
      rightIcon: EsvgFiles.car,
      initialValue: value?.arrangeInvoice || false,
    };
  }));

  monitorPaymentToggleData$: Observable<ITableToggler> = this.calculusStateService.showCartsTable$.pipe(map(value => {
    return {
      leftIcon: EsvgFiles.money,
      rightIcon: EsvgFiles.car,
      initialValue: value?.monitorPayment || false,
    };
  }));

  detailsActive = () => {
    return this.cartParent || this.clientParent;
  };


  constructor(
    public cdr: ChangeDetectorRef,
    protected calculusStateService: CalculusStateService,
    public controlManagerService: ControlManagerService,
    @Inject(GoldenLayoutComponentHost) protected goldenLayout: GoldenLayoutComponent,
    @Inject(GoldenLayoutContainer) protected container: GoldenLayout.Container,
  ) {
    super(goldenLayout, container, cdr, controlManagerService);
  }

  @HostListener('window:keydown.control.alt.u', ['$event']) preventUpdateToggle() {
    this.preventUpdate = !this.preventUpdate;
  }

  @HostListener('window:keydown.code.control.alt.keyt', ['$event'])
  @HostListener('window:keydown.code.meta.alt.keyt', ['$event'])
  openDynamicTable(event: KeyboardEvent): void {
    event.preventDefault();
    const control: IControl = {
      guid: ECalculusComponentNames.dynamicTable,
      cellvalue: '',
      menuname: '',
    }
    this.controlManagerService.openLayoutComponent(control, null);
  }

  @HostListener('window:keydown.code.control.alt.keyf', ['$event'])
  @HostListener('window:keydown.code.meta.alt.keyf', ['$event'])
  openDynamicForm(event: KeyboardEvent): void {
    event.preventDefault();
    const control: IControl = {
      guid: ECalculusComponentNames.dynamicForm,
      cellvalue: '',
      menuname: '',
    }
    this.controlManagerService.openLayoutComponent(control, null);
  }

  protected onResize(): void {
    this.openItemsRef?.tableRef?.fitColumns();
    this.cartsRef?.tableRef?.fitColumns();
    this.clientsRef?.tableRef?.fitColumns();
    this.documentOutboxRef?.tableRef?.fitColumns();
  }

  ngAfterViewInit(): void {
    console.log('After view init');
    this.subs.add(this.calculusStateService.activeCart$.pipe(
      distinctUntilChanged((a, b) => JSON.stringify(a) === JSON.stringify(b))
    ).subscribe((cart: ICart) => {
        if (cart == null) {
          this.cartParent = null;
          this.cdr.detectChanges();
          this.resetTabSelection();
          return
        }
        if (this.cartParent == null && cart.obj != null) {
          this.cartParent = cart.obj;
          this.cdr.detectChanges();
          this.resetTabSelection();
        } else {
          this.cartParent = cart.obj;
        }
      }));

    this.subs.add(this.calculusStateService.activeClient$
      .subscribe((client: IActiveClient) => {
      this.clientParent = client ? client.obj : null;
    }));

    // If the state of the breadcrumb is clicked, deselect all rows and clear the category selection
    this.subs.add(
      this.stateClicked$.subscribe(() => {
        this.getActiveTableRefs(this.activeTables).forEach(t => t.gridApi.deselectAll());
        this.processHeaderRef.categorySelection.clear(false);
        this.processHeaderRef.categorySelection.select(...this.view.processOverviewState.stateSelection.categories);
      })
    )

    // When active invoice is clicked, deselect all cart rows
    this.subs.add(
      this.activeCartFilterObjClicked$
        .subscribe(() => {
          if (this.activeTables.has('carts')) {
            this.cartsRef.tableRef.gridApi.deselectAll();
          }
          // hide the details of the cart
          this.calculusStateService.selectedCartRow$.next({
            obj: null,
            number: null,
          });
        })
    )

    // if active tables have changed, fit all tables
    this.subs.add(
      this.view$.pipe(
        distinctUntilChanged((prev, curr) => {
          return areSetsEqual(prev.activeTables, curr.activeTables)
            && areArraysEqual(prev.processOverviewState.stateSelection.categories, curr.processOverviewState.stateSelection.categories)
            && areArraysEqual(prev.processOverviewState.stateSelection.states, curr.processOverviewState.stateSelection.states)
        }),
        tap((view) => {
          this.view = view;
          this.cdr.detectChanges();
          this.recalculatePagination(view.activeTables);
          this.fitTables(view.activeTables);
        })
      ).subscribe()
    )

    this.subs.add(
      this.calculusStateService.replacedClientForCart$.subscribe((evt) => {
        if (this.activeTables.has('clients')) {
          this.calculusStateService.selectedClientRow$.emit({obj: evt.client, name: evt.client.fullname, replaced: true});
        }
        if (this.activeTables.has('carts')) {
          this.calculusStateService.selectedCartRow$.emit({obj: evt.cart, number: (evt.cart as ICart).number, replaced: true});
        }
      })
    )
  }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }

  onProcessSelectionChange(evt: ICategoryStateSelection) {
    // this.calculusStateService.activeCategoryStateSelection$.next(evt);
    this.calculusStateService.selectedProcessState$.next(evt);
    console.log('selection change', evt);
  }

  onCategoryClicked() {
    this.processHeaderRef.stateSelection.clear(true);
    this.processHeaderRef.categorySelection.clear(false);
    this.processHeaderRef.categorySelection.select(...this.view.processOverviewState.stateSelection.categories);
  }

  private recalculatePagination(tables: Set<TShownTables>) {
    this.getActiveTableRefs(tables).forEach(table => table.resetPaginationPageSize())
  }

  private getActiveTableRefs(tables: Set<TShownTables>) {
    const activeTableRefs: TableControlComponent[] = [];
    if (tables.has('carts')) activeTableRefs.push(this.cartsRef.tableRef);
    if (tables.has('openItems')) activeTableRefs.push(this.openItemsRef.tableRef);
    if (tables.has('documentOutbox')) activeTableRefs.push(this.documentOutboxRef.tableRef);
    if (tables.has('clients')) activeTableRefs.push(this.clientsRef.tableRef);
    return activeTableRefs;
  }

  private fitTables(tables: Set<TShownTables>) {
    this.getActiveTableRefs(tables).forEach(tref => tref.fitColumns());
  }

  toggleCollectiveInvoiceDisplay(IPdfOverlayToggleEvent): void {
    // Show the loading indicator
    this.cartsRef.isPdfOverlayLoading$.next(true);
    // When the pdf loading process is finished
    this.cartsRef.isPdfOverlayLoading$
      .pipe(
        filter(v => v === false),
        take(1)
      )
      .subscribe(() => {
        this.calculusStateService.isInvoiceLoading$.next(false);
        this.openItemsRef?.tableRef?.gridApi?.hideOverlay();
        this.cdr.detectChanges();
      });
    // this.cartsRef.pdfInvoiceToggle$.emit();
    this.cartsRef.pdfCollectiveInvoiceToggle$.emit(IPdfOverlayToggleEvent);
    this.cdr.detectChanges();
  }

  onShowCartsTableChange(type: TSwitchablePhases, evt: boolean): void {
    this.calculusStateService.showCartsTable$.next({
      ...this.calculusStateService.showCartsTable$.getValue(),
      [type]: evt,
    })
  }

  resetTabSelection(): void {
    if (this.detailsRef?.tabRef == null) return;
    this.detailsRef.tabRef.selectedIndex = 0;
    this.cdr.detectChanges();
  }

  resizeEnded() {
    setTimeout(() => {
      this.detailsRef?.conspicuityDetail?.resized();
    }, 50)
  }
}
