<app-dialog-header
  [title]="'Dauerverordnung abrechnen'"
  [dialogRef]="dialogRef"
  (close)="onCancel()"
></app-dialog-header>

<mat-dialog-content>
  @if (form.value.attendanceCertificate) {
    <div style="flex: 1; min-width: 25rem">
      <embed style="height: 100%; width: 100%" [src]="form.value.attendanceCertificate | pdf" type="application/pdf">
    </div>
  }

  <div [formGroup]="form">
    @if (data.permanentOrdinances == null || data.permanentOrdinances.length == 0) {
      <mat-error style="margin-top: -10px">Es wurde keine Dauerverordnung für den Patienten gefunden.</mat-error>
    } @else {
      <mat-form-field appearance="outline">
        <mat-label>Dauerverordnung</mat-label>
        <mat-select formControlName="selectedOrdinance">
          @for (ordinance of data.permanentOrdinances; track ordinance.signature) {
            <mat-option [value]="ordinance.signature">
              {{ ordinance.name }}
            </mat-option>
          }
        </mat-select>
      </mat-form-field>
    }
    @if (form.controls.selectedOrdinance.invalid && form.controls.selectedOrdinance.touched) {
      <mat-error style="margin-top: -10px" class="mat-caption">Bitte wählen Sie ein Dokument aus.</mat-error>
    }

    <div class="upload-wrapper-wrapper">
      <p>Dokumenten-Upload</p>
      <mat-slide-toggle [color]="'primary'" style="padding: 0.5rem 0" formControlName="hasDocument">Dokument vorhanden</mat-slide-toggle>
      @if (form.value.hasDocument) {
        <app-upload-wrapper [accept]="'.pdf'" (changed)="onPdfUpload($event)"
                            formControlName="attendanceCertificate"></app-upload-wrapper>
      }
    </div>


    @if (form.controls.attendanceCertificate.invalid && form.controls.attendanceCertificate.touched) {
      <mat-error style="margin-top: -10px" class="mat-caption">Bitte wählen Sie ein Dokument aus.</mat-error>
    }
    <div style="display: flex; gap: 0.5rem">
      <kpi4me-date-element label="von" [formControlElement]="form.controls.fromDate"></kpi4me-date-element>
      <kpi4me-date-element label="bis" [formControlElement]="form.controls.toDate"></kpi4me-date-element>
    </div>

    @if (form.value.selectedOrdinance) {
      <div class="mat-subtitle-2">
        Fahrten
      </div>
      <kpi4me-client-side-table-control
        [GUID]="clientGUID"
        (apiInstanceInitialize)="tableInitialized$.emit()"
        (cellValueChanged)="onCellValueChange($event)"
      ></kpi4me-client-side-table-control>
      @if (selectedCartsModel.selected.length === 0) {
        <mat-error style="margin-top: -10px" class="mat-caption">Bitte weisen Sie mindestens eine Fahrt zu.</mat-error>
      }
    }


  </div>
</mat-dialog-content>

<div mat-dialog-actions style="margin-top:1rem;">
  <app-confirm-icon-button
    [disabled]="form.invalid || this.selectedCartsModel.selected.length === 0"
    (customClick)="onConfirm()"></app-confirm-icon-button>
</div>
