export enum EsamControlGUID {
  CollectionInfo = 'A475FA1968D04BC1994BA0131BB63E66',
  ContractInfo = '45E2D0F8A5F04638AD965D233658C5DD',
  UserListTable = '3ED33075070F443A849D1A5AD6A1CAE3',
  ClientList = 'F40343C702904B5F9E1292C7E968AFF3',
  KindOfserviceList = 'C46D78D2092644CF87C25734FC5242DD',
  PaymentList = '578D68B4916C405A9D2BBFFAF2666028',
  FixedStructureHierarchy = '02C53B49AE9044218D80879F5782D080',
  HnzDeviceList = 'BBBF572CD6DB481C87381BCB847FF23A',
  EMailSmtpConfiguration = 'DBE5B7FD376F4EB481B3D688A2113B1F',
  EMailTemplateReplaceForm = '37D90EB8318E4F1AB42F02B60F6BABFC',
  FormMediPlan = '30C8412B2E5C40B2ACB2CC3FDFABF41D',
  ReferenceHierarchy = 'E901B16B90A741D79E6ACDD1329BB4AE',
  ClientHaskeysTable = '7332A3CFC88642A2B073025C65B28B83',
  ServiceContactList = 'A899001200DD49959F1AB7E98249A8B0',
  UserGroupListTable = '70333BA9B89E4993B79A9B711B8149AC',
  ClientInsuranceData = 'ABC153C463A44DE39227DB20B8AD89FD',
  HealthList = '315D1E25861C4B25AE64CBEF7951D8DB',
  ServiceproviderList = '9A4F8FD699D442F58C1A56EACC05342B',
  ClientManagement = '06FEC555836646BD909BA86E70D3EDF8',
  ClientContactData = '59BB36820D81462B973F88A6B6D7C9ED',
  ContractCollectionList = 'F7A159C0E88C4ADFA2D01B8C60B4AD35',
  EmailTemplateForm = 'F1EACFAE560B49109CE943BE46CB7653',
  TreeAnswerOverview = '0C915FC9BECE47D4A5947CFF9387F55A',
  KeyList = '382DCEEDB22F4213B7EC4029380A9B39',
  TreeQuestionnaire = 'E986A6CFD88F447FB2648197D5356C83',
  FieldMappingList = '01A99FE70FCA4956B233078DA65F2D30',
  ClientProviderList = '64D0D2908AED4D68B36A3108BEBEA160',
  AdminSettingsForm = 'DDBABC06E09F474C93E18B7FF88B425F',
  ContactList = '1FB1436A8C11414C87C46DDFE3934BC0',
  TemplateInfo = '60DEADF6431E44EE869CE6C32DA33F2A',
  TreeStatistic = '216BFD6089774E1383CA49830E49FF24',
  SendContractDetails = '2872D5C21C1F40A8A3530DCE29827268',
}
