export enum EcalculusControlGUID {
  CrewDetails = 'AE1FB8D37C3F4BF384064D8ED22C1E4C',
  ItemPriceHistoryView = '46E741106BC7441F8B1B09BEAE2D131D',
  TemplateConfigurationControl = 'FA7E86C4E2814767AC1974704B6044A5',
  AllClientList = '25925F5D7E1B4BFEA40D211A1FEE4BCB',
  InvoiceTemplateTable = '40DAED4C5CD24EEC86B4B860F9A23801',
  InvoiceTemplateConfigForm = 'E42762EF7BFF4A6495D6F7C61E9B5211',
  DunningInfo = '871D548555F24807BD7953789E820F18',
  DunningConfigView = '026642E642A046D0A6CAF86A9ABDDC89',
  ImportOpenItem = 'F327C24AC56B41F39B1EC7DB08CF2AB1',
  FixedStructureHierarchy = '02C53B49AE9044218D80879F5782D080',
  CustomerSettingsForm = '23B2068969EA41DDBD09406D7D30FA87',
  EMailSmtpConfiguration = 'DBE5B7FD376F4EB481B3D688A2113B1F',
  InvoiceList = '57421BCDE6364802BB19F5C83ED4956D',
  OpenItemList = 'A41BC1E3AB6043348BEF8897F9F6FC5F',
  ItemPriceMapping = 'B34C35C9A3BD4761919ACE53BA06B5D6',
  DataofficeTableAllFieldsHidden = 'C2B4D4C3246C4451BF38AB647014CA40',
  PayerTableAllFieldsHidden = 'C0AE9FB1EF9F40DF9FB7BEF581D56193',
  NotBillable = '32D70EB38E9B4458BFF6803B51F466D7',
  AlternativePayerTable = 'CAF7CD78BDFD4555B72AB4DBE451C284',
  ClientList = '857CB853E1F14BB1BB7D8B894AA9F43A',
  OrdinanceList = '47808EF2C0284301A57627758700B428',
  ItemTable = 'F01D6DD01B2C4E18B72DAF16C9110C75',
  OutboxBook = '814D814F0A6A4E539D403C74900C186A',
  GlobalTemplateConfigForm = '23EAB0B631C94FB1BC544D9C0E5F6375',
  PayerDataOfficeTable = '8723D23FFDE547579CDB18093F6EEBC6',
  Ordinance = 'D51F0B9E03B548F18F0027CF6A1FCA95',
  AlternativePayerView = 'B6A39F4844C34491969338F175853AFB',
  CrudCartAdditionaldata = '953A05FDB70646D787410E7112D21BA2',
  InvoiceTemplateImportTable = 'B45587DE47F74BA8BDC2A244BA7968E1',
  AddressInfoView = '76FC5FFC0633479CA9769CB3D17E94E0',
  TrapoDetails = '552DE5E36F924904A309DCB77F02B7A0',
  FullCartStatus = '082B811AD7494ECC9C79065B290B86A2',
  CartItemsList = '1F4A43B89DED40FB95C3A38FDBC3CC9E',
  UserListTable = '3ED33075070F443A849D1A5AD6A1CAE3',
  CartList = 'E8ED3F6ED2764F25B29FC702F6B696F8',
  CartTable = 'F99D661774F94DA8B7F285A0DA2BF32B',
  CrewList = 'D0D2743760004A6F95906D41BE9F36B0',
  ClientData = 'C16723F382FA4EEA914FFFADD96FA1C3',
  ClientTable = '26EF37AA16014857B7B698C38CBE39D9',
  InternalNotes = 'BD87F560679E4A69BCB4BF27C7EF9B50',
  AllPayerList = '1CE74297F34146E6B44E6530398885C7',
  EMailTemplateReplaceForm = '37D90EB8318E4F1AB42F02B60F6BABFC',
  TransportList = 'B10B1AA744C0440DAB3922314ABAC7A1',
  ItemgroupTable = 'BA258431C7814C8F94A0639AD0BE8DC4',
  BusinessAreaMappingTable = 'E1F728F40BD947848680B472D2772BCE',
  WebhookConfigTable = 'AA369705037C43F8B72A2EA5AB73D163',
  UserGroupListTable = '70333BA9B89E4993B79A9B711B8149AC',
  ItemGroupView = '7B2825EFBDCF49C5B23E621E5699DE71',
  TransportDetails = 'A5B48031205845D4918228E6544AD832',
  PayerTableHidden = '52A0441101AD42C4A7ECA4489B9BB12E',
  CollectiveInvoiceTable = '4F661FA20E6D49988E4F38861774C08A',
  CartTypeTable = 'C35C5F6B87F04640A05CC20FE8895C33',
  EmailTemplateForm = 'F1EACFAE560B49109CE943BE46CB7653',
  DunningLevelView = '0696B76D21114E92B365FB40EC4FF9EC',
  PayerTable = 'A6CAF0CD61DB44F698BA54A53A24A082',
  PermanentOrdinanceForClient = '653938F81E1E44D2948231EBA73D6542',
  AdminSettingsForm = 'DDBABC06E09F474C93E18B7FF88B425F',
  TrapoList = 'C9C8028A88D44898936C841703FBC6AD',
  ItemTypeView = 'F428D3BF103143BE86B514CED228E0E4',
  EpostConfiguration = '7A24B0B02FBA437A8CB00FA169465421',
}
