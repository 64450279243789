import {Component} from '@angular/core';
import {CustomCellComponent} from '../custom-cell/custom-cell.component';

@Component({
  selector: 'app-percentage-cell-renderer',
  templateUrl: './percentage-cell-renderer.component.html',
  styleUrls: ['../custom-cell/custom-cell.component.scss'],
})
export class PercentageCellRendererComponent extends CustomCellComponent {
}
