import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {ReferenceCellEditorComponent} from './editors/reference-cell-editor/reference-cell-editor.component';
import {TreeModule} from '@ali-hm/angular-tree-component';

import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {MatButtonModule} from '@angular/material/button';
import {CustomCellEditorComponent} from './editors/custom-cell-editor/custom-cell-editor.component';
import {DateCellEditorComponent} from './editors/date-cell-editor/date-cell-editor.component';
import {EnumCellEditorComponent} from './editors/enum-cell-editor/enum-cell-editor.component';
import {NgbDatepickerModule} from '@ng-bootstrap/ng-bootstrap';
import {SharedComponentsModule} from '../../shared-components/shared-components.module';
import {
  TimeRangeCellEditorComponent
} from './editors/custom-cell-editor/time-range-cell-editor/time-range-cell-editor.component';
import {DateCellRendererComponent} from './renderers/date-cell-renderer/date-cell-renderer.component';
import {EnumCellRendererComponent} from './renderers/enum-cell-renderer/enum-cell-renderer.component';
import {ReferenceCellRendererComponent} from './renderers/reference-cell-renderer/reference-cell-renderer.component';
import {
  ReferenceCellSelectionRendererComponent
} from './renderers/reference-cell-selection-renderer/reference-cell-selection-renderer.component';
import {CustomCellComponent} from './renderers/custom-cell/custom-cell.component';
import {DayColumnHeaderComponent} from './renderers/day-column-header/day-column-header.component';
import {CellErrorIconComponent} from './cell-error-icon/cell-error-icon.component';
import {TableControlComponent} from './table-control.component';
import {TreeControlModule} from '../tree-control/tree-control.module';
import {AgGridModule} from 'ag-grid-angular';
import {FormCellEditorComponent} from './editors/form-cell-ceditor/form-cell-editor.component';
import {RepositoryFilterComponent} from './filters/repository-filter/repository-filter.component';
import {ColorCellRendererComponent} from './renderers/color-cell-renderer/color-cell-renderer.component';
import {ColorCellEditorComponent} from './editors/color-cell-editor/color-cell-editor.component';
import {ColorPickerModule} from 'ngx-color-picker';
import {LoadingRendererComponent} from './renderers/loading-renderer/loading-renderer.component';
import {ControlRendererModule} from '../control-renderer/control-renderer.module';
import {LoadingComponent} from './overlays/loading/loading.component';
import {PercentageCellRendererComponent} from './renderers/percentage-cell-renderer/percentage-cell-renderer.component';
import {PercentageCellEditorComponent} from './editors/percentage-cell-editor/percentage-cell-editor.component';
import {BooleanCellEditorComponent} from './editors/boolean-cell-editor/boolean-cell-editor.component';
import {BooleanCellRendererComponent} from './renderers/boolean-cell-renderer/boolean-cell-renderer.component';
import {
  TimeOfDayCellRendererComponent
} from './renderers/time-of-day-cell-renderer/time-of-day-cell-renderer.component';
import {NgxMaterialTimepickerModule} from 'ngx-material-timepicker';
import {TimeOfDayCellEditorComponent} from './editors/time-of-day-cell-editor/time-of-day-cell-editor.component';
import {AutocompleteCellEditorComponent} from './editors/autocomplete-cell-editor/autocomplete-cell-editor.component';
import {
  AutocompleteCellRendererComponent
} from './renderers/autocomplete-cell-renderer/autocomplete-cell-renderer.component';
import {DynamicFormModule} from '../form-control/dynamic-form/dynamic-form.module';
import {CurrencyCellRendererComponent} from './renderers/currency-cell-renderer/currency-cell-renderer.component';
import {
  DocumentCellRendererSharedComponent
} from './renderers/document-cell-renderer/document-cell-renderer-shared.component';
import {CustomMaterialModule} from 'frontier/nucleus';
import {
  ClientSideTableControlComponent
} from 'frontier/browserkit/src/lib/control/table-control/client-side-table-control/client-side-table-control.component';
import {
  CustomBoolCellRendererComponent
} from 'frontier/browserkit/src/lib/control/table-control/renderers/custom-bool-cell-renderer/custom-bool-cell-renderer.component';
import {ValueOrFunctionPipe} from 'frontier/browserkit/src/lib/pipes/value-or-function.pipe';

@NgModule({
  declarations: [
    TableControlComponent,
    ReferenceCellEditorComponent,
    CustomCellEditorComponent,
    DateCellEditorComponent,
    EnumCellEditorComponent,
    TimeRangeCellEditorComponent,
    DateCellRendererComponent,
    EnumCellRendererComponent,
    ReferenceCellRendererComponent,
    ReferenceCellSelectionRendererComponent,
    CustomCellComponent,
    DayColumnHeaderComponent,
    CellErrorIconComponent,
    FormCellEditorComponent,
    RepositoryFilterComponent,
    ColorCellRendererComponent,
    ColorCellEditorComponent,
    LoadingRendererComponent,
    LoadingComponent,
    PercentageCellRendererComponent,
    PercentageCellEditorComponent,
    BooleanCellEditorComponent,
    BooleanCellRendererComponent,
    TimeOfDayCellRendererComponent,
    TimeOfDayCellEditorComponent,
    AutocompleteCellEditorComponent,
    AutocompleteCellRendererComponent,
    CurrencyCellRendererComponent,
    DocumentCellRendererSharedComponent,
    ClientSideTableControlComponent,
    CustomBoolCellRendererComponent
  ],
  exports: [
    TableControlComponent,
    ReferenceCellEditorComponent,
    CustomCellEditorComponent,
    DateCellEditorComponent,
    EnumCellEditorComponent,
    TimeRangeCellEditorComponent,
    DateCellRendererComponent,
    EnumCellRendererComponent,
    ReferenceCellRendererComponent,
    ReferenceCellSelectionRendererComponent,
    CustomCellComponent,
    DayColumnHeaderComponent,
    CellErrorIconComponent,
    AgGridModule,
    TreeModule,
    ClientSideTableControlComponent
  ],
  imports: [
    CommonModule,
    TreeModule,
    FormsModule,
    MatButtonModule,
    CustomMaterialModule,
    NgbDatepickerModule,
    SharedComponentsModule,
    TreeControlModule,
    AgGridModule,
    ColorPickerModule,
    ControlRendererModule,
    NgxMaterialTimepickerModule, //
    ReactiveFormsModule,
    DynamicFormModule,
    ValueOrFunctionPipe,
  ],
})
export class TableModule {
}
