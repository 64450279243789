import {Component, EventEmitter, Input, Output, signal, WritableSignal} from '@angular/core';
import {ColDef, ColGroupDef, GridApi, GridOptions} from 'ag-grid-community';
import {IClientMessageDetails} from './client-message-details.interface';

@Component({
  selector: 'app-client-message-details',
  templateUrl: './client-message-details.component.html',
  styleUrl: './client-message-details.component.scss'
})
export class ClientMessageDetailsComponent {
  rowData: WritableSignal<any[]> = signal(undefined);
  actionDescription: WritableSignal<string> = signal('');
  documentAssigned: WritableSignal<boolean> = signal(false);
  displayAction: WritableSignal<boolean> = signal(false);
  gridApi: GridApi<any>;

  @Input() set messageData(data: IClientMessageDetails) {
    if (!data) return;
    this.rowData.set(data.rows);
    this.actionDescription.set(data.actionDescription);
    this.documentAssigned.set(data.documentAssigned);
    this.displayAction.set(data.displayAction);
  };

  @Output() resolveAutomatically = new EventEmitter();
  @Output() resolveManually = new EventEmitter();
  @Output() showDocument = new EventEmitter();

  gridOptions: GridOptions<any> = {
    rowHeight: 30,
    headerHeight: 0,
    onGridReady: (event) => event.api.sizeColumnsToFit()
  };
  colDefs: ColDef[] = [
    {field: 'label', width: 205, maxWidth: 205, minWidth: 205, cellStyle: {'font-weight': 'bold'}},
    {field: 'value'},
  ];

  onResolveAutomatically() {
    this.resolveAutomatically.emit()
  }

  onResolveManually() {
    this.resolveManually.emit()
  }

  onShowDocument() {
    this.showDocument.emit()
  }
}
