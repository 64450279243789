import {Component, EventEmitter, Input, Output, ViewChild} from '@angular/core';
import {NG_VALUE_ACCESSOR} from '@angular/forms';
import {IGranularity} from './granularity.interface';
import {EGranularity} from '../../calendar/granularity.enum';
import {MatSelect, MatSelectChange} from '@angular/material/select';
import {EsvgFiles} from 'frontier/nucleus';

/**
 * Component for rotating granularity value
 */
@Component({
  selector: 'kpi4me-granularity-rotator',
  templateUrl: './granularity-rotator.component.html',
  styleUrls: ['./granularity-rotator.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: GranularityRotatorComponent,
    },
  ],
})
export class GranularityRotatorComponent {
  touched = false;
  @Output() granularityChange = new EventEmitter<number>();
  @ViewChild(MatSelect) select: MatSelect;

  private _granularities: IGranularity[] = [
    {name: 'Monat', id: EGranularity.month, shortcut: 'M'},
    {name: 'Quartal', id: EGranularity.quarter, shortcut: 'Q'},
    {name: 'Halbjahr', id: EGranularity.halfyear, shortcut: 'H'},
    {name: 'Jahr', id: EGranularity.year, shortcut: 'J'},
  ];

  get granularities(): IGranularity[] {
    return this._granularities;
  }

  /**
   * the granularities which will be rotated through.
   * @param g
   */
  @Input() set granularities(g: IGranularity[]) {
    this._granularities = g;
  }

  private _granularity: any;

  get granularity(): number {
    return this._granularity;
  }

  /**
   * The selected granularity
   * @param g
   */
  @Input() set granularity(g: number) {
    this._granularity = g;
  }

  onChange = (granularity: number) => {
    this.granularityChange.emit(granularity);
  };
  onTouched = () => {
  };

  selectedGranularity(): { shortcut: string; name: string } {
    return this.granularities.find((g) => g.id == this.granularity);
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  writeValue(obj: number): void {
    this.granularity = obj;
  }

  /**
   * Rotates through the given granularites
   * @param currentGranularity
   * @param $event
   */
  rotateGranularity(currentGranularity: number, $event: any) {
    this.select.open();
    return;
    let currentIndex = this.granularities.findIndex(
      (v) => v.id == this.granularity
    );
    currentIndex++;
    currentIndex %= this.granularities.length;
    this.granularity = this.granularities[currentIndex].id;
    this.onChange(this.granularity);
    if (!this.touched) {
      this.onTouched();
      this.touched = true;
    }
  }

  onGranularitySelectionChange(evt: MatSelectChange) {
    this.onChange(this.granularity);
  }

  protected readonly EsvgFiles = EsvgFiles;
}
