import {Component, EventEmitter, Input, OnDestroy, Output, ViewEncapsulation} from '@angular/core';
import {AbstractControl, FormControl, FormGroup, Validators} from '@angular/forms';
import {IForm, IFormElement, IFormGroup} from './form-element/form-data.interface';
import {Subscription} from 'rxjs';

@Component({
  selector: 'kpi4me-dynamic-form',
  templateUrl: './dynamic-form.component.html',
  styleUrls: ['./dynamic-form.component.scss'],
  encapsulation: ViewEncapsulation.Emulated,
})
export class DynamicFormComponent implements OnDestroy {

  constructor() {
  }

  @Output() formChange = new EventEmitter<any>();
  @Input() displayHeaders = true;
  @Input() standardForm: boolean;
  @Input() hasImports = false;
  @Input() showImports = false;

  private subs = new Subscription();

  private _formData: IForm;
  form: FormGroup;

  private controlFormObjects: any = {};

  @Input() set formData(form: IForm) {
    if (form == null) return;

    console.log('Received form: ', form);

    this._formData = form;

    if (this.standardForm) {
      this._formData = form;
    } else {
      this._formData = {
        confirmable: form.confirmable,
        formGroups: form.formGroups.map((fg) => {
          return {
            ...fg,
            elements: fg.elements.map((el: IFormElement) => {
              return {
                ...el,
                tableLabel: el.label,
                label: null,
              };
            }),
          };
        }),
      };
    }

    const group: any = {};
    this.formData.formGroups.forEach(fg => {
      let maxCol = 1;
      fg.elements.forEach(baseElement => {
        maxCol = Math.max(maxCol, baseElement.styling?.columnEnd == null ? 1 : baseElement.styling.columnEnd);

        // save reference for remap
        this.controlFormObjects[baseElement.key] = baseElement;

        // create a form group json object
        group[baseElement.key] = new FormControl(baseElement.value != null ? baseElement.value : null);
        if (baseElement.required) {
          group[baseElement.key].addValidators([Validators.required]);
        }
      });
      fg.maxCol = String(maxCol - 1);
    })
    this.form = new FormGroup(group);
  }

  get formData() {
    return this._formData;
  }

  ngOnDestroy() {
    this.subs.unsubscribe();
  }

  getTemplateColumns(fg: IFormGroup) {
    if (fg.maxCol === null) {
      return '';
    }

    return 'repeat(' + fg.maxCol + ', 1fr)'
  }

  onFocusOutOnFormControlElement(fc: FormControl, fg: IFormGroup) {
    console.log('onFocusOutOnFormControlElement');
    // map the form control to the initial db object and set the value to the input value
    const elements = [{...this.controlFormObjects[this.getControlName(fc)], value: fc.value}];
    this.formChange.emit({elements, formGroup: fg})
  }

  getControlName(c: AbstractControl): string | null {
    const formGroup: any = c.parent.controls;
    return Object.keys(formGroup).find(name => c === formGroup[name]) || null;
  }
}
