import {Observable} from 'rxjs';
import {ApiConfigService} from '../api-config.service';
import {HttpHeaders} from '@angular/common/http';
import {IControlObject} from '../../interfaces/control-object.interface';

export class Singleblockcoursetable {
  http;
  url;
  httpOptions;
  constructor(protected appConfig: ApiConfigService) {
    this.http = appConfig.http;
    this.url = appConfig.url;
    this.httpOptions = appConfig.httpOptions;
  }


  createnextblock(InstanceId: string, timeblock: IControlObject) : Observable<any> {
   return this.http.post(
      this.url + '/intern/api/control/ExecuteAction/' + InstanceId + '/createnextblock/', {"timeblock": timeblock}, this.httpOptions
    ) as unknown as Observable<any>;
  }

}
