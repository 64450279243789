import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
  ViewChild,
  ViewEncapsulation
} from '@angular/core';
import {
  ApiAdapter,
  BaseControlComponent,
  CoreService,
  ECalculateImportValue,
  EControlActions,
  StoreService,
} from 'frontier/nucleus';
import {DynamicFormComponent} from '../dynamic-form.component';
import {
  EFormControlType,
  IForm,
  IFormGroup,
  ISelectFormElement,
  ISelectFormOption,
} from '../form-element/form-data.interface';
import {BehaviorSubject, concatMap, Observable, Subject} from "rxjs";
import {catchError, debounceTime, filter, map, take, tap} from "rxjs/operators";
import {takeUntilDestroyed} from '@angular/core/rxjs-interop';

class FormApiAdapter extends ApiAdapter {
  from(apiData: IForm): IForm {
    apiData.formGroups.forEach(fg => {
      fg.elements.forEach(fe => {
        if (fe.formControlType === EFormControlType.select) {
          const selectFe = fe as ISelectFormElement;
          if (typeof fe.value !== "object") {
            selectFe.options = selectFe.options.map((o, i) => {
              return {
                value: i,
                name: String(o)
              } as ISelectFormOption
            })
          } else {
            selectFe.options = selectFe.options.map((o) => {
              return {
                value: o,
                name: String(o.name)
              } as ISelectFormOption
            })
          }
        }
      })
    })
    return apiData;
  }

  to(data: any): any {
    return data;
  }
}

@Component({
  selector: 'kpi4me-dynamic-form-control',
  templateUrl: './dynamic-form-control.component.html',
  styleUrls: ['./dynamic-form-control.component.scss'],
  encapsulation: ViewEncapsulation.Emulated,
})
export class DynamicFormControlComponent extends BaseControlComponent implements OnChanges {
  @ViewChild(DynamicFormComponent) formRef: DynamicFormComponent;
  @Input() apiAdapter: ApiAdapter = new FormApiAdapter();
  @Input() displayHeaders = true;

  private _selectedElement: any;
  @Input() set selectedElement(element: any) {
    this.isFormChanging$.pipe(
      filter(v => v === false),
      take(1),
      tap(() => this._selectedElement = element)
    ).subscribe()
  };
  get selectedElement() {
    return this._selectedElement;
  }

  @Input() standardForm = true;
  @Input() showImports = false;
  @Output() formChange = new EventEmitter<any>();

  private formChange$ = new Subject<{ elements: any[], formGroup: IFormGroup }>();
  private _delayedFormChange$ = this.formChange$.pipe(debounceTime(500));
  isFormChanging$ = new BehaviorSubject(false);

  constructor(
    protected coreService: CoreService,
    protected store: StoreService,
    protected cdr: ChangeDetectorRef,
  ) {
    super(coreService, store, cdr);

    this._delayedFormChange$.pipe(takeUntilDestroyed()).pipe(
      concatMap(evt => this.coreService.ActionForm.changevalues(this.apiInstance.instanceid, evt.elements, this.selectedElement ? this.selectedElement : null)
        .pipe(
          catchError(() => this.changeAndFetch()),
          map(res => ({res, evt}))
        )
      ),
      tap(({res, evt}) => {
        // this.data = res;
        res = this.apiAdapter.from(res);
        const respectiveFormGroup = res.formGroups.find((fg: IFormGroup) => fg.header === evt.formGroup.header);
        const formGroup = respectiveFormGroup.elements.reduce((acc: any, e: { key: any; value: any; }) => {
          acc[e.key] = e.value;
          return acc;
        }, {});
        this.formRef.form.patchValue(formGroup, {emitEvent: false});
        this.coreService.controlDataChanged.emit({GUID: this.GUID, changeType: EControlActions.changeLine});

        this.formChange.emit(res);
        this.isFormChanging$.next(false);
      })
    ).subscribe()
  }

  ngOnChanges(changes: SimpleChanges): void {
    // Fetch new values only if both showImports has been changed to true and the imports haven't already been loaded
    if (changes.showImports && changes.showImports.currentValue && this.formRef
      && this.apiInstance.filter.calculateimportvalue !== ECalculateImportValue.first) {
      this.changeAndFetch().subscribe();
    }
  }

  @Input() onFormChange(evt: { elements: any[], formGroup: IFormGroup }) {
    this.isFormChanging$.next(true);
    console.log('form change', evt);
    this.formChange$.next(evt);
    // response value is the entire form with changes
  }

  changeAndFetch(): Observable<any> {
    this.apiInstance = {
      ...this.apiInstance,
      filter: {
        ...this.apiInstance.filter,
        calculateimportvalue: this.showImports ? ECalculateImportValue.first : ECalculateImportValue.off,
      }
    }
    return super.changeAndFetch().pipe(
      map(data => this.apiAdapter.from(data)),
    );
  }
}
