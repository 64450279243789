<app-dialog-header
  [dialogRef]="dialogRef"
  [title]="'In welche Fahrten sollen die Daten eingetragen werden?'"
  (close)="dialogRef.close()"
>
</app-dialog-header>
<mat-dialog-content>
  @if (data.carts?.length === 0) {
    <div>
      Es wurden keine weiteren Fahrten für den
      Patienten {{ data.client?.client?.firstname }} {{ data.client?.client?.lastname }} gefunden
    </div>
  } @else {
    <ag-grid-angular
      style="height: 20rem; width: 940px"
      class="ag-theme-alpine"
      [rowData]="rowData"
      [columnDefs]="colDefs"
      (gridReady)="onGridReady($event)"
      [gridOptions]="gridOptions"
      [getRowId]="getRowId"
    >

    </ag-grid-angular>
  }
</mat-dialog-content>

<mat-dialog-actions>
  <app-confirm-icon-button
    (customClick)="onConfirm()"
    [disabled]="data.carts?.length > 0 && gridApi?.getSelectedNodes().length === 0"
  >
  </app-confirm-icon-button>
</mat-dialog-actions>

