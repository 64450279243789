import {ChangeDetectorRef, Component, EventEmitter, Inject, OnInit, Output, ViewChild} from '@angular/core';
import {ReplaySubject, Subject, Subscription} from 'rxjs';
import {GoldenLayoutComponent, IExtendedGoldenLayoutConfig} from '@kpi4me/golden-angular-layout';
import {filter} from 'rxjs/operators';
import {DOCUMENT} from '@angular/common';
import {ThemeService} from '../../theme/theme.service';
import {ControlManagerService} from '../../control-manager/control-manager.service';
import {LayoutHosterBaseComponent} from '../layout-hoster-base/layout-hoster-base.component';
import {TGoldenLayoutKeys} from '../view.interface';
import {IControl} from "../../control/table-control/interfaces/displayed-columns.interface";
import {SvgService} from 'frontier/nucleus';

/**
 * The root golden layout host component.
 */
@Component({
  selector: 'app-layout-root-host',
  templateUrl: './layout-root-host.component.html',
  styleUrls: ['./layout-root-host.component.scss']
})
export class LayoutRootHostComponent extends LayoutHosterBaseComponent implements OnInit {
  @Output() stateChanged = new Subject();
  @Output() openComponent = new EventEmitter<IControl>();
  layoutKey: TGoldenLayoutKeys = 'root';
  @ViewChild(GoldenLayoutComponent, {static: true}) gl: GoldenLayoutComponent;

  subs = new Subscription();
  layout$: ReplaySubject<IExtendedGoldenLayoutConfig> = new ReplaySubject<IExtendedGoldenLayoutConfig>(1);

  constructor(
    public themeService: ThemeService,
    protected svgService: SvgService,
    @Inject(DOCUMENT) protected document: Document,
    protected controlManagerService: ControlManagerService,
    protected cdr: ChangeDetectorRef,
  ) {
    super(themeService, svgService, document, controlManagerService, cdr);
  }

  ngOnInit(): void {
    if (!window.opener) {
      // Listen for layout loading events. Get the course planner layout only and pass it to this layout.
      this.subs.add(
        this.controlManagerService.loadView$.pipe(
          filter(v => {
          return v != null && v.goldenLayoutStates[this.layoutKey] != null
        })).subscribe(v => {
          this.layout$.next(v.goldenLayoutStates[this.layoutKey]);
        })
      )
    }
  }
}
