<app-dialog-header
  [title]="this.getTitle()"
  [dialogRef]="dialogRef"
  (close)="onCancel()"
></app-dialog-header>

<mat-dialog-content>
  <kpi4me-text-element
    [formControlElement]="this.option"
    [data]="textElementConfig"
  ></kpi4me-text-element>
</mat-dialog-content>

<div mat-dialog-actions style="margin-top:1rem;">
  <app-confirm-icon-button [disabled]="option.invalid" (customClick)="onConfirm()"></app-confirm-icon-button>
</div>
