import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {LayoutRootHostComponent} from './golden-layout/layout-root-host/layout-root-host.component';
import {TreeControlModule} from './control/tree-control/tree-control.module';
import {TreeTableEditor} from './control/tree-and-table-editor/tree-table-editor.component';
import {TreeMenuComponent} from './control/tree-and-table-editor/tree-menu/tree-menu.component';
import {TableModule} from './control/table-control/table.module';
import {ChartControlComponent} from './control/chart-control/chart-control.component';
import {ControlFormDialogComponent} from './control/control-form-dialog/control-form-dialog.component';
import {FormControlComponent} from './control/form-control/form-control.component';
import {ControlRendererModule} from './control/control-renderer/control-renderer.module';
import {LoginComponent} from './login/login.component';
import {UserMenuComponent} from './toolbar/user-menu/user-menu.component';
import {EntitySelectorComponent} from './dialogs/entity-selector/entity-selector.component';
import {BackupDialogComponent} from './dialogs/backup-dialog/backup-dialog.component';
import {SetUserComponent} from './dialogs/set-user/set-user.component';
import {ConfirmButtonDirective} from './directives/confirm-button.directive';
import {ConfirmationDialogComponent} from './dialogs/basics/confirmation-dialog/confirmation-dialog.component';
import {ProgressSpinnerDialogComponent} from './dialogs/progress-spinner-dialog/progress-spinner-dialog.component';
import {ToolbarComponent} from './toolbar/toolbar.component';
import {UploadWrapperComponent} from './upload-wrapper/upload-wrapper.component';
import {DropUploadDirective} from './upload-wrapper/drop-upload.directive';
import {ResetPasswordComponent} from './reset-password/reset-password.component';
import {SetPasswordDialogComponent} from './dialogs/basics/set-password-dialog/set-password-dialog.component';
import {RemoveDatepickerValidationDirective} from './directives/remove-datepicker-validation.directive';
import {CalendarModule} from './calendar/calendar.module';
import {DialogService} from './dialogs/dialog.service';
import {CustomMaterialModule} from 'frontier/nucleus';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {NgbDatepickerModule, NgbTooltipModule,} from '@ng-bootstrap/ng-bootstrap';
import {GoogleChartsModule} from 'angular-google-charts';
import {RouterModule} from '@angular/router';
import {TableVerticalControlComponent} from './control/table-vertical-control/table-vertical-control.component';
import {CellEditorComponent} from './control/table-vertical-control/cell-editor/cell-editor.component';
import {
  TextCellEditorComponent
} from './control/table-vertical-control/cell-editor/text-cell-editor/text-cell-editor.component';
import {TableRowSelectionComponent} from './control/table-row-selection/table-row-selection.component';
import {
  BaseCellEditorComponent
} from './control/table-vertical-control/cell-editor/base-cell-editor/base-cell-editor.component';
import {
  RepositorySelectionCellEditorComponent
} from './control/table-vertical-control/cell-editor/repository-selection-cell-editor/repository-selection-cell-editor.component';
import {
  DateCellEditorComponent
} from './control/table-vertical-control/cell-editor/date-cell-editor/date-cell-editor.component';
import {
  BoolCellEditorComponent
} from './control/table-vertical-control/cell-editor/bool-cell-editor/bool-cell-editor.component';
import {
  NumberCellEditorComponent
} from './control/table-vertical-control/cell-editor/number-cell-editor/number-cell-editor.component';
import {
  EnumCellEditorComponent
} from './control/table-vertical-control/cell-editor/enum-cell-editor/enum-cell-editor.component';
import {
  LongTextCellEditorComponent
} from './control/table-vertical-control/cell-editor/long-text-cell-editor/long-text-cell-editor.component';
import {
  AutocompleteEditorComponent
} from './control/table-vertical-control/cell-editor/autocomplete-editor/autocomplete-editor.component';
import {UserListComponent} from './shared-components/user-list/user-list.component';
import {UserGroupListComponent} from './shared-components/user-group-list/user-group-list.component';
import {WebhookComponent} from "./shared-components/webhook/webhook.component";
import {TreeRowSelectionComponent} from './control/table-row-selection/tree-row-selection.component';
import {ImageViewComponent} from './dialogs/image-view/image-view.component';
import {DynamicFormModule} from './control/form-control/dynamic-form/dynamic-form.module';
import {SmtpComponent} from './control-views/smtp/smtp.component';
import {UserComponent} from './control-views/user/user.component';
import {UserGroupComponent} from './control-views/user-group/user-group.component';
import {MatDrawerContentComponent} from './mat-drawer-content/mat-drawer-content.component';
import {CustomSnackbarComponent, FeedbackService,} from './user-feedback/feedback.service';
import {TableRowDialogComponent} from './control/table-vertical-control/table-row-dialog/table-row-dialog.component';
import {ResizedDirective} from './directives/resize.directive';
import {SnzHpModule} from './snz-hp/snz-hp.module';
import {NewEntryDialogComponent} from './dialogs/basics/new-entry-dialog/new-entry-dialog.component';
import {ToolbarSharedComponent} from './toolbar/toolbar-shared/toolbar-shared.component';
import {HomeComponent} from './home/home.component';
import {ControlManagerModule} from './control-manager/control-manager.module';
import {SharedComponentsModule} from './shared-components/shared-components.module';
import {TenantMenuComponent} from './toolbar/tenant-menu/tenant-menu.component';
import {DynamicControlComponent} from "./shared-components/dynamic-control/dynamic-control.component";
import {GuidTableComponent} from "frontier/browserkit/src/lib/shared-components/guid-table/guid-table.component";
import {GuidFormComponent} from "frontier/browserkit/src/lib/shared-components/guid-form/guid-form.component";
import {EasyDatePickerModule} from "./calendar/easy-date-picker/easy-date-picker.module";
import {CalendarService} from "./services/calendar.service";

// @ts-ignore
import * as $ from 'jquery';
import {CrudControlComponent} from './control/crud-control/crud-control.component';
import {LayoutRootComponent} from './layout-root/layout-root.component';
import {FormControlPipe} from './pipes/form-control-pipe';
import {KeycloakRedirectComponent} from './authentication/keycloak-redirect/keycloak-redirect.component';
import {OptionsMenuComponent} from './toolbar/options-menu/options-menu.component';
import {
  ReasoningDialogComponent
} from 'frontier/browserkit/src/lib/dialogs/basics/reasoning-dialog/reasoning-dialog.component';
import {GoldenLayoutModule} from '@kpi4me/golden-angular-layout';
import {
  DatePickerDialogComponent
} from 'frontier/browserkit/src/lib/dialogs/date-picker-dialog/date-picker-dialog.component';
import {PdfDialogComponent} from 'frontier/browserkit/src/lib/dialogs/pdf-dialog/pdf-dialog.component';
import {PdfViewerModule} from 'ng2-pdf-viewer';
// It is required to have JQuery as global in the window object.
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
window['$'] = $;

@NgModule({
  declarations: [
    LayoutRootComponent,
    LayoutRootHostComponent,
    TreeTableEditor,
    TreeMenuComponent,
    ChartControlComponent,
    ControlFormDialogComponent,
    FormControlComponent,
    LoginComponent,
    ToolbarComponent,
    UserMenuComponent,
    EntitySelectorComponent,
    SetUserComponent,
    ConfirmButtonDirective,
    ConfirmationDialogComponent,
    BackupDialogComponent,
    ProgressSpinnerDialogComponent,
    UploadWrapperComponent,
    ResetPasswordComponent,
    SetPasswordDialogComponent,
    TableVerticalControlComponent,
    CellEditorComponent,
    TextCellEditorComponent,
    TableRowSelectionComponent,
    BaseCellEditorComponent,
    RepositorySelectionCellEditorComponent,
    DateCellEditorComponent,
    BoolCellEditorComponent,
    NumberCellEditorComponent,
    EnumCellEditorComponent,
    LongTextCellEditorComponent,
    AutocompleteEditorComponent,
    UserListComponent,
    WebhookComponent,
    UserGroupListComponent,
    TreeRowSelectionComponent,
    ImageViewComponent,
    CustomSnackbarComponent,
    TableRowDialogComponent,
    SmtpComponent,
    UserComponent,
    UserGroupComponent,
    MatDrawerContentComponent,
    ResizedDirective,
    NewEntryDialogComponent,
    ToolbarSharedComponent,
    HomeComponent,
    TenantMenuComponent,
    DynamicControlComponent,
    CrudControlComponent,
    GuidTableComponent,
    GuidFormComponent,
    FormControlPipe,
    OptionsMenuComponent,
    KeycloakRedirectComponent,
    ReasoningDialogComponent,
    DatePickerDialogComponent,
    PdfDialogComponent
  ],
  imports: [
    CommonModule,
    TreeControlModule,
    TableModule,
    ControlRendererModule,
    CalendarModule,
    ReactiveFormsModule,
    NgbTooltipModule,
    FormsModule,
    GoogleChartsModule,
    GoldenLayoutModule,
    NgbTooltipModule,
    CustomMaterialModule,
    RouterModule,
    NgbDatepickerModule,
    SnzHpModule,
    DynamicFormModule,
    RemoveDatepickerValidationDirective,
    DropUploadDirective,
    ControlManagerModule.forRoot(),
    SharedComponentsModule,
    EasyDatePickerModule,
    PdfViewerModule
  ],
  exports: [
    LayoutRootHostComponent,
    TreeTableEditor,
    TreeControlModule,
    TableModule,
    ChartControlComponent,
    FormControlComponent,
    ToolbarComponent,
    UserMenuComponent,
    UploadWrapperComponent,
    LoginComponent,
    TableVerticalControlComponent,
    TableRowSelectionComponent,
    TreeRowSelectionComponent,
    MatDrawerContentComponent,
    CellEditorComponent,
    ResizedDirective,
    NewEntryDialogComponent,
    ToolbarSharedComponent,
    HomeComponent,
    TenantMenuComponent,
    CrudControlComponent,
    FormControlPipe,
    ReasoningDialogComponent,
    GuidFormComponent
  ],
  providers: [DialogService, FeedbackService, CalendarService],
})
export class BrowserkitModule {
}
