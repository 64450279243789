<div
  style="visibility: hidden;"
  [matMenuTriggerFor]="optionsMenu"
></div>

<mat-menu
  #optionsMenu="matMenu"
  xPosition="before"
  class="customized-menu"
>
  <div
    mat-menu-item
    *ngFor="let option of options"
    (click)="optionTriggered.emit(option.id)"
    style="display: flex; flex-direction: row"
  >
    <span>{{option.label}}</span>

    <mat-icon
      *ngIf="option.state"
      [svgIcon]="EsvgFiles.correct"
      style="margin-right: unset"
    ></mat-icon>
  </div>
</mat-menu>
