<!--<mat-select style="width: 100%" [(ngModel)]="row.value" (ngModelChange)="onSelectionChange()"-->
<!--        class="form-select" aria-label="Default select example">-->
<!--  <mat-option [value]="i + 1" *ngFor="let o of row.repository; index as i">{{o}}</mat-option>-->
<!--</mat-select>-->

<kpi4me-select-element
  class="form-field-no-padding"
  [formControlElement]="formControl"
  (modelChange)="onSelectionChange()"
  [editEnabled]="false"
  [deleteEnabled]="false"
  [data]="selectElementData"
  [compareWith]="compareWith"
></kpi4me-select-element>
