<kpi4me-text-element
  [formControlElement]="tableGuid"
  [data]="tableGuidFormData"
  (modelChange)="onTableGuidChange($event)"
>

</kpi4me-text-element>
<ng-container *ngIf="render">
  <app-table-control #table
                     *ngIf="tableGuid.value"
                     [GUID]="tableGuid.value"
                     (apiInstanceInitialize)="table.changeAndFetch().subscribe()"
  ></app-table-control>

</ng-container>
