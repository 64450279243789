import {Component, HostListener, Inject} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {ControlManagerService} from '../../control-manager.service';

@Component({
  selector: 'app-new-layout-dialog',
  templateUrl: './new-layout-dialog.component.html',
  styleUrls: ['./new-layout-dialog.component.scss'],
})
export class NewLayoutDialogComponent {
  form = new FormGroup({name: new FormControl('')}, [Validators.required]);

  constructor(
    public dialogRef: MatDialogRef<NewLayoutDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: NewLayoutDialogComponent,
    private controlManager: ControlManagerService
  ) {
  }


  @HostListener('window:keyup.Enter', ['$event'])
  onDialogClick(event: KeyboardEvent): void {
    this.onConfirm();
  }

  onConfirm() {
    if (this.form.valid) {
      this.dialogRef.close(this.form);
    } else {
      this.dialogRef.close();
    }
  }
}
