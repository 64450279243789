import {ChangeDetectorRef, Component, EventEmitter, Inject, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {IPermanentOrdinance, IPrescriptionAttendanceDialog} from './prescription-attendance-dialog.interface';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {dateObjectToISOString, EcalculusControlGUID, IControlObject} from 'frontier/nucleus';
import {combineLatest, Observable, Subscription, switchMap} from 'rxjs';
import {map, tap} from 'rxjs/operators';
import {CellValueChangedEvent, ColumnApi, GridApi} from 'ag-grid-community';
import {SelectionModel} from '@angular/cdk/collections';
import {DateTime} from 'luxon';
import {
  ClientSideTableControlComponent
} from 'frontier/browserkit/src/lib/control/table-control/client-side-table-control/client-side-table-control.component';

interface Form {
  selectedOrdinance: FormControl<string>;
  attendanceCertificate: FormControl<any>;
  fromDate: FormControl<string>;
  toDate: FormControl<string>;
  selectedCarts: FormControl<IControlObject[]>;
  hasDocument: FormControl<boolean>;
}

@Component({
  selector: 'app-prescription-attendance-dialog',
  templateUrl: './prescription-attendance-dialog.component.html',
  styleUrl: './prescription-attendance-dialog.component.scss'
})
export class PrescriptionAttendanceDialogComponent implements OnInit, OnDestroy {
  @ViewChild(ClientSideTableControlComponent) tableRef: ClientSideTableControlComponent;

  form: FormGroup<Form>;

  selectedCartsModel = new SelectionModel<string>(true);
  clientGUID = EcalculusControlGUID.CartList;

  gridApi: GridApi;
  colApi: ColumnApi;

  constructor(
    public dialogRef: MatDialogRef<PrescriptionAttendanceDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: Partial<IPrescriptionAttendanceDialog>,
    public cdr: ChangeDetectorRef,
    private fb: FormBuilder) {
    this.form = fb.group<Form>({
      selectedOrdinance: fb.control(null, [Validators.required]),
      attendanceCertificate: fb.control(null, [Validators.required]),
      fromDate: fb.control(null, [Validators.required]),
      toDate: fb.control(null, [Validators.required]),
      selectedCarts: fb.control(null),
      hasDocument: fb.control(true)
    });
    this.form.patchValue({...data, selectedOrdinance: null, fromDate: null, toDate: null}, {emitEvent: false});
  }

  protected readonly oncancel = oncancel;

  private subs = new Subscription();
  tableInitialized$ = new EventEmitter();

  ngOnInit() {
    this.subs.add(
      this.selectedOrdinance().pipe(
        map(ordinance => {
          const fromDate = DateTime.fromISO(ordinance.startdate).toFormat('yyyy-MM-dd');
          const toDate = DateTime.fromISO(ordinance.enddate).toFormat('yyyy-MM-dd');
          this.form.patchValue({
            fromDate,
            toDate
          }, {emitEvent: true});
        })).subscribe()
    )

    this.subs.add(
      combineLatest([
        this.tableInitialized$,
        combineLatest(
          [
            this.form.controls.fromDate.valueChanges.pipe(
              map(v => v)),
            this.form.controls.toDate.valueChanges.pipe(
              map(v => v))
          ]
        ).pipe(
          map(([fromDate, toDate]) => {
            return {startdate: fromDate, enddate: toDate}
          })),
      ])
        .pipe(
          switchMap(([evt, dateFilter]) => {
            const signature = this.form.value.selectedOrdinance;

            return this.tableRef.setApiInstanceAndFetch({
              ...this.tableRef.apiInstance,
              filter: {
                ...this.tableRef.apiInstance.filter,
                parent: this.data.permanentOrdinances.find((so => so.signature === signature)),
                typename: 'PermanentOrdinance',
                ...{startdate: dateFilter.startdate, enddate: dateFilter.enddate}
              }
            })
          }),
          tap(() => {
            this.updateSelectionModel();
          })
        )
        .subscribe()
    )

    this.subs.add(
      this.form.controls.hasDocument.valueChanges.pipe(
        tap((b) => {
          if (b) {
            this.form.controls.attendanceCertificate.setValidators([Validators.required])
          }
          else {
            this.form.controls.attendanceCertificate.clearValidators()
          }
          this.form.controls.attendanceCertificate.updateValueAndValidity();
        })
      ).subscribe()
    )

    if (this.data.permanentOrdinances.length === 1) {
      this.form.patchValue({
        selectedOrdinance: this.data.permanentOrdinances[0].signature
      })
    }
  }

  ngOnDestroy() {
    this.subs.unsubscribe();
  }

  selectedOrdinance(): Observable<IPermanentOrdinance> {
    return this.form.controls.selectedOrdinance.valueChanges.pipe(
      map(signature => this.data.permanentOrdinances
        .find((so => so.signature === signature))))
  }

  onCancel() {
    this.dialogRef.close();
  }

  onConfirm() {
    const selectedCarts: IControlObject[] = [];
    this.tableRef.gridRef.api.forEachNode(
      node => {
        console.log(node);
        if (node.data[0].value === true) selectedCarts.push(node.data.apiRow.obj);
      }
    )
    const formValue = this.form.value;
    const dialogRes: IPrescriptionAttendanceDialog = {
      fromDate: dateObjectToISOString(formValue.fromDate),
      toDate: dateObjectToISOString(formValue.toDate),
      selectedCarts,
      selectedOrdinance: this.data.permanentOrdinances.find((so => so.signature === formValue.selectedOrdinance)),
      attendanceCertificate: formValue.hasDocument ? formValue.attendanceCertificate || null : null,
    }
    this.dialogRef.close(dialogRes);
  }

  onPdfUpload(event: any) {
    console.log(event);
  }

  updateSelectionModel() {
    this.selectedCartsModel.clear();
    const selectedRows = this.tableRef.data.rowData.filter(r => r[0].value === true);
    this.selectedCartsModel.select(...selectedRows.map(r => r.apiRow.obj.signature) as string[]);
  }

  onCellValueChange(event: CellValueChangedEvent<any>) {
    this.selectedCartsModel.toggle(event.data.apiRow.obj.signature);
  }
}
