<button [style.flex]="process.categoryId === ECalculusStateFilter.onHold || process.categoryId === ECalculusStateFilter.clientAccessMessages ? '0.3' : 1"
        *ngFor="let process of processes"
        [ngClass]="{
        active: categorySelection?.isSelected(process.categoryId),
        inactive: !categorySelection?.isSelected(process.categoryId)
        }"
        (click)="onProcessClick(process)">
  @if (process.categoryId === ECalculusStateFilter.onHold) {
    <label class="process-header-label">
      <mat-icon [svgIcon]="EsvgFiles.hourglass"></mat-icon>
    </label>
    <kpi4me-evaluation-on-hold
      [process]="process"
      [selectedStates]="stateSelection?.selected"
      [evaluationData]="process.data"
      (barClicked)="onBarClicked($event, process)"
    />
  } @else if (process.categoryId === ECalculusStateFilter.clientAccessMessages) {
    <label class="process-header-label">
      <mat-icon [svgIcon]="EsvgFiles.user"></mat-icon>
    </label>
    <kpi4me-client-access
      [process]="process"
      [selectedStates]="stateSelection?.selected"
      [evaluationData]="process.data"
      (barClicked)="onBarClicked($event, process)"
    />
  }
  @else {
    <label class="process-header-label">
      {{process.label}}
    </label>
    <kpi4me-evaluation
      [process]="process"
      [selectedStates]="stateSelection?.selected"
      [evaluationData]="process.data"
      (barClicked)="onBarClicked($event, process)"
    ></kpi4me-evaluation>
  }
</button>
