import {
  ChangeDetectorRef,
  Component,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import {CoreService, EcalculusControlGUID, IApiControl, IControlObject} from 'frontier/nucleus';
import {
  ControlManagerService,
  DEFAULT_TABLE_ACTIONS,
  SharedGridOptions,
  TableControlComponent,
} from 'frontier/browserkit';
import {GridOptions} from "ag-grid-community";

@Component({
  selector: 'kpi4me-trapo-detail',
  templateUrl: './trapo-detail.component.html',
  styleUrls: ['./trapo-detail.component.scss'],
  encapsulation: ViewEncapsulation.Emulated
})
export class TrapoDetailComponent implements OnInit, OnChanges {
  documentColumnIndex: number;

  @Input() cartParent: IControlObject;
  trapoTableGUID = EcalculusControlGUID.TrapoList;

  @ViewChild('table') tableRef: TableControlComponent;
  tableApiInstance: IApiControl;
  tableActions = DEFAULT_TABLE_ACTIONS;
  gridOptions: GridOptions = {
    ...SharedGridOptions,
  };

  constructor(
    protected cdr: ChangeDetectorRef,
    protected controlManager: ControlManagerService,
    protected coreService: CoreService,
  ) {
  }

  ngOnInit() {
    this.filterAndFetch(this.tableApiInstance);
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.cartParent && this.tableRef) {
      this.filterAndFetch(this.tableApiInstance);
    }
  }

  filterAndFetch(apiInstance: IApiControl): void {
    if (this.cartParent) {
      this.tableApiInstance = {
        ...apiInstance,
        filter: {
          ...this.tableApiInstance?.filter,
          parent: this.cartParent,
          typename: 'cart',
        },
      };
      this.cdr.detectChanges();
      this.tableRef.changeAndFetch().subscribe((res) => {
        this.documentColumnIndex = Number(res.displayedColumns.find((column: any) => {
          return column.headerName === 'Dokument';
        }).field);
      });
    }
  }
}
