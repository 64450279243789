import {NgModule} from '@angular/core';
import {CommonModule, DatePipe} from '@angular/common';
import {AgGridModule} from 'ag-grid-angular';
import {CustomMaterialModule} from '../styles/material.module';
import {GoogleChartsModule} from 'angular-google-charts';

@NgModule({
  declarations: [],
  exports: [],
  imports: [
    CommonModule,
    AgGridModule,
    CustomMaterialModule,
    GoogleChartsModule
  ],
  providers: [DatePipe],
})
export class ControlModule {
}
