import {
  ChangeDetectorRef,
  Component,
  Input,
  OnChanges,
  SimpleChanges,
  ViewChild,
  ViewEncapsulation
} from '@angular/core';
import {EcalculusControlGUID, IApiControl, IControlObject} from 'frontier/nucleus';
import {ControlManagerService, TableVerticalControlComponent} from 'frontier/browserkit';
import {CalculusStateService} from "../../Services/calculus-state.service";

@Component({
  selector: 'kpi4me-crew-detail',
  templateUrl: './crew-detail.component.html',
  styleUrls: ['./crew-detail.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class CrewDetailComponent implements OnChanges {
  @Input() parent: IControlObject;
  @Input() showImports = true;
  @ViewChild('ordinanceForm') ordinanceFormRef: TableVerticalControlComponent;
  @ViewChild('crewForm') crewFormRef: TableVerticalControlComponent;
  ordinanceFormApiInstance: IApiControl;
  crewFormApiInstance: IApiControl;
  ordinanceDetailsGUID = EcalculusControlGUID.OrdinanceList;
  crewDetailsGUID = EcalculusControlGUID.CrewList;

  constructor(
    protected cdr: ChangeDetectorRef,
    protected controlManager: ControlManagerService,
    protected calculusStateService: CalculusStateService,
  ) {
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.parent && this.crewFormRef) {
      this.filterAndFetch(this.crewFormApiInstance, 'crew');
    }
    if (changes.parent && this.ordinanceFormRef) {
      this.filterAndFetch(this.ordinanceFormApiInstance, 'ordinance');
    }
  }

  protected onResize(): void {
  }

  filterAndFetch(apiInstance: any, id: 'ordinance' | 'crew'): void {
    switch (id) {
      case 'ordinance':
        if (this.ordinanceFormRef) {
          this.ordinanceFormApiInstance = {
            ...apiInstance,
            filter: {
              ...this.ordinanceFormApiInstance?.filter,
              parent: this.parent,
              typename: 'cart',
            },
          };

          this.cdr.detectChanges();
          this.ordinanceFormRef.changeAndFetch().subscribe();
        }
        break;
      case 'crew':
        if (this.crewFormRef) {
          this.crewFormApiInstance = {
            ...apiInstance,
            filter: {
              ...this.crewFormApiInstance?.filter,
              parent: this.parent,
              typename: 'cart',
            },
          };

          this.cdr.detectChanges();
          this.crewFormRef.changeAndFetch().subscribe();
        }
        break;
    }
  }
}
