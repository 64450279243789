import {Observable} from 'rxjs';
import {ApiConfigService} from '../api-config.service';
import {HttpHeaders} from '@angular/common/http';
import {IControlObject} from '../../interfaces/control-object.interface';

export class Externalsystemdatauploadtable {
  http;
  url;
  httpOptions;
  constructor(protected appConfig: ApiConfigService) {
    this.http = appConfig.http;
    this.url = appConfig.url;
    this.httpOptions = appConfig.httpOptions;
  }


  markasdeprecated(InstanceId: string, unploadref: IControlObject) : Observable<boolean> {
   return this.http.post(
      this.url + '/intern/api/control/ExecuteAction/' + InstanceId + '/markasdeprecated/', {"unploadref": unploadref}, this.httpOptions
    ) as unknown as Observable<boolean>;
  }

  loadalldata(InstanceId: string, unploadref: IControlObject) : Observable<string> {
   return this.http.post(
      this.url + '/intern/api/control/ExecuteAction/' + InstanceId + '/loadalldata/', {"unploadref": unploadref}, this.httpOptions
    ) as unknown as Observable<string>;
  }

  loaddata(InstanceId: string, unploadref: IControlObject) : Observable<string> {
   return this.http.post(
      this.url + '/intern/api/control/ExecuteAction/' + InstanceId + '/loaddata/', {"unploadref": unploadref}, this.httpOptions
    ) as unknown as Observable<string>;
  }

}
