import {ApiAdapter, IApiControl} from 'frontier/nucleus';
import {TColType} from '../table-control/cell.enum';
import {IApiTableData} from '../table-control/interfaces/api-table-data.interface';
import {Component, Type} from '@angular/core';

export interface IRepositorySelectionOptions {
  dialog: Type<Component>;
}

export type IComponentOptions = IRepositorySelectionOptions | null;

export interface ITableVerticalDataRow {
  attribute: string;
  fieldName: string;
  type: TColType;
  value: any;
  attributeIndex: number;
  editable: boolean;
  apiRow: any;
  apiInstance: IApiControl;
  repository: any[];
  invalidMessage?: string;
  component?: any;
  componentOptions?: IComponentOptions;
  imported?: string;
}

export class TableVerticalApiAdapter extends ApiAdapter {

  from(apiData: IApiTableData): IApiTableData {
    return apiData;
  }

  to(data: any): any {

  }

}
