import {Component, ElementRef, EventEmitter, Input, Output, ViewContainerRef,} from '@angular/core';
import {MatDialogRef} from '@angular/material/dialog';
import {EsvgFiles} from 'frontier/nucleus';

@Component({
  selector: 'app-dialog-header',
  templateUrl: './dialog-header.component.html',
  styleUrls: ['./dialog-header.component.scss'],
})
export class DialogHeaderComponent {
  @Input() title: string;
  @Input() dialogRef: MatDialogRef<any>;
  @Output() close = new EventEmitter();

  constructor(
    private _viewContainerRef: ViewContainerRef,
    private elementRef: ElementRef
  ) {
  }


  onCloseClick(evt: MouseEvent) {
    this.close.emit(evt);
  }

  protected readonly EsvgFiles = EsvgFiles;
}
