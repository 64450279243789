<div
  class="process-headers"
>
  <kpi4me-process-header
    style="flex: 1"
    [preventUpdate]="preventUpdate"
    (selectionChange)="onProcessSelectionChange($event)"
  ></kpi4me-process-header>
</div>

<ng-container *ngIf="view">

  <div style="display: flex; flex-direction: column">
    <!--  <div style="width: 3rem">-->
    <!--    <kpi4me-checkbox-element-->
    <!--      displayAs="toggle"-->
    <!--      [hidden]="activeTable !== EActiveTable.carts"-->
    <!--      [matTooltip]="'Alle Fahrten anzeigen: Zeigt auch die Fahrten des ausgewählten Patienten an, die nicht' +-->
    <!--                 ' dem aktuellen Filter entsprechen.'"-->
    <!--      [formControlElement]="allCartsShown"-->
    <!--      (modelChange)="toggleAllCartsShown($event)"-->
    <!--    >-->
    <!--    </kpi4me-checkbox-element>-->
    <!--  </div>-->


    <kpi4me-bread-crumb
      [activeFilterStatus]="view.processOverviewState"
      [activeTables]="view.activeTables"
      (categoryClick)="onCategoryClicked()"
      (stateClick)="stateClicked$.emit()"
      (activeCartFilterObjClick)="activeCartFilterObjClicked$.emit()"
    ></kpi4me-bread-crumb>

    <app-table-toggler
      *ngIf="view.processOverviewState.stateSelection?.categories.includes(ECalculusStateFilter.ETLnMapping) && view.activeTables.size === 1"
      [data]="arrangeClientToggleData$ | async"
      (stateChange)="onShowCartsTableChange('ETLnMapping', $event)"
    ></app-table-toggler>
    <app-table-toggler
      *ngIf="view.processOverviewState.stateSelection?.categories.includes(ECalculusStateFilter.arrangeInvoice)"
      [data]="arrangeInvoiceToggleData$ | async"
      (stateChange)="onShowCartsTableChange('arrangeInvoice', $event)"
    ></app-table-toggler>
    <app-table-toggler
      *ngIf="view.processOverviewState.stateSelection?.categories.includes(ECalculusStateFilter.monitorPayment) && view.activeTables.size === 1"
      [data]="monitorPaymentToggleData$ | async"
      (stateChange)="onShowCartsTableChange('monitorPayment', $event)"
    ></app-table-toggler>
  </div>

  <p-splitter
    (onResizeEnd)="resizeEnded()"
    class="main" [panelStyle]="{
      'white-space': 'nowrap',
      'text-overflow': 'ellipsis',
      overflow: 'hidden'
    }"
    [style]="{ height: '100%' }"

  >
    <ng-template pTemplate>
      <div [class.show-both]="view.activeTables.size > 1"
           class="table"
      >
        <kpi4me-clients
          [class.hide-pagination]="view.activeTables.size > 1"
          [style.height.px]="view.activeTables.size > 1 ? '130' : ''"
          #clients
          *ngIf="view.activeTables.has('clients')"
          [fixedPageSize]="fixedPageSize$ | async"
        ></kpi4me-clients>

        <kpi4me-document-outbox
          #documentOutbox
          *ngIf="view.activeTables.has('documentOutbox')"
          [fixedPageSize]="fixedPageSize$ | async"
        ></kpi4me-document-outbox>

        <kpi4me-open-items
          [class.hide-pagination]="view.activeTables.size > 1"
          [style.height.px]="view.activeTables.size > 1 ? '130' : ''"
          #openItems
          *ngIf="view.activeTables.has('openItems')"
          [fixedPageSize]="fixedPageSize$ | async"
          (invoiceToggleTriggered)="toggleCollectiveInvoiceDisplay($event)"
        ></kpi4me-open-items>

        <kpi4me-carts
          #carts
          *ngIf="!((view.processOverviewState?.invoice == null && !view.activeTables.has('carts')))"
          [fixedPageSize]="fixedPageSize$ | async"
        ></kpi4me-carts>
      </div>
    </ng-template>

    <ng-template pTemplate>
      <div
        class="details"
      >
        <kpi4me-cart-details
          #details
          [hidden]="!detailsActive()"
          [cartParent]="this.cartParent"
          [clientParent]="this.clientParent"
        ></kpi4me-cart-details>
      </div>
    </ng-template>
  </p-splitter>
</ng-container>

