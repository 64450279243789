import {ChangeDetectorRef, Component, EventEmitter, Input, OnDestroy, Output,} from '@angular/core';
import {FormBuilder, FormGroup} from '@angular/forms';
import {debounceTime, map, tap} from 'rxjs/operators';
import {IDatepickerForm} from './datepicker-form.interface';
import {Subject, Subscription} from 'rxjs';
import {EsvgFiles} from 'frontier/nucleus';
import {DateTime, Interval} from 'luxon';

@Component({
  selector: 'kpi4me-date-picker',
  templateUrl: './date-picker.component.html',
  styleUrls: ['./date-picker.component.scss'],
})
export class DatePickerComponent implements OnDestroy {
  @Output() configurationChange = new EventEmitter<IDatepickerForm>();
  granularities = [
    {name: 'Monat', id: 1, shortcut: 'M'},
    {name: 'Quartal', id: 2, shortcut: 'Q'},
    {name: 'Halbjahr', id: 3, shortcut: 'H'},
    {name: 'Jahr', id: 4, shortcut: 'J'},
  ];
  quarters = [{name: 'Q1'}, {name: 'Q2'}, {name: 'Q3'}, {name: 'Q4'}];
  months = [
    {name: 'J', id: 1},
    {name: 'F', id: 2},
    {name: 'M', id: 3},
    {name: 'A', id: 4},
    {name: 'M', id: 5},
    {name: 'J', id: 6},
    {name: 'J', id: 7},
    {name: 'A', id: 8},
    {name: 'S', id: 9},
    {name: 'O', id: 10},
    {name: 'N', id: 11},
    {name: 'D', id: 12},
  ];
  years: { name: string; year: number }[] = [];
  form: FormGroup;
  private configChange = new Subject<IDatepickerForm>();
  private delayedConfigChange: Subscription;
  protected readonly EsvgFiles = EsvgFiles;

  constructor(private fb: FormBuilder, private cdr: ChangeDetectorRef) {
    // create array of dates for the years
    const twoYearsAgo = DateTime.local().minus({ years: 2 });
    const range = Interval.fromDateTimes(twoYearsAgo, twoYearsAgo.plus({ years: 4 }));
    const array: number[] = [];
    for (let year = range.start.year; year <= range.end.year; year++) {
      array.push(year);
    }
    console.log(array);
    this.years = array.map((y) => {
      return {name: String(y).slice(-2), year: y};
    });

    this.form = this.fb.group({
      granularity: null,
      quarter: [null],
      month: [null],
      year: [null],
    });

    this.form.patchValue({
      granularity: this.granularities[0].id,
    });

    this.form.valueChanges
      .pipe(
        tap((form: IDatepickerForm) => {
          this.configChange.next(form);
        })
      )
      .subscribe();

    this.delayedConfigChange = this.configChange
      .pipe(
        debounceTime(200),
        map((v) => {
          v.month = v.month.sort((a, b) => a - b);
          return v;
        }),
        tap((v) => {
          this.configurationChange.emit(v);
          console.log('Val change', v);
        })
      )
      .subscribe();
  }

  private _configuration: IDatepickerForm;

  @Input() set configuration(c: IDatepickerForm) {
    this.form.patchValue(c, {emitEvent: false});
    this._configuration = c;
  }

  selectedGranularity(): { shortcut: string; name: string } {
    return this.granularities.find((g) => g.id == this.form.value.granularity);
  }

  onToggleAbleClick(evt: MouseEvent, formControlName: string, formValue: any) {
    evt.preventDefault();

    if (formControlName === 'quarter') {
      this.toggleQuarterSelection(formValue)
    } else if (formControlName === 'month') {
      this.toggleMonthSelection(formValue)
    } else if (formControlName === 'year' && this.form.value.year.includes(formValue) && this.areMonthAndQuartersEmpty()) {
      this.selectAllMonthsAndQuarters()
    }
  }

  private toggleQuarterSelection(quarterName: string): void {
    const isBeingSelected = this.form.value.quarter.includes(quarterName)
    if (isBeingSelected) {
      this.selectMonthsOfQuarter(quarterName)
    } else {
      this.deselectMonthsOfQuarter(quarterName)
    }
  }

  private selectMonthsOfQuarter(quarterName: string): void {
    const months = this.getMonthsFromQuarter(quarterName).map(m => m.id)
    const newMonths = new Set(this.form.value.month.concat(months))
    this.form.patchValue({
      month: Array.from(newMonths.values()),
    });
  }

  private deselectMonthsOfQuarter(quarterName: string): void {
    const quarterMonths = this.getMonthsFromQuarter(quarterName).map(
      (i) => i.id
    );
    const newFormMonths = this.removeSubarrayFromArray(
      this.form.value.month,
      quarterMonths
    );
    this.form.patchValue({
      month: newFormMonths,
    });
  }


  private toggleMonthSelection(monthId: number): void {
    const isBeingSelected = this.form.value.month.includes(monthId)
    if (isBeingSelected) {
      this.checkForSelectionOfQuarterForMonth(monthId)
    } else {
      this.deselectQuarter(this.getQuarterFromMonth(monthId).name)
    }
  }

  private checkForSelectionOfQuarterForMonth(monthId: number): void {
    const quarterName = this.getQuarterFromMonth(monthId).name;
    const monthsOfQuarter = this.getMonthsFromQuarter(quarterName);
    const allMonthsOfQuarterAreSelected = monthsOfQuarter.every(m =>
      this.form.value.month.includes(m.id));
    if (allMonthsOfQuarterAreSelected) {
      this.form.patchValue({
        quarter: [...this.form.value.quarter, quarterName],
      });
    }
  }

  private deselectQuarter(quarterName: string): void {
    this.form.patchValue({
      quarter: this.form.value.quarter.filter(
        (qName: string) => qName != quarterName
      ),
    });
  }


  private selectAllMonthsAndQuarters(): void {
    // Select all months and quarters
    this.form.setValue({
      granularity: this.form.value.granularity,
      quarter: this.quarters.map(q => q.name),
      month: this.months.map(m => m.id),
      year: this.form.value.year,
    })

  }

  private areMonthAndQuartersEmpty(): boolean {
    return this.form.value.month.length === 0 && this.form.value.quarter.length === 0;
  }


  removeSubarrayFromArray(array: any[], subarray: any[]): any[] {
    return array.filter((el) => !subarray.includes(el));
  }

  getMonthsFromQuarter(q: string) {
    let months: { name: string; id: number }[] = [];
    switch (q) {
      case 'Q1':
        months = this.months.slice(0, 3);
        break;
      case 'Q2':
        months = this.months.slice(3, 6);
        break;
      case 'Q3':
        months = this.months.slice(6, 9);
        break;
      case 'Q4':
        months = this.months.slice(9, 12);
        break;
    }
    return months;
  }

  ngOnDestroy(): void {
    this.delayedConfigChange.unsubscribe();
  }

  private getQuarterFromMonth(monthId: number) {
    const quarterIdx = Math.floor((monthId - 1) / 3);
    return this.quarters[quarterIdx];
  }

  resetSelection() {
    this.form.setValue({
      granularity: this.form.value.granularity,
      quarter: [],
      month: [],
      year: [],
    });
  }

  resetIsDisabled(): boolean {
    return this.areMonthAndQuartersEmpty() && this.form.value.year.length === 0;
  }
}
