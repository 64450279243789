import {Component, OnInit} from '@angular/core';
import {CustomCellComponent} from '../custom-cell/custom-cell.component';

@Component({
  selector: 'app-date-cell-renderer',
  templateUrl: './date-cell-renderer.component.html',
  styleUrls: ['../custom-cell/custom-cell.component.scss'],
})
export class DateCellRendererComponent
  extends CustomCellComponent
  implements OnInit {
  constructor() {
    super();
  }

  ngOnInit(): void {
    return;
  }
}
