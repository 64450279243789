import {ChangeDetectorRef, Component, EventEmitter, Inject, Input, OnInit, Output, ViewChild} from '@angular/core';
import {IExtendedGoldenLayoutConfig} from '@kpi4me/golden-angular-layout';
import {combineLatest, mergeMap, Observable, of, ReplaySubject, startWith} from 'rxjs';
import {ApiConfigService, CoreService, getQuarter, StoreService, SvgService} from 'frontier/nucleus';
import {DOCUMENT} from '@angular/common';
import {Router} from '@angular/router';
import {LayoutHosterBaseComponent} from '../golden-layout/layout-hoster-base/layout-hoster-base.component';
import {MatDrawerContainer} from '@angular/material/sidenav';
import {IToolbarAction} from "../toolbar/toolbar-shared/toolbar-shared.component";
import {CalendarService} from "../services/calendar.service";
import {filter, map} from "rxjs/operators";
import {ControlManagerComponent} from '../control-manager/control-manager/control-manager.component';
import {IView} from '../golden-layout/view.interface';
import {
  IGoldenLayoutDynamicComponent,
  ISideNavGlCategory
} from '../control-manager/control-manager/golden-layout-dynamic-components.interface';
import {ThemeService} from '../theme/theme.service';
import {ControlManagerService} from '../control-manager/control-manager.service';
import {LoginService} from '../login/login.service';
import {ECalenderType, IToolbarState} from '../golden-layout/custom-toolbar-state.interface';
import {IModuleFilter} from '../toolbar/tenant-menu/tenant-menu.component';
import {AuthService} from '../authentication/auth.service';
import {IMenuOption} from '../toolbar/user-menu/menu-option.interface';
import {EGranularity} from 'frontier/browserkit';
import {NgbCalendar} from '@ng-bootstrap/ng-bootstrap';

interface IHomeView {
  toolbarConfig: IToolbarState;
  versionNumber: string;
}

@Component({
  selector: 'kpi4me-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
})
export class HomeComponent extends LayoutHosterBaseComponent implements OnInit {
  @ViewChild(MatDrawerContainer) matDrawerContainer: MatDrawerContainer;
  @ViewChild(ControlManagerComponent) controlManagerComponent: ControlManagerComponent;

  layout$: ReplaySubject<IExtendedGoldenLayoutConfig> = new ReplaySubject(1);
  layoutKey = 'root';
  mainMenuState: 'full-width' | 'small-width' = 'full-width';
  states = ['full-width', 'small-width'];
  stateIndex = 0;
  toolbarConfig$: Observable<IToolbarState> = this.calendarService.currentView$.pipe(
    filter(v => v != null),
    mergeMap(v => of(v.toolbar))
  );
  versionNumber$: Observable<string> = this.coreService.ResourceApi.version();
  view$: Observable<IHomeView> = combineLatest([
    this.toolbarConfig$,
    this.versionNumber$
  ]).pipe(
    map(([toolbarConfig, versionNumber]) => {
      return ({toolbarConfig, versionNumber})
    }),
    startWith({
      toolbarConfig: {
        classicalCalendar: {
          // initialize dates
          fromDate: this.calendar.getPrev(
            this.calendar.getToday(),
            'm',
            3
          ),
          toDate: this.calendar.getToday(),
        },
        easyDatePicker: {
          granularity: EGranularity.month,
          year: [this.calendar.getToday().year],
          month: [this.calendar.getToday().month],
          quarter: ['Q' + getQuarter(new Date())],
        },
        filter: {},
        calendarType: ECalenderType.classic
      },
      versionNumber: null
    } as IHomeView)
  );

  @Input() isCurrentViewGoldenLayout = true;
  @Input() defaultView: IView;
  @Input() moduleFilters: IModuleFilter[] = [];
  @Input() customToolbarActions: IToolbarAction[] = [];
  @Input() components: ISideNavGlCategory[];
  @Input() detailComponents: IGoldenLayoutDynamicComponent[];
  @Input() filtersActive = true;
  @Input() showTenantMenu = true;
  @Input() productName = 'Mein Produkt';
  @Input() logoPath = 'assets/images/kpi4me-logo-zoomed.png';
  @Input() productLogoPath = null;
  @Input() menuOptions: IMenuOption[] = [];

  @Output() optionTriggered = new EventEmitter<any>()
  @Output() customToolbarActionClicked = new EventEmitter<string>();

  constructor(
    public themeService: ThemeService,
    protected svgService: SvgService,
    @Inject(DOCUMENT) protected document: Document,
    protected controlManager: ControlManagerService,
    protected cdr: ChangeDetectorRef,
    public store: StoreService,
    protected loginService: LoginService,
    protected router: Router,
    protected coreService: CoreService,
    public calendarService: CalendarService,
    private apiConfigService: ApiConfigService,
    private authService: AuthService,
    private calendar: NgbCalendar
  ) {
    super(themeService, svgService, document, controlManager, cdr);
  }

  ngOnInit(): void {
    // Wrapper does not use golden layout i.e. sam (sales-management)
    if (this.isCurrentViewGoldenLayout === false) {
      return;
    }
    if (this.defaultView == null) {
      throw new Error('The default view has to be set from an inherited class of the home component');
    }
    if (this.components == null) {
      throw new Error('There are now components set. ' +
        'The components are needed for the golden layout instance !.' +
        ' Initialize them in the child component.')
    }

    this.controlManager.defaultView = this.defaultView;
  }

  onMainMenuOpenedChange() {
    this.stateIndex++;
    this.stateIndex = this.stateIndex % this.states.length;
    this.mainMenuState = this.states[this.stateIndex] as any;
    setTimeout(() => this.cdr.detectChanges(), 100);
  }

  logout() {
    this.store.resetLastLogin();
    if (this.store.isAuthenticated()) {
      if (this.apiConfigService.environment.env !== 'prod') {
        setTimeout(() => {
          this.authService.deleteSession();
        }, 500)
      }
      this.loginService.logout()
        .subscribe(() => {
          this.loginService.loggedOut$.emit();
        this.router.navigate(['/login']);
      });
    } else {
      console.log('not authenticated');
      this.loginService.loggedOut$.emit();
      this.router.navigate(['/login']);
    }
  }

  onResize() {
    this.controlManager.windowResize.emit();
  }

  // toolbarConfigChanged(evt: IToolbarState) {
  //   const currentView = this.controlManager.currentView;
  //   if (currentView) {
  //     const view: IView = {
  //       ...currentView,
  //       toolbar: {
  //         ...currentView.toolbar,
  //         ...evt,
  //       },
  //     };
  //     this.controlManager.updateViewInStorage(view);
  //     this.eventService.currentView$.next(view);
  //   }
  // }

  doResizeLater() {
    setTimeout(() => this.onResize(), 500);
  }

  onResetLayout() {
    this.controlManager.resetLayout();
  }

  toolbarConfigChanged(evt: IToolbarState) {
    const currentView = this.controlManager.currentView;
    if (currentView) {
      const view: IView = {
        ...currentView,
        toolbar: {
          ...currentView.toolbar,
          ...evt,
        },
      };
      this.controlManager.updateViewInStorage(view);
      this.calendarService.currentView$.next(view);
    }
  }
}
