import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
  standalone: true,
  name: 'valueOrFunction',
  pure: false
})
export class ValueOrFunctionPipe implements PipeTransform {

  transform(value: any | (() => any)) {
    if (typeof value === 'function') {
      return value();
    } else {
      return value;
    }
  }
}
