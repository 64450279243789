import {Component, OnInit, ViewEncapsulation} from '@angular/core';
import {ApiConfigService} from 'frontier/nucleus';

@Component({
  selector: 'kpi4me-go-to-trapo',
  templateUrl: './go-to-trapo.component.html',
  styleUrls: ['./go-to-trapo.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class GoToTrapoComponent implements OnInit {
  href: string;

  constructor(
    public apiConfigService: ApiConfigService,
  ) {
    if (this.apiConfigService.environment.env === 'prod') {
      this.href = window.location.origin + '/trapo'
    } else {
      this.href = this.apiConfigService.environment.url + '/trapo'
    }

    this.href = this.href + '/#/?hideToolbar=true';
  }

  ngOnInit(): void {
    return;
  }
}
