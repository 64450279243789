import {Component, Inject} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {FormBuilder, Validators} from '@angular/forms';
import {ThemeService} from '../../../theme/theme.service';
import {CoreService, passwordMatchValidator} from 'frontier/nucleus';
import {FeedbackService} from '../../../user-feedback/feedback.service';

export interface IPasswordDialogData {
  username: string,
  type: 'new' | 'change';
  title: string,
}


@Component({
  selector: 'app-set-password-dialog',
  templateUrl: './set-password-dialog.component.html',
  styleUrls: ['../shared-dialogs.scss'],
})
export class SetPasswordDialogComponent {
  public formGroup: any;
  type: 'new' | 'change';
  confirmDisabled = false;

  constructor(
    public setPasswordDialogRef: MatDialogRef<SetPasswordDialogComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data: IPasswordDialogData,
    private formBuilder: FormBuilder,
    public themeService: ThemeService,
    private coreService: CoreService,
    private feedBackService: FeedbackService
  ) {
    if (this.data.type !== 'change') {
      this.formGroup = this.formBuilder.group(
        {
          password: this.formBuilder.control('', [Validators.required]),
          password2: this.formBuilder.control('', [Validators.required]),
        },
        {validator: passwordMatchValidator}
      );
    } else {
      this.formGroup = this.formBuilder.group(
        {
          oldPassword: this.formBuilder.control('', [Validators.required]),
          password: this.formBuilder.control('', [Validators.required]),
          password2: this.formBuilder.control('', [Validators.required]),
        },
        {validator: passwordMatchValidator}
      );
    }
  }

  /* Shorthands for form controls (used from within template) */
  get password() {
    return this.formGroup.get('password');
  }

  get password2() {
    return this.formGroup.get('password2');
  }

  get oldPassword() {
    return this.formGroup.get('oldPassword');
  }

  /* Called on each input in either password field */
  onPasswordInput() {
    if (this.formGroup.hasError('passwordMismatch')) {
      this.password2.setErrors([{passwordMismatch: true}]);
    } else {
      this.password2.setErrors(null);
    }
  }

  onConfirm() {
    if (this.data.type === 'change') {
      if (this.password.value === this.password2.value) {
        this.confirmDisabled = true;
        this.coreService.User.ChangePassword(this.oldPassword.value, this.password.value).subscribe((res) => {
          if (res === true) {
            this.feedBackService.setNotification('Passwort erfolgreich geändert');
            this.setPasswordDialogRef.close(true);
          } else {
            this.confirmDisabled = false;
            this.feedBackService.setError('Aktuelles Passwort ist ungültig', 3000);
          }
        });
      }
    } else {
      this.setPasswordDialogRef.close(this.password.value);
    }

    // this.getterService.User.ChangeOwnPassword(this.data.type === 'new' ? this.formGroup.value.password :
    //   this.formGroup.value.oldPassword,
    //   this.data.type === 'change' ? this.formGroup.value.password : '').subscribe((resp) => {
    //   this.confirmDisabled = false;
    //   if (resp) {
    //     this.store.activeUser$.next({
    //       ...this.store.activeUser$.getValue(),
    //       loginname: this.formGroup.value.username
    //     })
    //     // this.router.navigate(['kpi']);
    //     this.snackBar.open('Passwort erfolgreich gesetzt.');
    //     this.setPasswordDialogRef.close(false);
    //   }
    // }, error => this.confirmDisabled = false);
  }
}
