<app-dialog-header [dialogRef]="dialogRef" (close)="dialogRef.close()"/>
@defer (when pdfSrc) {
  <div #pdfOverlay id="pdf-overlay"
       style="flex: 1"
  >
    <embed
      style="display: flex; height: 100%; width: 100%"
      *ngIf="pdfSrc"
      [src]="pdfSrc"
    >
  </div>
} @placeholder (minimum 500) {
  <div style="height: 100%; width: 100%; display: flex; justify-content: center; align-items: center">
    <mat-spinner style="width: 550px; height: 550px"></mat-spinner>
  </div>
}
