import {EventEmitter, inject, Injectable, OnDestroy} from '@angular/core';
import {BehaviorSubject, skip, Subject, Subscription} from 'rxjs';

import {GoldenLayoutComponent, IExtendedGoldenLayoutConfig, MultiWindowService} from '@kpi4me/golden-angular-layout';
import {IView, TGoldenLayoutKeys} from '../golden-layout/view.interface';
import {NgbCalendar, NgbDate} from '@ng-bootstrap/ng-bootstrap';
import {EGranularity} from '../calendar/calendar/granularity.enum';
import {IControl} from "../control/table-control/interfaces/displayed-columns.interface";
import {ISelectFormOption} from "../control/form-control/dynamic-form/form-element/form-data.interface";
import {tap} from 'rxjs/operators';
import {LoginService, ToNgbDate} from 'frontier/browserkit';
import {DateTime} from 'luxon';

@MultiWindowService<ControlManagerService>('control-manager-service')
@Injectable({
  providedIn: 'root',
})
export class ControlManagerService implements OnDestroy {
  componentToBeOpened$ = new BehaviorSubject<{ control: IControl, parent: any }>(null);

  defaultView: IView;
  // Stores all layouts that are saved
  layouts = new BehaviorSubject<IView[]>([]);
  // The current view of the app.
  loadView$ = new Subject<IView>();
  initialLayout$ = new Subject();

  viewToRemoveFromLayout$ = new BehaviorSubject<string>(null);

  subs = new Subscription();
  /**
   * Event to rerender the drag elements on the left side panel.
   */
  createDragElements = new BehaviorSubject(null);

  // Registry for all golden layout roots
  goldenLayouts: { [idx: string]: GoldenLayoutComponent } = {};
  openComponents: any = {};
  openedComponentsUpdated = new EventEmitter();
  openLayoutId: number;
  windowResize = new EventEmitter();

  loggedIn = false;
  currentView: IView;
  openComponent$ = new EventEmitter<string>();

  loginService = inject(LoginService);

  constructor(private calendar: NgbCalendar) {
    this.subs.add(
      this.loadView$.subscribe((v) => {
        this.currentView = v;
      })
    );
    this.subs.add(
      this.layouts.pipe(
        skip(1),
        tap(layouts => {
          this.saveLayoutsLocally(layouts);
        })
      ).subscribe()
    );

    this.subs.add(
      this.loginService.loggedOut$.pipe(
        tap(() => this.resetLayout())
      ).subscribe()
    )
  }

  ngOnDestroy() {
    this.subs.unsubscribe();
  }

  /**
   * Creates a layout out of the dialog result.
   * @name name
   */
  createLayout(name: string): IView {
    const view: IView = {
      ...this.getViewInStorage(),
      name: name,
      goldenLayoutStates: this.getGoldenLayoutStates(),
      id: Math.random(),
      timeStamp: DateTime.local().toISO()
    };
    if (view.toolbar?.classicalCalendar.fromDate.month === 0) {
      view.toolbar.classicalCalendar.fromDate.month = 1;
    }
    this.openLayoutId = view.id;
    this.saveViewInStorage(view);
    // update the layouts that are saved client side
    this.layouts.next([...this.layouts.getValue(), view]);
    this.saveLayoutsLocally(this.layouts.getValue());
    return view;
  }

  deleteLayout(obj: IView): void {
    const viewToDelete: IView = this.layouts.getValue().find((layout: IView): boolean => {
      return layout.id === obj.id;
    });
    if (viewToDelete) {
      if (viewToDelete.id === this.openLayoutId) {
        this.openLayoutId = null;
      }
      this.layouts.next(this.layouts.getValue().filter((view: IView): boolean => {
        return view.id !== viewToDelete.id;
      }));
    }
  }

  changeLayout(evt: { oldEntry: ISelectFormOption; newName: string }): void {
    const changedView: IView = {
      ...this.layouts.getValue().find((layout: IView): boolean => {
        return layout.id === evt.oldEntry.value.id;
      }),
      name: evt.newName,
    };
    if (changedView) {
      this.openLayoutId = changedView.id;
      this.saveViewInStorage(changedView);
      this.layouts.next([
        ...this.layouts.getValue().filter((view: IView): boolean => {
          return view.id !== changedView.id;
        }),
        changedView,
      ]);
    }
  };

  copyView(obj: IView): void {
    const viewToDuplicate: IView = {
      ...this.layouts.getValue().find((layout: IView): boolean => {
        return layout.id === obj.id;
      }),
      id: Math.random(),
    };
    if (viewToDuplicate) {
      this.saveViewInStorage(viewToDuplicate);
      this.layouts.next([...this.layouts.getValue(), viewToDuplicate]);
    }
  }

  resetLayout(): void {
    if (!this.defaultView) {
      console.error('Tried resetting the view with a undefined defaultView');
      return;
    }
    this.loadView$.next(this.defaultView);
    this.createDragElements.next(true);
  }

  /**
   * Load a specific view.
   * @param layout
   */
  loadView(layout: IView) {
    console.log(layout);
    this.openLayoutId = layout.id;
    this.loadView$.next({
      ...layout,
      toolbar: layout.toolbar
        ? {
          ...layout.toolbar,
          classicalCalendar: {
            ...layout.toolbar.classicalCalendar,
            fromDate: NgbDate.from(layout.toolbar.classicalCalendar.fromDate),
            toDate: NgbDate.from(layout.toolbar.classicalCalendar.toDate),
          },
        }
        : {
          classicalCalendar: {
            // initialize dates
            fromDate: this.calendar.getPrev(
              this.calendar.getToday(),
              'm',
              3
            ),
            toDate: this.calendar.getToday(),
          },
          easyDatePicker: {
            granularity: EGranularity.month,
            year: [2022],
            month: [7],
            quarter: ['Q3'],
          },
          filter: {},
        },
    });
    setTimeout(() => {
      this.setOpenedComponents();
      this.saveViewInStorage(this.currentView);
      this.createDragElements.next(true);
    }, 0);
  }

  saveViewInStorage(view: IView) {
    view.timeStamp = DateTime.local().toISO();
    console.log('saving view', view);
    localStorage.setItem('savedLayout', JSON.stringify(view));
  }

  /**
   * Updates the current view in the local storage
   * @param view
   */
  updateViewInStorage(view: Partial<IView>) {
    if (this.isAGoldenLayoutInitialized()) {
      const newView: IView = {
        ...this.getViewInStorage(),
        ...view,
        goldenLayoutStates: this.getGoldenLayoutStates(),
      };
      this.saveViewInStorage(newView);
    }
  }

  /**
   * Loads the layouts from the local storage, sets the from and to date by mapping them to ngbDates
   * Passes the routes / layouts to the observable
   */
  public initialize() {
    console.log('Initializing control manager');
    const loadedLayouts: IView[] = JSON.parse(localStorage.getItem('layouts'));
    if (loadedLayouts) {
      loadedLayouts.forEach((l) => {
        if (l.toolbar && l.toolbar.classicalCalendar) {
          l.toolbar.classicalCalendar.fromDate = NgbDate.from(
            l.toolbar.classicalCalendar.fromDate
          );
          l.toolbar.classicalCalendar.toDate = NgbDate.from(
            l.toolbar.classicalCalendar.toDate
          );
        }
      });
      this.layouts.next([...loadedLayouts]);
    }
    console.log('Checking for local saved view');
    let storageView = this.getViewInStorage();
    if (storageView?.goldenLayoutStates?.root?.content) {
      console.log('Found view in storage. Loading view ...', storageView);
      if (storageView.goldenLayoutStates.root.content.length === 0) {
        console.log('content in storage view is empty:', storageView);
        this.resetLayout();
        return;
      }
      // if (storageView.goldenLayoutStates.root.content[0]?.activeItemIndex >= storageView.goldenLayoutStates.root.content[0].content?.length) {
      //   console.log('Fixing saved View');
      //   storageView.goldenLayoutStates.root.content[0].activeItemIndex = 0;
      // }
      storageView = this.resetStorageViewIfOutdated(storageView);
      this.loadView(storageView);
    } else {
      console.log('No view found in storage. Setting default view.');
      this.resetLayout();
    }
  }

  /**
   * Gets the current view from the storage.
   */
  getViewInStorage(): IView {
    return JSON.parse(localStorage.getItem('savedLayout'));
  }

  /**
   * overwrites the loaded layout with the current layout.
   */
  overwriteLoadedLayout() {
    const layoutList = this.layouts.getValue();
    const layoutInList = layoutList.findIndex(
      (l) => l.name == this.getViewInStorage().name
    );
    if (layoutInList != -1) {
      layoutList[layoutInList].goldenLayoutStates = this.getGoldenLayoutStates();
      this.saveLayoutsLocally(layoutList);
    }
  }

  /**
   * Saves the layout lists in the storage.
   * @param layouts
   */
  saveLayoutsLocally(layouts: IView[]) {
    console.log('saving layouts', layouts);
    localStorage.setItem('layouts', JSON.stringify(layouts));
  }

  updateGlInstance(
    config: IExtendedGoldenLayoutConfig,
    localStorageKey: TGoldenLayoutKeys
  ) {
    let currentView = this.getViewInStorage();
    if (currentView == null) {
      currentView = {
        toolbar: null,
        goldenLayoutStates: {
          coursePlanner: null,
          timePlans: null,
          courseEvents: null,
        },
        ...this.currentView,
      };
    }
    currentView.goldenLayoutStates[localStorageKey] = config;
    this.saveViewInStorage(currentView);
  }

  registerGoldenLayout(
    layoutKey: TGoldenLayoutKeys,
    gl: GoldenLayoutComponent
  ) {
    this.goldenLayouts[layoutKey] = gl;
    this.setOpenedComponents();
  }

  /**
   * Check which components are opened in the layout config, to highlight them in the control-manager component
   * @private
   */
  setOpenedComponents() {
    this.openComponents = {};
    Object.values(this.goldenLayouts).forEach((gl) => {
      if (gl && gl.getComponents) {
        const components = gl.getComponents();
        Object.values(components).forEach((component: any) => {
          if (component.componentName) {
            this.openComponents[component.componentName] = true;
          }
        });
      }
    });
    this.openedComponentsUpdated.emit();
  }

  private getGoldenLayoutStates() {
    const goldenLayoutStates: any = {};
    for (const key in this.goldenLayouts) {
      const value = this.goldenLayouts[key];
      if (value) {
        // Use `key` and `value`
        goldenLayoutStates[key] = value.getGoldenLayoutInstance().toConfig();
      }
    }
    return goldenLayoutStates;
  }

  /**
   * Checks if any golden layout component is initialized.
   * @private
   */
  private isAGoldenLayoutInitialized() {
    return Object.keys(this.goldenLayouts).length > 0;
  }

  focusTab(componentName: string) {
    let found = false;
    Object.values(this.goldenLayouts).forEach((goldenLayout) => {
      const glInstance = goldenLayout.getGoldenLayoutInstance();
      // @ts-ignore
      const openComponents = Object.values(glInstance._getAllComponents());
      for (let i = 0; i < openComponents.length; i++) {
        // console.log(myLayout._getAllContentItems()[i].componentName);
        // @ts-ignore
        if (openComponents[i].componentName == componentName) {
          found = true;
          const contentItem = openComponents[i];
          // contentItem.tab.header.parent.setActiveContentItem(contentItem);
          // @ts-ignore
          contentItem.parent.setActiveContentItem(contentItem);
          // contentItem.parent.header.parent.setActiveContentItem(contentItem);
        }
      }
    });

  }

  openLayoutComponent(component: IControl, parent: any): void {
    this.componentToBeOpened$.next({control: component, parent});
  }

  openComponent(componentName: string) {
    this.openComponent$.next(componentName);
  }

  private resetStorageViewIfOutdated(storageView: IView) {
    const storedDate =  DateTime.fromISO(storageView.timeStamp);
    const currentDate = DateTime.local();
    if (!storedDate.hasSame(currentDate, 'day')) {
      try {
        storageView.toolbar.filter.searchstring = '';
        storageView.toolbar.classicalCalendar.toDate = ToNgbDate(currentDate);
      }
      catch (err) {
        storageView.toolbar = this.defaultView.toolbar;
      }
    }
    return storageView;
  }
}
