<kpi4me-dynamic-form-control
  #notBillable
  [GUID]="notBillableFormGUID"
  [selectedElement]="parent"
  [apiAdapter]="apiAdapter"
  (apiInstanceInitialize)="filterAndFetch($event, EConspicuityForms.notBillable)"
  [(apiInstance)]="notBillableFormApiInstance"
  [displayHeaders]="false"
  [standardForm]="false"
></kpi4me-dynamic-form-control>

<ag-grid-angular
                 (gridReady)="conspcuityGridApi = $event.api"
                 [style.height.px]="30 * 8"
                 [gridOptions]="gridOptions"
                 [rowData]="rowData"
                 [columnDefs]="colDefs"
                 class="ag-theme-alpine hide-header"
></ag-grid-angular>


@if (clientsMessage()) {
  <app-client-message-details
    [messageData]="clientsMessage()"
    (resolveAutomatically)="onResolveAutomatically()"
    (resolveManually)="onResolveManually()"
    (showDocument)="onShowDocument()"
  >
  </app-client-message-details>
}

@if (clients$ | async; as clients; ) {
  @if (clients.length) {
    <div class="mat-h3">
      Patientenvergleich
      <div class="mat-caption">
        Es gibt Patienten mit ähnlichen Daten
      </div>
    </div>

    <app-clients-comparator-table style="flex: 1" [clients]="clients"
                                  (takeData)="onTakeDataOfClient($event)"
                                  (getAnotherClient)="getAnotherClient($event)"
    />
  }
}
