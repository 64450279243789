import {Observable} from 'rxjs';
import {ApiConfigService} from '../api-config.service';
import {HttpHeaders} from '@angular/common/http';

export class Form {
  http;
  url;
  httpOptions;

  constructor(protected appConfig: ApiConfigService) {
    this.http = appConfig.http;
    this.url = appConfig.url;
    this.httpOptions = appConfig.httpOptions;
  }


  ConfirmChanges(InstanceID: string, Changes: any): Observable<any> {
    return this.http.post(
      this.url + '/intern/api/form/ConfirmChanges/',
      {_parameters: [InstanceID, Changes]}, this.httpOptions
    ) as unknown as Observable<any>;
  }

  UploadDocument(name: string, datatype: string, data: any): Observable<any> {
    return this.http.post(
      this.url + '/intern/api/form/UploadDocument/' + encodeURI(name) + '/' + encodeURI(datatype) + '/', data, {
        headers: new HttpHeaders({
          Accept: 'application/json',
          'Content-type': 'application/octet-stream'
        }),
      }) as unknown as Observable<any>;
  }

}
