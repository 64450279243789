import {Observable} from 'rxjs';
import {ApiConfigService} from '../api-config.service';
import {HttpHeaders} from '@angular/common/http';
import {IControlObject} from '../../interfaces/control-object.interface';

export class Epostconfiguration {
  http;
  url;
  httpOptions;
  constructor(protected appConfig: ApiConfigService) {
    this.http = appConfig.http;
    this.url = appConfig.url;
    this.httpOptions = appConfig.httpOptions;
  }


  healthcheck(InstanceId: string, ) : Observable<string> {
   return this.http.post(
      this.url + '/intern/api/control/ExecuteAction/' + InstanceId + '/healthcheck/', {}, this.httpOptions
    ) as unknown as Observable<string>;
  }

  changedata(InstanceId: string, data: any) : Observable<string> {
   return this.http.post(
      this.url + '/intern/api/control/ExecuteAction/' + InstanceId + '/changedata/', {"data": data}, this.httpOptions
    ) as unknown as Observable<string>;
  }

  requestsmscode(InstanceId: string, ) : Observable<string> {
   return this.http.post(
      this.url + '/intern/api/control/ExecuteAction/' + InstanceId + '/requestsmscode/', {}, this.httpOptions
    ) as unknown as Observable<string>;
  }

  entersmscode(InstanceId: string, smscode: string) : Observable<any> {
   return this.http.post(
      this.url + '/intern/api/control/ExecuteAction/' + InstanceId + '/entersmscode/', {"smscode": smscode}, this.httpOptions
    ) as unknown as Observable<any>;
  }

}
