import {Component, EventEmitter, Input, OnInit, Output, ViewChild,} from '@angular/core';
import {MatMenu} from '@angular/material/menu';
import {TreeNode} from '@ali-hm/angular-tree-component';

import {CoreService, IApiControl} from 'frontier/nucleus';
import {ITreeControlConfiguration} from '../tree-control-config.interface';

@Component({
  selector: 'app-node-style-menu',
  templateUrl: './node-style-menu.component.html',
  styleUrls: ['./node-style-menu.component.scss'],
})
export class NodeStyleMenuComponent implements OnInit {
  @ViewChild(MatMenu, {static: true}) menu: MatMenu;
  @Input() node: TreeNode;
  @Input() config: ITreeControlConfiguration;
  @Input() apiInstance: IApiControl;
  @Output() updatedStyle = new EventEmitter();

  constructor(private coreService: CoreService) {
  }

  ngOnInit(): void {
  }

  onColorColorPickerClosed(color: string) {
    this.addStylePropertyToNode(this.node, 'color', color);
  }

  onBgColorPickerClosed(color: string) {
    this.addStylePropertyToNode(this.node, 'backgroundColor', color);
  }

  addStylePropertyToNode(
    node: TreeNode,
    styleAttribute: string,
    value: string
  ) {
    if (this.node.data.styles == null) {
      this.node.data.styles = {};
    }
    this.node.data.styles[styleAttribute] = value;
    this.saveNodeStyle(this.node);
  }

  private saveNodeStyle(menuNode: TreeNode) {
    const data = {
      node: menuNode.data,
      styles: menuNode.data.styles,
    };
    this.coreService.Control.ExecuteAction(
      this.apiInstance.instanceid,
      'ChangeStyles',
      data
    ).subscribe((res) => {
      console.log(res);
      this.updatedStyle.emit();
    });
  }
}
