<mat-list>
  <mat-list-item
    (click)="onOptionClick($event, item)"
    *ngFor="let item of data.layouts | async; let i = index"
    class="option-wrapper"
    style="cursor: pointer"
  >
    <div class="centered">
      <span>{{ item.name }}</span>
      <span *ngIf="data.inEditMode" style="margin-left: auto">
        <mat-icon
          (click)="copy($event, i)"
          [color]="'primary'"
          style="cursor: pointer"
          svgIcon="{{EsvgFiles.copy}}"
        ></mat-icon>
        <mat-icon
          (click)="delete($event, i)"
          [color]="'primary'"
          style="cursor: pointer"
          svgIcon="{{EsvgFiles.delete}}"
        ></mat-icon>
      </span>
    </div>
  </mat-list-item>
</mat-list>
