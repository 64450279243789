import {ChangeDetectorRef, Component, ComponentFactoryResolver, Inject} from '@angular/core';
import {ControlManagerService, GoldenLayoutContentComponent} from 'frontier/browserkit';
import {BehaviorSubject} from 'rxjs';
import {GoldenLayoutComponent, GoldenLayoutComponentHost, GoldenLayoutContainer} from '@kpi4me/golden-angular-layout';
import GoldenLayout from '@kpi4me/golden-layout';
import {CoreService} from 'frontier/nucleus';

@Component({
  selector: 'app-invoice-templates-config',
  templateUrl: './invoice-templates-config.component.html',
  styleUrl: './invoice-templates-config.component.scss'
})
export class InvoiceTemplatesConfigComponent extends GoldenLayoutContentComponent {

  templateVariants$: BehaviorSubject<string[]> = new BehaviorSubject<string[]>([]);
  mockTemplateVariants: string[] = ['Privatrechnung', 'Kassenrechnung', 'Sammelrechnung', 'Papierrechnung'];

  constructor(
    @Inject(GoldenLayoutComponentHost)
    protected goldenLayout: GoldenLayoutComponent,
    @Inject(GoldenLayoutContainer) protected container: GoldenLayout.Container,
    protected cdr: ChangeDetectorRef,
    protected controlManager: ControlManagerService,
    protected coreService: CoreService,
  ) {
    super(goldenLayout, container, cdr, controlManager);
  }

  ngOnInit(): void {
    this.templateVariants$.next(this.mockTemplateVariants);
  }

  protected onResize(): void {
  }

}
