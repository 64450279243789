import {Injectable} from '@angular/core';
import {HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse,} from '@angular/common/http';
import {Observable, throwError} from 'rxjs';
import {catchError, tap} from 'rxjs/operators';
import {Router} from '@angular/router';
import {AuthService} from '../authentication/auth.service';
import {FeedbackService} from '../user-feedback/feedback.service';

@Injectable()
export class TokenInterceptor implements HttpInterceptor {
  constructor(
    public auth: AuthService,
    private router: Router,
    private feedbackService: FeedbackService
  ) {
  }

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    // console.log(request);
    // If custom/link/auth request => return
    // Pragma header darf nicht gesetzt sein. Leerer Header.
    if (
      request.url.includes('/extern/') ||
      request.url.includes('/resource/') ||
      request.url.includes('demoservice')
    ) {
      return next.handle(request.clone()).pipe(
        tap(),
        catchError((err) => {
          console.error(err.error);
          this.feedbackService.setError(err.error);
          return throwError(err);
        })
      );
    } else {
      let newHeaders = {
        headers: request.headers.set('Pragma', `${this.auth.store.getSID()}`),
      };
      if (this.auth.apiConfigService.environment.env === 'dev') {
        const BearerToken = this.auth.getBearerToken();
        if (BearerToken) {
          const Tenant = this.auth.getTenant();
          if (Tenant) {
            newHeaders = {
              headers: request.headers.set('Authorization', 'Bearer ' + BearerToken).set('refresh_token', this.auth.getRefreshTokenToken()).set('tenant', Tenant),
            }
          } else {
            newHeaders = {
              headers: request.headers.set('Authorization', 'Bearer ' + BearerToken).set('refresh_token', this.auth.getRefreshTokenToken()),
            }
          }
        }
      }
      return next
        .handle(
          request.clone(newHeaders)
        )
        .pipe(
          tap((httpEvent: HttpEvent<any>) => {
            // Skip request
            if (httpEvent.type === 0) {
              return;
            }
            if (httpEvent instanceof HttpResponse) {
              this.auth.saveSessionPortal(httpEvent as HttpResponse<any>);
              if (this.auth.apiConfigService.environment.env === 'dev') {
                this.auth.updateBearerToken(httpEvent as HttpResponse<any>);
              }
            }
          }),
          catchError((err) => {
            if (err.status === 403 || err.status === 401) {
              if ((err.status === 403) && (err.statusText === 'Session has expired')) {
                // Route to url without #
                this.feedbackService.setError(
                  'Die Sitzung ist abgelaufen. Bitte erneut anmelden.'
                );
                this.auth.setSessionExpired();
                //this.auth.redirectToLogin();
              } else if (err.status === 403 && err.statusText === 'Forbidden') {
                this.feedbackService.setError(
                  'Falsche Anmeldedaten. Der Nutzername oder das Passwort ist falsch.'
                );
                this.auth.deleteSession();
                this.auth.navigateToLogin();
              } else if (err.status === 403 && err.statusText === 'Unauthorized') {
                this.feedbackService.setError(
                  'Sie besitzen nicht die erforderlichen Berechtigungen diese Aktion durchzuführen.'
                );
                console.error('unauthorized api request: ', err)
                // this.auth.deleteSession();
                // this.auth.navigateToLogin();
              } else {
                this.feedbackService.setError(
                  'Es ist ein unbekannter Fehler aufgetreten.'
                );
                console.error(err);
              }
            } else if (err.status === 0) {
              // Connection to service Failed
              this.feedbackService.setError(
                'Verbindung zum Server fehlgeschlagen.'
              );
            } else if (err.status === 412) {
              // Connection to service Failed
              this.feedbackService.setError(err.error);
            } else {
              console.error(err);
              if (err.error && err.error.error) {
                this.feedbackService.setError(err.error.error);
              } else {
                return throwError(err);
              }
            }
            console.log(err);
            // pass error
            return throwError(err);
          })
        );
    }
  }
}
