import {
  ChangeDetectorRef,
  Component,
  ComponentFactoryResolver,
  ComponentRef,
  Inject,
  ViewChild,
  ViewContainerRef,
} from '@angular/core';
import {GoldenLayoutComponent, GoldenLayoutComponentHost, GoldenLayoutContainer,} from '@kpi4me/golden-angular-layout';
import GoldenLayout from '@kpi4me/golden-layout';
import {GoldenLayoutContentComponent} from '../../golden-layout/golden-layout-content/golden-layout-content.component';
import {ControlManagerService} from '../../control-manager/control-manager.service';

@Component({
  selector: 'app-base-hierarchy-wrapper',
  templateUrl: './filter-hierarchies-wrapper.component.html',
  styleUrls: ['./filter-hierarchies-wrapper.component.scss'],
})
export class FilterHierarchiesWrapperComponent extends GoldenLayoutContentComponent {
  @ViewChild('host', {read: ViewContainerRef, static: true})
  host: ViewContainerRef;
  compRef: ComponentRef<unknown>;
  selectedView: boolean;

  constructor(
    @Inject(GoldenLayoutComponentHost)
    protected goldenLayout: GoldenLayoutComponent,
    @Inject(GoldenLayoutContainer) protected container: GoldenLayout.Container,
    protected cdr: ChangeDetectorRef,
    protected controlManager: ControlManagerService,
    protected componentFactoryResolver: ComponentFactoryResolver
  ) {
    super(goldenLayout, container, cdr, controlManager);
  }

  protected onResize() {
    return;
  }

  ngOnInit(): void {
    return;
  }
}
