import {Component, HostListener, Inject} from "@angular/core";
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {FormControl} from "@angular/forms";

@Component({
  selector: 'kpi4me-reasoning-dialog-component',
  templateUrl: './reasoning-dialog.component.html',
  styleUrls: ['./reasoning-dialog.component.scss'],
})
export class ReasoningDialogComponent {
  reason = new FormControl('');

  @HostListener('window:keydown.enter', ['$event'])
  onEnter() {
    this.onConfirm();
  }

  constructor(
    public dialogRef: MatDialogRef<ReasoningDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { title: string }
  ) {
  }

  ngOnInit(): void {
    return;
  }

  onConfirm(): void {
    this.dialogRef.close(this.reason.value);
  }

  onCancel(): void {
    this.dialogRef.close();
  }
}
