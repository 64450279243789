import {HttpHeaders} from '@angular/common/http';
//TODO Add environment to input of an api service
// import {environment} from '../../environments/environment';

export const HttpOptions = {
  headers: new HttpHeaders({
    Accept: 'application/json',
    'Content-type': 'text/plain;charset=UTF-8',
    // versionnumber: environment.globalVersion
  }),
};
