import {ChangeDetectorRef, Component, Inject, ViewChild} from '@angular/core';
import {
  CalendarService,
  ControlManagerService,
  DEFAULT_TABLE_ACTIONS,
  GoldenLayoutContentComponent,
  IAction,
  SharedGridOptions,
  TableControlComponent
} from 'frontier/browserkit';
import {CoreService, EcalculusControlGUID, EControlActions, IApiControl} from 'frontier/nucleus';
import {GridOptions} from 'ag-grid-community';
import {combineLatest, debounceTime, Subject, switchMap} from 'rxjs';
import {GoldenLayoutComponent, GoldenLayoutComponentHost, GoldenLayoutContainer} from '@kpi4me/golden-angular-layout';
import GoldenLayout from '@kpi4me/golden-layout';
import {filter} from 'rxjs/operators';

@Component({
  selector: 'app-tariff-options',
  templateUrl: './tariff-options.component.html',
  styleUrl: './tariff-options.component.scss'
})
export class TariffOptionsComponent extends GoldenLayoutContentComponent {
  @ViewChild('groups') groupsTableRef: TableControlComponent;
  @ViewChild('types') typesTableRef: TableControlComponent;

  groupsApiInstance: IApiControl;
  groupsGuid = EcalculusControlGUID.ItemGroupView;
  groupsGridOptions: GridOptions = {
    ...SharedGridOptions,
    rowSelection: 'multiple',
  };
  groupsActions: IAction[] = [
    ...DEFAULT_TABLE_ACTIONS
  ];

  typesApiInstance: IApiControl;
  typesGuid = EcalculusControlGUID.ItemTypeView;
  typesGridOptions: GridOptions = {
    ...SharedGridOptions,
    rowSelection: 'multiple',
  };
  typesActions: IAction[] = [
    ...DEFAULT_TABLE_ACTIONS
  ];

  groupsApiInstanceInitialized$: Subject<IApiControl> = new Subject();
  typesApiInstanceInitialized$: Subject<IApiControl> = new Subject();

  protected readonly EControlActions = EControlActions;

  constructor(
    @Inject(GoldenLayoutComponentHost) protected goldenLayout: GoldenLayoutComponent,
    @Inject(GoldenLayoutContainer) protected container: GoldenLayout.Container,
    protected cdr: ChangeDetectorRef,
    protected controlManager: ControlManagerService,
    protected coreService: CoreService,
    private calendarService: CalendarService
  ) {
    super(goldenLayout, container, cdr, controlManager);

    this.subs.add(
      combineLatest([
        this.calendarService.globalForkedEvents$.pipe(filter(v => v != null)),
        this.groupsApiInstanceInitialized$
      ]).pipe(
        debounceTime(100),
        switchMap(([filter, apiInstance]) => {
          // Update the apiInstance and trigger the fetching of the data with the new filter.
          return this.groupsTableRef.setApiInstanceAndFetch({
            ...apiInstance,
            filter: {
              ...apiInstance?.filter,
              ...filter
            },
          });
        }),
      ).subscribe()
    );

    this.subs.add(
      combineLatest([
        this.calendarService.globalForkedEvents$.pipe(filter(v => v != null)),
        this.typesApiInstanceInitialized$
      ]).pipe(
        debounceTime(100),
        switchMap(([filter, apiInstance]) => {
          // Update the apiInstance and trigger the fetching of the data with the new filter.
          return this.typesTableRef.setApiInstanceAndFetch({
            ...apiInstance,
            filter: {
              ...apiInstance?.filter,
              ...filter
            },
          });
        }),
      ).subscribe()
    );
  }

  protected onResize(): void {
    this.groupsTableRef.fitColumns();
    this.typesTableRef.fitColumns();
  }
}
