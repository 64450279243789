import {Observable} from 'rxjs';
import {ApiConfigService} from '../api-config.service';
import {HttpHeaders} from '@angular/common/http';
import {IControlObject} from '../../interfaces/control-object.interface';

export class Tablecontrol {
  http;
  url;
  httpOptions;
  constructor(protected appConfig: ApiConfigService) {
    this.http = appConfig.http;
    this.url = appConfig.url;
    this.httpOptions = appConfig.httpOptions;
  }


  csvexport(InstanceId: string, ) : Observable<any> {
   return this.http.post(
      this.url + '/intern/api/control/ExecuteAction/' + InstanceId + '/csvexport/', {}, {...this.httpOptions, responseType: 'blob'}) as unknown as Observable<any>;
  }

  newselectionentry(InstanceId: string, name: string, attributename: string, RowObject: IControlObject) : Observable<IControlObject[]> {
   return this.http.post(
      this.url + '/intern/api/control/ExecuteAction/' + InstanceId + '/newselectionentry/', {"name": name, "attributename": attributename, "RowObject": RowObject}, this.httpOptions
    ) as unknown as Observable<IControlObject[]>;
  }

  changeline(InstanceId: string, RowObject: IControlObject, RowIdx: number, Attribute: string, AttributeIndex: number, Value: any | null) : Observable<any> {
   return this.http.post(
      this.url + '/intern/api/control/ExecuteAction/' + InstanceId + '/changeline/', {"RowObject": RowObject, "RowIdx": RowIdx, "Attribute": Attribute, "AttributeIndex": AttributeIndex, "Value": Value}, this.httpOptions
    ) as unknown as Observable<any>;
  }

  deleteline(InstanceId: string, RowObjects: IControlObject[]) : Observable<boolean> {
   return this.http.post(
      this.url + '/intern/api/control/ExecuteAction/' + InstanceId + '/deleteline/', {"RowObjects": RowObjects}, this.httpOptions
    ) as unknown as Observable<boolean>;
  }

  changeselectionentry(InstanceId: string, name: string, attribute: IControlObject, attributename: string, RowObject: IControlObject) : Observable<IControlObject[]> {
   return this.http.post(
      this.url + '/intern/api/control/ExecuteAction/' + InstanceId + '/changeselectionentry/', {"name": name, "attribute": attribute, "attributename": attributename, "RowObject": RowObject}, this.httpOptions
    ) as unknown as Observable<IControlObject[]>;
  }

  deleteselectionentry(InstanceId: string, attribute: IControlObject, attributename: string, RowObject: IControlObject) : Observable<IControlObject[]> {
   return this.http.post(
      this.url + '/intern/api/control/ExecuteAction/' + InstanceId + '/deleteselectionentry/', {"attribute": attribute, "attributename": attributename, "RowObject": RowObject}, this.httpOptions
    ) as unknown as Observable<IControlObject[]>;
  }

  newline(InstanceId: string, ) : Observable<any> {
   return this.http.post(
      this.url + '/intern/api/control/ExecuteAction/' + InstanceId + '/newline/', {}, this.httpOptions
    ) as unknown as Observable<any>;
  }

  duplicateline(InstanceId: string, RowObjects: IControlObject[], RowIdx: number) : Observable<any[]> {
   return this.http.post(
      this.url + '/intern/api/control/ExecuteAction/' + InstanceId + '/duplicateline/', {"RowObjects": RowObjects, "RowIdx": RowIdx}, this.httpOptions
    ) as unknown as Observable<any[]>;
  }

}
