import {Component} from '@angular/core';
import {CustomCellComponent} from '../custom-cell/custom-cell.component';
import {tap} from 'rxjs/operators';
import {createGridRow} from '../../../adapters/table/create-grid-row.function';
import {ICustomColDef} from '../../interfaces/custom-col-def.interface';
import {CoreService, EControlActions} from 'frontier/nucleus';

@Component({
  selector: 'app-boolean-cell-renderer',
  templateUrl: './boolean-cell-renderer.component.html',
  styleUrls: ['./boolean-cell-renderer.component.scss'],
})
export class BooleanCellRendererComponent extends CustomCellComponent {
  constructor(private coreService: CoreService) {
    super();
  }

  checkedHandler(evt: MouseEvent, checked: boolean) {
    evt.preventDefault();
    if (this.params.value?.editable === false) {
      evt.stopPropagation();
      return
    };

    this.params.value.value = !checked;
    const colDef: ICustomColDef = this.params.colDef;
    this.coreService.TableAction.changeline(
      (this.params.context as any).apiInstance.instanceid,
      this.params.data.apiRow.obj,
      this.params.data.apiRow.rowidx,
      colDef.attribute,
      colDef.attributeindex,
      this.params.value.value
    )
      .pipe(
        tap((res) => {
          this.coreService.controlDataChanged.emit({
            GUID: (this.params.context as any).GUID,
            changeType: EControlActions.changeLine
          });
          this.params.node.setData(createGridRow(res));
          (this.params.context as any).onCellValueChanged(this.params);
        })
      )
      .subscribe();
  }
}
