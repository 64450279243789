import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
  ViewEncapsulation
} from '@angular/core';
import {
  CoreService,
  EcalculusControlGUID,
  EControlActions,
  EsvgFiles,
  IApiControl,
  IControlObject,
} from 'frontier/nucleus';
import {
  combineLatest,
  debounceTime,
  distinctUntilChanged,
  merge,
  Observable,
  of,
  ReplaySubject,
  Subscription,
  switchMap,
} from "rxjs";
import {
  CalendarService,
  ControlManagerService, CSV_EXPORT_ACTION,
  IAction,
  IApiRow,
  SharedGridOptions,
  TableControlComponent,
} from 'frontier/browserkit';
import {GridOptions, RowSelectedEvent} from "ag-grid-community";
import {CalculusStateService} from "../../Services/calculus-state.service";
import {filter, finalize, tap} from "rxjs/operators";
import {ECalculusStateFilter} from "../config/state-filter.enum";
import {IProcessOverviewState} from "../config/category-state-selection.interface";
import {IPdfOverlayToggleEvent} from '../carts/pdf-overlay-toggle-event.interface';

@Component({
  selector: 'kpi4me-open-items',
  templateUrl: './open-items.component.html',
  styleUrls: ['./open-items.component.scss'],
  encapsulation: ViewEncapsulation.Emulated
})
export class OpenItemsComponent implements OnInit, OnDestroy {
  subs = new Subscription();


  @ViewChild('table') tableRef: TableControlComponent;
  @Input() fixedPageSize: number;
  @Output() invoiceToggleTriggered = new EventEmitter<IPdfOverlayToggleEvent>();

  tableApiInstance: IApiControl;
  tableApiInstance$ = new ReplaySubject<IApiControl>(1);
  openItemsGUID = EcalculusControlGUID.OpenItemList;
  selectedProcess: IProcessOverviewState;
  shownOverlay: IPdfOverlayToggleEvent = null;
  isInvoiceLoading = false;

  tableActions: IAction[] = [
    {
      displayName: () => {
        console.log('displayName')
        return this.shownOverlay?.type === 'collectiveInvoice' ? 'Rechnung ausblenden' : 'Rechnung anzeigen'
      },
      controlAction: EControlActions.invoicePreview,
      disabledIfNoSelection: true,
      disabledIfMultiSelection: true,
      color: 'primary',
      icon: EsvgFiles.search,
      action: () => this.toggleCollectiveInvoicePreview(),
      isDisabled: () => {
        return this.isInvoiceLoading === true;
      },
    },
    {
      displayName: 'Absetzungsschreiben öffnen',
      controlAction: EControlActions.load,
      disabledIfNoSelection: true,
      disabledIfMultiSelection: true,
      color: 'primary',
      icon: EsvgFiles.link,
      action: () => this.openRevisorLink(),
    },
    {
      displayName: 'Mahnungen erstellen',
      controlAction: EControlActions.dunning,
      disabledIfNoSelection: false,
      disabledIfMultiSelection: false,
      color: 'accent',
      icon: EsvgFiles.breaking_news,
      action: () => this.createDunning(),
      isHidden: () => {
        return !this.selectedProcess?.stateSelection?.states?.includes(ECalculusStateFilter.monitorPaymentError);
      },
    },
    CSV_EXPORT_ACTION
  ];

  gridOptions: GridOptions = {
    ...SharedGridOptions,
    pagination: true,
    rowSelection: 'single',
  };

  private _openItemSelectedSource = new ReplaySubject<IControlObject>(1);

  protected readonly EControlActions = EControlActions;

  constructor(
    public cdr: ChangeDetectorRef,
    protected controlManager: ControlManagerService,
    protected calculusStateService: CalculusStateService,
    private calendarService: CalendarService,
    protected coreService: CoreService,
  ) {
    this.subs.add(
      this.coreService.controlDataChanged.pipe(
        filter(evt => {
          return new Map([
            [EcalculusControlGUID.InvoiceList,
              new Set([EControlActions.cancel, EControlActions.uncollectible, EControlActions.payed, EControlActions.credit])],
            [EcalculusControlGUID.CartList, new Set([EControlActions.dunning])],
            [EcalculusControlGUID.OpenItemList, new Set([EControlActions.dunning])],
          ]).get(evt.GUID as EcalculusControlGUID)?.has(evt.changeType);
        }),
        tap(() => {
          // The open items table should change and fetch if an invoice was canceled.
          this.tableRef.refreshInfiniteCache()
        })).subscribe(),
    );

    this.subs.add(
      this._openItemSelectedSource.pipe(
        tap((obj: IControlObject) => {
          this.calculusStateService.selectedInvoice$.next({obj, invoiceId: this.tableRef.selectedRows[0][0].value})
        })
      ).subscribe()
    );

    this.subs.add(this.calculusStateService.processOverviewState$.subscribe(res => {
      this.selectedProcess = res;
    }));
  }

  ngOnInit(): void {
    this.subs.add(
      combineLatest([
        this.calendarService.globalForkedEvents$.pipe(filter(v => v != null)),
        this.tableApiInstance$,
        this.calculusStateService.processOverviewState$.pipe(
          distinctUntilChanged((a: IProcessOverviewState, b: IProcessOverviewState) => {
              // Ignore cart change selections
              delete a.cart;
              delete b.cart;
              return JSON.stringify(a) === JSON.stringify(b);
            }
          ),
          tap(selection => {
            if (selection.invoice == null) {
              this.tableRef?.gridApi?.deselectAll();
            }
          }),
        ),
      ]).pipe(
        debounceTime(50),
        switchMap(([filter, apiInstance, processSelection]) => {
          const {states, categories} = processSelection.stateSelection;
          // Update the apiInstance and trigger the fetching of the data with the new filter.
          return this.tableRef.setApiInstanceAndFetch({
            ...apiInstance,
            filter: {
              ...this.tableApiInstance?.filter,
              ...filter,
              valuelist: states.length > 0 ? states : categories,
              typename: processSelection.invoice?.invoiceId || (processSelection.client ? 'client' : null),
              parent: processSelection.client?.obj || null,
            },
          });
        }),
      )
        .subscribe()
    );

    this.subs.add(
      merge(
        this.calculusStateService.shownOverlay$.pipe(tap((v) => this.shownOverlay = v)),
        this.calculusStateService.isInvoiceLoading$.pipe(tap((v) => this.isInvoiceLoading = v))
      ).subscribe(() => {
        // changes table input. Call change detection
        this.cdr.detectChanges();
        this.tableRef?.cdr.detectChanges();
      })
    )
  }

  ngOnDestroy() {
    this.subs.unsubscribe();
  }

  openItemDetails(evt: RowSelectedEvent): void {
    this._openItemSelectedSource.next((evt.data.apiRow as IApiRow).obj);
  }

  onOpenItemDeselect(evt: RowSelectedEvent): void {
    console.log('Deselected open item', evt);
  }

  private toggleCollectiveInvoicePreview(): Observable<any> {
    this.isInvoiceLoading = true;
    this.invoiceToggleTriggered.emit({
      type: 'collectiveInvoice',
      data: {obj: this.tableRef?.gridApi?.getSelectedRows()[0].apiRow.obj, apiInstance: this.tableApiInstance}
    });
    return of(null);
  }

  private openRevisorLink(): Observable<string> {
    return this.coreService.Openitemlist.revisorurl(
      this.tableRef.apiInstance.instanceid,
      this.tableRef.selectedRows[0].apiRow.obj,
    ).pipe(
      tap((link) => {
        window.open(link, '_blank');
      }),
      finalize(() => {
        this.tableRef?.gridApi?.hideOverlay();
      })
    );
  }

  createDunning(): Observable<boolean> {
    return this.coreService.Openitemlist.createalldunning(this.tableRef.apiInstance.instanceid);
  }
}
