<app-dialog-header
  [dialogRef]="dialogRef"
  [title]="data.title"
  (close)="cancel()"
>
</app-dialog-header>
<mat-dialog-content [formGroup]="form">
  <mat-form-field>
    <mat-label>Name</mat-label>
    <input formControlName="name" matInput minlength="3" required/>
  </mat-form-field>
</mat-dialog-content>

<mat-dialog-actions>
  <app-confirm-icon-button
    (customClick)="onConfirm()"
    [disabled]="form.invalid"
  ></app-confirm-icon-button>
</mat-dialog-actions>
