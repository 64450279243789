import {Component, ViewEncapsulation} from "@angular/core";
import {GridOptions} from "ag-grid-community";
//import {EMydormControlGUID} from 'frontier/nucleus';
import {SharedGridOptions} from "../../control/table-control/shared-gridoptions.const";

@Component({
  selector: 'kpi4me-webhook',
  templateUrl: './webhook.component.html',
  styleUrls: ['./webhook.component.scss'],
  encapsulation: ViewEncapsulation.Emulated,
})
export class WebhookComponent {

  gridOptions: GridOptions = {
    ...SharedGridOptions,
    rowSelection: 'single',
  };

  tableGUID = 'AA369705037C43F8B72A2EA5AB73D163';

}
