import {EventEmitter, Injectable} from '@angular/core';
import {Control} from './intern.api/control.class';
import {Form} from './intern.api/form.class';
import {Hierarchy} from './intern.api/hierarchy.class';
import {Sysconfig} from './intern.api/sysconfig.class';
import {Table} from './intern.api/table.class';
import {User} from './intern.api/user.class';
import {ApiConfigService} from './api-config.service';
import {HttpClient} from '@angular/common/http';
import {Tablecontrol} from './api.actions/ttablecontrol.class';
import {Formcontrol} from './api.actions/tformcontrol.class';
import {Observable} from 'rxjs';
import {Call} from './extern.api/call.class';
import {Campaign} from './extern.api/Campaign.class';
import {Auth} from "./extern.api/auth.class";
import {Courseplantable} from "./api.actions/tcourseplantable.class";
import {Coursedaytable} from "./api.actions/tcoursedaytable.class";
import {Emailsmtpconfiguration} from "./api.actions/temailsmtpconfiguration.class";
import {Userlisttable} from "./api.actions/tuserlisttable.class";
import {Style} from "./resource.api/style.class";
import {Tablesynctask} from "./api.actions/ttablesynctask.class";
import {Singleblockcoursetable} from "./api.actions/tsingleblockcoursetable.class";
import {Externalsystemdatauploadtable} from "./api.actions/texternalsystemdatauploadtable.class";
import {Etlstepoverviewtable} from "./api.actions/tetlstepoverviewtable.class";
import {Tableexternalsystem} from "./api.actions/ttableexternalsystem.class";
import {Crudcontrol} from "./api.actions/tcrudcontrol.class";
import {Hierarchycontrol} from "./api.actions/thierarchycontrol.class";
import {Contractinfo} from "./api.actions/tcontractinfo.class";
import {Templateinfo} from "./api.actions/ttemplateinfo.class";
import {Contractcollectionlist} from "./api.actions/tcontractcollectionlist.class";
import {Sendcontractdetails} from "./api.actions/tsendcontractdetails.class";
import {Cartlist} from "./api.actions/tcartlist.class";
import {Allclientlist} from "./api.actions/tallclientlist.class";
import {Invoicelist} from "./api.actions/tinvoicelist.class";
import {IControlDataChanged} from './control-data-changed.interface';
import {Demomanagement} from "./intern.api/Demomanagement.class";
import {Clientlist} from "./api.actions/tclientlist.class";
import {Emailtemplatereplaceform} from "./api.actions/temailtemplatereplaceform.class";
import {Cartitemslist} from "frontier/nucleus/src/lib/api/api.actions/tcartitemslist.class";
import {Openitemlist} from "frontier/nucleus/src/lib/api/api.actions/topenitemlist.class";
import {Epostconfiguration} from "frontier/nucleus/src/lib/api/api.actions/tepostconfiguration.class";
import {Outboxbook} from 'frontier/nucleus/src/lib/api/api.actions/toutboxbook.class';
import {Dunninglevelview} from "frontier/nucleus/src/lib/api/api.actions/tdunninglevelview.class";
import {Permanentordinanceforclient} from "frontier/nucleus/src/lib/api/api.actions/tpermanentordinanceforclient.class";
import {Itempricehistoryview} from 'frontier/nucleus/src/lib/api/api.actions/titempricehistoryview.class';
import {
  Templateconfigurationcontrol
} from 'frontier/nucleus/src/lib/api/api.actions/ttemplateconfigurationcontrol.class';
import {Usetoken} from 'frontier/nucleus';

@Injectable({
  providedIn: 'root',
})
export class CoreService {
  User = new User(this.appConfig);
  Table = new Table(this.appConfig);
  Form = new Form(this.appConfig);
  CrudControl = new Crudcontrol(this.appConfig);
  Control = new Control(this.appConfig);
  Hierarchy = new Hierarchy(this.appConfig);
  Sysconfig = new Sysconfig(this.appConfig);
  UseToken = new Usetoken(this.appConfig);
  Call = new Call(this.appConfig);
  Campaign = new Campaign(this.appConfig);

  Auth = new Auth(this.appConfig);
  TableAction = new Tablecontrol(this.appConfig);

  HierarchyAction = new Hierarchycontrol(this.appConfig);
  Tablesyncaction = new Tablesynctask(this.appConfig);
  ActionForm = new Formcontrol(this.appConfig);
  sendContractDetails = new Sendcontractdetails(this.appConfig);
  mainMenuClicked = new EventEmitter();
  CoursePlan = new Courseplantable(this.appConfig);
  CourseDay = new Coursedaytable(this.appConfig);
  SingleBlockCourseTable = new Singleblockcoursetable(this.appConfig);
  Externalsystemdatauploadtable = new Externalsystemdatauploadtable(this.appConfig);
  Etlstepoverviewtable = new Etlstepoverviewtable(this.appConfig);
  Tableexternalsystem = new Tableexternalsystem(this.appConfig);
  DemoManagement = new Demomanagement(this.appConfig);

  Cartlist = new Cartlist(this.appConfig);
  Clientlist = new Clientlist(this.appConfig);
  Allclientlist = new Allclientlist(this.appConfig);
  Invoicelist = new Invoicelist(this.appConfig);
  Cartitemlist = new Cartitemslist(this.appConfig);
  Openitemlist = new Openitemlist(this.appConfig);
  Epostconfiguration = new Epostconfiguration(this.appConfig);
  Dunninglevelview = new Dunninglevelview(this.appConfig);
  Permanentordinanceforclient = new Permanentordinanceforclient(this.appConfig);
  Templateconfigurationcontrol = new Templateconfigurationcontrol(this.appConfig);

  Itempricehistoryview = new Itempricehistoryview(this.appConfig);

  SMTP = new Emailsmtpconfiguration(this.appConfig);

  UserList = new Userlisttable(this.appConfig);

  Emailtemplatereplaceform = new Emailtemplatereplaceform(this.appConfig);
  TemplateInfo = new Templateinfo(this.appConfig);

  ContractInfo = new Contractinfo(this.appConfig);
  ContractCollection = new Contractcollectionlist(this.appConfig);
  Outboxbook = new Outboxbook(this.appConfig);

  ResourceApi = new Style(this.appConfig);

  controlDataChanged = new EventEmitter<IControlDataChanged>();

  constructor(
    protected appConfig: ApiConfigService,
    private http: HttpClient
  ) {
    this.ResourceApi.version().subscribe((res) => {
      console.log('Service Version', res);
    });
    this.ResourceApi.VerifyVersion(this.appConfig.environment.buildNumber, this.appConfig.environment.buildHash).subscribe((res) => {
      if (res) {
        console.log('Frontend Version verified!');
      } else {
        console.error('Frontend Version verification failed!');
      }
    })
  }


  getSubscriberStatus(aSubscriberId: string): Observable<any> {
    return this.http.get(
      this.appConfig.url +
      '/intern/api/demo/getSubscriberStatus/' +
      encodeURI(aSubscriberId) +
      '/',
      this.appConfig.httpOptions
    ) as Observable<any>;
  }

  csvExport(InstanceId: string): Observable<any> {
    return this.http.post(
      this.appConfig.url + '/intern/api/control/ExecuteAction/' + InstanceId + '/csvexport/', {}, {...this.appConfig.httpOptions, responseType: 'blob', observe: 'response'}) as unknown as Observable<any>;
  }
}
