import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Renderer2,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import {
  CalendarService,
  ColTypeColDefMap,
  ControlManagerService, CSV_EXPORT_ACTION,
  DialogService,
  DisplayedColumns,
  IAction,
  ReasoningDialogComponent,
  SharedGridOptions,
  TableApiAdapter,
  TableControlComponent,
} from 'frontier/browserkit';
import {
  changes,
  CoreService,
  dateObjectToISOString,
  EcalculusControlGUID,
  EControlActions,
  EsvgFiles,
  IApiControl,
  IControlObject,
} from 'frontier/nucleus';
import {GridOptions, PaginationChangedEvent, RowNode, RowSelectedEvent} from "ag-grid-community";
import {CalculusStateService} from "../../Services/calculus-state.service";
import {MatDialog, MatDialogRef} from "@angular/material/dialog";
import {StatusDialogComponent} from "./status-dialog/status-dialog.component";
import {
  BehaviorSubject,
  combineLatest,
  distinctUntilChanged,
  merge,
  Observable,
  of,
  ReplaySubject,
  scan,
  shareReplay,
  startWith,
  Subscription,
  switchMap,
} from "rxjs";
import {catchError, concatMap, filter, finalize, map, tap} from "rxjs/operators";
import {ECalculusStateFilter} from '../config/state-filter.enum';
import {IProcessOverviewState} from '../config/category-state-selection.interface';
import {ICustomGridRow} from 'frontier/browserkit/src/lib/control/table-control/interfaces/custom-grid-row.interface';
import {NewMissionDialogComponent} from './new-mission-dialog/new-mission-dialog.component';
import {INewMissionDialog} from './new-mission-dialog/new-mission-dialog.interface';
import {TShownTables} from '../process-overview/shown-tables.type';
import {IPdfOverlayToggleEvent} from './pdf-overlay-toggle-event.interface';
import {areSetsEqual} from 'frontier/nucleus/src/lib/utils/equal-sets.func';
import {IPdfBlobEvent} from 'frontier/browserkit/src/lib/control/table-control/pdf-blob-event.interface';

export class CartsApiAdapter extends TableApiAdapter {
  checkedIndex: number;
  noteIndex: number;

  from(apiData: any): any {
    const filteredColumns = apiData.displayedColumns.filter((col: DisplayedColumns, index: number) => {
      if (col.attribute === 'Checked') {
        this.checkedIndex = index;
      } else if (col.attribute === 'AttachmentNoteCombined') {
        this.noteIndex = index;
      }
      return col.attribute !== 'Checked' && col.attribute !== 'AttachmentNoteCombined';
    });
    return {
      displayedColumns: filteredColumns,
      columnDefs: this.createGridCols(filteredColumns),
      name: null,
      objecttype: apiData.objecttype,
      entityName: null,
      parentobj: apiData.parentobj,
    };
  }
}

@Component({
  selector: 'kpi4me-carts',
  templateUrl: './carts.component.html',
  styleUrls: ['./carts.component.scss'],
  encapsulation: ViewEncapsulation.Emulated
})
export class CartsComponent implements OnInit, AfterViewInit, OnDestroy {
  private subs = new Subscription();

  dialogRef: MatDialogRef<StatusDialogComponent>;
  @ViewChild('table') tableRef: TableControlComponent;
  @ViewChild('table', {static: true, read: ElementRef}) elementRef: ElementRef;
  @Input() fixedPageSize: number;

  tableApiInstance: IApiControl;
  tableApiInstance$ = new ReplaySubject<IApiControl>(1);
  cartsApiAdapter: CartsApiAdapter = new CartsApiAdapter({}, ColTypeColDefMap);
  cartGUID = EcalculusControlGUID.CartList;
  processOverViewState: IProcessOverviewState;
  tableActions: IAction[] = [
    {
      displayName: 'Erzeugen',
      controlAction: EControlActions.invoice,
      disabledIfNoSelection: false,
      disabledIfMultiSelection: false,
      color: 'primary',
      icon: EsvgFiles.document,
      tooltip: 'Erstellt alle erstellbaren, noch nicht erstellten Rechnungen gemäß den aktuell gesetzten Filtern und ' +
        'dem Breadcrumb.',
      tooltipDelay: 1000,
      action: () => this.createAllInvoices(),
      isHidden: () => {
        return !this.processOverViewState?.stateSelection?.categories.includes(ECalculusStateFilter.createInvoice);
      },
    },
    {
      displayName: () => {
        return this.shownOverlay?.type === 'trapo' ? 'Trapo ausblenden' : 'Trapo anzeigen';
      },
      controlAction: EControlActions.preview,
      disabledIfNoSelection: true,
      disabledIfMultiSelection: true,
      color: 'primary',
      icon: EsvgFiles.search,
      action: () => of(null).pipe(tap(() => this.pdfTrapoToggle$.emit({type: 'trapo'}))),
      isDisabled: () => {
        return this.isPdfOverlayLoading$.getValue();
      },
    },
    {
      displayName: () => {
        return this.shownOverlay?.type === 'invoice' ? 'Rechnung ausblenden' : 'Rechnung anzeigen';
      },
      controlAction: EControlActions.invoicePreview,
      disabledIfNoSelection: true,
      disabledIfMultiSelection: true,
      color: 'primary',
      icon: EsvgFiles.search,
      action: () => of(null).pipe(tap(() => this.pdfInvoiceToggle$.emit({type: 'invoice'}))),
      isDisabled: () => {
        return this.isPdfOverlayLoading$.getValue();
      },
      isHidden: () => {
        return !!this.processOverViewState.invoice || this.processOverViewState.stateSelection?.categories.includes(ECalculusStateFilter.ETLnMapping);
      },
    },
    {
      displayName: 'Neuer Einsatz',
      controlAction: EControlActions.newMission,
      disabledIfNoSelection: false,
      disabledIfMultiSelection: false,
      color: 'primary',
      icon: EsvgFiles.add,
      action: () => this.createMission(),
      isHidden: () => {
        return !this.processOverViewState.stateSelection?.categories.includes(ECalculusStateFilter.ETLnMapping);
      },
    },
    {
      controlAction: EControlActions.check,
      displayName: () => {
        if (this.tableRef?.gridApi == null || this.processOverViewState?.stateSelection?.categories == null) return 'Geprüft';

        const selectedRows = this.tableRef.gridApi.getSelectedRows();
        const categoryIDs = this.processOverViewState.stateSelection?.categories || null;

        return selectedRows.length !== 1 || selectedRows[0][this.cartsApiAdapter.checkedIndex]?.value ||
        categoryIDs.includes(ECalculusStateFilter.check) || categoryIDs.includes(ECalculusStateFilter.kpiConnect) ?
          'Geprüft' : 'Prüfung anfordern'
      },
      color: () => {
        if (this.tableRef?.gridApi == null) return 'accent';
        const selectedRows = this.tableRef.gridApi.getSelectedRows();
        return selectedRows.length !== 1 ? 'primary' : selectedRows[0][this.cartsApiAdapter.checkedIndex]?.value ? 'accent' : 'accent'
      },
      icon: () => {
        if (this.tableRef?.gridApi == null) return '';
        const selectedRows = this.tableRef.gridApi.getSelectedRows();
        return selectedRows.length !== 1 ? '' : selectedRows[0][this.cartsApiAdapter.checkedIndex]?.value ? EsvgFiles.list_checked : ''
      },
      disabledIfNoSelection: true,
      disabledIfMultiSelection: true,
      action: () => {
        if (this.tableRef?.gridApi == null || this.processOverViewState?.stateSelection?.categories == null) return this.toggleChecked();
        const categoryIDs = this.processOverViewState?.stateSelection?.categories || null;

        const selectedRows = this.tableRef.gridApi.getSelectedRows();
        return selectedRows.length !== 1 || selectedRows[0][this.cartsApiAdapter.checkedIndex]?.value
        || categoryIDs.includes(ECalculusStateFilter.check) || categoryIDs.includes(ECalculusStateFilter.kpiConnect) ?
          this.toggleChecked() : this.manualCheck();
      },
      isHidden: () => {
        return this.haveCommonElements(
          this.processOverViewState?.stateSelection?.categories,
          [ECalculusStateFilter.total, ECalculusStateFilter.monitorPayment, ECalculusStateFilter.arrangeInvoice],
        );
      },
    },
    {
      displayName: 'Mahnungen erstellen',
      controlAction: EControlActions.dunning,
      disabledIfNoSelection: false,
      disabledIfMultiSelection: false,
      color: 'accent',
      icon: EsvgFiles.breaking_news,
      action: () => this.createDunning(),
      isHidden: () => {
        return !this.processOverViewState?.stateSelection?.states.includes(ECalculusStateFilter.monitorPaymentError);
      },
    },
    CSV_EXPORT_ACTION,
  ];
  gridOptions: GridOptions = {
    ...SharedGridOptions,
    pagination: true,
    rowSelection: 'single'

  };

  selectedRow$ = new ReplaySubject<RowNode>(1);
  paginationChange$ = new EventEmitter<PaginationChangedEvent>();

  // The currently selected row
  paginationSelectedRow$ = merge(
    this.paginationChange$.pipe(
      map((paginationChange) => {
        // only if the page size is one, the first row should be selected
        if (this.gridOptions.paginationPageSize === 1) {
          const topRowIndex = paginationChange.api.paginationGetCurrentPage();
          paginationChange.api.setFocusedCell(topRowIndex, "0");
          const firstRow = paginationChange.api.getDisplayedRowAtIndex(topRowIndex);
          firstRow?.setSelected(true, true)
          return firstRow;
        }
        return null;
      }),
      filter(row => row != null)),
    this.selectedRow$
  ).pipe(
    filter(node => node.data != null),
    distinctUntilChanged((previous: RowNode, current: RowNode) =>
      (previous?.data as ICustomGridRow)?.apiRow?.obj?.signature === (current.data as ICustomGridRow)?.apiRow?.obj?.signature
    ),
    tap((row) => console.log('selected row changed', row)),
    startWith(null)
  )

  pdfTrapoToggle$ = new EventEmitter<IPdfOverlayToggleEvent>();
  pdfInvoiceToggle$ = new EventEmitter<IPdfOverlayToggleEvent>();
  pdfCollectiveInvoiceToggle$ = new EventEmitter<IPdfOverlayToggleEvent>();

  // The type of overlay that is displayed
  pdfOverlayType$: Observable<IPdfOverlayToggleEvent | null> =
    merge(
      this.pdfTrapoToggle$,
      this.pdfInvoiceToggle$,
      this.pdfCollectiveInvoiceToggle$,
      this.calculusStateService.activeTables$.pipe(
        distinctUntilChanged((previous, current) => {
          return areSetsEqual(previous, current);
        }),
        map(() => null)
      )
    ).pipe(
      scan((oldOverlay: IPdfOverlayToggleEvent, newOverlay: IPdfOverlayToggleEvent | null) => {
        if (newOverlay?.type === oldOverlay?.type) {
          return null;
        }
        return newOverlay;
      }, null),
      shareReplay(1)
    );
  isPdfOverlayLoading$ = new BehaviorSubject(false);
  shownOverlay: IPdfOverlayToggleEvent | null = null;

  // The pdf that is downloaded and should be displayed in the pdf overlay
  pdfBlob$: Observable<IPdfBlobEvent> = combineLatest([
      this.pdfOverlayType$,
      this.paginationSelectedRow$
    ]
  ).pipe(
    tap(() => this.isPdfOverlayLoading$.next(true)),
    switchMap(([newOverlay, paginationSelectedRow]) => {
      let req$: Observable<IPdfBlobEvent> = of(null);
      const newOverlayType = newOverlay?.type || null;
      // If no current overlay is shown and the selected pagination row has changed select it.
      if (newOverlayType === 'collectiveInvoice') {
        req$ = this.coreService.Openitemlist.downloadcurrentinvoice(
          newOverlay.data?.apiInstance.instanceid,
          newOverlay.data?.obj,
        ).pipe(
          map(v => ({
            blob: v, externalData: true
          }))
        );
      }
      else if (newOverlayType === 'invoice') {
        req$ = this.coreService.Cartlist.downloadcurrentinvoice(
          this.tableRef.apiInstance.instanceid,
          paginationSelectedRow.data.apiRow.obj,
        ).pipe(
          map(v => ({
            blob: v, externalData: false
          }))
        );
      } else if (newOverlayType === 'trapo') {
        req$ = this.coreService.Cartlist.downloadtrapo(
          this.tableRef.apiInstance.instanceid,
          paginationSelectedRow.data.apiRow.obj,
        ).pipe(
          map(v => ({
            blob: v, externalData: false
          }))
        );
      }
      return req$.pipe(
        catchError(() => of(null)),
        finalize(() => this.calculusStateService.shownOverlay$.next(newOverlay)));
    }),
    tap((overlay) => {
      this.tableRef?.gridApi?.hideOverlay();
      this.isPdfOverlayLoading$.next(false);
    }),
    catchError((e) => {
        this.tableRef?.gridApi?.hideOverlay();
        this.isPdfOverlayLoading$.next(false);
        return of(e);
      }
    ));

  protected readonly EControlActions = EControlActions;
  private dynamicText$ = new BehaviorSubject<string>('');
  private activeTables: Set<TShownTables>;

  constructor(
    public cdr: ChangeDetectorRef,
    protected controlManager: ControlManagerService,
    public calculusStateService: CalculusStateService,
    protected dialog: MatDialog,
    protected coreService: CoreService,
    private calendarService: CalendarService,
    protected dialogService: DialogService,
    private renderer: Renderer2,
  ) {
    this.subs.add(
      this.coreService.controlDataChanged.pipe(
        filter(evt => {
          return new Map([
            [EcalculusControlGUID.CartList,
              new Set([
                EControlActions.arrange,
                EControlActions.invoice,
                EControlActions.check,
                EControlActions.dunning,
              ])],
            [EcalculusControlGUID.InvoiceList, new Set([
              ...changes,
              EControlActions.cancel,
              EControlActions.uncollectible,
              EControlActions.payed,
              EControlActions.credit
            ])],
            [EcalculusControlGUID.CartItemsList, new Set([...changes, EControlActions.partlyCancel])],
            [EcalculusControlGUID.ClientList, changes],
            [EcalculusControlGUID.CrewList, changes],
            [EcalculusControlGUID.CrudCartAdditionaldata, changes],
            [EcalculusControlGUID.OrdinanceList, changes],
            [EcalculusControlGUID.TransportList, changes],
            [EcalculusControlGUID.NotBillable, changes],
            [EcalculusControlGUID.OpenItemList, new Set([EControlActions.dunning])],
            [EcalculusControlGUID.PermanentOrdinanceForClient, new Set([EControlActions.delete])],
            [null, new Set([EControlActions.resolveClientMessage])]
          ]).get(evt.GUID as EcalculusControlGUID)?.has(evt.changeType);
        }),
        tap(() => this.tableRef.refreshInfiniteCache())).subscribe(),
    );

    this.subs.add(
      this.coreService.controlDataChanged.pipe(
        filter(evt => {
          return new Map([
            [EcalculusControlGUID.AllClientList, new Set([...changes, EControlActions.attendance])],
            [EcalculusControlGUID.PermanentOrdinanceForClient, new Set([EControlActions.delete])],
          ]).get(evt.GUID as EcalculusControlGUID)?.has(evt.changeType);
        }),
        switchMap(() => this.tableRef.changeAndFetch())).subscribe(),
    );

    this.subs.add(this.calculusStateService.processOverviewState$.subscribe(res => {
      this.processOverViewState = res;
    }));

    this.subs.add(this.calculusStateService.shownOverlay$.subscribe(v => {
      this.shownOverlay = v;
      this.tableRef?.cdr?.detectChanges();
    }));
  }


  ngOnInit() {
    this.subs.add(
      combineLatest([
        this.calendarService.globalForkedEvents$.pipe(filter(v => v != null)),
        this.tableApiInstance$,
        this.calculusStateService.processOverviewState$.pipe(
          distinctUntilChanged((previous, current) => {
            // If the current cart for clients were replaced
            if (current?.cart?.replaced) {
              // When client table is shown, change and fetch the carts again
              if (this.activeTables.has('clients')) {
                return false;
              }
              // Only refresh the rows if clients not shown
              this.tableRef?.refreshInfiniteCache();
              return true;
            }
            // Ignore cart changes to prevent refreshes when another cart is selected
            delete previous.cart;
            delete current.cart;
            return JSON.stringify(previous) === JSON.stringify(current)
          }),
          tap(() => {
            this.tableRef?.gridApi?.setFilterModel(null);
            this.tableRef?.gridApi?.deselectAll();
          })),
      ]).pipe(
        switchMap(([filter, apiInstance,
                     processOverviewState]) => {
          const {states, categories} = processOverviewState.stateSelection;
          // Update the apiInstance and trigger the fetching of the data with the new filter.
          return this.tableRef.setApiInstanceAndFetch({
            ...apiInstance,
            filter: {
              ...this.tableApiInstance?.filter,
              ...filter,
              valuelist: states.length > 0 ? states : categories,
              typename: processOverviewState.invoice?.invoiceId || (processOverviewState.client?.name ? 'client' : null),
              parent: processOverviewState.client?.obj || null,
            }
          })
        }),
      ).subscribe()
    );

    this.subs.add(
      this.calculusStateService.activeTables$.subscribe(tables => {
        this.activeTables = tables;
      })
    )
  }

  ngAfterViewInit(): void {
    // Get the footerElement by class name
    const footerElement = this.elementRef.nativeElement.querySelector('.ag-paging-panel');
    const firstChild = this.elementRef.nativeElement.querySelector('.ag-paging-row-summary-panel');

    // Create a new span footerElement
    const span = this.renderer.createElement('span');
    this.renderer.addClass(span, 'trapo-note'); // Add a custom class to the span

    // Add the span to the selected footerElement
    this.renderer.insertBefore(footerElement, span, firstChild);

    // Update the text content dynamically
    this.subs.add(
      this.dynamicText$.subscribe((note) => {
        this.updateTextContent(span, note);
      })
    );
  }

  ngOnDestroy() {
    this.subs.unsubscribe();
  }

  onCartRowSelected(row: RowSelectedEvent): void {
    // update trapo note in footer
    this.selectedRow$.next(row.node);
    if (row.data.apiRow.cols[this.cartsApiAdapter.noteIndex] == null) {
      this.dynamicText$.next('');
    } else {
      this.dynamicText$.next(row.data.apiRow.cols[this.cartsApiAdapter.noteIndex].value);
    }

    this.cdr.detectChanges();

    this.calculusStateService.selectedCartRow$.next({
      obj: this.tableRef.selectedRows[0].apiRow.obj,
      number: this.tableRef.selectedRows[0][0].value,
    })
    this.tableRef?.gridApi?.hideOverlay();
    this.coreService.controlDataChanged.emit({changeType: EControlActions.detail, GUID: this.cartGUID});
  }

  private createAllInvoices(): Observable<boolean> {
    return this.coreService.Cartlist.createallinvoices(this.tableRef.apiInstance.instanceid)
      .pipe(
        finalize(() => {
          this.tableRef?.refreshInfiniteCache();
          this.tableRef?.gridApi?.hideOverlay();
        })
      );
  }

  private toggleChecked(): Observable<any> {
    return this.coreService.Cartlist.setchecked(
      this.tableRef.apiInstance.instanceid, this.tableRef.selectedRows[0].apiRow.obj,
    ).pipe(
      tap(() => {
        this.tableRef.refreshInfiniteCache();
      })
    );
  }

  private manualCheck(): Observable<boolean | null> {
    return this.dialogService.dialog.open(ReasoningDialogComponent, {
      data: {
        title: 'Begründung'
      }
    }).afterClosed().pipe(
      concatMap((reason: string): Observable<boolean> => {
          if (reason) {
            return this.coreService.Cartlist.setmanualcheckneeded(
              this.tableRef.apiInstance.instanceid,
              this.tableRef.selectedRows[0].apiRow.obj,
              reason,
            )
          }
          return of(null);
        }
      ),
      finalize(
        () => this.tableRef?.gridApi?.hideOverlay()
      )
    )
  }

  private updateTextContent(element: any, text: string): void {
    // Set the text content of the span element
    element.textContent = text;
  }

  haveCommonElements(arr1: any[], arr2: any[]): boolean {
    const set1 = new Set(arr1);
    for (const item of arr2) {
      if (set1.has(item)) {
        return true;
      }
    }
    return false;
  }

  private createMission(): Observable<any> {
    return this.coreService.Cartlist.getbusinessareas(this.tableApiInstance.instanceid).pipe(
      switchMap((businessAreas) => {
        return this.openCreateMissionDialog(businessAreas)
      })
    )
  }

  private openCreateMissionDialog(businessAreas: IControlObject[]) {
    const dialogRef = this.dialogService.dialog.open(NewMissionDialogComponent, {
      width: '32rem',
      data: {
        instanceId: this.tableApiInstance.instanceid,
        businessAreas: businessAreas.map(b => ({
          name: b.name,
          value: b.signature
        }))
      } as INewMissionDialog
    })
    return dialogRef.afterClosed().pipe(
      concatMap((result: INewMissionDialog) => {
        if (result) {
          return this.coreService.Cartlist.newcart(
            this.tableApiInstance.instanceid,
            result.missionNumber,
            dateObjectToISOString(result.date),
            businessAreas.find(ba => ba.signature === result.businessAreaSignature)
          ).pipe(
            tap(() => this.coreService.controlDataChanged.emit({
              changeType: EControlActions.create,
              GUID: this.cartGUID
            }))
          )
        }
        return of(null);
      }),
      finalize(() => {
        this.tableRef?.gridApi?.hideOverlay();
      })
    )
  }

  createDunning(): Observable<boolean> {
    return this.coreService.Cartlist.createalldunning(this.tableApiInstance.instanceid);
  }
}
