import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import {
  CalendarService,
  ControlManagerService, CSV_EXPORT_ACTION,
  DialogService,
  IAction,
  IApiRow,
  SharedGridOptions,
  TableControlComponent,
} from 'frontier/browserkit';
import {GridOptions, RowSelectedEvent} from "ag-grid-community";
import {
  CoreService,
  EcalculusControlGUID,
  EControlActions,
  EsvgFiles,
  IApiControl,
  IControlObject,
} from 'frontier/nucleus';
import {CalculusStateService} from "../../Services/calculus-state.service";
import {
  combineLatest,
  concatMap,
  debounceTime,
  distinctUntilChanged,
  mergeMap,
  Observable,
  of,
  ReplaySubject,
  Subscription,
  switchMap
} from "rxjs";
import {filter, tap} from "rxjs/operators";
import {
  PrescriptionAttendanceDialogComponent
} from './prescription-attendance-dialog/prescription-attendance-dialog.component';
import {
  IPermanentOrdinance,
  IPrescriptionAttendanceDialog
} from './prescription-attendance-dialog/prescription-attendance-dialog.interface';
import {areSetsEqual} from 'frontier/nucleus/src/lib/utils/equal-sets.func';

@Component({
  selector: 'kpi4me-clients',
  templateUrl: './clients.component.html',
  styleUrls: ['./clients.component.scss'],
  encapsulation: ViewEncapsulation.Emulated
})
export class ClientsComponent implements OnInit, OnDestroy {
  subs = new Subscription();

  @Input() fixedPageSize: number;

  @ViewChild('table') tableRef: TableControlComponent;
  tableApiInstance: IApiControl;
  tableApiInstance$ = new ReplaySubject<IApiControl>();
  clientGUID = EcalculusControlGUID.AllClientList;
  tableActions: IAction[] = [
    {
      displayName: 'Dauerverordnung abrechnen',
      controlAction: EControlActions.attestation,
      disabledIfNoSelection: true,
      disabledIfMultiSelection: true,
      color: 'primary',
      icon: EsvgFiles.document,
      action: () => this.proofOfAttendance(),
    },
    CSV_EXPORT_ACTION
  ];

  gridOptions: GridOptions = {
    ...SharedGridOptions,
    pagination: true,
    rowSelection: 'single',
  };

  @Output() switchToCarts = new EventEmitter<IControlObject>();

  private _selectedClient$ = new ReplaySubject<IControlObject>(1);

  protected readonly EControlActions = EControlActions;

  constructor(
    protected cdr: ChangeDetectorRef,
    protected controlManager: ControlManagerService,
    protected calculusStateService: CalculusStateService,
    private calendarService: CalendarService,
    protected coreService: CoreService,
    private dialogService: DialogService
  ) {
    this.subs.add(
      this.coreService.controlDataChanged.pipe(
        filter(evt => {
          return new Map([
            [EcalculusControlGUID.AllClientList, new Set([EControlActions.attendance, EControlActions.delete])],
            [EcalculusControlGUID.PermanentOrdinanceForClient, new Set([EControlActions.delete])],
          ]).get(evt.GUID as EcalculusControlGUID)?.has(evt.changeType);
        }),
        tap(() => this.tableRef?.refreshInfiniteCache())).subscribe(),
    );

    this.subs.add(
      this._selectedClient$.pipe(
        tap((obj: IControlObject) => {
          // this.calculusStateService.activeCategoryStateSelection$.next({
          //   ...this.calculusStateService.activeCategoryStateSelection$.getValue(),
          //   activeClient: {obj: obj, name: this.tableRef.selectedRows[0][0].value},
          // });
          this.calculusStateService.selectedClientRow$.emit(
            {obj, name: this.tableRef.selectedRows[0][0].value},
          )
        })
      ).subscribe()
    );

    // TODO this.subs.add(
    //   combineLatest([
    //     this.tableApiInstance$,
    //     this.calculusStateService.replacedClientForCart$
    //   ]).pipe(
    //     switchMap(([apiInstance, clientAndCart]) => {
    //       return this.tableRef.setApiInstanceAndFetch({
    //         ...apiInstance,
    //         filter: {
    //           ...this.tableApiInstance?.filter,
    //           parent: clientAndCart.client || null,
    //         },
    //       });
    //     })
    //   ).subscribe())
  }

  ngOnInit(): void {
    this.subs.add(
      combineLatest([
        this.calendarService.globalForkedEvents$.pipe(filter(v => v != null)),
        this.tableApiInstance$,
        this.calculusStateService.processOverviewState$,
        this.calculusStateService.activeTables$
      ]).pipe(
        debounceTime(100),
        distinctUntilChanged((a, b) => {
          delete a[2].cart;
          delete b[2].cart;
          return JSON.stringify(a[0]) === JSON.stringify(b[0]) && JSON.stringify(a[2]) === JSON.stringify(b[2]) && areSetsEqual(a[3], b[3]);
        }),
        switchMap(([filter, apiInstance,
                     processOverViewState, activeTables]) => {
          // prevent setting of filters if the active tables does not contains clients
          if (!activeTables.has('clients')) return of(null);

          if (processOverViewState.client == null) {
            this.tableRef?.gridApi?.deselectAll();
            this.calculusStateService.selectedCartRow$.next({obj: null, number: null});
          }

          const {states, categories} = processOverViewState.stateSelection;
          // Update the apiInstance and trigger the fetching of the data with the new filter.
          return this.tableRef.setApiInstanceAndFetch({
            ...apiInstance,
            filter: {
              ...this.tableApiInstance?.filter,
              ...filter,
              valuelist: states.length > 0 ? states : categories,
              typename: processOverViewState.invoice?.invoiceId || (processOverViewState.client ? 'client' : null),
              parent: processOverViewState.client?.obj || null,
            },
          });
        }),
      ).subscribe()
    );
  }

  ngOnDestroy() {
    this.subs.unsubscribe();
  }

  clientDetails(evt: RowSelectedEvent): void {
    this._selectedClient$.next((evt.data.apiRow as IApiRow).obj);
  }

  proofOfAttendance(): Observable<any> {
    this.tableRef?.gridApi?.hideOverlay();
    let instance: IApiControl = null;
    return this.coreService.Allclientlist.getrepository(this.tableApiInstance.instanceid,
      'PermanentOrdinance', -1).pipe(
      mergeMap((repo: IControlObject[]) => {
        const permanentOrdinanceRepo = repo as IPermanentOrdinance[];
        const data: Partial<IPrescriptionAttendanceDialog> = {
          permanentOrdinances: permanentOrdinanceRepo
        }
        const dialogRef = this.dialogService.dialog.open(
          PrescriptionAttendanceDialogComponent, {data})
        return dialogRef.afterClosed();
      }),
      filter(v => v != null),
      concatMap((dialogRes: IPrescriptionAttendanceDialog | null) => {
        return this.addAttendanceCertificate(
          dialogRes.selectedOrdinance, dialogRes.fromDate, dialogRes.toDate, dialogRes.selectedCarts
        ).pipe(
          tap(() => {
              this.coreService.controlDataChanged.emit({GUID: this.clientGUID, changeType: EControlActions.attendance});
            }
          ),
          concatMap(result => {
            return result && dialogRes.attendanceCertificate != null ?
              this.coreService.Control.UploadDocument(
                dialogRes.attendanceCertificate?.name,
                dialogRes.attendanceCertificate?.fileFormate,
                result.rowid,
                result.type,
                result.signature,
                'document',
                dialogRes.attendanceCertificate?.data,
              )
              : of(null)
          })
        )
      }),
    );
  }

  private addAttendanceCertificate(permanentOrdinance: IControlObject, startDate: string, endDate: string,
                                   selectedCarts: IControlObject[]): Observable<any> {
    return this.coreService.Allclientlist.adattendancecertificate(
      this.tableApiInstance.instanceid, permanentOrdinance, startDate, endDate, selectedCarts
    )
  }
}
