import {Component, effect, model, signal, WritableSignal} from '@angular/core';
import {
  EcalculusControlGUID, IApiControl, IControlObject,
} from 'frontier/nucleus';
import {tap} from 'rxjs/operators';
import {SignalControl} from 'frontier/nucleus/src/lib/control/base-control-new/signal-control.directive';

@Component({
  selector: 'app-templates-control',
  templateUrl: './templates-control.component.html',
  styleUrl: './templates-control.component.scss'
})
export class TemplatesControl extends SignalControl<any> {
  GUID = EcalculusControlGUID.TemplateConfigurationControl;

  selectedInvoice: WritableSignal<IControlObject | null> = model<IControlObject | null>(null);
  selectedInvoiceConfiguration: WritableSignal<any> = signal<any>(undefined);
  isCancellationConfig: WritableSignal<boolean> = signal<boolean>(false);

  constructor() {
    super();
    effect((): void => {
      this.fetchInvoiceConfiguration(this.selectedInvoice(), this.isCancellationConfig());
    });
  }

  fetchInvoiceConfiguration(invoice: IControlObject | null, isCancellationConfig: boolean): void {
    if (!this.apiInstance()?.instanceid) return;
    this.coreService.Templateconfigurationcontrol.getconfiguration(
      this.apiInstance().instanceid,
      invoice,
      isCancellationConfig,
    ).pipe(
      tap(res => {
        this.selectedInvoiceConfiguration.set(res);
      })
    ).subscribe()
  }

  onSelectedInvoiceChange(event: IControlObject | null): void {
    if (event === undefined) return;
    console.log(event);
    this.selectedInvoice.set(event)
  }

  toggleIsCancellationConfig(): void {
    this.isCancellationConfig.update((val: boolean) => !val)
  }
}
