<div class="diagram-container">
  <button class="bar-container"
          [class.selected]="selectedStates.includes(ECalculusStateFilter.onHold)">
    <div
      class="on-hold-bar">
      <div class="bar"
           [style.height.%]="_evaluationData[0]">
      </div>
    </div>
    <div class="label">
      {{showPercentages ? evaluationDisplayPercentages[0] : evaluationDisplayAbsolute[0]}}
    </div>
  </button>
</div>
