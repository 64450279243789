import {Component, ElementRef, ViewChild, ViewEncapsulation} from '@angular/core';
import {MatDialog} from '@angular/material/dialog';
import {BaseControlComponent, CoreService, EsvgFiles} from 'frontier/nucleus';
import {DomSanitizer} from '@angular/platform-browser';
import {ICellRendererParams} from 'ag-grid-community';
import {CustomCellComponent} from '../custom-cell/custom-cell.component';
import {ICustomColDef} from "frontier/browserkit";

interface IDocumentCellRendererParams extends ICellRendererParams {
  accept: string
}

@Component({
  selector: 'kpi4me-document-cell-renderer-shared',
  template: `
    <mat-icon svgIcon="{{EsvgFiles.edit}}" class="blue-icon icon-button-centered" *ngIf="showUpload()"
              (click)="onEdit()">
    </mat-icon>
    <mat-icon *ngIf="showDownload()" svgIcon="{{EsvgFiles.download}}" class="blue-icon icon-button-centered"
              (click)="onDownload()">
    </mat-icon>
    <input
      #fileUpload
      style="display: none"
      type="file"
      [accept]="accept"
      (change)="addAttachment($event)"
    />
  `,
  styles: [
    `
           :host {
             display: flex;
             justify-content: center;
             gap: 0.5rem;
           }
     
           mat-icon {
             cursor: pointer;
           }
         `
  ],
  encapsulation: ViewEncapsulation.Emulated,
})

export class DocumentCellRendererSharedComponent extends CustomCellComponent {
  document: { data?: string | ArrayBuffer; name?: any; fileFormate?: any } =
    null;
  @ViewChild('fileUpload') fileUploadRef: ElementRef;
  accept: string;

  protected readonly EsvgFiles = EsvgFiles;

  constructor(
    private dialog: MatDialog,
    private coreService: CoreService,
    private sanitizer: DomSanitizer
  ) {
    super();
  }

  agInit(params: IDocumentCellRendererParams) {
    if (params.accept) {
      this.accept = params.accept;
    }
    super.agInit(params);
  }

  onEdit() {
    this.fileUploadRef.nativeElement.click();
  }

  onDownload() {
    const d = this.params.value.value;
    this.coreService.Control.DownloadDocument(
      (<BaseControlComponent>this.params.context).apiInstance.instanceid, d.rowid, d.type, d.signature
    ).subscribe((data) => {

      const blob = new Blob([data], {type: this.accept});

      const downloadURL = window.URL.createObjectURL(data);
      const link = document.createElement('a');
      link.href = downloadURL;
      link.download = d.name;
      link.click();

    });
  }

  showDownload() {
    return !!this.params.value;

  }

  showUpload() {
    return this.params.value && this.params.value.editable;
  }

  addAttachment(evt: Event) {
    const fileReaded = (evt.target as any).files[0];
    console.log('add attachment');
    this.readFile(fileReaded);
  }

  private readFile(fileReaded: any) {
    this.params.api.showLoadingOverlay();
    const reader = new FileReader();
    reader.onloadend = (e) => {
      console.log(reader.result);
      // Ergebnis vom FileReader auslesen
      this.document = {
        name: fileReaded.name,
        data: e.target.result,
        fileFormate: fileReaded.name.split('.')[1],
      };
      this.coreService.Control.UploadDocument(this.document.name, this.document.fileFormate, this.params.data.apiRow.obj.rowid, this.params.data.apiRow.obj.type, this.params.data.apiRow.obj.signature, (this.params.colDef as ICustomColDef).apiCol.attribute, this.document.data).subscribe((res) => {
        this.params.api.hideOverlay();
        this.params.api.refreshInfiniteCache();
      });
    };
    reader.readAsArrayBuffer(fileReaded);
  }
}
