import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  Input, OnDestroy,
  Output, QueryList,
  ViewChild,
  ViewChildren,
  ViewEncapsulation
} from '@angular/core';
import {FormControl} from '@angular/forms';
import {EsvgFiles} from 'frontier/nucleus';
import {filter} from 'rxjs/operators';
import {combineLatest, Subject, Subscription} from 'rxjs';

@Component({
  selector: 'kpi4me-search-input',
  templateUrl: './search-input.component.html',
  styleUrls: ['./search-input.component.scss'],
  encapsulation: ViewEncapsulation.Emulated,
})
export class SearchInputComponent {
  @Output() ngModelChange = new EventEmitter();
  @Input() control: FormControl = new FormControl('');
  @ViewChild('searchInput') input: ElementRef<HTMLInputElement>;

  protected readonly EsvgFiles = EsvgFiles;

  constructor() {

  }


  onKeyPress(evt: KeyboardEvent) {
    if (evt.key === ' ' || evt.code === 'Space') {
      evt.stopPropagation(); // Prevent default behavior of form element on space key press, which would be submitting the form
    }
  }

  focusInput() {
    this.input?.nativeElement.focus();
  }
}
