<div
  (contextmenu)="onContextMenu($event)"
  [matTooltip]="getValueFromRepository()"
>
  &nbsp;
  <span class="display-value">{{ getValueFromRepository() }}</span>
  <mat-icon class="expand-icon" svgIcon="{{EsvgFiles.arrow_down}}" *ngIf="cellEditable()"
            (click)="startEdit($event)"></mat-icon>
</div>
<app-cell-error-icon [params]="params"></app-cell-error-icon>
