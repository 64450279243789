<mat-tab-group>
  <mat-tab>
    <ng-template mat-tab-label class="mat-tab-label">
          <span>
            Standardkonfiguration
          </span>
    </ng-template>

    <ng-template matTabContent>

    </ng-template>
  </mat-tab>

  <mat-tab *ngFor="let template of templateVariants$ | async">
    <ng-template mat-tab-label class="mat-tab-label">
          <span>
            {{ template }}
          </span>
    </ng-template>

    <ng-template matTabContent>

    </ng-template>
  </mat-tab>
</mat-tab-group>
