<mat-label
  style="cursor: pointer; padding-left: 5px;"
  (click)="categoryClick.emit()"
  *ngFor="let category of stateSelection?.categories;let indexOfElement = index;"
>
  {{CartStatuses.get(category)?.label}} {{indexOfElement === stateSelection?.categories?.length - 1 ? '' : ', '}}
</mat-label>
<mat-label
  *ngIf="stateSelection?.states?.length > 0"
  style="cursor: pointer"
  (click)="stateClick.emit()"
>
  / {{stateFilterToLabelMap.get(stateSelection?.states[0])}}
</mat-label>

<mat-label
  *ngIf="processOverviewState?.invoice"
  style="cursor: pointer"
  (click)="activeCartFilterObjClick.emit()"
>
  / {{(processOverviewState?.invoice?.invoiceId)}}
</mat-label>

<mat-label
  *ngIf="processOverviewState?.client"
  style="cursor: pointer"
  (click)="activeCartFilterObjClick.emit()"
>
  / {{(processOverviewState?.client?.name)}}
</mat-label>

<ng-container *ngIf="(calculusStateService.activeCart$ | async) as activeCart">
  <mat-label *ngIf="activeCart.number">
    / {{activeCart.number}}
  </mat-label>
</ng-container>

