export function areArraysEqual(a: (string | number)[], b: (string | number)[]) {
  if (a.length !== b.length) return false;
  const sortedA = a.sort();
  const sortedB = b.sort();

  for (let i = 0; i < sortedA.length; i++) {
    if (sortedA[i] !== sortedB[i]) {
      return false;
    }
  }
  return true;
}
