import {ChangeDetectorRef, Component, EventEmitter, ViewEncapsulation} from '@angular/core';
import {ApiAdapter, BaseControlComponent, CoreService, EcalculusControlGUID, StoreService} from 'frontier/nucleus';
import {BehaviorSubject, concatMap, debounceTime, of, switchMap} from 'rxjs';
import {catchError, filter, finalize, map, tap} from 'rxjs/operators';
import {IEPostConfig, IEpostConfigApi} from '../epost-config-form.interface';
import {MatDialog, MatDialogRef} from '@angular/material/dialog';
import {FeedbackService, ReasoningDialogComponent} from 'frontier/browserkit';

class EPostConfigApiAdapter extends ApiAdapter {
  from(apiData: IEpostConfigApi): IEPostConfig {
    return {
      active: apiData.active,
      color: apiData.color,
      duplex: apiData.duplex,
      senderCity: apiData.sender_city,
      ekpNumber: apiData.ekp_number,
      senderPostCode: apiData.sender_postcode,
      senderName: apiData.sender_name,
      senderStreet: apiData.sender_street,
      senderHouseNumber: apiData.sender_housenumber
    }
  }

  to(data: IEPostConfig): IEpostConfigApi {
    return {
      active: data.active,
      color: data.color,
      duplex: data.duplex,
      ekp_number: data.ekpNumber,
      sender_postcode: data.senderPostCode,
      sender_name: data.senderName,
      sender_street: data.senderStreet,
      sender_city: data.senderCity,
      sender_housenumber: data.senderHouseNumber
    }
  }

}

@Component({
  selector: 'app-epost-config-wrapper',
  templateUrl: './epost-config-wrapper.component.html',
  styleUrls: ['./epost-config-wrapper.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class EpostConfigWrapperComponent extends BaseControlComponent {
  readonly formChangeDebounceTime = 1000;

  apiAdapter = new EPostConfigApiAdapter();
  GUID = EcalculusControlGUID.EpostConfiguration;

  requestSmsClicked$ = new EventEmitter();
  healthCheckClicked$ = new EventEmitter();

  // Boolean if the form change is currently in process.
  isChangingForm$ = new BehaviorSubject(false);
  formChangeEvent$ = new EventEmitter();
  formChange$ = this.formChangeEvent$
    .pipe(
      tap(() => this.isChangingForm$.next(true)),
      debounceTime(this.formChangeDebounceTime),
      map(v => this.apiAdapter.to(v)),
      switchMap((form: IEpostConfigApi) =>
        this.coreService.Epostconfiguration.changedata(this.apiInstance.instanceid, form)
          .pipe(finalize(() => {
            this.isChangingForm$.next(false)
          }))
      )
    );

  // when request sms is clicked and no open form change request is open do:
  // request sms => open sms dialog
  smsProcess$ = this.requestSmsClicked$.pipe(
    switchMap(() =>
      this.isChangingForm$.pipe(filter(v => v === false)),
    ),
    concatMap(() => this.requestSms()),
    concatMap(() => this.openSmsDialog()),
    catchError((res) => {
      this.feedbackService.setError(res.error, 10000);
      return of(res);
    })
  )


  constructor(
    protected coreService: CoreService,
    protected store: StoreService,
    protected cdr: ChangeDetectorRef,
    protected dialog: MatDialog,
    public dialogRef: MatDialogRef<EpostConfigWrapperComponent>,
    private feedbackService: FeedbackService
  ) {
    super(coreService, store, cdr);
  }

  ngOnInit() {
    this.subs.add(
      this.formChange$.subscribe()
    );
    this.subs.add(
      this.apiInstanceInitialize.pipe(
        concatMap(() => this.changeAndFetch())
      ).subscribe()
    )
    this.subs.add(this.smsProcess$.subscribe());
    this.subs.add(this.healthCheckClicked$
      .pipe(
        switchMap(() => this.healthCheck())
      ).subscribe());

    super.ngOnInit();
  }

  /**
   * opens the sms dialog. If an error in the enter SMS api call is happened, reopen the sms dialog
   */
  openSmsDialog() {
    return this.dialog.open(ReasoningDialogComponent, {
      data: {
        title: 'Geben Sie bitte den SMS-Code ein, den Sie erhalten haben.'
      },
      maxWidth: '25rem'
    }).afterClosed().pipe(
      concatMap(sms => {
        if (sms) {
          return this.enterSms(sms).pipe(
            catchError((res) => {
              this.feedbackService.setError(res.error, 10000);
              return this.openSmsDialog();
            })
          );
        }
        return of(null);
      }),
    )
  }

  /**
   * API call to enter the received sms
   * @param sms the received and entered sms
   */
  enterSms(sms: string) {
    return this.coreService.Epostconfiguration.entersmscode(this.apiInstance.instanceid, sms).pipe(
      tap(updatedForm => {
        if (typeof updatedForm === 'object') {
          this.data = updatedForm
        }
      })
    );
  }

  /**
   * API call to receive a new sms
   */
  requestSms() {
    return this.coreService.Epostconfiguration.requestsmscode(this.apiInstance.instanceid);
  }

  /**
   * API call to check if the EPost configuration is healthy
   */
  healthCheck() {
    return this.coreService.Epostconfiguration.healthcheck(this.apiInstance.instanceid)
      .pipe(
        tap((val) => this.feedbackService.setNotification(val, 10000)),
        catchError((err) => {
          this.feedbackService.setError(err.error, 10000);
          return of(err);
        })
      )
  }
}
