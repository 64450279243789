import {Observable} from 'rxjs';
import {ApiConfigService} from '../api-config.service';
import {HttpHeaders} from '@angular/common/http';
import {IControlObject} from '../../interfaces/control-object.interface';

export class Formcontrol {
  http;
  url;
  httpOptions;
  constructor(protected appConfig: ApiConfigService) {
    this.http = appConfig.http;
    this.url = appConfig.url;
    this.httpOptions = appConfig.httpOptions;
  }


  deleteelement(InstanceId: string, dbobject: IControlObject) : Observable<boolean> {
   return this.http.post(
      this.url + '/intern/api/control/ExecuteAction/' + InstanceId + '/deleteelement/', {"dbobject": dbobject}, this.httpOptions
    ) as unknown as Observable<boolean>;
  }

  duplicateelement(InstanceId: string, dbobject: IControlObject) : Observable<boolean> {
   return this.http.post(
      this.url + '/intern/api/control/ExecuteAction/' + InstanceId + '/duplicateelement/', {"dbobject": dbobject}, this.httpOptions
    ) as unknown as Observable<boolean>;
  }

  newelement(InstanceId: string, name: string | null, formElements: any[] | null) : Observable<any> {
   return this.http.post(
      this.url + '/intern/api/control/ExecuteAction/' + InstanceId + '/newelement/', {"name": name, "formElements": formElements}, this.httpOptions
    ) as unknown as Observable<any>;
  }

  getrepository(InstanceId: string, ) : Observable<any> {
   return this.http.post(
      this.url + '/intern/api/control/ExecuteAction/' + InstanceId + '/getrepository/', {}, this.httpOptions
    ) as unknown as Observable<any>;
  }

  confirmchanges(InstanceId: string, data: any | null) : Observable<any> {
   return this.http.post(
      this.url + '/intern/api/control/ExecuteAction/' + InstanceId + '/confirmchanges/', {"data": data}, this.httpOptions
    ) as unknown as Observable<any>;
  }

  changevalues(InstanceId: string, formElements: any[], dbobject: IControlObject | null) : Observable<any> {
   return this.http.post(
      this.url + '/intern/api/control/ExecuteAction/' + InstanceId + '/changevalues/', {"formElements": formElements, "dbobject": dbobject}, this.httpOptions
    ) as unknown as Observable<any>;
  }

  getothercartsofclient(InstanceId: string, ) : Observable<any[]> {
   return this.http.post(
      this.url + '/intern/api/control/ExecuteAction/' + InstanceId + '/getothercartsofclient/', {}, this.httpOptions
    ) as unknown as Observable<any[]>;
  }

  getcartsofclient(InstanceId: string, ) : Observable<any[]> {
   return this.http.post(
      this.url + '/intern/api/control/ExecuteAction/' + InstanceId + '/getcartsofclient/', {}, this.httpOptions
    ) as unknown as Observable<any[]>;
  }

  resolveclientmessagemanual(InstanceId: string, ) : Observable<boolean> {
   return this.http.post(
      this.url + '/intern/api/control/ExecuteAction/' + InstanceId + '/resolveclientmessagemanual/', {}, this.httpOptions
    ) as unknown as Observable<boolean>;
  }

  getclientmessage(InstanceId: string, ) : Observable<any> {
   return this.http.post(
      this.url + '/intern/api/control/ExecuteAction/' + InstanceId + '/getclientmessage/', {}, this.httpOptions
    ) as unknown as Observable<any>;
  }

  executeclientmessage(InstanceId: string, ) : Observable<boolean> {
   return this.http.post(
      this.url + '/intern/api/control/ExecuteAction/' + InstanceId + '/executeclientmessage/', {}, this.httpOptions
    ) as unknown as Observable<boolean>;
  }

  replaceclientforcart(InstanceId: string, client: IControlObject, SelectedCarts: IControlObject[]) : Observable<boolean> {
   return this.http.post(
      this.url + '/intern/api/control/ExecuteAction/' + InstanceId + '/replaceclientforcart/', {"client": client, "SelectedCarts": SelectedCarts}, this.httpOptions
    ) as unknown as Observable<boolean>;
  }

  getsimilarclients(InstanceId: string, count: number, minimumScore: number | null) : Observable<any[]> {
   return this.http.post(
      this.url + '/intern/api/control/ExecuteAction/' + InstanceId + '/getsimilarclients/', {"count": count, "minimumScore": minimumScore}, this.httpOptions
    ) as unknown as Observable<any[]>;
  }

  downloadclientmessagedocument(InstanceId: string, ) : Observable<any> {
   return this.http.post(
      this.url + '/intern/api/control/ExecuteAction/' + InstanceId + '/downloadclientmessagedocument/', {}, {...this.httpOptions, responseType: 'blob'}) as unknown as Observable<any>;
  }

}
