import {Observable} from 'rxjs';
import {ApiConfigService} from '../api-config.service';
import {HttpHeaders} from '@angular/common/http';
import {IControlObject} from '../../interfaces/control-object.interface';

export class Clientlist {
  http;
  url;
  httpOptions;
  constructor(protected appConfig: ApiConfigService) {
    this.http = appConfig.http;
    this.url = appConfig.url;
    this.httpOptions = appConfig.httpOptions;
  }


  getrepository(InstanceId: string, AttributeName: string, AttributeIdx: number) : Observable<any[]> {
   return this.http.post(
      this.url + '/intern/api/control/ExecuteAction/' + InstanceId + '/getrepository/', {"AttributeName": AttributeName, "AttributeIdx": AttributeIdx}, this.httpOptions
    ) as unknown as Observable<any[]>;
  }

}
