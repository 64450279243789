import {Reason, TClientData} from './client-message.interface';
import {IClientMessageDetails} from './client-message-details.interface';

const attributeToLabel = {
  'Action': 'Begründung',
  'Note': 'Notiz',
  'Email': 'E-Mail',
  'Phone': 'Telefon',
  'InvoiceNumber': 'Rechnungsnummer',
  'ClientHasPayer': 'Kassenpatient',
  'ClientIdentifierNumber': 'Versichertennummer',
  'ClientPayerName': 'Kassenbezeichnung',
  'ClientPayerNumber': 'Kassennummer',
  'ClientFirstName': 'Vorname',
  'ClientLastName': 'Nachname',
  'ClientStreet': 'Straße',
  'ClientPostCode': 'PLZ',
  'ClientCity': 'Ort',
  'ClientCountryCode': 'Land',
  'ActionDescription': 'Aktionsbeschreibung'
}

const valueMap= {
  [Reason.changeInsuranceCompany]: 'Krankenkasse ändern',
  [Reason.changeInvoiceAddress]: 'Rechnungsaddresse ändern',
  [Reason.submitAuthorization]: 'Genehmigung nachreichen',
  [Reason.other]: 'Sonstige Bemerkung',
  false: 'Nein',
  true: 'Ja'
}

// TODO: Action Description should be displayed above the buttons instead of inside the table
export class ClientMessageMapper {
  static toModel(message: TClientData): IClientMessageDetails {
    const mappedMessage = Object.entries(message)
      .filter(([key, value]) => ClientMessageMapper.filterAttributes(message.Action, key, value))
      .map(([key, value]) =>
      ({label: attributeToLabel[key], value: valueMap[value] ?? value, key})
    );
    return {
      rows: mappedMessage,
      actionDescription: message.ActionDescription,
      documentAssigned: message.DocumentAssigned,
      displayAction: message.DisplayAction
    };
  }

  private static filterAttributes(Action: Reason, key: string, value: any) {
    switch (Action) {
      case Reason.submitAuthorization:
      case Reason.other:
        if (['Action', 'Note', 'Email', 'Phone'].includes(key)) {
          return true;
        }
        break;
      case Reason.changeInsuranceCompany:
        if (['Action', 'Note', 'Email', 'Phone', 'ClientHasPayer', 'ClientPayerName', 'ClientIdentifierNumber'].includes(key)) {
          return true;
        }
        break;
      case Reason.changeInvoiceAddress:
        if (['Action', 'Note', 'Email', 'Phone', 'ClientFirstName', 'ClientLastName',
          'ClientStreet', 'ClientPostCode', 'ClientCity', 'ClientCountryCode'].includes(key)) {
          return true;
        }
        break;
    }
    return false;
  }
}
