import {AfterViewInit, Component, OnInit, ViewEncapsulation} from '@angular/core';
import {
  ControlManagerService,
  ECalenderType,
  EGranularity,
  IControl,
  IMenuOption,
  IModuleFilter,
  IView,
} from 'frontier/browserkit';
import {INITIAL_VIEW} from "./initial-view.const";
import {CalculusStateService} from "../Services/calculus-state.service";
import {MatDialog} from "@angular/material/dialog";
import {PageSizeDialogComponent} from "../dialogs/page-size-dialog/page-size-dialog.component";
import {ActivatedRoute} from '@angular/router';
import {concatMap, take, tap} from 'rxjs/operators';
import {components, detailComponents} from './components.const';
import {EpostConfigWrapperComponent} from './epost-config/epost-config-wrapper/epost-config-wrapper.component';
import {of, Subscription} from 'rxjs';
import {NgbDate} from '@ng-bootstrap/ng-bootstrap';
import {EcalculusControlGUID, getQuarter} from 'frontier/nucleus';

enum ECalculusMenuOptions {
  percentileDisplay,
  customizePagination,
  epostConfig,
  dunningConfig,
  showImportedValues,
  alternativePayer,
  tariffChanges,
  invoiceTemplates,
}

@Component({
  selector: 'kpi4me-calculus-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
  encapsulation: ViewEncapsulation.Emulated,
})
export class HomeComponent implements OnInit, AfterViewInit {
  today = new Date();

  defaultView: IView = {
    id: 1,
    goldenLayoutStates: {
      root: INITIAL_VIEW,
    },
    toolbar: {
      calendarType: ECalenderType.classic,
      classicalCalendar: {
        fromDate: new NgbDate(
          this.today.getFullYear() - 1,
          this.today.getMonth() + 1,
          this.today.getDate(),
        ),
        toDate: new NgbDate(
          this.today.getFullYear(),
          this.today.getMonth() + 1,
          this.today.getDate(),
        ),
        granularity: EGranularity.month,
      },
      easyDatePicker: {
        year: [this.today.getFullYear()],
        month: [this.today.getMonth() + 1],
        quarter: ['Q' + getQuarter()],
        granularity: EGranularity.month,
      },
      filter: {},
    },
    timeStamp: new Date().toISOString()
  };

  menuOptions: IMenuOption[] = [
    {
      label: 'Prozentdarstellung',
      id: ECalculusMenuOptions.percentileDisplay,
      state: false,
    },
    {
      label: 'Seitenzahl festlegen',
      id: ECalculusMenuOptions.customizePagination,
    },
    {
      label: 'E-Post-Einstellungen',
      id: ECalculusMenuOptions.epostConfig,
    },
    {
      label: 'Mahnstufen-Einstellungen',
      id: ECalculusMenuOptions.dunningConfig,
    },
    {
      label: 'Tarif-Einstellungen',
      id: ECalculusMenuOptions.tariffChanges,
    },
    // {
    //   label: 'Rechnungs-Templates-Konfiguration',
    //   id: ECalculusMenuOptions.invoiceTemplates,
    // },
    {
      label: 'Alternative Kostenträger',
      id: ECalculusMenuOptions.alternativePayer,
    },
    {
      label: 'Importierte Werte anzeigen',
      id: ECalculusMenuOptions.showImportedValues,
    },
  ];

  calculusFilters: IModuleFilter[] = [
    {
      name: 'calculusFilter',
      attributes: ['cart', 'invoiceID'],
    },
  ];
  components = components;
  detailComponents = detailComponents;
  productLogo$ = of('assets/images/Roboter.png');
  private subs = new Subscription();

  constructor(
    protected controlManager: ControlManagerService,
    protected calculusStateService: CalculusStateService,
    protected dialog: MatDialog,
    private route: ActivatedRoute,
  ) {
    this.subs.add(this.calculusStateService.showImportedValues$.subscribe(v => {
      this.menuOptions.find(mo => mo.id === ECalculusMenuOptions.showImportedValues).state = v;
    }))
  }

  ngOnInit(): void {
    this.subs.add(
      this.route.paramMap.subscribe(params => {
        const id = params.get('id');
        if (id) {
          this.calculusStateService.invoiceIdFromUrl$.next({ invoiceId: id, obj: null });
        } else {
          this.calculusStateService.invoiceIdFromUrl$.next(null);
        }
      })
    );
  }

  ngAfterViewInit(): void {
    this.controlManager.initialize();
  }

  onOptionTriggered(evt: any): void {
    switch (evt) {
      case ECalculusMenuOptions.percentileDisplay:
        this.menuOptions = this.menuOptions.map((option: IMenuOption) => {
          if (option.id === evt) {
            option.state = !option.state;
            this.calculusStateService.showPercentages$.next(option.state);
          }
          return option;
        });
        break;
      case ECalculusMenuOptions.customizePagination:
        this.calculusStateService.customPageSize$.pipe(
          take(1),
          concatMap((pageSize) => {
            return this.dialog.open(PageSizeDialogComponent, {
              data: {
                pageSize
              },
              maxWidth: '35rem'
            }).afterClosed();
          }),
          tap((newPageSize: number) => {
            if (newPageSize != null) {
              this.calculusStateService.setCustomPageSize$.next(newPageSize);
            }
          })
        ).subscribe()
        break;
      case ECalculusMenuOptions.epostConfig:
        this.dialog.open(EpostConfigWrapperComponent);
        break;
      case ECalculusMenuOptions.dunningConfig:
        let control: IControl = {
          guid: EcalculusControlGUID.DunningConfigView,
        }
        this.controlManager.openLayoutComponent(control, null);
        break;
      case ECalculusMenuOptions.invoiceTemplates:
        this.controlManager.openLayoutComponent({
          guid: EcalculusControlGUID.TemplateConfigurationControl,
        }, null);
        break;
      case ECalculusMenuOptions.tariffChanges:
        this.controlManager.openLayoutComponent({
          guid: EcalculusControlGUID.ItemPriceHistoryView,
        }, null);
        break;
      case ECalculusMenuOptions.showImportedValues:
        this.menuOptions = this.menuOptions.map((option: IMenuOption) => {
          if (option.id === evt) {
            option.state = !option.state;
            this.calculusStateService.showImportedValues$.next(option.state);
          }
          return option;
        });
        break;
      case ECalculusMenuOptions.alternativePayer:
        this.controlManager.openLayoutComponent({
          guid: EcalculusControlGUID.AlternativePayerView,
        }, null);
        break;
    }
  }
}
