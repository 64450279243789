import {IClassicCalendar, IToolbarFilters} from '../toolbar/toolbar-state.interface';
import {IDatepickerForm} from '../calendar/easy-date-picker/date-picker/datepicker-form.interface';

export enum ECalenderType {
  classic,
  new
}

export interface IToolbarState {
  calendarType?: ECalenderType;
  classicalCalendar?: IClassicCalendar;
  easyDatePicker?: IDatepickerForm;
  filter: IToolbarFilters;
}
