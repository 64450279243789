import {Route} from '@angular/router';
import {KeycloakRedirectComponent, LoginComponent} from 'frontier/browserkit';
import {HomeComponent} from './home/home.component';
import {GoToTrapoComponent} from "./home/go-to-trapo/go-to-trapo.component";

export const routes: Route[] = [
  {path: 'home', component: HomeComponent},
  {path: 'login', component: LoginComponent, data: {nextRoute: 'home'}},
  {path: 'sso', component: KeycloakRedirectComponent, pathMatch: 'prefix'},
  {path: '', redirectTo: 'login', pathMatch: 'full'},
  {path: 'trapo', component: GoToTrapoComponent},
  {path: 'openitem/:id', component: HomeComponent},
]
