import {Observable} from 'rxjs';
import {ApiConfigService} from '../api-config.service';
import {HttpHeaders} from '@angular/common/http';
import {IControlObject} from '../../interfaces/control-object.interface';

export class Hierarchycontrol {
  http;
  url;
  httpOptions;
  constructor(protected appConfig: ApiConfigService) {
    this.http = appConfig.http;
    this.url = appConfig.url;
    this.httpOptions = appConfig.httpOptions;
  }


  newreference(InstanceId: string, parent: IControlObject | null, next_sibling: IControlObject | null, refer_to: IControlObject, only_reference_children: boolean) : Observable<boolean> {
   return this.http.post(
      this.url + '/intern/api/control/ExecuteAction/' + InstanceId + '/newreference/', {"parent": parent, "next_sibling": next_sibling, "refer_to": refer_to, "only_reference_children": only_reference_children}, this.httpOptions
    ) as unknown as Observable<boolean>;
  }

  newnode(InstanceId: string, parent: IControlObject | null, next_sibling: IControlObject | null, name: string) : Observable<boolean> {
   return this.http.post(
      this.url + '/intern/api/control/ExecuteAction/' + InstanceId + '/newnode/', {"parent": parent, "next_sibling": next_sibling, "name": name}, this.httpOptions
    ) as unknown as Observable<boolean>;
  }

  changenodeposition(InstanceId: string, parent: any | null, next_sibling: IControlObject | null, node: any) : Observable<boolean> {
   return this.http.post(
      this.url + '/intern/api/control/ExecuteAction/' + InstanceId + '/changenodeposition/', {"parent": parent, "next_sibling": next_sibling, "node": node}, this.httpOptions
    ) as unknown as Observable<boolean>;
  }

  removenode(InstanceId: string, node: any, delete_nodes_and_children_recursively: boolean, delete_just_children_recursively: boolean, allow_deletion_of_references_to_deleted_nodes: boolean) : Observable<boolean> {
   return this.http.post(
      this.url + '/intern/api/control/ExecuteAction/' + InstanceId + '/removenode/', {"node": node, "delete_nodes_and_children_recursively": delete_nodes_and_children_recursively, "delete_just_children_recursively": delete_just_children_recursively, "allow_deletion_of_references_to_deleted_nodes": allow_deletion_of_references_to_deleted_nodes}, this.httpOptions
    ) as unknown as Observable<boolean>;
  }

  changename(InstanceId: string, node: IControlObject, name: string) : Observable<boolean> {
   return this.http.post(
      this.url + '/intern/api/control/ExecuteAction/' + InstanceId + '/changename/', {"node": node, "name": name}, this.httpOptions
    ) as unknown as Observable<boolean>;
  }

}
