import {Component} from '@angular/core';
import {SaveIconButtonComponent} from '../save-icon-button/save-icon-button.component';
import {EsvgFiles} from 'frontier/nucleus';

@Component({
  selector: 'app-confirm-icon-button',
  templateUrl: './confirm-icon-button.component.html',
  styleUrls: ['./confirm-icon-button.component.scss'],
})
export class ConfirmIconButtonComponent extends SaveIconButtonComponent {
  constructor() {
    super();
  }

  protected readonly EsvgFiles = EsvgFiles;
}
