import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
  ViewContainerRef,
  ViewEncapsulation,
} from '@angular/core';
import {ColtypeToEditorMap} from './coltype-to-editor-map.const';
import {BaseCellEditorComponent} from './base-cell-editor/base-cell-editor.component';
import {ITableVerticalDataRow} from '../table-vertical-api-adapter';
import {Subscription} from "rxjs";
import {CoreService, IControlDataChanged, IControlObject} from 'frontier/nucleus';
import {IApiRow} from '../../table-control/api-row.interface';
import {TColType} from '../../table-control/cell.enum';
import {ISelectFormOption} from '../../form-control/dynamic-form/form-element/form-data.interface';

@Component({
  selector: 'kpi4me-cell-editor',
  templateUrl: './cell-editor.component.html',
  styleUrls: ['./cell-editor.component.scss'],
  encapsulation: ViewEncapsulation.Emulated,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CellEditorComponent implements OnInit, OnDestroy {
  @ViewChild('inputHost', {static: true, read: ViewContainerRef}) inputHost!: ViewContainerRef;
  @Output() cellChanged = new EventEmitter<{ apiRow: IApiRow, verticalRow: ITableVerticalDataRow }>();
  @Output() updateTable = new EventEmitter();
  @Output() selectionDataChange = new EventEmitter<IControlDataChanged>();

  @Input() set params(row: ITableVerticalDataRow) {
    if (row == null) {
      throw new Error('No row set. Cannot create component')
    }
    if (row.apiInstance == null) {
      throw new Error('No Api Instance as input. Cannot create component.')
    }


    this.inputHost.clear();
    const type = row.type;

    this.invalidmessage = row.invalidMessage ? {value: {invalidmessage: row.invalidMessage}} : null;

    const component = ColtypeToEditorMap[type];
    if (component == null) {
      throw new Error('Could not find a respective component for type ' + String(type));
    }
    // map repository to options for the select element
    if ((type === TColType.clRefSelection || type === TColType.clReference) && row.repository) {
      row.repository = row.repository.map((repoObject: IControlObject) => {
        let option: ISelectFormOption = {
          value: repoObject,
          name: repoObject.name
        }
        return option;
      })
    }

    const componentRef = this.inputHost.createComponent<BaseCellEditorComponent>(component);
    (<BaseCellEditorComponent>componentRef.instance).row = row;
    this.subs.add(
      (<BaseCellEditorComponent>componentRef.instance).cellChanged.subscribe((row) => {
        this.cellChanged.emit(row);
      }))
    this.subs.add(
      (<BaseCellEditorComponent>componentRef.instance).updateTable.subscribe((row) => {
        this.updateTable.emit();
      }))
    this.subs.add(
      (<BaseCellEditorComponent>componentRef.instance).selectionDataChanged.subscribe((evt) => {
        this.selectionDataChange.emit(evt);
      }))
    row.component = componentRef.instance;
  }

  invalidmessage: { value: { invalidmessage: string } };
  private subs = new Subscription();

  constructor(private coreService: CoreService,) {
  }

  ngOnInit(): void {
    return;
  }

  ngOnDestroy() {
    this.subs.unsubscribe();
  }
}
