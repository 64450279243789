import {AfterViewInit, Directive, HostListener, Inject,} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {FormBuilder} from '@angular/forms';
import {IDialogData} from './dialog-data.interface';
import {DialogService} from '../../dialogs/dialog.service';

/**
 * A dialog class that provides shared functions for other dialogs.
 * Should have an enter key listener that closes the dialog on keyup.
 */
@Directive()
// eslint-disable-next-line @angular-eslint/directive-class-suffix
export abstract class BaseDialogComponent implements AfterViewInit {
  constructor(
    public dialogRef: MatDialogRef<any>,
    @Inject(MAT_DIALOG_DATA) public data: IDialogData,
    protected fb: FormBuilder,
    protected dialogService: DialogService
  ) {
  }

  ngAfterViewInit() {
    if (!this.data) {
      return;
    }
    if (this.data.objectId == null) {
      console.warn(
        'No object ID set in the dialog input data! Cannot load the dialog values'
      );
    } else {
      const storedData: IDialogData = this.dialogService.loadDialogState(
        this.constructor.name
      );
      if (storedData && storedData.objectId == this.data.objectId) {
        this.loadForm(storedData);
      }
    }
  }

  @HostListener('window:keyup.Enter', ['$event'])
  onDialogClick(event: KeyboardEvent): void {
    this.close();
  }

  close(): void {
    if (this.isValid()) {
      this.dialogRef.close(this.getDialogOutput());
    }
  }

  protected abstract getDialogOutput(): any;

  protected abstract isValid(): boolean;

  protected abstract loadForm(storedData: IDialogData): void;

  protected abstract getForm(): IDialogData;

  cancel() {
    const dialogFormData = this.getForm();
    this.dialogService.saveDialogState(this.constructor.name, dialogFormData);
    this.dialogRef.close();
  }
}
