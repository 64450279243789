<div (contextmenu)="onContextMenu($event)"
     [matTooltip]="params.value?.value?.length > 22 ? params.value?.value : null"
>
  <ng-container *ngIf="isFloatValue(); else elseBlock">
    {{ params.value?.value | number: null:'de' }}
  </ng-container>
  <ng-template #elseBlock>
    {{ params.value?.value }}
  </ng-template>
</div>
<app-cell-error-icon [params]="params"></app-cell-error-icon>
