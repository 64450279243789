import {Component} from '@angular/core';
import {ILoadingCellRendererAngularComp} from 'ag-grid-angular';
import {ICellRendererParams} from 'ag-grid-community';

@Component({
  selector: 'app-loading-renderer',
  templateUrl: './loading-renderer.component.html',
  styleUrls: ['./loading-renderer.component.scss'],
})
export class LoadingRendererComponent
  implements ILoadingCellRendererAngularComp {
  params: any;

  constructor() {
  }

  agInit(params: ICellRendererParams): void {
    this.params = params;
  }

  refresh(params: ICellRendererParams): boolean {
    return false;
  }
}
