import {Component, Inject, OnDestroy, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {BehaviorSubject} from 'rxjs';
import {IView} from '../../../golden-layout/view.interface';
import {EsvgFiles} from 'frontier/nucleus';

@Component({
  selector: 'app-load-layout-dialog',
  templateUrl: './load-layout-dialog.component.html',
  styleUrls: ['./load-layout-dialog.component.scss'],
})
export class LoadLayoutDialogComponent implements OnInit, OnDestroy {
  layouts: BehaviorSubject<IView[]>;
  inEditMode: boolean;

  constructor(
    public dialogRef: MatDialogRef<LoadLayoutDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: LoadLayoutDialogComponent
  ) {
  }

  ngOnInit(): void {
    return;
  }

  ngOnDestroy() {
    if (this.data.inEditMode) {
      this.dialogRef.close(this.data);
    }
  }

  onLoadLayoutChange(evt: boolean, item: IView) {
    this.dialogRef.close(item);
  }

  delete(evt: MouseEvent, index: number) {
    evt.stopPropagation();
    this.data.layouts.next(
      this.data.layouts.getValue().filter((item, i) => i != index)
    );
  }

  copy(evt: MouseEvent, i: number) {
    evt.stopPropagation();
    let layouts = this.data.layouts.getValue();
    layouts.splice(i, 0, layouts[i]);
    this.data.layouts.next(layouts);
  }

  onOptionClick($event: MouseEvent, item: IView) {
    if (this.data.inEditMode) {
      $event.preventDefault();
      $event.stopPropagation();
      $event.stopImmediatePropagation();
    } else {
      this.dialogRef.close(item);
    }
  }

  protected readonly EsvgFiles = EsvgFiles;
}
