import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {DatePickerComponent} from './date-picker/date-picker.component';
import {MatSelectModule} from '@angular/material/select';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {NgbTooltipModule} from '@ng-bootstrap/ng-bootstrap';
import {MatButtonToggleModule} from '@angular/material/button-toggle';
import {MatTooltipModule} from '@angular/material/tooltip';
import {GranularityRotatorComponent} from './granularity-rotator/granularity-rotator.component';
import {MatButtonModule} from '@angular/material/button';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {MatIconModule} from "@angular/material/icon";

@NgModule({
  declarations: [DatePickerComponent, GranularityRotatorComponent],
  exports: [DatePickerComponent, GranularityRotatorComponent],
  imports: [
    CommonModule,
    MatSelectModule,
    ReactiveFormsModule,
    MatButtonToggleModule,
    NgbTooltipModule,
    MatTooltipModule,
    MatButtonModule,
    MatCheckboxModule,
    FormsModule,
    MatIconModule,
  ],
})
export class EasyDatePickerModule {
}
