import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  HostListener,
  Renderer2,
  ViewChild,
  ViewEncapsulation
} from '@angular/core';
import {BaseCellEditorComponent} from '../base-cell-editor/base-cell-editor.component';
import {NgbDate, NgbInputDatepicker} from '@ng-bootstrap/ng-bootstrap';
import {ITableVerticalDataRow} from '../../table-vertical-api-adapter';
import {CoreService, dateObjectToISOString, EsvgFiles} from 'frontier/nucleus';
import {DatePipe} from '@angular/common';

@Component({
  selector: 'kpi4me-date-cell-editor',
  templateUrl: './date-cell-editor.component.html',
  styleUrls: ['./date-cell-editor.component.scss'],
  encapsulation: ViewEncapsulation.Emulated,
})
export class DateCellEditorComponent extends BaseCellEditorComponent {
  @ViewChild(NgbInputDatepicker) datePickerRef: NgbInputDatepicker;
  startDate: NgbDate;
  dateString: NgbDate;


  constructor(
    protected coreService: CoreService,
    protected renderer: Renderer2,
    protected element: ElementRef,
    private datePipe: DatePipe,
    protected cdr: ChangeDetectorRef
  ) {
    super(coreService, renderer, element, cdr);
  }

  set row(r: ITableVerticalDataRow) {
    super.row = r;
    this.internalSetData(r);
  }

  private internalSetData(r: ITableVerticalDataRow) {
    if (r.value) {
      const date = new Date(r.value);
      this.dateString = new NgbDate(date.getFullYear(), date.getMonth() + 1, date.getDate())
      this.formControl.setValue(this.dateString);
      super.initialValue = this.getValueForApi();
    }
  }

  get row() {
    return super.row;
  }

  @HostListener('keydown.enter', ['$event']) onEnter(event: KeyboardEvent) {
    if (this.formControl.status !== 'VALID') {
      return;
    }
    this.changeValue().subscribe(() => {
      const cellEditorWrapper = this.renderer.parentNode(this.element.nativeElement);
      const td = this.renderer.parentNode(cellEditorWrapper);
      const tr = this.renderer.parentNode(td);
      const nextRow = this.renderer.nextSibling(tr);
      console.log(nextRow);
      try {
        const inputOfNextRow = nextRow.children[1].children[0].children[0].children[0];
        (<HTMLInputElement>inputOfNextRow).focus();
      } catch (e) {
        console.warn('Could not select next input element');
      }
    })

  }

  updateRow(r: ITableVerticalDataRow) {
    super.updateRow(r);
    this.internalSetData(r);
  }


  getValueForApi = () => {
    this.cdr.detectChanges();
    if (this.formControl.value) {
      return dateObjectToISOString(this.formControl.value);
    }
    return null;
  }

  onDateChange(event: NgbDate) {
    // this.setIsoString();
    this.confirmChange();
  }

  onInputFocusOut(status: string) {
    if (status !== 'VALID') {
      return;
    }
    this.confirmChange();
  }


  confirmChange() {
    if (this.formControl.status === 'VALID') {
      super.confirmChange();
    }
  }

  protected readonly EsvgFiles = EsvgFiles;
}
