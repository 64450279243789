import {Observable} from 'rxjs';
import {ApiConfigService} from '../api-config.service';
import {HttpHeaders} from '@angular/common/http';

export class Hierarchy {
  http;
  url;
  httpOptions;
  constructor(protected appConfig: ApiConfigService) {
    this.http = appConfig.http;
    this.url = appConfig.url;
    this.httpOptions = appConfig.httpOptions;
  }


  FetchMore(InstanceID: string, SelectedNode: any, FetchDepth: number) : Observable<any> {
    return this.http.post(
      this.url + '/intern/api/hierarchy/FetchMore/',
      {_parameters: [InstanceID, SelectedNode, FetchDepth]}, this.httpOptions
    ) as unknown as Observable<any>;
  }

}
