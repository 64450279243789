<div *ngIf="form">
  <h4 class="mat-headline-6 info" [class.active]="form.get('active').value === true"
      [class.inactive]="!form.get('active').value">
    <mat-icon [svgIcon]="isActive()? EsvgFiles.correct : EsvgFiles.error"></mat-icon>
    <span>E-Post ist {{ form.get('active').value === true ? 'aktiv' : 'inaktiv' }}!</span>
  </h4>

  <kpi4me-text-element [formControlElement]="form.controls.ekpNumber" [data]="epkText"></kpi4me-text-element>

  <h4 class="mat-headline-6">Einstellungen für den Druckvorgang</h4>
  <kpi4me-checkbox-element [formControlElement]="form.controls.duplex" [data]="duplex"></kpi4me-checkbox-element>
  <kpi4me-checkbox-element [formControlElement]="form.controls.color" [data]="color"></kpi4me-checkbox-element>

  <div>
    <h4 class="mat-headline-6" style="padding-top: 1rem">
      Adresse des Senders
      <div class="mat-subtitle-2">Die Adresse, die als Absender auf dem Brief angegeben ist.</div>
    </h4>

    <div class="form-field-wrapper">
      <kpi4me-text-element [formControlElement]="form.controls.senderName"
                           [label]="'Absendername'"></kpi4me-text-element>

      <div class="two-elements">
        <span id="street">
          <kpi4me-text-element [formControlElement]="form.controls.senderStreet"
                               [label]="'Straße '"></kpi4me-text-element>
        </span>
        <span>
          <kpi4me-text-element [formControlElement]="form.controls.senderHouseNumber"
                               [inputType]="'text'"
                               [label]="'Hausnummer '"></kpi4me-text-element>
        </span>
      </div>
      <div class="two-elements">
        <span id="postalcode">
          <kpi4me-text-element [formControlElement]="form.controls.senderPostCode"
                               [inputType]="'text'"
                               [label]="'PLZ '"></kpi4me-text-element>
        </span>
        <span>
          <kpi4me-text-element [formControlElement]="form.controls.senderCity"
                               [inputType]="'text'"
                               [label]="'Ort '"></kpi4me-text-element>
        </span>
      </div>
    </div>
  </div>

  <!--  <app-confirm-icon-button></app-confirm-icon-button>-->
  <div class="button-wrapper">
    <button [disabled]="!form.valid" mat-stroked-button [color]="'accent'" (click)="onRequestSms()">SMS Code Anfordern
    </button>
    <button mat-stroked-button [color]="'primary'" (click)="onHealthCheck()">System Check</button>
  </div>
</div>
