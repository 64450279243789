import {Observable} from 'rxjs';
import {ApiConfigService} from '../api-config.service';
import {HttpHeaders} from '@angular/common/http';
import {IControlObject} from '../../interfaces/control-object.interface';

export class Invoicelist {
  http;
  url;
  httpOptions;
  constructor(protected appConfig: ApiConfigService) {
    this.http = appConfig.http;
    this.url = appConfig.url;
    this.httpOptions = appConfig.httpOptions;
  }


  createinvoice(InstanceId: string, RowObject: IControlObject) : Observable<boolean> {
   return this.http.post(
      this.url + '/intern/api/control/ExecuteAction/' + InstanceId + '/createinvoice/', {"RowObject": RowObject}, this.httpOptions
    ) as unknown as Observable<boolean>;
  }

  canceleandnewafterpayed(InstanceId: string, invoices: IControlObject[] | null) : Observable<boolean> {
   return this.http.post(
      this.url + '/intern/api/control/ExecuteAction/' + InstanceId + '/canceleandnewafterpayed/', {"invoices": invoices}, this.httpOptions
    ) as unknown as Observable<boolean>;
  }

  setnotexported(InstanceId: string, invoices: IControlObject[] | null, collectiveInvoices: IControlObject[] | null) : Observable<boolean> {
   return this.http.post(
      this.url + '/intern/api/control/ExecuteAction/' + InstanceId + '/setnotexported/', {"invoices": invoices, "collectiveInvoices": collectiveInvoices}, this.httpOptions
    ) as unknown as Observable<boolean>;
  }

  canceleandnew(InstanceId: string, invoices: IControlObject[] | null) : Observable<boolean> {
   return this.http.post(
      this.url + '/intern/api/control/ExecuteAction/' + InstanceId + '/canceleandnew/', {"invoices": invoices}, this.httpOptions
    ) as unknown as Observable<boolean>;
  }

  payed(InstanceId: string, invoices: IControlObject[] | null) : Observable<boolean> {
   return this.http.post(
      this.url + '/intern/api/control/ExecuteAction/' + InstanceId + '/payed/', {"invoices": invoices}, this.httpOptions
    ) as unknown as Observable<boolean>;
  }

  rollbackcanceleandnew(InstanceId: string, invoice: IControlObject) : Observable<boolean> {
   return this.http.post(
      this.url + '/intern/api/control/ExecuteAction/' + InstanceId + '/rollbackcanceleandnew/', {"invoice": invoice}, this.httpOptions
    ) as unknown as Observable<boolean>;
  }

  uncollectible(InstanceId: string, invoices: IControlObject[] | null, reason: string) : Observable<boolean> {
   return this.http.post(
      this.url + '/intern/api/control/ExecuteAction/' + InstanceId + '/uncollectible/', {"invoices": invoices, "reason": reason}, this.httpOptions
    ) as unknown as Observable<boolean>;
  }

}
