import {Observable} from 'rxjs';
import {ApiConfigService} from '../api-config.service';
import {HttpHeaders} from '@angular/common/http';
import {IControlObject} from '../../interfaces/control-object.interface';

export class Etlstepoverviewtable {
  http;
  url;
  httpOptions;
  constructor(protected appConfig: ApiConfigService) {
    this.http = appConfig.http;
    this.url = appConfig.url;
    this.httpOptions = appConfig.httpOptions;
  }


  importetlstep(InstanceId: string, etlstep: IControlObject, json: any) : Observable<boolean> {
   return this.http.post(
      this.url + '/intern/api/control/ExecuteAction/' + InstanceId + '/importetlstep/', {"etlstep": etlstep, "json": json}, this.httpOptions
    ) as unknown as Observable<boolean>;
  }

  exportetlstep(InstanceId: string, etlstep: IControlObject) : Observable<any> {
   return this.http.post(
      this.url + '/intern/api/control/ExecuteAction/' + InstanceId + '/exportetlstep/', {"etlstep": etlstep}, this.httpOptions
    ) as unknown as Observable<any>;
  }

  executeetlstep(InstanceId: string, etlstep: IControlObject) : Observable<string> {
   return this.http.post(
      this.url + '/intern/api/control/ExecuteAction/' + InstanceId + '/executeetlstep/', {"etlstep": etlstep}, this.httpOptions
    ) as unknown as Observable<string>;
  }

}
