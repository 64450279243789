<div
  style="
    display: flex;
    flex-direction: column;"
>
  <h5>Person</h5>
  <kpi4me-table-vertical-control
    #personForm
    [apiAdapter]="personFormApiAdapter"
    [showImports]="showImports"
    [hasImports]="false"
    [(apiInstance)]="personFormApiInstance"
    [GUID]="clientListGUID"
    (apiInstanceInitialize)="filterAndFetch($event, 'person')"
  ></kpi4me-table-vertical-control>

  <h5>Adresse</h5>
  <kpi4me-table-vertical-control
    #addressForm
    [apiAdapter]="addressFormApiAdapter"
    [showImports]="showImports"
    [hasImports]="false"
    [(apiInstance)]="addressFormApiInstance"
    [GUID]="clientListGUID"
    (apiInstanceInitialize)="filterAndFetch($event, 'address')"
  ></kpi4me-table-vertical-control>

  <h5>Versicherung</h5>
  <kpi4me-table-vertical-control
    #insuranceForm
    [apiAdapter]="insuranceFormApiAdapter"
    [showImports]="showImports"
    [hasImports]="false"
    [(apiInstance)]="insuranceFormApiInstance"
    [GUID]="clientListGUID"
    (apiInstanceInitialize)="filterAndFetch($event, 'insurance')"
    (cellChanged)="onCellChanged($event)"
  ></kpi4me-table-vertical-control>
</div>
