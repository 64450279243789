<kpi4me-text-element class="form-field-no-padding"
                     #textElementRef
                     [formControlElement]="formControl"
                     [inputType]="mapType()"
                     [row]="row"
                     #tooltip="matTooltip"
                     (modelChange)="tooltip.show()"
                     [matTooltip]="textElementRef.getErrorMessage().join(', ')"
                     [matTooltipDisabled]="formControl.valid"
></kpi4me-text-element>
