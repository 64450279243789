import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  inject,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output, signal,
  SimpleChanges,
  ViewChild,
  ViewEncapsulation, WritableSignal,
} from '@angular/core';
import {
  DialogService,
  DynamicFormControlComponent,
  FeedbackService,
  FormApiAdapter,
  IForm,
  IFormElement,
  IFormGroup,
} from 'frontier/browserkit';
import {CoreService, EcalculusControlGUID, EControlActions, IApiControl, IControlObject} from 'frontier/nucleus';
import {catchError, concatMap, filter, tap} from "rxjs/operators";
import {BehaviorSubject, forkJoin, of, Subscription} from "rxjs";
import {IClientApi, IClientsTileApi} from './clients-tile.interface';
import {ColDef, GridApi, GridOptions} from 'ag-grid-community';
import {CartSelectionComponent} from './cart-selection/cart-selection.component';
import {ICartControlObj} from './cart-selection/cart-control-obj.interface';
import {ClientsComparatorTableComponent} from '../clients-comparator-table/clients-comparator-table.component';
import {TClientData} from './client-message-details/client-message.interface';
import {ClientMessageMapper} from './client-message-details/client-message.mapper';
import {IClientMessageDetails} from './client-message-details/client-message-details.interface';
import {PdfDialogComponent} from 'frontier/browserkit/src/lib/dialogs/pdf-dialog/pdf-dialog.component';
import {ClientMessageDetailsComponent} from './client-message-details/client-message-details.component';

class ConspicuityApiAdapter extends FormApiAdapter {
  displayOnlyFlags: IDisplayOnlyFlag[];

  from(apiData: IForm): IForm {
    const fg: IFormGroup = {
      ...apiData.formGroups[0],
      elements: apiData.formGroups[0].elements.slice(0, 3), // [0]: Not billable, [1]: Is on hold, [2]: re-introduce on time
    };

    console.log(fg);

    this.displayOnlyFlags = apiData.formGroups[0].elements.slice(3).map((formElem: IFormElement) => {
      return {
        label: formElem.label,
        value: formElem.value,
      };
    });

    console.log(this.displayOnlyFlags);

    return {
      confirmable: apiData.confirmable,
      formGroups: [fg],
    };
  }
}

interface IDisplayOnlyFlag {
  label: string;
  value: string;
}

enum EConspicuityForms {
  notBillable,
}

@Component({
  selector: 'kpi4me-conspicuity-detail',
  templateUrl: './conspicuity-detail.component.html',
  styleUrls: ['./conspicuity-detail.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ConspicuityDetailComponent implements OnInit, OnChanges, OnDestroy {
  @Input() parent: IControlObject;
  @Output() clientReplaced = new EventEmitter<{ client: IClientApi, cart: IControlObject }>();

  @ViewChild(ClientMessageDetailsComponent, {static: false}) clientMessageDetails: ClientMessageDetailsComponent;
  @ViewChild(ClientsComparatorTableComponent, {static: false}) clientsComparatorTable: ClientsComparatorTableComponent;
  conspcuityGridApi: GridApi<any>;

  subs = new Subscription();

  numberOfWantedClients = 3;
  minScore = 0.4;
  availableClients: number;

  apiAdapter = new ConspicuityApiAdapter();
  displayOnlyFlags: IDisplayOnlyFlag[];

  @ViewChild('notBillable') notBillableFormRef: DynamicFormControlComponent;

  notBillableFormGUID = EcalculusControlGUID.NotBillable;
  notBillableFormApiInstance: IApiControl;

  protected readonly EConspicuityForms = EConspicuityForms;

  clientsMessage: WritableSignal<IClientMessageDetails> = signal(undefined);

  // clients with overlapping data
  clients$ = new BehaviorSubject<IClientsTileApi[]>(null);
  rowData: any[];
  colDefs: ColDef[] = [
    {field: 'label', width: 205, maxWidth: 205, minWidth: 205, cellStyle: {'font-weight': 'bold'}},
    {field: 'value'},
  ];
  gridOptions: GridOptions<any> = {
    rowHeight: 30,
    headerHeight: 0,
    onGridReady: (event) => event.api.sizeColumnsToFit()
  };

  feedbackService = inject(FeedbackService);

  constructor(
    protected cdr: ChangeDetectorRef,
    protected coreService: CoreService,
    private dialogService: DialogService
  ) {
    this.subs.add(
      this.coreService.controlDataChanged.pipe(
        filter(evt => {
          return new Map([
            [EcalculusControlGUID.AllClientList, new Set([EControlActions.attendance])],
            [null, new Set([EControlActions.resolveClientMessage])]
          ]).get(evt.GUID as EcalculusControlGUID)?.has(evt.changeType);
        }),
        tap(() => {
          this.filterAndFetch(this.notBillableFormApiInstance, EConspicuityForms.notBillable);
        })
      ).subscribe(),
    );
  }

  ngOnInit(): void {
    return;
  }

  ngOnDestroy() {
    this.subs.unsubscribe();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.parent && this.notBillableFormRef) {
      if (this.notBillableFormRef) {
        this.filterAndFetch(this.notBillableFormApiInstance, EConspicuityForms.notBillable);
      }
    }
  }

  filterAndFetch(apiInstance: IApiControl, form: EConspicuityForms): void {
    if (this.parent) {
      switch (form) {
        case EConspicuityForms.notBillable:
          this.notBillableFormRef.setApiInstanceAndFetch({
            ...apiInstance,
            filter: {
              ...this.notBillableFormApiInstance?.filter,
              parent: this.parent,
              typename: 'cart',
            },
          }).pipe(
            concatMap(res => {
              if (res) {
                return forkJoin([
                  this.coreService.ActionForm.getsimilarclients(
                    this.notBillableFormApiInstance.instanceid,
                    this.numberOfWantedClients,
                    this.minScore,
                  ).pipe(
                    tap((similarClients: IClientsTileApi[]) => {
                      this.availableClients = similarClients[0].HitRate;
                      this.clients$.next([...similarClients]);
                    })),
                  this.coreService.ActionForm.getclientmessage(
                    this.notBillableFormApiInstance.instanceid
                  ).pipe(
                    tap((clientMessage) => {
                      if (clientMessage) {
                        this.clientsMessage.set(ClientMessageMapper.toModel(clientMessage as TClientData));
                      } else {
                        this.clientsMessage.set(null);
                      }
                    }
                  )),
                ]);
              }
              return of(null);
            })
          ).subscribe((res) => {
            if (res) {
              this.displayOnlyFlags = this.apiAdapter.displayOnlyFlags;
              this.rowData = this.apiAdapter.displayOnlyFlags.map(i => {
                return {label: i.label, value: i.value === '0' ? 'nein' : i.value === '1' ? 'ja' : i.value};
              })
            }
          });
          break;
      }
    }
  }

  getAnotherClient(evt: number) {
    // +1 (we want one more) and -1 (current client is also counted) cancel out
    this.numberOfWantedClients = evt;
    if (this.numberOfWantedClients > this.availableClients) {
      this.minScore -= 0.05;
    }

    return this.coreService.ActionForm.getsimilarclients(
      this.notBillableFormApiInstance.instanceid,
      this.numberOfWantedClients,
      this.minScore,
    ).pipe(
      concatMap((similarClients: IClientsTileApi[]) => {
        if (similarClients.length > this.numberOfWantedClients) {
          this.availableClients = similarClients[0].HitRate;
          this.clients$.next([...similarClients]);
        } else {
          this.minScore -= 0.05;
          return this.getAnotherClient(evt);
        }
        return of(null);
      }),
    ).subscribe(() => {
      // scroll to the right end of the table
      this.clientsComparatorTable.scrollRight();
    })
  }

  onTakeDataOfClient(client: IClientsTileApi) {
    this.coreService.ActionForm.getcartsofclient(this.notBillableFormApiInstance.instanceid).pipe(
      concatMap(
        (otherCartsOfClient: IControlObject[]) => {
          const dialogRef = this.dialogService.dialog.open(
            CartSelectionComponent,
            {
              data: {
                client,
                selectedCart: this.parent,
                carts: otherCartsOfClient
              },
            }
          )
          return dialogRef.afterClosed();
        }
      ),
      concatMap((res: ICartControlObj[] | null) => {
        if (res) {
          return this.coreService.ActionForm.replaceclientforcart(
            this.notBillableFormApiInstance.instanceid,
            client.client,
            res).pipe(
            tap(
              () => {
                this.clientReplaced.emit({
                  client: client.client,
                  cart: res.find(r => r.signature === this.parent.signature)
                });
              }
            ))
        }
        return of(null);
      })
    ).subscribe()
  }

  onResolveAutomatically() {
    this.coreService.ActionForm.executeclientmessage(this.notBillableFormApiInstance.instanceid)
      .pipe(
        tap(() =>
          this.coreService.controlDataChanged.next({
            GUID: null,
            changeType: EControlActions.resolveClientMessage
          })
        ),
        catchError(() => {
          this.feedbackService.setError('Fehler beim Ausführen der Client-Nachricht');
          return of(null);
        }
      )
    ).subscribe()
  }

  onResolveManually() {
    this.coreService.ActionForm.resolveclientmessagemanual(this.notBillableFormApiInstance.instanceid)
      .pipe(
        tap(() =>
          this.coreService.controlDataChanged.next({
            GUID: null,
            changeType: EControlActions.resolveClientMessage
          })
        ),
        catchError(() => {
          this.feedbackService.setError('Fehler beim manuellen Lösen der Client-Nachricht');
          return of(null);
        }
      )
    ).subscribe()
  }

  onShowDocument() {
    this.coreService.ActionForm.downloadclientmessagedocument(this.notBillableFormApiInstance.instanceid)
      .pipe(
        tap(response => {
          if (response) {
            this.dialogService.dialog.open(PdfDialogComponent, {
              data: response,
              width: '80vw',
              height: '90vh'
            });
          } else {
            this.feedbackService.setError('Fehler beim Anzeigen des Dokuments');
          }
        }),
        catchError(() => {
          this.feedbackService.setError('Fehler beim Anzeigen des Dokuments');
          return of(null);
        }
      )
    ).subscribe()
  }

  resized() {
    [this.conspcuityGridApi,
      this.clientMessageDetails?.gridApi,
      this.clientsComparatorTable?.gridApi].forEach(api => {
      if (api) {
        api.sizeColumnsToFit();
      }
    })
  }
}
