import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {TreeControlComponent} from './tree-control.component';
import {TreeModule} from '@ali-hm/angular-tree-component';

import {MAT_CHECKBOX_DEFAULT_OPTIONS, MatCheckboxDefaultOptions, MatCheckboxModule,} from '@angular/material/checkbox';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {MatTooltipModule} from '@angular/material/tooltip';
import {MatSlideToggleModule} from '@angular/material/slide-toggle';
import {MatButtonModule} from '@angular/material/button';
import {NodeEditorDialogComponent} from './node-editor-dialog/node-editor-dialog.component';
import {MatDialogModule} from '@angular/material/dialog';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatInputModule} from '@angular/material/input';
import {SharedComponentsModule} from '../../shared-components/shared-components.module';
import {MatIconModule} from '@angular/material/icon';
import {MatMenuModule} from '@angular/material/menu';
import {MatRippleModule} from '@angular/material/core';
import {ColorPickerModule} from 'ngx-color-picker';
import {NodeStyleMenuComponent} from './node-style-menu/node-style-menu.component';
import {DeleteNodeComponent} from './delete-node/delete-node.component';
import {SelectTargetNodeComponent} from './delete-node/select-target-node-component/select-target-node.component';

@NgModule({
  declarations: [
    TreeControlComponent,
    NodeEditorDialogComponent,
    NodeStyleMenuComponent,
    DeleteNodeComponent,
    SelectTargetNodeComponent,
  ],
  imports: [
    CommonModule,
    TreeModule,
    MatCheckboxModule,
    FormsModule,
    MatTooltipModule,
    MatSlideToggleModule,
    MatButtonModule,
    MatDialogModule,
    MatFormFieldModule,
    MatInputModule,
    ReactiveFormsModule,
    SharedComponentsModule,
    MatIconModule,
    MatMenuModule,
    MatRippleModule,
    ColorPickerModule,
  ],
  exports: [TreeControlComponent],
  providers: [
    {
      provide: MAT_CHECKBOX_DEFAULT_OPTIONS,
      useValue: {clickAction: 'check'} as MatCheckboxDefaultOptions,
    },
  ]
})
export class TreeControlModule {
}
