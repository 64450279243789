<div
  style="display: flex; flex-direction: column"
  [class]="state + ' wrapper'"
>
  <div
    #menuContainer
    class="menu-container"
  >
    <ul
      class="side-nav-menu"
      *ngFor="let category of categories"
    >
      <app-section-title
        [class]="state"
        *ngIf="category.title"
        [title]="category.title"
        [icon]="category.icon"
        [categoryIsClosed]="category.closed"
        [showIcons]="showIcons"
        (openClosedStateToggled)="updateCategoryState($event, category)"
        (mouseover)="state === 'small-width' ? openContextMenu($event, category) : ''"
        [state]="state"
      ></app-section-title>

      <ng-container *ngIf="!category.closed && state === 'full-width'">
        <li
          class="side-nav-item"
          *ngFor="let comp of category.components"
          (click)="onComponentListItemClick($event, comp)"
        >
          <a
            class="side-nav-link"
            [routerLink]="getRoute(comp)"
          >

            <ng-container>
              <span>
                {{comp.title}}
              </span>

              <button
                *ngIf="isGoldenLayoutComponent(comp)"
                mat-icon-button
                class="close-components-type-icon"
                (click)="closeAllComponentsOfType($event, comp)"
              >
                <mat-icon
                  class="centered"
                  svgIcon="{{EsvgFiles.close}}"
                ></mat-icon>
              </button>
            </ng-container>
          </a>
        </li>
      </ng-container>
    </ul>

    <div #saveLayoutReference></div>
  </div>

  <div style="flex: 0; min-height: 41px">
    <mat-divider style="width: 100%; color: var(--divider)"></mat-divider>

    <div
      class="mat-h4 header"
      style="display: flex; justify-content: center; gap: 0.5rem; padding: 0.5rem 1rem; color: var(--open-close-icon); cursor: pointer;"
      (click)="onIconClick()"
    >
      <span
        class="icon-wrapper"
        [style.width.px]="showIcons ? 24 : 2"
      >
        <mat-icon
          class="centered"
          style="
          display: flex;
          margin-left: auto;
          margin-right: 13px;
          color: var(--open-close-icon);
          background-color: transparent;
          "
          [svgIcon]="getOpenCloseIcon()"
        ></mat-icon>
      </span>

      <span class="title" *ngIf="state === 'full-width'">
        <span>Seitenleiste minimieren</span>
      </span>
    </div>
  </div>
</div>

<!-- a hidden div is created to set the position of appearance of the menu-->
<div
  style="visibility: hidden; position: fixed;"
  [style.left]="menuTopLeftPosition.x"
  [style.top]="menuTopLeftPosition.y"
  [matMenuTriggerFor]="hoverMenu"
></div>

<!-- standard material menu -->
<mat-menu
  #hoverMenu="matMenu"
  class="customized-menu"
>
  <div (mouseleave)="closeHoverMenu()" (mouseenter)="onMouseEnter()">
    <ng-template matMenuContent let-item="item">
      <span class="mat-menu-title">
        {{item.title}}
      </span>

      <button
        mat-menu-item
        *ngFor="let component of item.components"
        (click)="onComponentListItemClick($event, component)"
        (keydown.enter)="preventSelection($event)"
      >
        <span style="display: flex; align-items: center">
          <span>{{component.title}}</span>

          <button
            *ngIf="isGoldenLayoutComponent(component)"
            mat-icon-button
            style="margin-left: auto"
            (click)="closeAllComponentsOfType($event, component)"
          >
            <mat-icon
              class="centered"
              svgIcon="{{EsvgFiles.close}}"
            ></mat-icon>
          </button>
        </span>
      </button>
    </ng-template>
  </div>
</mat-menu>
