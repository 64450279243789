import {AfterViewInit, ChangeDetectorRef, Component, Inject, OnDestroy, OnInit} from '@angular/core';
import * as GoldenLayout from '@kpi4me/golden-layout';
import {Tab} from '@kpi4me/golden-layout';
import {
  GlOnClose,
  GlOnPopin,
  GlOnResize,
  GlOnShow,
  GlOnTab,
  GoldenLayoutComponent,
  GoldenLayoutComponentHost,
  GoldenLayoutContainer
} from '@kpi4me/golden-angular-layout';
import {BehaviorSubject, Subscription} from 'rxjs';
import {ControlManagerService} from '../../control-manager/control-manager.service';

interface ICustomGoldenLayoutConfig {
  showCloseIcon?: boolean;
}

@Component({
  selector: 'app-golden-layout-content',
  templateUrl: './golden-layout-content.component.html',
  styleUrls: ['./golden-layout-content.component.css']
})
export abstract class GoldenLayoutContentComponent implements GlOnShow, GlOnResize, GlOnTab, GlOnShow, AfterViewInit, GlOnPopin, GlOnClose, OnInit, OnDestroy {

  // if or if not the tab of the component has been activated
  protected activated = new BehaviorSubject<boolean>(null);

  private _config: ICustomGoldenLayoutConfig = {};

  protected subs = new Subscription();

  private _onTabClick = () => {
    // this.triggerResize();
  }

  get config() {
    return this._config;
  }

  set config(c: ICustomGoldenLayoutConfig) {
    this._config = c;
    this.hideUnnecessaryIcons();
  }

  constructor(
    @Inject(GoldenLayoutComponentHost) protected goldenLayout: GoldenLayoutComponent,
    @Inject(GoldenLayoutContainer) protected container: GoldenLayout.Container,
    protected cdr: ChangeDetectorRef,
    protected controlManager: ControlManagerService
  ) {
    if (this.container.tab && this.container.tab.isActive) {
      this.activated.next(true);
    }
    this.hideUnnecessaryIcons();

  }

  ngOnInit() {
    this.subs.add(this.controlManager.windowResize.subscribe(
      () => this.onResize()
    ));

    this.subs.add(this.controlManager.openedComponentsUpdated.subscribe(
      () => this.onResize()
    ));
  }

  glOnPopin() {
    this.hideUnnecessaryIcons();
    this.triggerResize();
  }

  glOnResize(): void {
    this.hideUnnecessaryIcons();
    this.onResize();
  }

  glOnShow() {
    this.hideUnnecessaryIcons();
    this.triggerResize();
    this.activate();
    this.controlManager.setOpenedComponents();
  }

  glOnTab(tab: Tab): void {
    this.hideUnnecessaryIcons();
    this.triggerResize();
    this.activate();
  }

  glOnClose(): Promise<void> {
    return new Promise((resolve) => {
      this.controlManager.setOpenedComponents();
      // TODO: If it was the last opened component, set the startPage as the new view
      resolve();
    });
  }

  ngAfterViewInit(): void {
    if (window.opener) {
      console.log("Sub window init");
    }

    // Add tab click listener
    this.container.tab.element[0].addEventListener('mousedown', this._onTabClick)

    console.log("window opener", window.opener);
    // sometimes the golden layout does not take the full height of the window.
    // update its size once the easy plan is rendered
    setTimeout(() => {
      this.goldenLayout.getGoldenLayoutInstance().updateSize(), 100
    });
  }

  ngOnDestroy() {
    this.subs.unsubscribe();
    this.container.tab.element[0].removeEventListener('mousedown', this._onTabClick);
  }


  /**
   * Template method that can be overwritten by subclasses.
   * @protected
   */
  protected abstract onResize(): void;

  protected hideUnnecessaryIcons() {
    // hide the icons for popout windows
    let controlsContainer: any;
    // @ts-ignore
    if (this.container?.parent?.parent?.header) {
      ({controlsContainer} = (this.container.parent.parent as any).header);
      if (window.opener) {
        controlsContainer.find('.lm_popout').hide();
        controlsContainer.find('.lm_maximise').hide();
        controlsContainer.find('.lm_close').hide();

        console.log('CONTAINER', this.container);
      }
    }
  }

  private activate() {
    if (!this.activated.getValue()) {
      this.activated.next(true);
    }
  }

  triggerResize() {
    setTimeout(() => {
      this.onResize();
    }, 100)
  }
}
