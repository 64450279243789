<div
  style="
    display: flex;
    flex-direction: column;
    height: 100%;"
>
  <app-table-control
    #table
    [(apiInstance)]="tableApiInstance"
    (apiInstanceInitialize)="tableApiInstance$.next($event)"
    [GUID]="documentOutboxTableGUID"
    [actions]="tableActions"
    [gridOptions]="gridOptions"
    [fixedPageSize]="fixedPageSize"
    (rowSelected)="selectedRow$.next($event.node)"
    [pdf]="pdfBlob$ | async"
    (paginationChanged)="paginationChange$.emit($event)"
  ></app-table-control>
</div>
