<mat-form-field appearance="outline">
  <mat-label>{{label}}</mat-label>
  <mat-chip-grid #chipList aria-label="Entity Selection" *ngIf="multiple; else single">
    <mat-chip-row
      *ngFor="let option of formControlElement.value"
      (removed)="remove(option)">
      {{option.label}}
      <button matChipRemove>
        <mat-icon style="display: flex;" class="centered" svgIcon="{{EsvgFiles.delete}}"></mat-icon>
      </button>
    </mat-chip-row>
    <input matInput type="text" style="width: 100%;"
           #input
           placeholder="Suche"
           [formControl]="textForm"
           [matAutocomplete]="auto"
           [matChipInputFor]="chipList"
           [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
           (matChipInputTokenEnd)="add($event)"
           (keydown)="edited = true"
    >
  </mat-chip-grid>
  <ng-template #single>
    <input matInput type="text" style="width: 100%;"
           placeholder="Suche"
           [formControl]="textForm"
           [matAutocomplete]="auto"
           (keydown)="edited = true">
  </ng-template>
  <mat-autocomplete #auto="matAutocomplete" (optionSelected)="onOptionSelected($event)" [displayWith]="displayWith">
    <mat-option *ngFor="let entity of filteredEntities" [value]="entity">
      {{entity.label}}
    </mat-option>

  </mat-autocomplete>


</mat-form-field>
