import {Component, Inject, ViewEncapsulation} from '@angular/core';
import {ITableVerticalDataRow} from '../table-vertical-api-adapter';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {DisplayedColumns} from '../../table-control/interfaces/displayed-columns.interface';
import {IApiRow} from '../../table-control/api-row.interface';
import {IApiControl} from 'frontier/nucleus';

@Component({
  selector: 'kpi4me-table-row-dialog',
  templateUrl: './table-row-dialog.component.html',
  styleUrls: ['./table-row-dialog.component.scss'],
  encapsulation: ViewEncapsulation.Emulated,
})
export class TableRowDialogComponent {
  rows: ITableVerticalDataRow[] = [];

  constructor(
    public dialogRef: MatDialogRef<TableRowDialogComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data: {
      row: IApiRow, columns: DisplayedColumns[],
      apiInstance: IApiControl, title: string
    }) {
    data.columns.forEach(col => {
      const cell = data.row.cols[Number(col.field)];
      this.rows.push({
        type: col.type,
        attributeIndex: col.attributeindex,
        attribute: col.attribute,
        fieldName: col.headerName,
        value: cell.value,
        editable: cell.editable,
        apiRow: data.row,
        apiInstance: {...data.apiInstance},
        repository: col.repository
      })
    })

  }


  onCancel() {
    this.dialogRef.close()
  }

  onConfirm() {
    this.dialogRef.close(this.data.row);
  }
}
