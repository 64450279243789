<ng-template #cancel>
  <button mat-flat-button>
    <span>Abbruch</span>
  </button>
</ng-template>

<input
  #inputField
  [format]="24"
  [ngxTimepicker]="timePickerValue"
  [value]="initialValue"
  aria-label="default time"
  readonly
  style="width: 100%"
/>
<ngx-material-timepicker
  #timePickerValue
  [cancelBtnTmpl]="cancel"
></ngx-material-timepicker>
