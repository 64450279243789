import {ChangeDetectorRef, Component, ElementRef, HostListener, ViewChild,} from '@angular/core';

import {ICellEditorParams} from 'ag-grid-community';
import {NgxMaterialTimepickerComponent} from 'ngx-material-timepicker';
import {Subscription} from 'rxjs';
import {DatePipe} from '@angular/common';
import {CustomCellEditorComponent} from '../custom-cell-editor/custom-cell-editor.component';
import {CoreService} from 'frontier/nucleus';
import {FeedbackService} from '../../../../user-feedback/feedback.service';

@Component({
  selector: 'app-time-of-day-cell-editor',
  templateUrl: './time-of-day-cell-editor.component.html',
  styleUrls: ['../custom-cell-editor/custom-cell-editor.component.scss'],
})
export class TimeOfDayCellEditorComponent extends CustomCellEditorComponent {
  @ViewChild('inputField') timePickerInput: ElementRef<any>;
  @ViewChild(NgxMaterialTimepickerComponent)
  timePickerRef: NgxMaterialTimepickerComponent;

  subs = new Subscription();
  initialValue: string = '';
  private timePickerOpen: boolean = false;

  constructor(
    protected coreService: CoreService,
    protected cdr: ChangeDetectorRef,
    protected elementRef: ElementRef,
    protected feedbackService: FeedbackService,
    private datePipe: DatePipe
  ) {
    super(coreService, cdr, elementRef, feedbackService);
  }

  agInit(params: ICellEditorParams) {
    super.agInit(params);

    if (!params.charPress) {
      this.initialValue = this.datePipe.transform(params.value.value, 'HH:mm');
      this.textFormControl.patchValue(this.initialValue);
    }
  }

  ngAfterViewInit() {
    this.subs.add(
      this.timePickerRef.opened.subscribe(() => {
        this.timePickerOpen = true;
      })
    );
    this.subs.add(
      this.timePickerRef.closed.subscribe(() => {
        this.timePickerOpen = false;
        this.params.value.value = this.params.value.value.replace(
          /T\d\d:\d\d/,
          'T' + this.timePickerInput.nativeElement.value
        );
        this.textFormControl.patchValue(this.params.value.value);
        this.params.api.stopEditing();
      })
    );

    setTimeout(() => {
      this.timePickerInput.nativeElement.click();
    }, 0);
  }

  // detect clicks outside
  @HostListener('document:click', ['$event'])
  protected clickOut(event: MouseEvent) {
    const target = event.target as HTMLElement;
    const timePickerContainer = document.getElementsByTagName(
      'ngx-material-timepicker-container'
    )[0];
    if (
      !this.elementRef.nativeElement.contains(target) &&
      (timePickerContainer == undefined ||
        !timePickerContainer.contains(target)) &&
      this.timePickerOpen == false
    ) {
      this.params.api.stopEditing();
    }
  }
}
