import {Component} from '@angular/core';
import {MatSlideToggleChange} from '@angular/material/slide-toggle';
import {CustomCellComponent} from '../custom-cell/custom-cell.component';

@Component({
  selector: 'app-custom-bool-cell-renderer',
  templateUrl: './custom-bool-cell-renderer.component.html',
  styleUrl: './custom-bool-cell-renderer.component.scss'
})
export class CustomBoolCellRendererComponent extends CustomCellComponent {

  ngOnInit() {
    super.ngOnInit();
  }

  onChange(event: MatSlideToggleChange) {
    this.params.setValue({...this.params.value, value: event.checked});
  }
}
