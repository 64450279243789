import {Component, HostListener, ViewChild} from '@angular/core';
import {CustomCellEditorComponent} from '../custom-cell-editor/custom-cell-editor.component';
import {GridApi, ICellEditorParams} from 'ag-grid-community';
import {
  SelectElementComponent
} from '../../../form-control/dynamic-form/form-element/select-element/select-element.component';
import {
  ISelectFormElement,
  ISelectFormOption
} from '../../../form-control/dynamic-form/form-element/form-data.interface';

interface IEnumCellEditorParams extends ICellEditorParams {
  isMulti: boolean;
}

@Component({
  selector: 'app-enum-cell-editor',
  templateUrl: './enum-cell-editor.component.html',
  styleUrls: ['./enum-cell-editor.component.scss'],
})
export class EnumCellEditorComponent extends CustomCellEditorComponent {
  @ViewChild(SelectElementComponent) selectionElement: SelectElementComponent;
  // @ViewChild(MatSelectionList) selectionElement: MatSelectionList;

  selectElementData: ISelectFormElement;

  params: IEnumCellEditorParams;

  @HostListener('document:click', ['$event'])
  protected clickOut(event: MouseEvent) {
    const target = event.target as HTMLElement;
    console.log(target);
    const pane = document.getElementsByClassName('custom-panel')[0];
    if (
      pane == null || !pane.contains(target)
    ) {
      this.params?.api?.stopEditing();
    }
  }

  agInit(params: IEnumCellEditorParams) {
    this.selectElementData = {
      options: (params.colDef as any).repository.map((o: any, i: number) => {
        return {
          name: o, value: i
        } as ISelectFormOption
      }),
      createNewEnabled: false,
      emptyOptionAdded: false,
      multiple: params.isMulti,
      confirmButton: params.isMulti,
    };
    super.agInit(params);
  }

  ngAfterViewInit() {
    setTimeout(() => {
      this.selectionElement.selector.open();
    });
  }

  compareWith = (e1: any, e2: any) => {
    return e1 != null && e2 != null && e1 === e2;
  };

  isPopup(): boolean {
    return false;
  }

  getValue(): any {
    return super.getValue();
  }

  onSelectionChange() {
    if (this.params.isMulti === false) {
      this.params.context.gridApi.stopEditing();
    }
  }

  protected setPastedValue(pasted: string) {
    try {
      const patchValue = this.selectElementData.multiple ? JSON.parse(pasted) : Number(JSON.parse(pasted));
      this.textFormControl.patchValue(patchValue);
      this.params.value.value = patchValue;
    } catch (e) {
      console.error(e);
    } finally {
      this.params.api.stopEditing();
    }
  }


  saveValue() {
    const colDef: any = this.params.colDef;
    // When it's a multi select, take the first element of the array.
    const value = this.textFormControl.value;
    this.changeLine(
      this.params.data.apiRow.obj,
      this.params.data.apiRow.rowidx,
      colDef.attribute,
      colDef.attributeindex,
      value,
    ).subscribe(() => {
      this.selectionElement.selector.open();
    });
  }

  onConfirm() {
    this.params.context.gridApi.stopEditing();
    (<GridApi>this.params.context.gridApi).setFocusedCell(this.params.rowIndex, this.params.colDef.colId);
  }


}
