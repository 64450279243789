import {Component, ViewEncapsulation} from '@angular/core';
import {BaseCellEditorComponent} from '../base-cell-editor/base-cell-editor.component';

@Component({
  selector: 'kpi4me-number-cell-editor',
  templateUrl: './number-cell-editor.component.html',
  styleUrls: ['./number-cell-editor.component.scss'],
  encapsulation: ViewEncapsulation.Emulated,
})
export class NumberCellEditorComponent extends BaseCellEditorComponent {

}
