<div class="flex">
  <div class="preview">
    Preview
  </div>
  <div>
    @defer (when data()?.data) {
      <div class="flex" style="flex-direction: column">
        <mat-button-toggle-group
          [(value)]="selectedInvoice"
        >
            <mat-button-toggle [value]="null">
              Standardkonfiguration
            </mat-button-toggle>
          @for(invoiceTemplate of data().data; track invoiceTemplate.signature) {
            <mat-button-toggle [value]="invoiceTemplate">
              {{invoiceTemplate.name}}
            </mat-button-toggle>
          }
        </mat-button-toggle-group>
        <mat-checkbox (change)="toggleIsCancellationConfig()">
          Storno bearbeiten
        </mat-checkbox>
        <div>
          {{isCancellationConfig()}}
          {{selectedInvoiceConfiguration() | json}}
        </div>
      </div>
    }
  </div>
</div>
