import {
  computed,
  Directive,
  effect,
  EventEmitter,
  inject, OnDestroy,
  OnInit,
  Output,
  signal,
} from '@angular/core';
import {CoreService, IApiControl, IControlConfiguration, IFilter} from 'frontier/nucleus';
import {BehaviorSubject, concatMap, defer, finalize, first, Observable, Subject, Subscription} from 'rxjs';
import {debounceTime, filter} from 'rxjs/operators';

@Directive({})
export abstract class SignalControl<T> implements OnInit, OnDestroy
{
  protected GUID!: string;
  @Output() apiInstanceChanged = new EventEmitter<IApiControl>();
  @Output() dataChanged = new EventEmitter<any>();

  apiInstance = signal<IApiControl>(undefined);
  data = signal<T | undefined>(undefined);

  protected searchChanged: Subject<string> = new Subject<string>();
  protected coreService = inject(CoreService);
  protected filter = signal<IFilter>({});
  protected custom = signal<any>({});
  protected sorting = signal<any>({});
  private _search = signal<string>(undefined);

  private _changeRequestLock = 0;
  private _changeQueue = new BehaviorSubject<number>(0);
  private _subs = new Subscription();
  private _debounceTime = 500;

  private _config: IControlConfiguration = {
    showFilter: true,
    showNewObject: true,
  };

  set config(c: IControlConfiguration) {
    this._config = c;
  }

  get config(): IControlConfiguration {
    return this._config;
  }

  constructor() {
    this._subs.add(
      this.searchChanged
        .pipe(debounceTime(this._debounceTime))
        .subscribe((filterString) => {
          this._search.set(filterString);
        })
    );

    // When the search string changes, update the filter object
    computed(() => {
      const search = this._search();

      if (search) {
        this.filter.update(filter => {
          return {
            ...filter,
            searchstring: search
          };
        });
      }
    })

    effect(() => {
      const filter = this.filter();
      const custom = this.custom();
      const sorting = this.sorting();
      const apiInstance= this.apiInstance();

      if (!apiInstance) {
        return;
      }
      this.changeAndFetch(apiInstance.instanceid, filter, sorting, custom);
    });
  }

  ngOnInit() {
    this.create(this.filter(), this.sorting(), this.custom());
  }

  ngOnDestroy() {
    this.drop();
  }

  private create(filter: IFilter, sorting: any, custom: any) {
    this.coreService.Control.NewInstance(this.GUID, filter, sorting, custom)
      .subscribe((apiInstance) => {
      this.apiInstance.set(apiInstance);
    });
  }

  /**
   * Method to prevent multiple api requests that trigger database transaction, possibly leading to an "open current transaction error"
   * @param apiRequest
   */
  private lockedRequest(apiRequest: Observable<any>): Observable<any> {
    return defer(() => {
      const lock = this._changeRequestLock;
      this._changeRequestLock++;
      return this._changeQueue.pipe(
        filter((i) => i === lock),
        first(),
        concatMap(() => apiRequest),
        finalize(() =>
          this._changeQueue.next(
            this._changeRequestLock - lock > 1 ? this._changeRequestLock - 1 : this._changeRequestLock
          )
        )
      );
    });
  }

  private changeAndFetch(instanceId: string, filter: IFilter, sorting: any, custom: any) {
    this.lockedRequest(   this.coreService.Control.ChangeInstanceAndFetch(
      instanceId, filter, sorting, custom
    )).subscribe((data) => {
      this.data.set(data);
    });
  }

  private drop() {
    if (!this.apiInstance().instanceid) {
      return;
    }
    this.apiInstance.set(undefined);
    this.lockedRequest(this.coreService.Control.DropInstance(this.apiInstance().instanceid)).subscribe();
  }
}
