import {Component, EventEmitter, Input, Output, ViewChild,} from '@angular/core';
import {MatMenu} from '@angular/material/menu';
import {TreeNode} from '@ali-hm/angular-tree-component';

import {DialogService} from '../../../dialogs/dialog.service';
import {CoreService, IApiControl} from 'frontier/nucleus';

@Component({
  selector: 'kpi4me-delete-node',
  templateUrl: './delete-node.component.html',
  styleUrls: ['./delete-node.component.scss'],
})
export class DeleteNodeComponent {
  @ViewChild(MatMenu, {static: true}) menu: MatMenu;
  @Input() node: TreeNode;
  @Input() apiInstance: IApiControl;
  @Output() deletePress = new EventEmitter<{
    mode: 'node' | 'children' | 'all';
    attachTo?: any;
  }>();

  constructor(
    private dialogService: DialogService,
    private coreService: CoreService
  ) {
  }

  getRefCountOfNode() {
    if (this.node) {
      if (this.node.data.referenceCount <= 0) {
        return '';
      } else if (this.node.data.referenceCount === 1) {
        return 'Es wird 1 Referenz gelöscht'
      }
      return `Es werden ${this.node.data.referenceCount} Referenzen gelöscht`;
    } else {
      return '';
    }
  }

  // /**
  //  * Opens the dialog for selecting a node where the children of the deleted nodes will be placed to.
  //  */
  // openSelectTargetNodeDialog() {
  //   const d: {
  //     formData: {
  //       node: TreeNode;
  //     };
  //   } = {
  //     formData: {
  //       node: this.node,
  //     },
  //   };
  //   this.dialogService.dialog
  //     .open(SelectTargetNodeComponent, {
  //       data: d,
  //     })
  //     .afterClosed()
  //     .subscribe((selectedNodeData) => {
  //       if (selectedNodeData) {
  //         this.deleteNode('node');
  //       }
  //     });
  // }

  /**
   * Deletes a node from the hierarchy and conditionally attaches the children to some other node in the same hierarchy.
   * @param mode
   * @param attachTo
   */
  deleteNode(mode: 'node' | 'children' | 'all') {
    this.deletePress.emit({mode});
  }
}
