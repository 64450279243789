import {Component, OnInit} from '@angular/core';
import {CustomCellComponent} from '../custom-cell/custom-cell.component';
import {ICellRendererParams} from 'ag-grid-community';
import {IEnumCellParams} from './enum-cell-params.interface';
import {EsvgFiles} from 'frontier/nucleus';

@Component({
  selector: 'app-enum-cell-renderer',
  templateUrl: './enum-cell-renderer.component.html',
  styleUrls: ['../custom-cell/custom-cell.component.scss'],
  styles: [
    `
     
         `
  ]
})
export class EnumCellRendererComponent extends CustomCellComponent implements OnInit {
  params: IEnumCellParams;

  agInit(params: ICellRendererParams): void {
    super.agInit(params);
  }

  getValueFromRepository(): string {
    if (this.params.value) {
      if (this.params.isMulti) {
        return this.params.value.value.map((v: any) => {
          return (this.params.colDef as any).repository[v];
        });
      } else {
        return (this.params.colDef as any).repository[this.params.value.value];
      }
    } else {
      return '';
    }
  }


  cellEditable() {
    return this.params && this.params.value ? this.params.value.editable : true;
  }

  protected readonly EsvgFiles = EsvgFiles;
}
