<kpi4me-checkbox-element
  [data]="checkboxData"
  [formControlElement]="hasRepo"
  (modelChange)="onHasRepoChanged($event)"
></kpi4me-checkbox-element>

<kpi4me-text-element
  [formControlElement]="formGuid"
  [data]="textElementData"
  (modelChange)="onFormGuidChange($event)"
>

</kpi4me-text-element>
<ng-container *ngIf="render">
  <kpi4me-form-wrapper *ngIf="hasRepo.value; else noRepo"
                       [GUID]="formGuid.value"
                       [apiAdapter]="apiAdapter">
  </kpi4me-form-wrapper>
  <ng-template #noRepo>
    <kpi4me-dynamic-form-control #controlRef
                                 [standardForm]="true"
                                 [GUID]="formGuid.value"
                                 (apiInstanceInitialize)="controlRef.changeAndFetch().subscribe()"
    ></kpi4me-dynamic-form-control>
  </ng-template>
</ng-container>

