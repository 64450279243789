import {EventEmitter, Injectable, OnDestroy} from '@angular/core';
import {Location} from '@angular/common';
import {
  BehaviorSubject,
  combineLatest,
  debounceTime, distinctUntilChanged,
  merge,
  Observable, of,
  shareReplay,
  Subject,
  Subscription,
  tap
} from "rxjs";
import {filter, map, take} from "rxjs/operators";
import {ECalculusStateFilter} from '../home/config/state-filter.enum';
import {
  IActiveClient,
  IActiveInvoice,
  ICart,
  IProcessOverviewState,
  IProcessState
} from '../home/config/category-state-selection.interface';
import {environment} from '../../environments/environment';
import {CalendarService, ControlManagerService, sameArrayValues} from 'frontier/browserkit';
import {ECalculusComponentNames} from '../home/golden-layout.component';
import {TShownTables} from "../home/process-overview/shown-tables.type";
import {IClientApi} from '../home/conspicuity-detail/clients-tile.interface';
import {IControlObject} from 'frontier/nucleus';
import {IPdfOverlayToggleEvent} from '../home/carts/pdf-overlay-toggle-event.interface';


export interface ICalculusState {
  processOverviewState: IProcessOverviewState;
  customPageSize: number;
  buildHash: string;
}

export interface IShowCartsTable {
  ETLnMapping: boolean;
  arrangeInvoice: boolean;
  monitorPayment: boolean;
}

export type TSwitchablePhases = keyof IShowCartsTable;

const INITIAL_CALCULUS_STATE: ICalculusState = {
  processOverviewState: {
    stateSelection: {
      categories: [ECalculusStateFilter.total],
      states: []
    },
    invoice: null,
    client: null,
    cart: null
  },
  customPageSize: 0,
  buildHash: environment.buildHash,
};

export interface IReplacedClientForCart {
  client: IClientApi,
  cart: IControlObject
}

@Injectable({
  providedIn: 'root'
})
export class CalculusStateService implements OnDestroy {
  private subs = new Subscription();

  // TODO: Add to calculus state
  showPercentages$ = new BehaviorSubject<boolean>(false);
  showImportedValues$ = new BehaviorSubject<boolean>(
    JSON.parse(localStorage.getItem('showImportedValues')) == null ? true : JSON.parse(localStorage.getItem('showImportedValues'))
  );
  showCartsTable$ = new BehaviorSubject<IShowCartsTable>(
    JSON.parse(localStorage.getItem('showCartsTable')) || {
      ETLnMapping: false,
      arrangeInvoice: false,
      monitorPayment: false,
    });

  // User Events
  selectedProcessState$ = new Subject<IProcessState>();
  selectedCartRow$ = new EventEmitter<ICart>();
  selectedClientRow$ = new EventEmitter<IActiveClient>();
  selectedInvoice$ = new EventEmitter<IActiveInvoice>();
  replacedClientForCart$ = new EventEmitter<IReplacedClientForCart>();
  setCustomPageSize$ = new EventEmitter();

  // Url for deep redirect for open items
  invoiceIdFromUrl$: Subject<IActiveInvoice> = new Subject();

  // Setter event
  setLocalstorageState$ = new Subject<ICalculusState>();

  // Single Source State which is saved in the local storage
  localStorageCalculusState$ =
    merge(
      this.setLocalstorageState$.pipe(),
      of(
        JSON.parse(localStorage.getItem('calculusFilter') || null)
        // Default
        || INITIAL_CALCULUS_STATE
      )).pipe(
      distinctUntilChanged((_, current) => {
        // Check if the state has changed. Only if it has, update the localstorage and pass down the state.
        // Prevents a stack overflow by breaking the state change cycle
        const previous = JSON.parse(localStorage.getItem('calculusFilter'));
        const b = previous.customPageSize === current.customPageSize &&
          previous.processOverviewState.cart?.obj?.rowid === current.processOverviewState.cart?.obj?.rowid &&
          previous.processOverviewState.client?.obj?.rowid === current.processOverviewState.client?.obj?.rowid &&
          previous.processOverviewState.invoice?.obj?.rowid === current.processOverviewState.invoice?.obj?.rowid &&
          sameArrayValues(previous.processOverviewState.stateSelection.states, current.processOverviewState.stateSelection.states) &&
          sameArrayValues(previous.processOverviewState.stateSelection.categories, current.processOverviewState.stateSelection.categories)
        console.log(b);
        return b;
      }),
      tap(state => {
        localStorage.setItem('calculusFilter', JSON.stringify(state))
      }),
      shareReplay(1)
      );

  // Derived localstorage state that resets it if the build hash has changed
  calculusState$: Observable<ICalculusState> = this.localStorageCalculusState$.pipe(
    map((v) => {
      if (environment.env === 'prod' && v.buildHash !== environment.buildHash) {
        return INITIAL_CALCULUS_STATE;
      }
      return v;
    }),
  ).pipe(
    shareReplay(1)
  );

  // Custom Page size either from parent state or user action which changes the page size
  customPageSize$: Observable<number> = merge(
    this.setCustomPageSize$,
    this.calculusState$.pipe(map(v => v.customPageSize))
  ).pipe(
    shareReplay(1)
  );

  activeProcessState$: Observable<IProcessState> = merge(
    this.calculusState$.pipe(
      map(v => v.processOverviewState?.stateSelection)
    ),
    this.selectedProcessState$
  ).pipe(
    shareReplay(1)
  )

  // Current active client.
  // Either is selected by the user or other sources
  activeClient$: Observable<IActiveClient> = merge(
    this.selectedClientRow$,
    this.calculusState$.pipe(map(v => (v.processOverviewState.client))),
    this.selectedProcessState$.pipe(map(v => (INITIAL_CALCULUS_STATE.processOverviewState.client as IActiveClient)))
  ).pipe(
    shareReplay(1)
  );

  // The current active cart
  // Either is selected by the user of the state is loaded
  activeCart$: Observable<ICart> = merge(
    // If the showCarts table does not display the carts table in any view => reset the active cart
    this.showCartsTable$.pipe(
      map(showCarts => {
        if (Object.values(showCarts).every(v => v === false)) {
          return {obj: null, number: null} as ICart
        }
        return null;
      }),
      filter(v => v != null)
    ),
    this.selectedCartRow$,
    this.calculusState$.pipe(map(v => (v.processOverviewState.cart))),
    this.selectedProcessState$.pipe(map(v => (INITIAL_CALCULUS_STATE.processOverviewState.cart as ICart)))
  ).pipe(
    shareReplay(1)
  );

  // The current active invoice
  // EIther comes from the url or is selected by the user
  activeInvoice$: Observable<IActiveInvoice> = merge(
    this.selectedInvoice$,
    this.calculusState$.pipe(map(v => (v.processOverviewState.invoice))),
    this.selectedProcessState$.pipe(map(v => (INITIAL_CALCULUS_STATE.processOverviewState.invoice as IActiveInvoice)))
  ).pipe(
    shareReplay(1)
  );

  // The process overview state. Contains the seelcted process / subprocess and active client, cart and invoice
  processOverviewState$: Observable<IProcessOverviewState> =
    merge(
      this.invoiceIdFromUrl$.pipe(
        filter(invoiceId => invoiceId != null),
        map((invoiceFromUrl: IActiveInvoice) => {
          this.location.replaceState('/home');
          const processOverviewState = {
            invoice: invoiceFromUrl,
            cart: null,
            client: null,
            stateSelection: {categories: [ECalculusStateFilter.monitorPayment], states: []}
          }

          // Reset the fulltext filter in the toolbar
          this.calenderService.resetFullTextFilter();

          if (this.controlManagerService.openComponents[ECalculusComponentNames.processOverview] == null) {
            this.controlManagerService.openComponent(ECalculusComponentNames.processOverview);
          }
          return processOverviewState as IProcessOverviewState;
        })),
      combineLatest([
        this.activeProcessState$,
        this.activeClient$,
        this.activeCart$,
        this.activeInvoice$
      ]).pipe(map(([stateSelection, client, cart, invoice]) => {
        return {
          stateSelection, client, cart, invoice
        }
      }))
    ).pipe(
      debounceTime(200),
      tap((v) => {
        console.log('process overview state changed', v)
      }),
      shareReplay(1)
    )


  // The shown tables. Derives from the process overview state and the show-carts-table state
  activeTables$: Observable<Set<TShownTables>> = combineLatest([
    this.showCartsTable$.pipe(
      tap(showCartsTable => {
        if (showCartsTable != null) {
          localStorage.setItem('showCartsTable', JSON.stringify(showCartsTable));
        }
      })),
    this.processOverviewState$
  ]).pipe(
    map(([showCartsTable, processOverviewState]) => {
      const {categories, states} = processOverviewState.stateSelection;
      if (categories.includes(ECalculusStateFilter.arrangeInvoice)) {
        return new Set<TShownTables>([showCartsTable.arrangeInvoice ? 'carts' : 'documentOutbox'])
      }
      // If an active invoice is set, display two tables: carts and openItems
      if (processOverviewState.invoice) {
        return new Set<TShownTables>(['carts', 'openItems']);
      }
      if (categories.includes(ECalculusStateFilter.ETLnMapping)) {
        return new Set<TShownTables>(showCartsTable.ETLnMapping ? ['carts'] : processOverviewState.client ? ['clients', 'carts'] : ['clients'])
      }
      // If an active client is set, display two tables: carts and clients
      if (processOverviewState.client) {
        return new Set<TShownTables>(['carts', 'clients']);
      }
      // if the payment monitoring is selected and only one category is selected, display the openItems
      if (categories.includes(ECalculusStateFilter.monitorPayment)) {
        if (categories.length === 1) {
          // If the table display toggle is triggered, display the correct table
          return new Set<TShownTables>([showCartsTable.monitorPayment ? 'carts' : 'openItems'])
        }
      }

      // Display the carts in all other cases
      return new Set<TShownTables>(['carts']);
    })
  );
  shownOverlay$ = new BehaviorSubject<IPdfOverlayToggleEvent | null>(null);
  isInvoiceLoading$ = new BehaviorSubject<boolean>(false);


  constructor(private location: Location, private calenderService: CalendarService, private controlManagerService: ControlManagerService) {
    this.subs.add(
      this.showImportedValues$.pipe(
        tap(v => localStorage.setItem('showImportedValues', JSON.stringify(v)))
      ).subscribe()
    );

    this.subs.add(this.calculusState$.subscribe(state => {
      console.log('Calculus state changed')
    }))

    this.subs.add(
      combineLatest([
        this.processOverviewState$,
        this.customPageSize$,
      ]).pipe(
        map(([processOverviewState, customPageSize]) => {
          return {
            processOverviewState,
            customPageSize,
            buildHash: environment.buildHash
          } as ICalculusState
        }),
      ).subscribe(state => {
        this.setLocalstorageState$.next(state);
        console.log('Update local storage')
      }))
  }

  ngOnDestroy() {
    this.subs.unsubscribe();
  }
}
