import {IExtendedGoldenLayoutConfig} from '@kpi4me/golden-angular-layout';
import {ECalculusComponentNames, ECalculusLabels} from "./golden-layout.component";

export const INITIAL_VIEW: IExtendedGoldenLayoutConfig = {
  dimensions: {
    headerHeight: 30
  },
  settings: {
    hasHeaders: true,
    showPopoutIcon: false,
    showMaximiseIcon: true,
    showCloseIcon: true,
    selectionEnabled: false,
  },
  content: [
    {
      type: 'stack',
      isClosable: false,
      content: [
        {
          componentName: ECalculusComponentNames.processOverview,
          type: 'component',
          title: ECalculusLabels.processOverview,
        },
      ]
    }
  ]
}
