import {Observable} from 'rxjs';
import {ApiConfigService} from '../api-config.service';
import {HttpHeaders} from '@angular/common/http';

export class Usetoken {
  http;
  url;
  httpOptions;
  constructor(protected appConfig: ApiConfigService) {
    this.http = appConfig.http;
    this.url = appConfig.url;
    this.httpOptions = appConfig.httpOptions;
  }


  ResetPassword(TokenStr: string, NewPassword: string) : Observable<boolean> {
    return this.http.post(
      this.url + '/resource/api/UseToken/ResetPassword/',
      {_parameters: [TokenStr, NewPassword]}, this.httpOptions
    ) as unknown as Observable<boolean>;
  }

  ConfirmEmailAddress(TokenStr: string) : Observable<boolean> {
    return this.http.post(
      this.url + '/resource/api/UseToken/ConfirmEmailAddress/',
      {_parameters: [TokenStr]}, this.httpOptions
    ) as unknown as Observable<boolean>;
  }

}
