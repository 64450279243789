import {Observable} from 'rxjs';
import {ApiConfigService} from '../api-config.service';
import {HttpHeaders} from '@angular/common/http';
import {IControlObject} from '../../interfaces/control-object.interface';

export class Courseplantable {
  http;
  url;
  httpOptions;
  constructor(protected appConfig: ApiConfigService) {
    this.http = appConfig.http;
    this.url = appConfig.url;
    this.httpOptions = appConfig.httpOptions;
  }


  generateplanning(InstanceId: string, courseplans: IControlObject[]) : Observable<boolean> {
   return this.http.post(
      this.url + '/intern/api/control/ExecuteAction/' + InstanceId + '/generateplanning/', {"courseplans": courseplans}, this.httpOptions
    ) as unknown as Observable<boolean>;
  }

}
