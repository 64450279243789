<input
  (ngModelChange)="onInputChange($event)"
  type="text"
  [formControl]="textFormControl"
  minlength="3"
  [pattern]="validRegex"
  #input
  style="width: 100%"
/>
<mat-icon
  class="red-icon"
  style="display: flex"
  *ngIf="showError"
  svgIcon="{{EsvgFiles.error}}"
  [matTooltip]="errorTooltip"
></mat-icon>
