<crud-control
  style="display: none;"
  [GUID]="cartAdditionalDataGuid"
  (apiInstanceInitialize)="onApiInstanceInitialize()"
></crud-control>

<div class="grid-container">
  <label class="grid-item" *ngFor="let label of labels">
    {{ label }}
  </label>
</div>
