import {Component, Inject} from '@angular/core';
import {IDateFormElement, IFileUploadFormElement, ISelectFormElement,} from "frontier/browserkit";
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {INewPermanentOrdinanceForm} from "./new-permanent-ordinance-form.interface";
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {INewPermanentOrdinanceDialog} from "./new-permanent-ordinance-dialog.interface";

@Component({
  selector: 'app-new-permanent-ordinance-dialog',
  templateUrl: './new-permanent-ordinance-dialog.component.html',
  styleUrl: './new-permanent-ordinance-dialog.component.scss'
})
export class NewPermanentOrdinanceDialogComponent {
  form: FormGroup<INewPermanentOrdinanceForm> = this.fb.group({
    itemGroup: this.fb.control(null, [Validators.required]),
    fromDate: this.fb.control(null, [Validators.required]),
    toDate: this.fb.control(null, [Validators.required]),
    document: this.fb.control(null, [Validators.required]),
  });
  itemGroup: ISelectFormElement = {
    label: 'Tarif',
    options: [],
    createNewEnabled: false,
    deleteElementEnabled: false,
    editElementEnabled: false,
    emptyOptionAdded: false,
  };
  fromDateData: IDateFormElement = {
    label: 'von',
    insertDelimiter: false,
  };
  toDateData: IDateFormElement = {
    label: 'bis',
    insertDelimiter: false,
  };
  documentData: IFileUploadFormElement = {
    readAs: 'ArrayBuffer',
    maxSize: 1024,
    selectType: 'upload',
    fileTypes: '.pdf',
  }

  protected readonly document = document;
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: INewPermanentOrdinanceDialog,
    public dialogRef: MatDialogRef<NewPermanentOrdinanceDialogComponent>,
    private fb: FormBuilder,
    ) {
    this.itemGroup.options = data.itemGroups ||  this.itemGroup.options;
  }
}
