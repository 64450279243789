<div
  style="
    display: flex;
    flex-direction: column;
    height: 100%;"
>
  <h5>Arzt</h5>
  <kpi4me-table-vertical-control
    #ordinanceForm
    [showImports]="showImports"
    [hasImports]="false"
    [(apiInstance)]="ordinanceFormApiInstance"
    [GUID]="ordinanceDetailsGUID"
    (apiInstanceInitialize)="filterAndFetch($event, 'ordinance')"
  ></kpi4me-table-vertical-control>

  <h5>Besatzung</h5>
  <kpi4me-table-vertical-control
    #crewForm
    [showImports]="showImports"
    [hasImports]="false"
    [(apiInstance)]="crewFormApiInstance"
    [GUID]="crewDetailsGUID"
    (apiInstanceInitialize)="filterAndFetch($event, 'crew')"
  ></kpi4me-table-vertical-control>
</div>
