import {Observable} from 'rxjs';
import {ApiConfigService} from '../api-config.service';
import {HttpHeaders} from '@angular/common/http';

export class User {
  http;
  url;
  httpOptions;
  constructor(protected appConfig: ApiConfigService) {
    this.http = appConfig.http;
    this.url = appConfig.url;
    this.httpOptions = appConfig.httpOptions;
  }


  Login() : Observable<boolean> {
    return this.http.get(
      this.url + '/intern/api/user/Login/', this.httpOptions
    ) as unknown as Observable<boolean>;
  }

  Logout() : Observable<boolean> {
    return this.http.get(
      this.url + '/intern/api/user/Logout/', this.httpOptions
    ) as unknown as Observable<boolean>;
  }

  SessionIsValid() : Observable<boolean> {
    return this.http.get(
      this.url + '/intern/api/user/SessionIsValid/', this.httpOptions
    ) as unknown as Observable<boolean>;
  }

  GetLoginData() : Observable<any> {
    return this.http.get(
      this.url + '/intern/api/user/GetLoginData/', this.httpOptions
    ) as unknown as Observable<any>;
  }

  GetTenansts() : Observable<any[]> {
    return this.http.get(
      this.url + '/intern/api/user/GetTenansts/', this.httpOptions
    ) as unknown as Observable<any[]>;
  }

  SetTenant(TenantGuid: string) : Observable<boolean> {
    return this.http.get(
      this.url + '/intern/api/user/SetTenant/' + encodeURI(TenantGuid) + '/', this.httpOptions
    ) as unknown as Observable<boolean>;
  }

  SetCurrentTenantAsDefault() : Observable<boolean> {
    return this.http.get(
      this.url + '/intern/api/user/SetCurrentTenantAsDefault/', this.httpOptions
    ) as unknown as Observable<boolean>;
  }

  GetTenantInfos() : Observable<any> {
    return this.http.get(
      this.url + '/intern/api/user/GetTenantInfos/', this.httpOptions
    ) as unknown as Observable<any>;
  }

  RedirectToChangePassword(origin: string, path: string) : Observable<string> {
    return this.http.post(
      this.url + '/intern/api/user/RedirectToChangePassword/',
      {_parameters: [origin, path]}, this.httpOptions
    ) as unknown as Observable<string>;
  }

  ChangePassword(OldPW: string, NewPW: string) : Observable<boolean> {
    return this.http.post(
      this.url + '/intern/api/user/ChangePassword/',
      {_parameters: [OldPW, NewPW]}, this.httpOptions
    ) as unknown as Observable<boolean>;
  }

  RedirectToChangeProfile(origin: string, path: string) : Observable<string> {
    return this.http.post(
      this.url + '/intern/api/user/RedirectToChangeProfile/',
      {_parameters: [origin, path]}, this.httpOptions
    ) as unknown as Observable<string>;
  }

  RedirectToChangeMail(origin: string, path: string) : Observable<string> {
    return this.http.post(
      this.url + '/intern/api/user/RedirectToChangeMail/',
      {_parameters: [origin, path]}, this.httpOptions
    ) as unknown as Observable<string>;
  }

}
