import {ChangeDetectorRef, Component, Input, OnInit, ViewChild, ViewEncapsulation} from '@angular/core';
import {MatMenu, MatMenuTrigger} from "@angular/material/menu";
import {CoreService, EsvgFiles} from 'frontier/nucleus';
import {ConfirmationDialogComponent} from "../../dialogs/basics/confirmation-dialog/confirmation-dialog.component";
import {MatDialog} from "@angular/material/dialog";
import {FeedbackService} from "../../user-feedback/feedback.service";

export interface ITenant {
  guid: string;
  name: string;
}

export interface IModuleFilter {
  name: string,
  attributes: string[],
}

@Component({
  selector: 'kpi4me-tenant-menu',
  templateUrl: './tenant-menu.component.html',
  styleUrls: ['./tenant-menu.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class TenantMenuComponent implements OnInit {
  @ViewChild(MatMenu, {static: true}) menuRef: MatMenu;
  @ViewChild(MatMenuTrigger, {static: true}) matMenuTrigger: MatMenuTrigger;

  @Input() moduleFilters: IModuleFilter[] = [];

  tenants: ITenant[] = [];
  defaultTenant = '';
  currentTenant = '';
  protected readonly EsvgFiles = EsvgFiles;

  constructor(
    private coreService: CoreService,
    private dialog: MatDialog,
    private feedBackService: FeedbackService,
    protected cdr: ChangeDetectorRef,
  ) {
  }

  ngOnInit() {
    return;
  }

  public updateAndOpen(): void {
    this.coreService.User.GetTenantInfos().subscribe((res: {
      default: string,
      current: string,
      tenants: ITenant[]
    }) => {
      this.defaultTenant = res.default;
      this.currentTenant = res.current;
      this.tenants = res.tenants;
      this.cdr.detectChanges();
    });
  }

  changeTenant(tenant: ITenant) {
    if (tenant.guid !== this.currentTenant) {
      console.log('Updating tenant: ', tenant);
      this.dialog.open(
        ConfirmationDialogComponent,
        {
          data: {
            title: 'Wollen Sie den Mandanten wirklich wechseln?',
            description: 'Die Anwendung wird daraufhin neu geladen. Wir empfehlen andere Tabs vorher zu schließen.',
            confirmButtonText: 'Ja',
            cancelButtonText: 'Nein',
          }
        }
      ).afterClosed().subscribe((res: any) => {
        if (res) {
          this.moduleFilters.forEach((filter: IModuleFilter) => {
            const loadedFilter: any = JSON.parse(localStorage.getItem(filter.name));
            filter.attributes.forEach((attribute: string) => {
              if (Object.prototype.hasOwnProperty.call(loadedFilter, attribute)) {
                delete loadedFilter[attribute];
              }
            });
            localStorage.setItem(filter.name, JSON.stringify(loadedFilter));
          });

          this.coreService.User.SetTenant(tenant.guid).subscribe((res) => {
            if (res) {
              window.location.reload();
            }
          });
        }
      });
    }
  }

  changeDefaultTenant() {
    this.coreService.User.SetCurrentTenantAsDefault().subscribe((res) => {
      if (res) {
        this.feedBackService.setNotification('Dieser Mandant wird nach erneutem Sitzungsbegin geöffnet.')
      }
    });
  }
}
