export enum ECalculusStateFilter {
  kpiConnectError,
  kpiConnectWarning,
  kpiConnectOk,
  kpiConnect,
  ETLnMappingError,
  ETLnMappingWarning,
  ETLnMappingOk,
  ETLnMapping,
  checkError,
  checkWarning,
  checkOk,
  check,
  createInvoiceError,
  createInvoiceWarning,
  createInvoiceOk,
  createInvoice,
  arrangeInvoiceError,
  arrangeInvoiceWarning,
  arrangeInvoiceOk,
  arrangeInvoice,
  monitorPaymentError,
  monitorPaymentWarning,
  monitorPaymentOk,
  monitorPayment,
  totalError,
  totalWarning,
  totalOk,
  total,
  onHold,
  clientAccessMessages,
}
