import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {UserFormComponent} from './user-form/user-form.component';
import {SubscriberFormComponent} from './subscriber-form/subscriber-form.component';
import {MainPageComponent} from './main-page/main-page.component';
import {ShortDescriptionComponent} from './short-description/short-description.component';
import {DetailedDescriptionComponent} from './detailed-description/detailed-description.component';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {AlarmComponent} from './alarm/alarm.component';
import {MatInputModule} from '@angular/material/input';
import {MatFormFieldModule} from '@angular/material/form-field';
import {CustomMaterialModule, SvgService} from 'frontier/nucleus';

@NgModule({
  declarations: [
    UserFormComponent,
    SubscriberFormComponent,
    MainPageComponent,
    ShortDescriptionComponent,
    DetailedDescriptionComponent,
    AlarmComponent,
  ],
  imports: [
    CommonModule,
    CustomMaterialModule,
    // NgImageSliderModule,
    ReactiveFormsModule,
    FormsModule,
    MatFormFieldModule,
    MatInputModule,
  ],
  exports: [UserFormComponent, MainPageComponent],
  providers: [SvgService],
})
export class SnzHpModule {
}
