import {ChangeDetectorRef, Component, Inject, ViewChild} from '@angular/core';
import {
  CalendarService,
  ControlManagerService,
  DEFAULT_TABLE_ACTIONS,
  GoldenLayoutContentComponent,
  IAction,
  SharedGridOptions,
  TableControlComponent
} from 'frontier/browserkit';
import {CoreService, EcalculusControlGUID, IApiControl} from 'frontier/nucleus';
import {GridOptions} from 'ag-grid-community';
import {combineLatest, debounceTime, Subject, switchMap} from 'rxjs';
import {GoldenLayoutComponent, GoldenLayoutComponentHost, GoldenLayoutContainer} from '@kpi4me/golden-angular-layout';
import GoldenLayout from '@kpi4me/golden-layout';
import {MatSnackBar} from '@angular/material/snack-bar';
import {filter} from 'rxjs/operators';
import {TemplatesControl} from './templates-control/templates-control.component';

@Component({
  selector: 'app-template-editor',
  templateUrl: './template-editor.component.html',
  styleUrl: './template-editor.component.scss'
})
export class TemplateEditorComponent extends GoldenLayoutContentComponent {
  @ViewChild(TemplatesControl) controlRef: TemplatesControl;

  constructor(
    @Inject(GoldenLayoutComponentHost) protected goldenLayout: GoldenLayoutComponent,
    @Inject(GoldenLayoutContainer) protected container: GoldenLayout.Container,
    protected cdr: ChangeDetectorRef,
    protected controlManager: ControlManagerService,
    protected coreService: CoreService,
    public snackbar: MatSnackBar,
    private calendarService: CalendarService,
  ) {
    super(goldenLayout, container, cdr, controlManager);

  }

  protected onResize(): void {
  }

}
