import {Component} from '@angular/core';
import {CustomCellEditorComponent} from '../custom-cell-editor/custom-cell-editor.component';
import {ICustomColDef} from '../../interfaces/custom-col-def.interface';
import {ICellEditorParams} from 'ag-grid-community';
import {FormControl} from "@angular/forms";

// TODO: Not adapted to standard form elements.
@Component({
  selector: 'app-percentage-cell-editor',
  templateUrl: './percentage-cell-editor.component.html',
  styleUrls: ['../custom-cell-editor/custom-cell-editor.component.scss'],
})
export class PercentageCellEditorComponent extends CustomCellEditorComponent {
  editorValue: string;

  agInit(params: ICellEditorParams): void {
    this.params = params;
    this.columnApi = params.context.colApi;
    console.log(params);
    if (params.charPress) {
      this.handlePaste(params);
    }
    this.editorValue = String(
      (params.value.value * 100 + Number.EPSILON).toFixed(2)
    ).replace('.', ',');
    this.oldValue = this.editorValue;
    this.textFormControl = new FormControl(this.editorValue);
  }

  getValue(): any {
    return this.params.value;
  }

  onKeyDown(evt: KeyboardEvent) {
    super.onKeyDown(evt);
    const decimals = this.editorValue.split(',');
  }

  protected saveValue() {
    // prevent change line if the value did not change
    const colDef: ICustomColDef = this.params.colDef;
    this.editorValue = this.editorValue.replace(',', '.');
    this.params.value.value = Number(this.editorValue) / 100;
    this.changeLine(
      this.params.data.apiRow.obj,
      this.params.data.apiRow.rowidx,
      colDef.attribute,
      colDef.attributeindex,
      this.params.value.value
    ).subscribe(() => {
      console.log('saved Value');
    });
  }
}
