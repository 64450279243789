import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {IGoldenLayoutDynamicComponent} from '../golden-layout-dynamic-components.interface';
import {EsvgFiles} from 'frontier/nucleus';

@Component({
  selector: 'app-drag-button',
  templateUrl: './drag-button.component.html',
  styleUrls: ['./drag-button.component.scss'],
})
export class DragButtonComponent implements OnInit {
  @Input() data: IGoldenLayoutDynamicComponent;
  @Input() instanstiated: boolean;

  @Output() closeInstances = new EventEmitter();
  @Output() click = new EventEmitter();

  constructor() {
  }

  ngOnInit(): void {
  }

  onCloseInstances(evt: MouseEvent) {
    this.closeInstances.emit();
    evt.stopPropagation();
    evt.stopImmediatePropagation();
  }

  onClick() {
    this.click.emit();
  }

  protected readonly EsvgFiles = EsvgFiles;
}
