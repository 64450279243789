import {
  ChangeDetectorRef,
  Component,
  Input,
  OnChanges,
  OnDestroy,
  SimpleChanges,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import {
  changes,
  CoreService,
  EcalculusControlGUID,
  EControlActions,
  EsvgFiles,
  IApiControl,
  IControlObject
} from 'frontier/nucleus';
import {
  ControlManagerService,
  DialogService,
  IAction,
  IApiRow,
  ReasoningDialogComponent,
  SharedGridOptions,
  TableControlComponent,
} from 'frontier/browserkit';
import {GridOptions} from "ag-grid-community";
import {concatMap, filter, finalize, tap} from 'rxjs/operators';
import {Observable, of, Subscription} from 'rxjs';
import {CalculusStateService} from '../../Services/calculus-state.service';
import {IProcessOverviewState} from '../config/category-state-selection.interface';
import {ECalculusStateFilter} from '../config/state-filter.enum';

type TInvoiceDetailTable = 'invoice' | 'dunningInfo' | 'cartItems';

@Component({
  selector: 'kpi4me-invoice-detail',
  templateUrl: './invoice-detail.component.html',
  styleUrls: ['./invoice-detail.component.scss'],
  encapsulation: ViewEncapsulation.Emulated
})
export class InvoiceDetailComponent implements OnChanges, OnDestroy {
  @Input() parent: IControlObject;
  invoiceDetailsTableGUID = EcalculusControlGUID.InvoiceList;
  dunningInfoTableGUID = EcalculusControlGUID.DunningInfo;
  cartItemsTableGUID = EcalculusControlGUID.CartItemsList;

  @ViewChild('invoiceTable') invoiceTableRef: TableControlComponent;
  @ViewChild('dunningInfoTable') dunningInfoTableRef: TableControlComponent;
  @ViewChild('cartItemsTable') cartItemsTableRef: TableControlComponent;
  invoiceTableApiInstance: IApiControl;
  dunningInfoTableApiInstance: IApiControl;
  cartItemsTableApiInstance: IApiControl;
  invoiceTableActions: IAction[] = [
    {
      displayName: 'Rechnung stornieren',
      controlAction: EControlActions.cancel,
      disabledIfNoSelection: true,
      disabledIfMultiSelection: false,
      color: 'warn',
      icon: EsvgFiles.close,
      action: () => this.cancelAndCreate(),
      isHidden: () => {
        return this.activeCategoryStateSelection?.stateSelection?.states.includes(ECalculusStateFilter.monitorPaymentOk);
      }
    },
    {
      displayName: 'Gutschrift',
      controlAction: EControlActions.credit,
      disabledIfNoSelection: true,
      disabledIfMultiSelection: false,
      color: 'warn',
      icon: EsvgFiles.close,
      action: () => this.canceleAndNewAfterPayed(),
      isHidden: () => {
        return !this.activeCategoryStateSelection?.stateSelection?.states.includes(ECalculusStateFilter.monitorPaymentOk);
      }
    },
    {
      displayName: 'Uneinbringlich',
      controlAction: EControlActions.uncollectible,
      disabledIfNoSelection: true,
      disabledIfMultiSelection: false,
      color: 'warn',
      icon: EsvgFiles.block,
      action: () => this.setUncollectible(),
      isHidden: () => {
        return this.activeCategoryStateSelection?.stateSelection?.states.includes(ECalculusStateFilter.monitorPaymentOk);
      }
    },
    {
      displayName: 'Bezahlt',
      controlAction: EControlActions.payed,
      disabledIfNoSelection: true,
      disabledIfMultiSelection: false,
      color: 'accent',
      icon: EsvgFiles.money,
      action: () => this.setPayed(),
      isHidden: () => {
        return this.activeCategoryStateSelection?.stateSelection?.states.includes(ECalculusStateFilter.monitorPaymentOk);
      }
    },
  ];
  dunningInfoTableActions: IAction[] = [
    {
      displayName: 'Mahnung herunterladen',
      controlAction: EControlActions.import,
      disabledIfNoSelection: true,
      disabledIfMultiSelection: true,
      color: 'accent',
      icon: EsvgFiles.download,
      action: () => this.downloadDunningDocument(),
    }
  ];
  cartItemsTableActions: IAction[] = [
    {
      displayName: 'Teilstorno',
      controlAction: EControlActions.partlyCancel,
      disabledIfNoSelection: true,
      disabledIfMultiSelection: false,
      color: 'warn',
      icon: EsvgFiles.close,
      action: () => this.partlyCancel(),
    }
  ];
  invoiceGridOptions: GridOptions = {
    ...SharedGridOptions,
  };
  dunningInfoGridOptions: GridOptions = {
    ...SharedGridOptions,
  };
  cartItemsGridOptions: GridOptions = {
    ...SharedGridOptions,
  };
  private subs = new Subscription();
  private activeCategoryStateSelection: IProcessOverviewState;
  isDunningInfoHidden = true;

  constructor(
    protected cdr: ChangeDetectorRef,
    protected controlManager: ControlManagerService,
    protected coreService: CoreService,
    private dialogService: DialogService,
    private calculusStateService: CalculusStateService
  ) {
    this.subs.add(
      this.coreService.controlDataChanged.pipe(
        filter(evt => {
          return new Map([
            [EcalculusControlGUID.AllClientList, new Set([EControlActions.attendance])],
            [EcalculusControlGUID.CartList, new Set([EControlActions.invoice])],
            [EcalculusControlGUID.CartItemsList, new Set([...changes, EControlActions.partlyCancel])],
            [EcalculusControlGUID.InvoiceList,
              new Set([
                EControlActions.cancel,
                EControlActions.uncollectible,
                EControlActions.payed,
                EControlActions.credit
              ])],
          ]).get(evt.GUID as EcalculusControlGUID)?.has(evt.changeType);
        }),
        tap((evt) => {
          if (evt.changeType === EControlActions.cancel || evt.changeType === EControlActions.credit) {
            this.invoiceTableRef.changeAndFetch().subscribe()
          } else {
            this.invoiceTableRef.refreshInfiniteCache()
          }
        })).subscribe()
    );

    this.subs.add(
      this.coreService.controlDataChanged.pipe(
        filter(evt => {
          return new Map([
            [EcalculusControlGUID.CartList, new Set([EControlActions.dunning])],
            [EcalculusControlGUID.OpenItemList, new Set([EControlActions.dunning])],
          ]).get(evt.GUID as EcalculusControlGUID)?.has(evt.changeType);
        }),
        tap(() => {
          // The open items table should change and fetch if an invoice was canceled.
          this.dunningInfoTableRef.refreshInfiniteCache();
        })).subscribe(),
    );

    this.subs.add(
      this.coreService.controlDataChanged.pipe(
        filter(evt => {
          return new Map([
            [EcalculusControlGUID.InvoiceList, changes],
            [EcalculusControlGUID.CartItemsList, new Set([...changes, EControlActions.partlyCancel])],
          ]).get(evt.GUID as EcalculusControlGUID)?.has(evt.changeType);
        }),
        tap((evt) => {
          if (evt.changeType === EControlActions.changeLine || evt.changeType === EControlActions.create) {
            if (evt.GUID === EcalculusControlGUID.InvoiceList) {
              this.cartItemsTableRef.changeAndFetch().subscribe();
            }
          } else {
            this.cartItemsTableRef.refreshInfiniteCache();
          }
        })).subscribe(),
    );

    this.subs.add(this.calculusStateService.processOverviewState$.subscribe(res => {
      this.activeCategoryStateSelection = res;
    }));
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.parent && this.invoiceTableRef) {
      this.filterAndFetch(this.invoiceTableApiInstance, 'invoice');
    }
    if (changes.parent && this.dunningInfoTableRef) {
      this.filterAndFetch(this.dunningInfoTableApiInstance, 'dunningInfo');
    }
    if (changes.parent && this.cartItemsTableRef) {
      this.filterAndFetch(this.cartItemsTableApiInstance, 'cartItems');
    }
  }

  ngOnDestroy() {
    this.subs.unsubscribe();
  }

  filterAndFetch(apiInstance: IApiControl, reference: TInvoiceDetailTable): void {
    if (this.parent) {
      switch (reference) {
        case 'invoice':
          this.invoiceTableRef.setApiInstanceAndFetch({
            ...apiInstance,
            filter: {
              ...this.invoiceTableApiInstance?.filter,
              parent: this.parent,
              typename: 'cart',
            },
          }).subscribe();
          break;
        case 'dunningInfo':
          this.dunningInfoTableRef.setApiInstanceAndFetch({
              ...apiInstance,
              filter: {
                ...this.dunningInfoTableApiInstance?.filter,
                parent: this.parent,
                typename: 'cart',
              },
            }
          ).subscribe((response) => {
            this.isDunningInfoHidden = !(response.rowcount > 0)
          });
          break;
        case 'cartItems':
          this.cartItemsTableRef.setApiInstanceAndFetch({
            ...apiInstance,
            filter: {
              ...this.cartItemsTableApiInstance?.filter,
              parent: this.parent,
              typename: 'cart',
            },
          }).subscribe();
          break;
      }
    }
  }

  onCustomInvoiceAction(evt: any) {
    switch (evt) {
    }
  }

  private cancelAndCreate(): Observable<boolean> {
    return this.coreService.Invoicelist.canceleandnew(
      this.invoiceTableRef.apiInstance.instanceid,
      this.invoiceTableRef.selectedRows.map((row) => {
        return row.apiRow.obj;
      }),
    ).pipe(finalize(() => this.invoiceTableRef?.gridApi?.hideOverlay()));
  }

  private setUncollectible(): Observable<boolean | null> {
    return this.dialogService.dialog.open(ReasoningDialogComponent, {
      data: {
        title: 'Begründung'
      }
    }).afterClosed().pipe(
      concatMap((reason: string): Observable<boolean> => {
          if (reason) {
            return this.coreService.Invoicelist.uncollectible(
              this.invoiceTableRef.apiInstance.instanceid,
              this.invoiceTableRef.selectedRows.map((row) => {
                  return row.apiRow.obj;
                }
              ),
              reason,
            )
          }
          return of(null);
        }
      ),
      finalize(
        () => this.invoiceTableRef.gridApi.hideOverlay()
      )
    )
  }

  private setPayed(): Observable<boolean> {
    return this.coreService.Invoicelist.payed(
      this.invoiceTableRef.apiInstance.instanceid,
      this.invoiceTableRef.selectedRows.map((row) => {
        return row.apiRow.obj;
      }),
    ).pipe(finalize(() => this.invoiceTableRef.gridApi.hideOverlay()));
  }

  private partlyCancel(): Observable<boolean> {
    return this.coreService.Cartitemlist.cancleitem(
      this.cartItemsTableRef.apiInstance.instanceid,
      this.cartItemsTableRef.selectedRows.map((row) => {
        return row.apiRow.obj;
      }),
    ).pipe(finalize(() => this.cartItemsTableRef.gridApi.hideOverlay()));
  }

  private downloadDunningDocument(): Observable<any> {
    const selectedRowObj: IApiRow = this.dunningInfoTableRef.selectedRows[0].apiRow;
    const cell = selectedRowObj.cols[selectedRowObj.cols.length - 1];
    const documentType = cell.value.type;
    return this.coreService.Control.DownloadDocument(
      this.dunningInfoTableApiInstance.instanceid,
      cell.value.rowid.toString(),
      documentType,
      cell.value.signature,
    ).pipe(
      tap((data) => {
        if(data) {
          const downloadURL = window.URL.createObjectURL(data);
          const link = document.createElement('a');
          link.href = downloadURL;
          link.download = selectedRowObj.obj.name || this.dunningInfoTableRef.selectedRows[0][0].value;
          link.click();
        }
      })
    )
  }

  private canceleAndNewAfterPayed() {
    return this.coreService.Invoicelist.canceleandnewafterpayed(
      this.invoiceTableRef.apiInstance.instanceid,
      this.invoiceTableRef.selectedRows.map((row) => {
        return row.apiRow.obj;
      }),
    ).pipe(finalize(() => this.invoiceTableRef?.gridApi?.hideOverlay()));
  }
}
