import {IApiTableData} from '../../table-control/interfaces/api-table-data.interface';
import {IAgGridData} from '../../table-control/interfaces/ag-grid-data.interface';
import {ColDef, ICellEditorParams} from 'ag-grid-community';
import {EditableCallbackParams} from 'ag-grid-community/dist/lib/entities/colDef';
import {DisplayedColumns} from '../../table-control/interfaces/displayed-columns.interface';
import {TColType} from '../../table-control/cell.enum';
import {ICustomColDef} from '../../table-control/interfaces/custom-col-def.interface';
import {createGridRow} from './create-grid-row.function';
import {IDynamicComponent} from '../../table-control/editors/form-cell-ceditor/dynamic-component.interface';
import {ApiAdapter} from 'frontier/nucleus';
import {IApiRow} from '../../table-control/api-row.interface';
import {ICustomGridRow} from '../../table-control/interfaces/custom-grid-row.interface';

export class TableApiAdapter extends ApiAdapter {
  private _dynamicComponents: Record<string, IDynamicComponent>;
  private _colTypeColDefMap: Record<number, ICustomColDef>;

  constructor(
    dynamicComponents: Record<string, IDynamicComponent>,
    colTypeColDefMap: Record<number, ICustomColDef>
  ) {
    super();
    this._dynamicComponents = dynamicComponents;
    this._colTypeColDefMap = colTypeColDefMap;
  }

  /**
   * Gets the table data from the api. It returns the data in the form for the ag grid.
   * It just maps the grid rows and column definitions to some extended format.
   * @param apiData: The source from the api.
   */
  from(apiData: IApiTableData): IAgGridData {
    // console.log(apiData);
    return {
      displayedColumns: apiData.displayedColumns,
      columnDefs: this.createGridCols(apiData.displayedColumns),
      name: null,
      objecttype: apiData.objecttype,
      entityName: null,
      parentobj: apiData.parentobj,
      rowcount: apiData.rowcount
    };
  }

  to(data: any): any {
  }

  createGridRows(dataSource: IApiRow[]): ICustomGridRow[] {
    return dataSource.map((r) => {
      return createGridRow(r);
    });
  }

  /**
   * Maps the api columns to the grid columnDefs
   * @param cols: Columns definitions from the api
   */
  createGridCols(cols: DisplayedColumns[]): ColDef[] {
    return cols.map((c) => {
      const colDef = this._colTypeColDefMap[c.type as TColType]
        ? this._colTypeColDefMap[c.type as TColType]
        : this._colTypeColDefMap[-1];
      let col: ICustomColDef =
        c.forms && c.forms.length > 0
          ? {
            cellEditorParams: (params: any) => {
              return {
                ...params,
                dynamicComponents: this._dynamicComponents,
              };
            },
            cellEditorSelector: (params: any) => {
              {
                return {component: 'formCellEditor'};
              }
            },
          }
          : {
            cellEditorParams: colDef.cellEditorParams,
          };
      col = {
        ...col,
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        cellRenderer: colDef.cellRenderer,
        cellEditor: colDef.cellEditor,
        filter: colDef.filter,
        cellRendererParams: colDef.cellRendererParams,
        // filter: colDef.filter,
        comparator: colDef.comparator,
        ...c,
        type: undefined, // used internally by ag grid => define colType attribute
        colType: c.type,
        cellStyle: cellClassParams => {
          if (cellClassParams.data && cellClassParams.data[Number(cellClassParams.colDef.field)]
            && cellClassParams.data[Number(cellClassParams.colDef.field)].grouplength) {
            return {
              'background-color': 'var(--background)',
              'border-bottom': '3px solid var(--color-surface-100)',
            }
          } else if (cellClassParams.data && cellClassParams.data[Number(cellClassParams.colDef.field)]
            && cellClassParams.data[Number(cellClassParams.colDef.field)].editable === false) {
            return {
              //'background-color': 'var(--background)'
            }
          } else {
            return {};
          }
        },
        editable: (params: EditableCallbackParams) => {
          return (
            params.data[Number(params.colDef.field)].editable &&
            colDef.hasEditor != false
          );
        },
        sortable: true,
        apiCol: c,
      };
      return col;
    });
  }

  protected getFormComponentGuids(params: ICellEditorParams) {
    return {...params, dynamicComponents: this._dynamicComponents};
  }
}
