import {ChangeDetectorRef, Component, Inject, ViewChild} from '@angular/core';
import {
  CoreService,
  EcalculusControlGUID,
  EControlActions,
  EsvgFiles,
  IApiControl,
  IControlObject,
} from "frontier/nucleus";
import {
  ControlManagerService,
  DEFAULT_TABLE_ACTIONS,
  GoldenLayoutContentComponent,
  IAction,
  IApiRow,
  SharedGridOptions,
  TableControlComponent,
} from "frontier/browserkit";
import {GridOptions, RowSelectedEvent} from "ag-grid-community";
import {GoldenLayoutComponent, GoldenLayoutComponentHost, GoldenLayoutContainer} from "@kpi4me/golden-angular-layout";
import GoldenLayout from "@kpi4me/golden-layout";
import {Observable, ReplaySubject} from "rxjs";
import {filter, finalize, tap} from "rxjs/operators";
import {MatSnackBar} from "@angular/material/snack-bar";

@Component({
  selector: 'app-dunning-config',
  templateUrl: './dunning-config.component.html',
  styleUrl: './dunning-config.component.scss'
})
export class DunningConfigComponent extends GoldenLayoutContentComponent {
  @ViewChild('config') configRef: TableControlComponent;
  configTableApiInstance: IApiControl;
  configGuid = EcalculusControlGUID.DunningConfigView;
  configGridOptions: GridOptions = {
    ...SharedGridOptions,
    rowSelection: 'single',
  };
  configTableActions: IAction[] = [
    ...DEFAULT_TABLE_ACTIONS,
    {
      displayName: 'Mahnprüflauf durchführen',
      controlAction: EControlActions.dunning,
      disabledIfNoSelection: false,
      disabledIfMultiSelection: false,
      color: 'accent',
      icon: EsvgFiles.search,
      action: () => this.startDunningCheck(),
    },
  ];

  @ViewChild('level') levelRef: TableControlComponent;
  levelTableApiInstance: IApiControl;
  levelGuid = EcalculusControlGUID.DunningLevelView;
  levelGridOptions: GridOptions = {
    ...SharedGridOptions,
  };

  _dunningConfigSelectedSource = new ReplaySubject<IControlObject>(1);

  protected readonly EControlActions = EControlActions;

  constructor(
    @Inject(GoldenLayoutComponentHost) protected goldenLayout: GoldenLayoutComponent,
    @Inject(GoldenLayoutContainer) protected container: GoldenLayout.Container,
    protected cdr: ChangeDetectorRef,
    protected controlManager: ControlManagerService,
    protected coreService: CoreService,
    public snackbar: MatSnackBar,
  ) {
    super(goldenLayout, container, cdr, controlManager);

    this.subs.add(
      this.coreService.controlDataChanged.pipe(
        filter(evt => {
          return new Map([
            [EcalculusControlGUID.DunningConfigView, new Set([EControlActions.changeLine, EControlActions.create])],
          ]).get(evt.GUID as EcalculusControlGUID)?.has(evt.changeType);
        }),
        tap(() => this.levelRef.changeAndFetch().subscribe())).subscribe(),
    );


    this.subs.add(
      this._dunningConfigSelectedSource.pipe(
        tap((obj: IControlObject) => {
          this.levelRef.setApiInstanceAndFetch({
            ...this.levelTableApiInstance,
            filter: {
              ...this.levelTableApiInstance.filter,
              parent: obj,
            },
          }).subscribe();
        }),
      ).subscribe(),
    );
  }

  protected onResize(): void {
  }

  filterDunningLevels(evt: RowSelectedEvent): void {
    this._dunningConfigSelectedSource.next((evt.data.apiRow as IApiRow).obj);
  }

  unfilterDunningLevels(): void {
    // Detects if the row was deselected by ctrl+left click to prevent triggering by selection of another row
    if(!this.configRef.selectedRows.length) {
      this._dunningConfigSelectedSource.next(null);
    }
  }

  private startDunningCheck(): Observable<boolean> {
    return this.coreService.Dunninglevelview.startdunningcheck(this.configTableApiInstance.instanceid).pipe(
      tap((res) => {
        if (res) {
          this.snackbar.open('Juhu, Mahnprüflauf erfolgreich durchgeführt.', 'ok', {
            verticalPosition: 'top',
            duration: 3000,
          });
        }
      }),
      finalize(() => {
        this.configRef.gridApi.hideOverlay();
      }),
    );
  }
}
