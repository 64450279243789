import {Pipe, PipeTransform} from '@angular/core';
import {DomSanitizer} from '@angular/platform-browser';
import {IUploadDocument} from 'frontier/browserkit/src/lib/upload-wrapper/uploaded-document.interface';

@Pipe({
  standalone: true,
  name: 'pdf'
})
export class PdfPipe implements PipeTransform {
  constructor(private sanitizer: DomSanitizer) {
  }

  transform(src: IUploadDocument) {
    if (src == null || src.data == null) return this.sanitizer.bypassSecurityTrustResourceUrl('');
    const url = URL.createObjectURL(new Blob([src.data as ArrayBuffer], {type: 'application/pdf'}));
    return this.sanitizer.bypassSecurityTrustResourceUrl(url);
  }
}
