import {Component, Input, ViewEncapsulation} from '@angular/core';
import {BaseFormElementComponent} from '../base-form-element.class';
import {IFormElement, IRadioElement, IRadioOption} from '../form-data.interface';

@Component({
  selector: 'kpi4me-radio-element',
  templateUrl: './radio-element.component.html',
  styleUrls: ['../form-element.component.scss', './radio-element.component.scss'],
  encapsulation: ViewEncapsulation.Emulated,
})
export class RadioElementComponent extends BaseFormElementComponent {

  @Input() options: IRadioOption[];

  @Input() set data(form: IRadioElement) {
    if (form == null) return;
    this.options = form.options;

    super.data = form as IFormElement;
  }

  getErrorMessage(): string[] {
    const formControl = this.getFormControl();
    const errorMessage: string[] = [];
    if (formControl.hasError('required')) {
      errorMessage.push('Bitte treffen Sie eine Auswahl.');
    }

    return errorMessage;
  }
}
