import {Component} from '@angular/core';
import {MatDialogRef} from '@angular/material/dialog';
import {FormControl, Validators} from '@angular/forms';
import {DateValidator} from 'frontier/nucleus';

@Component({
  selector: 'lib-date-picker-dialog',
  templateUrl: './date-picker-dialog.component.html',
  styleUrl: './date-picker-dialog.component.css'
})
export class DatePickerDialogComponent {
  dateForm: FormControl = new FormControl(null, [Validators.required, DateValidator.isoFormat]);

  constructor(
    public dialogRef: MatDialogRef<any>
  ) {
    this.dateForm.patchValue('');
  }

  confirm() {
    this.dialogRef.close(this.dateForm.value);
  }
}
