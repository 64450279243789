import {EventEmitter, Injectable} from '@angular/core';
import {MultiWindowService} from '@kpi4me/golden-angular-layout';
import {BehaviorSubject, combineLatest} from "rxjs";
import {ControlManagerService} from "../control-manager/control-manager.service";
import {ICalendarChangeEvent} from "../calendar/calendar-event.interface";
import {IView} from "../golden-layout/view.interface"
import {debounceTime, filter, map, tap} from "rxjs/operators";
import {ECalenderType} from '../golden-layout/custom-toolbar-state.interface';
import {FromNgbDate} from 'frontier/browserkit';

@MultiWindowService<CalendarService>('calendar-service')
@Injectable({
  providedIn: 'root'
})
export class CalendarService {
  calendarChange$: EventEmitter<ICalendarChangeEvent>;

  currentView$ = new BehaviorSubject<IView>(null);

  globalForkedEvents$ = new BehaviorSubject<{
    startdate: string;
    enddate: string;
    objectlist: any[];
  }>(null);

  constructor(public controlManager: ControlManagerService) {
    console.log('EVENT SERVICE CONSTRUCTOR');
    this.controlManager.loadView$
      .pipe(tap((v) => this.currentView$.next(v)))
      .subscribe();
    this.initCombinedEvents();
  }

  private initCombinedEvents() {
    this.calendarChange$ = new EventEmitter<ICalendarChangeEvent>();

    combineLatest([this.currentView$])
      .pipe(
        filter(([view1]) => {
          return !(view1 == null || view1.toolbar == null);
        }),
        map(([view1]) => {
          return view1;
        }),
        debounceTime(50),
        map((view) => {
          console.log('Received view: ', view);
          /**
           * Create the filter object needed for the control elements
           */
          const apiFilter: any = {
            objectlist: [],
            dateranges: null,
            // todate: null,
            // fromdate: null,
            granularity: null,
          };
          // If there is a month or year selected in the datepicker add them to the dateranges filter
          if (view.toolbar?.calendarType === ECalenderType.new) {
            if (
              view.toolbar.easyDatePicker.month.length !== 0 &&
              view.toolbar.easyDatePicker.year.length !== 0
            ) {
              apiFilter.dateranges = {
                months: view.toolbar.easyDatePicker.month,
                years: view.toolbar.easyDatePicker.year,
              };
              apiFilter.granularity = view.toolbar.easyDatePicker.granularity;
              apiFilter.startdate = null;
              apiFilter.enddate = null;
            }
          } else {
            // classical calendar
            // Initial setting of the filter for the table instance.
            const calender = view.toolbar.classicalCalendar;
            const outEvt: ICalendarChangeEvent = {
              from: FromNgbDate(calender.fromDate).toFormat('yyyy-MM-dd'),
              to: FromNgbDate(calender.toDate).toFormat('yyyy-MM-dd')
            };
            apiFilter.granularity = view.toolbar.classicalCalendar.granularity;
            apiFilter.startdate = outEvt.from;
            apiFilter.enddate = outEvt.to;
          }

          apiFilter.searchstring = view.toolbar.filter.searchstring;
          // Object.values(view.toolbar.filter)
          //   .filter((val) => val != null)
          //   .forEach((filterArray: any) => {
          //     if (filterArray) {
          //       apiFilter.selectednodelist = [
          //         ...apiFilter.objectlist,
          //         ...filterArray,
          //       ];
          //     }
          //   });
          return apiFilter;
        }),
        debounceTime(200),
        tap((v) => {
          this.globalForkedEvents$.next(v);
        })
      )
      .subscribe();
  }

  resetFullTextFilter() {
    if (!this.currentView$.getValue()?.toolbar?.filter) return;
    this.currentView$.next({
      ...this.currentView$.getValue(),
      toolbar: {
        ...this.currentView$.getValue().toolbar,
        filter: {
          ...this.currentView$.getValue().toolbar.filter,
          searchstring: ''
        }
      }
    })
  }
}
