import {Component, Input} from '@angular/core';
import {EsvgFiles} from 'frontier/nucleus';

@Component({
  selector: 'app-cell-error-icon',
  templateUrl: './cell-error-icon.component.html',
  styleUrls: ['./cell-error-icon.component.scss'],
})
export class CellErrorIconComponent {
  @Input() params: any;
  protected readonly EsvgFiles = EsvgFiles;
}
