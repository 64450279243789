<div class="mainWrapper">
  <kpi4me-toolbar-shared *ngIf="view$ | async as view"
    (logout)="logout()"
    [helpUrl]="'https://kpi4me.odoo.com/knowledge/article/52'"
    [moduleFilters]="moduleFilters"
    [productName]="productName"
    [productLogoPath]="productLogoPath"
    [version]="view.versionNumber?.toString()"
    [showTenantMenu]="showTenantMenu"
    [companyLogoPath]="logoPath"
    [customToolbarActions]="customToolbarActions"
    [menuOptions]="menuOptions"
    [config]="view.toolbarConfig"
    (configChange)="toolbarConfigChanged($event)"
    (optionTriggered)="optionTriggered.emit($event)"
    (themeToggle)="themeService.toggleTheme()"
    (applyTheme)="themeService.applyTheme($event)"
    (customToolbarActionClicked)="customToolbarActionClicked.emit($event)"
  >
    <!--    ToDo: Add a filter component that is more flexible than the one used in plan4me adapt the outcommented -->
    <!--    Todo: implementation of app-custom-toolbar-->

    <!--    <app-custom-toolbar-->
    <!--      *ngIf="filtersActive"-->
    <!--      (configChange)="toolbarConfigChanged($event)"-->
    <!--      [config]="(eventService.currentView$ | async)?.toolbar"-->
    <!--      class="toolBar"-->
    <!--    ></app-custom-toolbar>-->
  </kpi4me-toolbar-shared>

  <mat-drawer-container
    autosize
    id="nav-wrapper"
  >
    <mat-drawer
      #drawer
      [opened]="true"
      mode="side"
    >
      <div
        style="height: 100%; justify-content: space-between; display: flex; flex-direction: column"
      >
        <app-control-manager
          [state]="mainMenuState"
          [states]="states"
          [categories]="components"
          [detailComponents]="detailComponents"
          (resetLayout)="onResetLayout()"
          [goldenLayoutInstance]="controlManager.goldenLayouts.root"
          (stateChanged)="onMainMenuOpenedChange()"
        ></app-control-manager>
      </div>
    </mat-drawer>

    <mat-drawer-content
      style="display: flex; flex-direction: column;"
      (resized)="onResize()"
    >
      <div class="route">
        <app-layout-root-host
          *ngIf="isCurrentViewGoldenLayout && controlManager?.defaultView?.goldenLayoutStates?.root"
        ></app-layout-root-host>

        <router-outlet
          *ngIf="!isCurrentViewGoldenLayout"
        ></router-outlet>
      </div>
    </mat-drawer-content>
  </mat-drawer-container>
</div>
