<div class="table-toggler-wrapper" *ngIf="data">
  <mat-label>
    <mat-icon [svgIcon]="data.leftIcon"
    ></mat-icon>
  </mat-label>

  <mat-slide-toggle [formControl]="isOn"></mat-slide-toggle>

  <mat-label class="mat-slide-toggle-content">
    <mat-icon class="car-icon" [svgIcon]="data.rightIcon"
    ></mat-icon>
  </mat-label>
</div>
