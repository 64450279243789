<div
  style="visibility: hidden;"
  [matMenuTriggerFor]="tenantMenu"
></div>

<mat-menu
  #tenantMenu="matMenu"
  xPosition="before"
  class="customized-menu tenant-menu"
>
  <div
    mat-menu-item
    *ngFor="let tenant of tenants"
    (click)="changeTenant(tenant)"
    [class.default-tenant]="tenant.guid === defaultTenant"
    [class.current-tenant]="tenant.guid === currentTenant"
    style="display: flex; flex-direction: row; justify-content: space-between;"
  >
    <span style="display: flex; align-items: center">
      <span>{{tenant.name}}</span>
      <button
        mat-icon-button
        (click)="changeDefaultTenant()"
        *ngIf="tenant.guid === currentTenant"
      >
        <mat-icon
          [svgIcon]="EsvgFiles.bookmark"
          matTooltip="Als Standard-Mandant festlegen"
          style="margin-right: unset"
          color="accent"
        ></mat-icon>
      </button>
    </span>


  </div>
</mat-menu>
