<mat-form-field
  appearance="outline"
  class="form-field-no-padding"
  (focusin)="onFocusIn()"
>
  <mat-icon
    class="left-arrow-icon"
    style="margin-right: 0.5rem"
    *ngIf="arrowPosition  !== 'right'"
    matPrefix
    [svgIcon]="EsvgFiles.arrow_down"
  ></mat-icon>

  <mat-label *ngIf="label">{{label}}</mat-label>

  <input
    #inputTrigger
    matInput
    type="treeselect"
    [formControl]="formControlElement"
    readonly
    cdkOverlayOrigin
    #trigger=cdkOverlayOrigin
  >
  <mat-icon
    class="left-arrow-icon"
    *ngIf="arrowPosition === 'right'"
    matSuffix [svgIcon]="EsvgFiles.arrow_down"
  ></mat-icon>
</mat-form-field>

<ng-template
  cdkConnectedOverlay
  [cdkConnectedOverlayOrigin]="trigger"
  [cdkConnectedOverlayOpen]="isOpen"
  [cdkConnectedOverlayLockPosition]="false"
  [cdkConnectedOverlayHasBackdrop]="true"
  [cdkConnectedOverlayBackdropClass]="'cdk-overlay-transparent-backdrop'"
  (backdropClick)="toggleDropdown()"
>
  <div
    #treeselect
    cdkTrapFocus
    cdkTrapFocusAutoCapture
    class="panel-wrapper mat-select-panel"
    style="display: flex; flex-direction: column; justify-content: space-between; align-items: center"
  >
    <kpi4me-search-input tabIndex="0" (ngModelChange)="onSearchChange($event)">

    </kpi4me-search-input>

    <tree-root
      (nodeActivate)="onNodeActivate($event)"
      #tree
      (initialized)="onTreeInit()"
      [nodes]="nodes"
      [options]="treeOptions"
      style="width: 100%; padding: 0.5rem;"
      class="customTreeStyle tree-select"
      (contextmenu)="$event.preventDefault()"
    >
      <ng-template #loadingTemplate let-loadingNode>
        <div *ngIf="loadingNode.hasChildren">Lädt...</div>
      </ng-template>
      <ng-template #treeNodeWrapperTemplate let-node let-index="index">
        <div class="tree-node-wrapper" (contextmenu)="onNodeContextMenu($event, menuTrigger)">
          <div>
            <div
              class="node-wrapper"

            >
              <tree-node-expander
                [node]="node"
              ></tree-node-expander>
              <!--        <tree-node-checkbox [node]="node"></tree-node-checkbox>-->
              <mat-checkbox *ngIf="selection.isMultipleSelection()"
                            #checkbox="matCheckbox"
                            (change)="onNodeSelectionChange($event, node)"
                            [color]="'primary'"
                            [checked]="node.checked"
                            [indeterminate]="node.partiallyChecked"
              >
              </mat-checkbox>
              <div tabIndex="0"
                   (keydown.enter)="node.setIsActive(true)"
                   [class.node-content-wrapper-active]="node.isActive"
                   [class.node-content-wrapper-focused]="node.isFocused"
                   (contextmenu)="node.mouseAction('contextMenu', $event)"
                   (dblclick)="node.mouseAction('dblClick', $event)"
                   (click)="node.mouseAction('click', $event)"
                   [ngClass]="
                showCheckbox(node) && node.isLeaf
                  ? 'node-content-wrapper-leaf node-content-wrapper'
                  : showCheckbox(node)
                  ? 'extended-node-content-wrapper node-content-wrapper'
                  : 'node-content-wrapper'
              "
                   [style.background-color]="node.data.styles?.backgroundColor || ''"
                   [style.color]="node.data.styles?.color || ''"
                   [treeDrag]="node"
              >
                <tree-node-content
                  #myTooltip="matTooltip"
                  (mouseenter)="onGetNodeTooltip(node, myTooltip)"
                  (mouseleave)="node.data.mouseInside = false; myTooltip.hide()"
                  [index]="index"
                  [matTooltipDisabled]="false"
                  [template]="treeNodeTemplate"
                  [matTooltip]="getNodeTooltip(node)"
                  [matTooltipShowDelay]="300"
                  [node]="node"
                  [treeDrag]="node"
                ></tree-node-content>
                <div class="icon-wrapper" *ngIf="node.data.rowid">
                  <mat-icon
                    (click)="delete($event, node.data); $event.preventDefault(); $event.stopPropagation();"
                    *ngIf="deleteEnabled && !node.data.inuse"
                    svgIcon="{{EsvgFiles.delete}}" class="smaller-icon centered red-icon"
                  >
                  </mat-icon>

                  <mat-icon
                    (click)="edit($event, node.data); $event.preventDefault(); $event.stopPropagation();"
                    *ngIf="editEnabled"
                    svgIcon="{{EsvgFiles.edit}}" class="smaller-icon centered blue-icon"
                  >
                  </mat-icon>
                </div>
              </div>
            </div>
          </div>
          <mat-icon
            (click)="onNewNode(node.parent, index,index + 1,true); $event.preventDefault();"
            *ngIf="config?.editableTreeStructure"
            class="blue-icon node-action bottom-icon"
            svgIcon="{{EsvgFiles.add}}"
          ></mat-icon>
          <mat-icon
            (click)="onNewNode(node, index, 0, true); $event.preventDefault();"
            *ngIf="config?.editableTreeStructure"
            class="blue-icon node-action right-icon"
            svgIcon="{{EsvgFiles.add}}"
          ></mat-icon>
        </div>
      </ng-template>
      <ng-template #treeNodeTemplate let-index="index" let-node>
        <div
          class="tree-node-content"
          [class]="node.data.name.trim() === '' ? 'unnamed' : ''"
        >
          {{ node.data.name.trim() !== '' ? node.data.name.trim() : 'Unbenannter Eintrag ' + index.toString()}}
        </div>
      </ng-template>
    </tree-root>

    <kpi4me-create-new-entity
      *ngIf="createNewEnabled"
      tabIndex="0"
      style="border-top: 1px solid var(--color-surface-100);"
      (click)="createNewOption(columnName)"
    >
    </kpi4me-create-new-entity>

    <kpi4me-clear-entity
      *ngIf="clearEnabled"
      tabIndex="0"
      style="border-top: 1px solid var(--color-surface-100);"
      (click)="clearSelection()"
    >
    </kpi4me-clear-entity>
    <button
      *ngIf="selection.isMultipleSelection()"
      class="confirm-button" mat-raised-button color="primary" (click)="onConfirm()">OK
    </button>
  </div>

  <button [style.top.px]="menuPos.y"
          [style.left.px]="menuPos.x"
          style="visibility: hidden; position: fixed" #menuTrigger="matMenuTrigger"
          [matMenuTriggerFor]="menu"></button>

</ng-template>

<mat-menu #menu="matMenu">
  <div mat-menu-item *ngIf="createNewEnabled" class="create-new-option" (click)="createNewOption(columnName)">
    <mat-icon svgIcon="{{EsvgFiles.add}}"></mat-icon>
    <span>Neues Element</span>
  </div>
</mat-menu>
