<app-dialog-header
  [title]="'Dauerverordnung anlegen'"
  (close)="dialogRef.close()"
></app-dialog-header>

<mat-dialog-content>
  @if (form.value.document) {
    <div style="height: 30rem; width: 20rem; display: flex; flex-direction: column;">
      <embed style="flex: 1" [src]="form.value.document | pdf" type="application/pdf">
    </div>
  }

  <div class="form-wrapper" [formGroup]="form">
    <kpi4me-select-element
      [formControlElement]="form.controls.itemGroup"
      [data]="itemGroup"
    ></kpi4me-select-element>

    <p style="margin-bottom: unset">Dokumenten-Upload</p>
    <app-upload-wrapper
      [formControlName]="'document'"
      [accept]="'.pdf'"
    ></app-upload-wrapper>

    <kpi4me-date-element
      [formControlElement]="form.controls.fromDate"
      [data]="fromDateData"
    ></kpi4me-date-element>

    <kpi4me-date-element
      [formControlElement]="form.controls.toDate"
      [data]="toDateData"
    ></kpi4me-date-element>
  </div>
</mat-dialog-content>

<div mat-dialog-actions>
  <app-confirm-icon-button
    [disabled]="form.invalid || form.pending"
    (customClick)="dialogRef.close(form.value)"
  ></app-confirm-icon-button>
</div>
