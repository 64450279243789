import {Component, EventEmitter, Input, OnDestroy, Output, ViewChild, ViewEncapsulation} from '@angular/core';
import {FormControl} from "@angular/forms";
import {CoreService, EControlActions, EsvgFiles} from 'frontier/nucleus';
import {MatButtonToggleChange} from "@angular/material/button-toggle";
import {CalendarService} from "../../services/calendar.service";
import {Subscription} from "rxjs";
import {filter} from "rxjs/operators";
import {UserMenuComponent} from '../user-menu/user-menu.component';
import {ECalenderType, IToolbarState} from '../../golden-layout/custom-toolbar-state.interface';
import {ISelectFormOption} from '../../control/form-control/dynamic-form/form-element/form-data.interface';
import {EGranularity} from '../../calendar/calendar/granularity.enum';
import {ThemeService} from '../../theme/theme.service';
import {ControlManagerService} from '../../control-manager/control-manager.service';
import {IView} from '../../golden-layout/view.interface';
import {IModuleFilter, ITenant} from '../tenant-menu/tenant-menu.component';
import {IClassicCalendar} from '../toolbar-state.interface';
import {IDatepickerForm} from '../../calendar/easy-date-picker/date-picker/datepicker-form.interface';
import {IMenuOption} from '../user-menu/menu-option.interface';

export interface IToolbarAction {
  id: EControlActions,
  label?: string,
  icon: EsvgFiles,
  tooltip?: string,
}

@Component({
  selector: 'kpi4me-toolbar-shared',
  templateUrl: './toolbar-shared.component.html',
  styleUrls: ['./toolbar-shared.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ToolbarSharedComponent implements OnDestroy {
  private subs = new Subscription();
  //@ContentChild('toolbarCenter') toolbarCenter: TemplateRef<any>;

  @ViewChild(UserMenuComponent, {static: true}) userMenu: UserMenuComponent;
  @Output() mainMenuClicked = new EventEmitter();
  @Output() logout = new EventEmitter();
  @Output() themeToggle = new EventEmitter();
  @Output() applyTheme = new EventEmitter<string>();
  @Output() customToolbarActionClicked = new EventEmitter<string>();
  @Output() configChange = new EventEmitter<IToolbarState>();
  @Output() optionTriggered = new EventEmitter<any>()

  @Input() menuOptions: IMenuOption[] = [];
  @Input() helpUrl = '';
  @Input() showTenantMenu = true;
  @Input() moduleFilters: IModuleFilter[] = [];
  @Input() productName = 'Mein Produkt';
  @Input() version = '';
  @Input() companyLogoPath = 'assets/images/kpi4me-logo-zoomed.png';
  @Input() productLogoPath = null;
  @Input() customToolbarActions: IToolbarAction[] = [];
  @Input() textFilterControl: FormControl = new FormControl('');

  showSecondaryToolbar = false;

  options: ISelectFormOption[] = [];
  layoutSelectFormControl = new FormControl(null);
  tenant: string;

  lowestGranularity = EGranularity.week;

  private _state: IToolbarState;

  protected readonly EsvgFiles = EsvgFiles;
  protected readonly ECalenderType = ECalenderType;
  private initialFullTextFilter: string;


  @Input() set config(c: IToolbarState) {
    if (c) {
      if (c.classicalCalendar.fromDate.month === 0) {
        c.classicalCalendar.fromDate.month = 1;
      }
      this._state = c;

      this.textFilterControl.setValue(c.filter.searchstring || '');
    }
  }

  constructor(
    public themeService: ThemeService,
    public controlManagerService: ControlManagerService,
    private coreService: CoreService,
    protected calendarService: CalendarService,
  ) {
    this.subs.add(this.controlManagerService.layouts.subscribe((layouts: IView[]) => {
      this.options = layouts.map((layout: IView): ISelectFormOption => {
        return {
          value: layout,
          name: layout.name,
        };
      });
    }));

    this.subs.add(this.coreService.User.GetTenantInfos().subscribe((res) => {
      this.tenant = res.tenants.find((tenant: ITenant) => {
        return tenant.guid === res.current
      }).name;
    }));

    this.subs.add(this.calendarService.currentView$.pipe(
        filter((view) => view == null || view.toolbar == null)
      ).subscribe((view) => this.configChange.emit(this._state))
    );
  }

  ngOnDestroy() {
    this.subs.unsubscribe();
  }

  get state(): IToolbarState {
    return this._state;
  }

  onNew(evt: string): void {
    this.controlManagerService.createLayout(evt);
  }

  onDelete(evt: ISelectFormOption): void {
    this.controlManagerService.deleteLayout(evt.value);
  }

  onChange(evt: { oldEntry: ISelectFormOption; newName: string }): void {
    this.controlManagerService.changeLayout(evt);
  }

  onModelChange(evt: any): void {
    this.controlManagerService.loadView(evt);
  }

  onCopy(evt: ISelectFormOption): void {
    this.controlManagerService.copyView(evt.value);
  }

  onResetLayout(): void {
    this.controlManagerService.resetLayout();
    this.layoutSelectFormControl.setValue(null);
  }

  onSaveLayout(): void {
    this.controlManagerService.overwriteLoadedLayout();
  }

  onCalenderTypeChange(evt: MatButtonToggleChange) {
    this._state.calendarType = evt.value;
    this.configChange.emit(this._state);
  }

  onDateChange(evt: IClassicCalendar) {
    this._state.classicalCalendar = evt;
    this.configChange.emit(this._state);
  }

  onEasyDatePickerChange(evt: IDatepickerForm) {
    this._state.easyDatePicker = evt;
    if (evt.month.length > 0 && evt.year.length > 0) {
      this.configChange.emit(this._state);
    } else {
      // not supported
    }
  }

  toggleSecondaryToolbar() {
    this.showSecondaryToolbar = !this.showSecondaryToolbar;
  }

  updateTextFilter(filterText: string) {
    this._state.filter.searchstring = filterText;
    this.configChange.emit(this._state);
  }

  onFocusFullTextFilter(value: string) {
    this.initialFullTextFilter = value;
  }

  checkForFullTextFilterChanges(value: string) {
    if (this.initialFullTextFilter === value) return;
    this.updateTextFilter(value);
    this.initialFullTextFilter = value;
  }

  onClear() {
    this.checkForFullTextFilterChanges('');
  }
}
