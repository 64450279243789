import {ICartStatus, ICartStatusOnHold} from './cart-status-data.interface';
import {ECalculusStateFilter} from './state-filter.enum';

export const CartStatuses: Map<ECalculusStateFilter, (ICartStatus | ICartStatusOnHold)> = new Map([
  [ECalculusStateFilter.kpiConnect,  {
    label: 'Datenimport',
    categoryId: ECalculusStateFilter.kpiConnect,
    errorId: ECalculusStateFilter.kpiConnectError,
    warningId: ECalculusStateFilter.kpiConnectWarning,
    okId: ECalculusStateFilter.kpiConnectOk
  }],
  [ECalculusStateFilter.ETLnMapping, {
      label: 'Trapo zuordnen',
      categoryId: ECalculusStateFilter.ETLnMapping,
      errorId: ECalculusStateFilter.ETLnMappingError,
      warningId: ECalculusStateFilter.ETLnMappingWarning,
      okId: ECalculusStateFilter.ETLnMappingOk
    }],
  [ ECalculusStateFilter.check, {
    label: 'Prüfung',
    categoryId: ECalculusStateFilter.check,
    errorId: ECalculusStateFilter.checkError,
    warningId: ECalculusStateFilter.checkWarning,
    okId: ECalculusStateFilter.checkOk
  }],
  [ECalculusStateFilter.createInvoice, {
    label: 'Rechnung erstellen',
    categoryId: ECalculusStateFilter.createInvoice,
    errorId: ECalculusStateFilter.createInvoiceError,
    warningId: ECalculusStateFilter.createInvoiceWarning,
    okId: ECalculusStateFilter.createInvoiceOk
  }],
  [ECalculusStateFilter.arrangeInvoice, {
    label: 'Rechnung anordnen',
    categoryId: ECalculusStateFilter.arrangeInvoice,
    errorId: ECalculusStateFilter.arrangeInvoiceError,
    warningId: ECalculusStateFilter.arrangeInvoiceWarning,
    okId: ECalculusStateFilter.arrangeInvoiceOk
  }],
  [ECalculusStateFilter.monitorPayment, {
    label: 'Zahlung überwachen',
    categoryId: ECalculusStateFilter.monitorPayment,
    errorId: ECalculusStateFilter.monitorPaymentError,
    warningId: ECalculusStateFilter.monitorPaymentWarning,
    okId: ECalculusStateFilter.monitorPaymentOk
  }],
  [ECalculusStateFilter.total, {
    label: 'Gesamtauswertung',
    categoryId: ECalculusStateFilter.total,
    errorId: ECalculusStateFilter.totalError,
    warningId: ECalculusStateFilter.totalWarning,
    okId: ECalculusStateFilter.totalOk
  }],
  [ECalculusStateFilter.onHold, {
    label: 'On Hold',
    categoryId: ECalculusStateFilter.onHold,
    icon: 'hourglass',
    errorId: undefined,
    okId: undefined,
    warningId: undefined
  }],
  [ECalculusStateFilter.clientAccessMessages, {
    label: 'Patientenzugriff',
    categoryId: ECalculusStateFilter.clientAccessMessages,
    errorId: undefined,
    okId: undefined,
    warningId: undefined
  }]
])
