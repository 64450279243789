import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  QueryList,
  ViewChildren,
  ViewEncapsulation
} from '@angular/core';
import {combineLatest, concatMap, debounceTime, interval, mergeWith, Subscription, switchMap, tap} from "rxjs";
import {filter, map} from "rxjs/operators";
import {
  BaseControlComponent,
  CoreService,
  DefaultApiAdapter,
  EcalculusControlGUID,
  EsvgFiles,
  StoreService
} from 'frontier/nucleus';
import {CalendarService} from 'frontier/browserkit';
import {
  ICartStatus,
  ICartStatusApiData,
  ICartStatusClientAccess,
  ICartStatusOnHold
} from '../config/cart-status-data.interface';
import {SelectionModel} from '@angular/cdk/collections';
import {IBarClickedEvent} from '../evaluation/bar-click-event.interface';
import {ICategoryStateSelection, IProcessOverviewState} from '../config/category-state-selection.interface';
import {CartStatuses} from '../config/cart-status-data.const';
import {ECalculusStateFilter} from '../config/state-filter.enum';
import {CalculusStateService} from '../../Services/calculus-state.service';
import {Processes} from './processes.type';


@Component({
  selector: 'kpi4me-process-header',
  templateUrl: './process-header.component.html',
  styleUrls: ['./process-header.component.scss'],
  encapsulation: ViewEncapsulation.Emulated
})
export class ProcessHeaderComponent extends BaseControlComponent implements OnInit {
  GUID = EcalculusControlGUID.FullCartStatus;
  ECalculusStateFilter = ECalculusStateFilter;
  @ViewChildren(ProcessHeaderComponent) componentInstances: QueryList<ProcessHeaderComponent>;

  @Input() preventUpdate = false;

  categorySelection: SelectionModel<ECalculusStateFilter>;
  stateSelection: SelectionModel<ECalculusStateFilter>;

  @Output() selectionChange = new EventEmitter<ICategoryStateSelection>();

  subs = new Subscription();
  apiAdapter = new DefaultApiAdapter();
  processes: Processes;
  _data: ICartStatusApiData;
  get data(): ICartStatusApiData {
    return super.data;
  }

  set data(d: ICartStatusApiData) {
    const processes: Processes = [...CartStatuses.values()] as Processes;
    processes[0].data = d.kpiconnect;
    processes[1].data = d.etlnmap;
    processes[2].data = d.check;
    processes[3].data = d.createinvoice;
    processes[4].data = d.arrangeinvoice;
    processes[5].data = d.monitorpayment;
    processes[6].data = d.total;
    processes[7].data = [d.onhold];
    processes[8].data = [d.clientmessage];
    this.processes = processes;

    super.data = d;
  }

  constructor(
    protected elementRef: ElementRef,
    private calendarService: CalendarService,
    protected cdr: ChangeDetectorRef,
    protected coreService: CoreService,
    protected store: StoreService,
    private calculusStateService: CalculusStateService
  ) {
    super(coreService, store, cdr);
  }

  ngOnInit(): void {
    this.subs.add(
      combineLatest([
        this.calendarService.globalForkedEvents$,
        this.apiInstanceChange,
      ])
        .pipe(
          filter(
            ([filter, apiInstance]) => filter != null && apiInstance != null
          ),
          map(([filter, apiInstance]) => {
            return {filter, apiInstance};
          }),
          concatMap((combinedEvents) => {
            // Update the apiInstance and trigger the fetching of the data with the new filter.
            this.apiInstance = {
              ...combinedEvents.apiInstance,
              filter: {
                ...this.apiInstance?.filter,
                ...combinedEvents.filter
              },
            };

            this.cdr.detectChanges();
            return this.changeAndFetch()
          })
        )
        .subscribe()
    );

    // Initial loading
    this.subs.add(
      this.calculusStateService.processOverviewState$.pipe(
        // take(1),
        switchMap((processOverviewState: IProcessOverviewState) => {
          const {states, categories} = processOverviewState.stateSelection;
          this.categorySelection = new SelectionModel<ECalculusStateFilter>(true, categories);
          this.stateSelection = new SelectionModel<ECalculusStateFilter>(true, states);
          return this.stateSelection.changed.pipe(
            mergeWith(this.categorySelection.changed),
            debounceTime(50),
            map(_ => ({
              states: this.stateSelection.selected,
              categories: this.categorySelection.selected,
              activeInvoice: null,
              activeClient: null,
            })),
            tap(selection => this.selectionChange.emit(selection))
          );
        })
      ).subscribe()
    )

    this.subs.add(
      interval(10000)
        .pipe(
          filter(_ => !this.preventUpdate),
          concatMap(_ => this.changeAndFetch())
        )
        .subscribe()
    )
    super.ngOnInit();
  }

  onProcessClick(process: ICartStatus | ICartStatusOnHold | ICartStatusClientAccess) {
    this.stateSelection.clear(true);
    this.categorySelection.clear(false);
    this.categorySelection.select(process.categoryId);
  }

  onBarClicked(evt: IBarClickedEvent, process: ICartStatus | ICartStatusOnHold | ICartStatusClientAccess) {
    // stop propagation to parent button
    evt.event.stopPropagation();
    this.categorySelection.clear(false);
    this.stateSelection.clear(false);
    this.categorySelection.select(process.categoryId);
    this.stateSelection.select(evt.state);
  }

  protected readonly EsvgFiles = EsvgFiles;
}
