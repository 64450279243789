import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
  ViewEncapsulation
} from '@angular/core';
import {
  BaseControlComponent,
  CoreService,
  ECalculateImportValue,
  EControlActions,
  IControlDataChanged,
  StoreService
} from 'frontier/nucleus';
import {ITableVerticalDataRow, TableVerticalApiAdapter} from './table-vertical-api-adapter';
import {Observable, of} from 'rxjs';
import {map, mergeMap, tap} from 'rxjs/operators';
import {IApiRow} from '../table-control/api-row.interface';
import {DisplayedColumns} from '../table-control/interfaces/displayed-columns.interface';

@Component({
  selector: 'kpi4me-table-vertical-control',
  templateUrl: './table-vertical-control.component.html',
  styleUrls: ['./table-vertical-control.component.scss'],
  encapsulation: ViewEncapsulation.Emulated,
})
export class TableVerticalControlComponent extends BaseControlComponent implements OnChanges {
  @Input() header: string;
  @Input() hasImports = false;
  @Input() showImports = false;
  @Output() showImportsChange = new EventEmitter<boolean>();
  @Output() cellChanged = new EventEmitter<{ apiRow: IApiRow, verticalRow: ITableVerticalDataRow }>();
  private rowCount: number;
  private colCount: number;

  rows: ITableVerticalDataRow[] = [];
  private displayedColumns: DisplayedColumns[];
  apiRows: any[];

  constructor(
    protected coreService: CoreService,
    protected store: StoreService,
    protected cdr: ChangeDetectorRef,
  ) {
    super(coreService, store, cdr,);
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (this.apiInstance == null) return;
    // Fetch new values only if both showImports has been changed to true and the imports haven't already been loaded
    if (changes.showImports && changes.showImports.currentValue
      && this.apiInstance?.filter?.calculateimportvalue !== ECalculateImportValue.first) {
      this.changeAndFetch().subscribe();
    }
  }

  @Input() apiAdapter: TableVerticalApiAdapter = new TableVerticalApiAdapter();

  fetchRows() {
    if (this.apiInstance == null) return of(null);
    return this.coreService.Table.FetchRows(
      this.apiInstance.instanceid,
      0,
      1,
      0,
      this.colCount - 1
    ).pipe(tap((rows) => {
      this.apiRows = rows;
      this.rows = this.mapRows(rows);
    }));
  }

  changeAndFetch(): Observable<any> {
    this.apiInstance = {
      ...this.apiInstance,
      filter: {
        ...this.apiInstance?.filter,
        calculateimportvalue: this.showImports ? ECalculateImportValue.first : ECalculateImportValue.off,
      }
    }
    return super.changeAndFetch().pipe(
      map(data => this.apiAdapter.from(data)),
      mergeMap((value) => {
        this.displayedColumns = value.displayedColumns;
        this.rowCount = value.rowcount;

        this.colCount =
          value && value.displayedColumns ? value.displayedColumns.length : 0;
        return this.fetchRows();
      })
    );
  }

  private mapRows(rows: IApiRow[]) {
    if (rows.length != 1) {
      console.warn(`Unexpected number of rows: ${rows.length}. Only expected one row for the client. Is the client filter applied?`);
    }
    const newRows: ITableVerticalDataRow[] = [];
    console.log(rows);
    for (const col of this.displayedColumns) {
      const cell = rows[0].cols[Number(col.field)];
      newRows.push({
        type: col.type,
        attributeIndex: col.attributeindex,
        attribute: col.attribute,
        fieldName: col.headerName,
        value: cell.value,
        editable: cell.editable,
        apiRow: rows[0],
        apiInstance: this.apiInstance,
        repository: col.repository,
        invalidMessage: cell.invalidmessage ? cell.invalidmessage : null,
        imported: cell.imported
        // TODO: Add import value
      })
    }
    return newRows;
  }

  onCellChange(row: { apiRow: IApiRow, verticalRow: ITableVerticalDataRow }) {
    this.cellChanged.emit(row);
    const newRows: ITableVerticalDataRow[] = [];
    const apiRow = row.apiRow;
    console.log(apiRow);
    for (const col of this.displayedColumns) {
      const cell = apiRow.cols[Number(col.field)];
      newRows.push({
        type: col.type,
        attributeIndex: col.attributeindex,
        attribute: col.attribute,
        fieldName: col.headerName,
        value: cell.value,
        editable: cell.editable,
        apiRow: apiRow,
        apiInstance: this.apiInstance,
        repository: col.repository,
        invalidMessage: cell.invalidmessage ? cell.invalidmessage : null,
        imported: cell.imported
      })
    }
    newRows.forEach((value: any, index: any) => {
      this.rows[index].value = value.value;
      this.rows[index].invalidMessage = value.invalidMessage;
      this.rows[index].component?.updateRow(value)
    })
    this.coreService.controlDataChanged.emit({GUID: this.GUID, changeType: EControlActions.changeLine});
  }

  onUpdateTable() {
    this.changeAndFetch().subscribe();
  }

  onUpdateRepositories() {
    this.changeAndFetch().subscribe();
    // this.rows.forEach((row:ITableVerticalDataRow, index: number) => {
    //   if (row.repository) {
    //     this.coreService.Table.GetRepositoryForProperty(this.apiInstance.instanceid, row.attribute, row.attributeIndex)
    //       .subscribe((repository: any[]) => {
    //         const newRow = {...row, repository: repository.map((entry) => {
    //           return {value: entry, name: entry.name};
    //           })};
    //         console.log(newRow);
    //         this.rows = this.rows.map((oldRow:ITableVerticalDataRow, oldIndex: number) => {
    //           if (index === oldIndex) {
    //             return  newRow;
    //           }
    //           else {
    //             return oldRow;
    //           }
    //         });
    //       });
    //   }
    // });
  }


  onSelectionDataChange(evt: IControlDataChanged) {
    this.coreService.controlDataChanged.emit({changeType: evt.changeType, GUID: this.GUID});
  }
}
