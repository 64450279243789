import {Component, HostListener, Inject, OnInit, ViewEncapsulation} from '@angular/core';
import {FormControl} from "@angular/forms";
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {CalculusStateService} from "../../Services/calculus-state.service";
import {ITextFormElement} from 'frontier/browserkit';

@Component({
  selector: 'kpi4me-page-size-dialog',
  templateUrl: './page-size-dialog.component.html',
  styleUrls: ['./page-size-dialog.component.scss'],
  encapsulation: ViewEncapsulation.Emulated
})
export class PageSizeDialogComponent implements OnInit {
  option = new FormControl('');
  textElementConfig: ITextFormElement = {
    maxLength: 6,
    inputType: 'number'
  };

  @HostListener('window:keydown.enter', ['$event'])
  onEnter() {
    this.onConfirm();
  }

  constructor(
    public dialogRef: MatDialogRef<PageSizeDialogComponent>,
    private calculusStateService: CalculusStateService,
    @Inject(MAT_DIALOG_DATA) public data: { pageSize: number }
  ) {
  }

  ngOnInit(): void {
    this.option.patchValue(this.data.pageSize > 0 ? String(this.data.pageSize) : '')
  }

  /**
   * closes the dialog and returns the new/changed entry for the dynamic array as a string
   */
  onConfirm(): void {
    this.dialogRef.close(Number(this.option.value));
  }

  /**
   * closes the new entry dialog without returning a response value
   */
  onCancel(): void {
    this.dialogRef.close();
  }

  /**
   * Returns the title string for display
   */
  getTitle(): string {
    return 'Wie viele Zeilen soll jede Tabellenseite haben? Für eine automatische Zeilenanzahlermittlung, geben Sie ' +
      'bitte "0" oder nichts ein.'
  }
}
