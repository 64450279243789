import {Component, HostListener, ViewEncapsulation} from '@angular/core';
import {BaseCellEditorComponent} from '../base-cell-editor/base-cell-editor.component';

@Component({
  selector: 'kpi4me-bool-cell-editor',
  templateUrl: './bool-cell-editor.component.html',
  styleUrls: ['./bool-cell-editor.component.scss'],
  encapsulation: ViewEncapsulation.Emulated,
})
export class BoolCellEditorComponent extends BaseCellEditorComponent {

  temporarilyDisabled = false;

  /**
   Override confirmChange call from base-cell-editor and replace with another trigger since focusout seems to not
   reliably fire on mat-slide-toggle.
   */
  @HostListener('focusout', ['$event']) onBlur() {
    console.log('focusout');
  }

  onChange() {
    this.temporarilyDisabled = true;
    this.confirmChange();
  }

  @HostListener('changeComplete', ['$event']) reenable() {
    this.temporarilyDisabled = false;
  }
}
