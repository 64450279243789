import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ElementRef,
  HostListener,
  OnDestroy,
  ViewChild,
  ViewContainerRef,
  ViewEncapsulation
} from '@angular/core';
import {CustomCellEditorComponent} from '../custom-cell-editor/custom-cell-editor.component';
import {ICellEditorParams} from 'ag-grid-community';
import {BehaviorSubject, Observable, of, startWith} from 'rxjs';
import {CoreService} from 'frontier/nucleus';
import {debounceTime, map, mergeMap} from 'rxjs/operators';
import {FeedbackService} from "../../../../user-feedback/feedback.service";
import {createGridRow} from "../../../adapters/table/create-grid-row.function";
import {ICustomColDef} from '../../interfaces/custom-col-def.interface';

@Component({
  selector: 'kpi4me-autocomplete-cell-editor',
  templateUrl: './autocomplete-cell-editor.component.html',
  styleUrls: ['./autocomplete-cell-editor.component.scss'],
  encapsulation: ViewEncapsulation.Emulated,
})
export class AutocompleteCellEditorComponent extends CustomCellEditorComponent
  implements OnDestroy, AfterViewInit {
  @ViewChild('input', {read: ViewContainerRef})
  public input: ViewContainerRef;
  filteredEntities = new BehaviorSubject<any>([]);

  constructor(
    protected coreService: CoreService,
    protected cdr: ChangeDetectorRef,
    protected elementRef: ElementRef,
    protected feedbackService: FeedbackService
  ) {
    super(coreService, cdr, elementRef, feedbackService);
  }

  displayWith = (value: any) => {
    if (value) {
      return typeof value === 'string' ? value : value.name;
    }
  };

  @HostListener('keydown.enter', ['$event']) onEnter(event: KeyboardEvent) {
    this.onOptionSelected()
  }

  agInit(params: ICellEditorParams) {
    super.agInit(params);
    this.textFormControl.valueChanges.pipe(
      startWith(''),
      debounceTime(10),
      mergeMap(value => {
        return value ? this.getEntities(value) : []
      }),
    ).subscribe((entities) => {
      this.filteredEntities.next(entities);
    });
  }

  ngAfterViewInit() {
    // focus on the input
    setTimeout(() => {
      (<ViewContainerRef>this.input).element.nativeElement.focus();
    });
  }

  ngOnDestroy(): void {
    return null;
  }

  isPopup(): boolean {
    return false;
  }

  onOptionSelected() {
    const data: {
      client: any, location?: string, district?: string, postcode?: string, city?: any
    } = {
      client: this.params.node.data.apiRow.obj,
    }
    if (typeof this.textFormControl.value === 'string') {
      data[this.attribute as 'location' | 'district' | 'postcode'] = this.textFormControl.value;
    } else {
      data.city = this.textFormControl.value;
    }
    this.coreService.Control.ExecuteAction(
      (this.params.context as any).apiInstance.instanceid,
      'setcity',
      data
    ).subscribe((res) => {
      this.params.node.setData(createGridRow(res));
      (this.params.context as any).onCellValueChanged(this.params);
    })
  }

  getEntities(filterValue: string): Observable<any[]> {
    if (typeof filterValue !== 'string') {
      return of([]);
    }
    return this.coreService.Control.ExecuteAction(
      (this.params.context as any).apiInstance.instanceid,
      'getcity',
      {
        [this.attribute]: filterValue
      }
    ).pipe(map((rows: any[]) => {
      if (typeof rows === 'string') return [];

      if (rows) {
        return rows.map(r => {
          return {
            apiRow: r,
            name: r.name,
          }
        })
      }
      return null
    }))
  }

  get attribute() {
    return (this.params.colDef as ICustomColDef).apiCol.attribute;
  }
}
