export enum EControlGUID {
  tableEasyPlan = '904C1D4877CA43CBB968A3C7665003AE',
  tableLocation = '80E79D35D1A045B98523C419FA06DAA8',
  tableCalendar = 'D8AEC822289943699844F7925A561BF4',
  tableTimePlan = 'CA79E3FA44CE4BC398CE680523EE4282',
  tableBlockTemplate = 'A96E07E630A1471D859C88CA500199DD',
  tableEvents = '680379F81009495F9B7AF5A6E65FAA62',
  treeRoomRepository = 'F581FCB834914F7482601881EBE1D791',
  treeDepartmentEventType = '1F697248B0C646E5A7DA2D2D374A741F',
  formEventBegin = '7BA77716B7564018ACB967567D2900E5',
  chartCourseDayTimeline = 'B3428EECA7C045C38F4F8727064B5934',
  tableEmployee = '5434042844CE42A99BB890FAE8011A8C',
  tableQualification = 'E30E9FEF6CB14799B3B916D0BE452CD9',
  tableEmployeeJob = '87D8450BB0B0420DBB1B0705DB6EDA1A',
  treeEmployeeRepository = '3168ECD85917483BA74BD4ABCE128620',
  hierarchyFixedStructure = '02C53B49AE9044218D80879F5782D080',
  hierarchyMissingInFixed = '331F783D74A347CB9695D66E3DF3EBA4',
  hierarchyReferences = 'E901B16B90A741D79E6ACDD1329BB4AE',
  tableVehicleProvisioning = '6B49AA3BF8BD470F975CB4326B81491C',
  tableVehicleType = '519BB4DE2A0F4FF485F49113BE3D04FC',
  tableVehicleCrew = '1EE6952D4AB8443D8D58B9EEF422906B',
  tableEmployeeJobDemand = 'BE1A78B4C9FD4FD78FB083BBCD55F939',
  tableUserList = '3ED33075070F443A849D1A5AD6A1CAE3',
  tableUserGroupList = '70333BA9B89E4993B79A9B711B8149AC',
  tableCalculationRule = '85F1A7C1DCE8473E9D84A5F8D6C27E94',
  tableFinancialAccount = '5C7DFDBD8FB54E848F0634A9F3487BC9',
  tableEventCostEstimation = '88F82D4A617842EB92802436D389BCA9',
  matrixCostEstimation = '2D924EA5C88546B09A65333E8CC3F7CE',
}
