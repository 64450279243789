import {Component} from '@angular/core';
import {EsvgFiles} from 'frontier/nucleus';

@Component({
  selector: 'app-cancel-icon-button',
  templateUrl: './cancel-icon-button.component.html',
  styleUrls: ['./cancel-icon-button.component.scss'],
})
export class CancelIconButtonComponent {
  protected readonly EsvgFiles = EsvgFiles;
}
