import {Injectable} from '@angular/core';
import {MultiWindowService} from '@kpi4me/golden-angular-layout';
import {BehaviorSubject} from "rxjs";

@MultiWindowService<EventService>('event-service')
@Injectable({
  providedIn: 'root'
})
export class EventService {
  userGroupListChanged$ = new BehaviorSubject('');

  constructor() {
  }
}
