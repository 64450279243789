import {Observable} from 'rxjs';
import {ApiConfigService} from '../api-config.service';
import {HttpHeaders} from '@angular/common/http';
import {IControlObject} from '../../interfaces/control-object.interface';

export class Coursedaytable {
  http;
  url;
  httpOptions;
  constructor(protected appConfig: ApiConfigService) {
    this.http = appConfig.http;
    this.url = appConfig.url;
    this.httpOptions = appConfig.httpOptions;
  }


  removeemployee(InstanceId: string, courseplan: IControlObject, employee: IControlObject, srcattributeindex: number[]) : Observable<boolean> {
   return this.http.post(
      this.url + '/intern/api/control/ExecuteAction/' + InstanceId + '/removeemployee/', {"courseplan": courseplan, "employee": employee, "srcattributeindex": srcattributeindex}, this.httpOptions
    ) as unknown as Observable<boolean>;
  }

  ignoreconflict(InstanceId: string, courseday: IControlObject) : Observable<boolean> {
   return this.http.post(
      this.url + '/intern/api/control/ExecuteAction/' + InstanceId + '/ignoreconflict/', {"courseday": courseday}, this.httpOptions
    ) as unknown as Observable<boolean>;
  }

  changeroom(InstanceId: string, courseday: IControlObject, room: IControlObject) : Observable<boolean> {
   return this.http.post(
      this.url + '/intern/api/control/ExecuteAction/' + InstanceId + '/changeroom/', {"courseday": courseday, "room": room}, this.httpOptions
    ) as unknown as Observable<boolean>;
  }

  deletecourseplan(InstanceId: string, courseday: IControlObject) : Observable<boolean> {
   return this.http.post(
      this.url + '/intern/api/control/ExecuteAction/' + InstanceId + '/deletecourseplan/', {"courseday": courseday}, this.httpOptions
    ) as unknown as Observable<boolean>;
  }

  cancelcourseday(InstanceId: string, courseday: IControlObject) : Observable<boolean> {
   return this.http.post(
      this.url + '/intern/api/control/ExecuteAction/' + InstanceId + '/cancelcourseday/', {"courseday": courseday}, this.httpOptions
    ) as unknown as Observable<boolean>;
  }

  addemployee(InstanceId: string, courseplan: IControlObject, employee: IControlObject, srcattributeindex: number[]) : Observable<boolean> {
   return this.http.post(
      this.url + '/intern/api/control/ExecuteAction/' + InstanceId + '/addemployee/', {"courseplan": courseplan, "employee": employee, "srcattributeindex": srcattributeindex}, this.httpOptions
    ) as unknown as Observable<boolean>;
  }

  draganddrop(InstanceId: string, courseplan: IControlObject, srcrowobj: IControlObject, srcattribute: string, srcattributeindex: number[], dstrowobj: IControlObject, dstattribute: string, dstattributeindex: number[]) : Observable<any[]> {
   return this.http.post(
      this.url + '/intern/api/control/ExecuteAction/' + InstanceId + '/draganddrop/', {"courseplan": courseplan, "srcrowobj": srcrowobj, "srcattribute": srcattribute, "srcattributeindex": srcattributeindex, "dstrowobj": dstrowobj, "dstattribute": dstattribute, "dstattributeindex": dstattributeindex}, this.httpOptions
    ) as unknown as Observable<any[]>;
  }

  changestartdatetime(InstanceId: string, courseday: IControlObject, datetime: string) : Observable<boolean> {
   return this.http.post(
      this.url + '/intern/api/control/ExecuteAction/' + InstanceId + '/changestartdatetime/', {"courseday": courseday, "datetime": datetime}, this.httpOptions
    ) as unknown as Observable<boolean>;
  }

  distributecourseday(InstanceId: string, courseday: IControlObject) : Observable<boolean> {
   return this.http.post(
      this.url + '/intern/api/control/ExecuteAction/' + InstanceId + '/distributecourseday/', {"courseday": courseday}, this.httpOptions
    ) as unknown as Observable<boolean>;
  }

}
