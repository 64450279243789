<kpi4me-home
  [defaultView]="defaultView"
  [components]="components"
  [detailComponents]="detailComponents"
  [menuOptions]="menuOptions"
  [moduleFilters]="calculusFilters"
  [productName]="'Abrechenknecht'"
  [productLogoPath]="productLogo$ | async"
  (optionTriggered)="onOptionTriggered($event)"
></kpi4me-home>
