import {ChangeDetectorRef, Component, Inject, OnDestroy, ViewChild, ViewEncapsulation} from '@angular/core';
import {GridOptions} from 'ag-grid-community';
import {changes, CoreService, EControlGUID} from 'frontier/nucleus';
import {SharedGridOptions} from '../../control/table-control/shared-gridoptions.const';
import {Subscription} from "rxjs";
import {filter, tap} from "rxjs/operators";
import {TableControlComponent} from "../../control/table-control/table-control.component";
import {GoldenLayoutContentComponent} from "../../golden-layout/golden-layout-content/golden-layout-content.component";
import {GoldenLayoutComponent, GoldenLayoutComponentHost, GoldenLayoutContainer} from '@kpi4me/golden-angular-layout';
import GoldenLayout from '@kpi4me/golden-layout';
import {ControlManagerService} from "../../control-manager/control-manager.service";
import {EventService} from "../../services/event.service";

@Component({
  selector: 'kpi4me-user-group-list',
  templateUrl: './user-group-list.component.html',
  styleUrls: ['./user-group-list.component.scss'],
  encapsulation: ViewEncapsulation.Emulated,
})
export class UserGroupListComponent extends GoldenLayoutContentComponent implements OnDestroy {
  subscriptions = new Subscription();

  @ViewChild(TableControlComponent, {static: false})
  tableRef: TableControlComponent;

  gridOptions: GridOptions = {
    ...SharedGridOptions,
    rowSelection: 'single'
  };
  userGroupGUID = EControlGUID.tableUserGroupList;

  constructor(@Inject(GoldenLayoutComponentHost)
              protected goldenLayout: GoldenLayoutComponent,
              @Inject(GoldenLayoutContainer)
              protected container: GoldenLayout.Container,
              protected cdr: ChangeDetectorRef,
              protected controlManager: ControlManagerService,
              private coreService: CoreService,
  ) {
    super(goldenLayout, container, cdr, controlManager);

    this.subscriptions.add(
      this.coreService.controlDataChanged.pipe(
        filter(evt => {
          return new Map([
            [EControlGUID.tableUserList, changes],
          ]).get(evt.GUID as EControlGUID)?.has(evt.changeType);
        }),
        tap(() => {
          this.tableRef.refreshInfiniteCache();
        })).subscribe(),
    );
  }


  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }

  ngOnInit(): void {
    return;
  }

  protected onResize() {
    if (this.tableRef) {
      this.tableRef.fitColumns();
    }
  }
}
