import {Observable} from 'rxjs';
import {ApiConfigService} from '../api-config.service';
import {HttpHeaders} from '@angular/common/http';

export class Table {
  http;
  url;
  httpOptions;
  constructor(protected appConfig: ApiConfigService) {
    this.http = appConfig.http;
    this.url = appConfig.url;
    this.httpOptions = appConfig.httpOptions;
  }


  FetchRows(InstanceID: string, startidx: number, endidx: number, colstart: number, colend: number) : Observable<any[]> {
    return this.http.post(
      this.url + '/intern/api/table/FetchRows/',
      {_parameters: [InstanceID, startidx, endidx, colstart, colend]}, this.httpOptions
    ) as unknown as Observable<any[]>;
  }

  FetchObjectCache(InstanceID: string) : Observable<any[]> {
    return this.http.post(
      this.url + '/intern/api/table/FetchObjectCache/',
      {_parameters: [InstanceID]}, this.httpOptions
    ) as unknown as Observable<any[]>;
  }

  ChangeFilterAndSorting(InstanceID: string, filter: any[], sorting: any) : Observable<number> {
    return this.http.post(
      this.url + '/intern/api/table/ChangeFilterAndSorting/',
      {_parameters: [InstanceID, filter, sorting]}, this.httpOptions
    ) as unknown as Observable<number>;
  }

  GetTableRowForObject(InstanceID: string, obj: any, rowidx: number) : Observable<any> {
    return this.http.post(
      this.url + '/intern/api/table/GetTableRowForObject/',
      {_parameters: [InstanceID, obj, rowidx]}, this.httpOptions
    ) as unknown as Observable<any>;
  }

  GetRepositoryForProperty(InstanceID: string, attribute: string, attributeIndex: number) : Observable<any[]> {
    return this.http.post(
      this.url + '/intern/api/table/GetRepositoryForProperty/',
      {_parameters: [InstanceID, attribute, attributeIndex]}, this.httpOptions
    ) as unknown as Observable<any[]>;
  }

  DownloadDocument(InstanceID: string, Data: any) : Observable<any> {
    return this.http.post(
      this.url + '/intern/api/table/DownloadDocument/',
      {_parameters: [InstanceID, Data]}, {...this.httpOptions, responseType: 'blob'}) as unknown as Observable<any>;
  }

  UploadDocument(name: string, datatype: string, data: any) : Observable<any> {
    return this.http.post(
      this.url + '/intern/api/table/UploadDocument/' + encodeURI(name) + '/' + encodeURI(datatype) + '/', data, {headers: new HttpHeaders({Accept: 'application/json','Content-type': 'application/octet-stream'}),}) as unknown as Observable<any>;
  }

}
