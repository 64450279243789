<app-dialog-header
  [title]="data.title"
  [dialogRef]="dialogRef"
  (close)="onCancel()"
></app-dialog-header>

<mat-dialog-content style="flex: 1">
  <table style="border-spacing: 1rem 0.5rem;">
    <!--  <thead>-->
    <!--  <tr>-->
    <!--    <th>Feld</th>-->
    <!--    <th></th>-->
    <!--  </tr>-->
    <!--  </thead>-->

    <tbody>
    <tr *ngFor="let r of rows">
      <td>{{r.fieldName}}</td>
      <td>
        <kpi4me-cell-editor
          [params]="r"
        ></kpi4me-cell-editor>
      </td>
    </tr>
    </tbody>
  </table>
</mat-dialog-content>
<div mat-dialog-actions style="margin-top:1rem;">
  <app-confirm-icon-button (customClick)="onConfirm()"></app-confirm-icon-button>
</div>
