import {ChangeDetectorRef, Directive, Inject, OnDestroy, ViewChild} from '@angular/core';
import {DOCUMENT} from '@angular/common';
import {ControlManagerService} from '../../control-manager/control-manager.service';
import {GlOnClose, GlOnShow, GoldenLayoutComponent, IExtendedGoldenLayoutConfig} from '@kpi4me/golden-angular-layout';
import {ReplaySubject, Subscription} from 'rxjs';
import {BrowserWindow} from '@kpi4me/golden-layout';
import {ThemeService} from '../../theme/theme.service';
import {SvgService} from 'frontier/nucleus';

/**
 * Base Component that implements shared behavior for every golden layout hoster component.
 * Registers at the control manager service.
 * Saves its own golden layout configuration every time the state changes.
 */
@Directive()
export abstract class LayoutHosterBaseComponent implements OnDestroy, GlOnShow, GlOnClose {
  @ViewChild(GoldenLayoutComponent, {static: false}) gl: GoldenLayoutComponent;

  abstract readonly layout$: ReplaySubject<IExtendedGoldenLayoutConfig>;
  // Key that accesses the golden layout instance from the gl collection of the service
  abstract readonly layoutKey: string;

  subs = new Subscription();
  private initialized = false;

  constructor(
    public themeService: ThemeService,
    protected svgService: SvgService,
    @Inject(DOCUMENT) protected document: Document,
    protected controlManagerService: ControlManagerService,
    protected cdr: ChangeDetectorRef
  ) {

    // Set theme on popped out windows
    if (window.opener) {
      // Set theme class
      this.document.body.classList.add(this.themeService.themeClass);
      // update the theme class in the popped window
      this.subs.add(this.themeService.theme$.subscribe((themeClass) => {
        if (themeClass === 'light-theme') {
          this.document.body.classList.replace('dark-theme', themeClass);
        } else {
          this.document.body.classList.replace('light-theme', themeClass);
        }
      }));
    }

      // Prevent double subscriptions by checking if the current window is no popout.
    // Only create Subscriptions when it is the main window
    else if (!window.opener) {
      this.subs.add(controlManagerService.windowResize.subscribe(() => {
        if (this.gl && (this.gl as any).goldenLayout) {
          this.gl.getGoldenLayoutInstance().updateSize();
          console.log('update golden layout size')
        }
      }));
    }
  }

  glOnShow() {
    console.log('show');
    setTimeout(() =>
        this.gl.getGoldenLayoutInstance().updateSize()
      , 0)
  }

  glOnClose(): Promise<void> {
    return new Promise((resolve) => {
      resolve();
      this.controlManagerService.setOpenedComponents();
    });
  }

  /**
   * Save the own state in the localstorage every change.
   */
  onStateChanged() {
    if (!window.opener) {
      // this.cdr.detectChanges();
      // Initial state change event. Pass the
      if (this.gl?.getGoldenLayoutInstance().isInitialised
        && this.gl?.getGoldenLayoutInstance().openPopouts.every((popout: BrowserWindow) => popout.isInitialised)
      ) {
        // pass the layout once after initialized.
        if (this.initialized == false) {
          this.initialized = true;
          this.registerGoldenLayout();
        }
        this.controlManagerService.updateGlInstance(this.gl.getGoldenLayoutInstance().toConfig(), this.layoutKey as any);
        // localStorage.setItem(this.localStorageKey, JSON.stringify(this.gl.getGoldenLayoutInstance().toConfig()));
      } else {
        // The instance was not yet initialized => repeat the method until the condition above is met.
        setTimeout(() => this.onStateChanged(), 400);
      }
    }
  }

  /**
   * Remove reference of GoldenLayout component at the control manager.
   */
  ngOnDestroy() {
    this.subs.unsubscribe();
    this.controlManagerService.goldenLayouts[this.layoutKey] = null;
  }

  /**
   * Registers its own GoldenLayout component at the control manager.
   * @private
   * TODO: pass some type id to the function to specifically save each root layout instance individually.
   *  E.g. in the case of multiple open instances of course-event layout hosts: Save each layout config
   */
  private registerGoldenLayout() {
    this.controlManagerService.registerGoldenLayout(this.layoutKey as any, this.gl);
  }
}
