<div
  [formGroup]="form"
>
  <!-- Granularities -->
  <kpi4me-granularity-rotator
    formControlName="granularity"
  ></kpi4me-granularity-rotator>

  <div style="display: flex; flex-direction: column" class="double-row">
    <!-- Quarters -->
    <mat-button-toggle-group
      [matTooltip]="'Quartal'"
      formControlName="quarter"
      multiple
    >
      <mat-button-toggle
        style="width: 3.3rem; height: 17px"
        (click)="onToggleAbleClick($event, 'quarter', q.name)"
        *ngFor="let q of quarters"
        [value]="q.name"
      >
        {{ q.name }}
      </mat-button-toggle>
    </mat-button-toggle-group>

    <!-- Months -->
    <mat-button-toggle-group
      [matTooltip]="'Monat'"
      formControlName="month"
      multiple
    >
      <mat-button-toggle
        style="width: 1.1rem; height: 17px"
        (click)="onToggleAbleClick($event, 'month', m.id)"
        *ngFor="let m of months"
        [value]="m.id"
      >
        {{ m.name }}
      </mat-button-toggle>
    </mat-button-toggle-group>
  </div>

  <!-- Years -->
  <mat-button-toggle-group
    [matTooltip]="'Jahr'"
    formControlName="year"
    multiple
  >
    <mat-button-toggle
      style="height: 17px"
      (click)="onToggleAbleClick($event, 'year', y.year)"
      *ngFor="let y of years"
      [value]="y.year"
    >
      {{ y.name }}
    </mat-button-toggle>
  </mat-button-toggle-group>


  <button
    mat-icon-button
    class="icon-button-centered align-right icon-button-wrapper"
    (click)="resetSelection()"
    [disabled]="resetIsDisabled()"
    [matTooltip]="'Zeitfilter zurücksetzen'"
  >
    <mat-icon
      svgIcon="{{EsvgFiles.refresh}}"
      class="light-icon"
    ></mat-icon>
  </button>
</div>
