import {IAction} from './interfaces/actions.interface';
import {EControlActions, EsvgFiles} from 'frontier/nucleus';

export const CSV_EXPORT_ACTION: IAction = {
  displayName: 'CSV Export',
  controlAction: EControlActions.csvExport,
  disabledIfNoSelection: false,
  disabledIfMultiSelection: false,
  color: 'primary',
  icon: EsvgFiles.download,
};

export const DEFAULT_TABLE_ACTIONS: IAction[] = [
  {
    displayName: 'Neuer Eintrag',
    controlAction: EControlActions.create,
    disabledIfNoSelection: false,
    disabledIfMultiSelection: false,
    color: 'accent',
    icon: EsvgFiles.add,
  },
  {
    displayName: 'Löschen',
    controlAction: EControlActions.delete,
    disabledIfNoSelection: true,
    disabledIfMultiSelection: false,
    color: 'warn',
    icon: EsvgFiles.delete,
  },
  CSV_EXPORT_ACTION,
];
