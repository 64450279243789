import {Observable} from 'rxjs';
import {ApiConfigService} from '../api-config.service';
import {HttpHeaders} from '@angular/common/http';

export class Auth {
  http;
  url;
  httpOptions;
  constructor(protected appConfig: ApiConfigService) {
    this.http = appConfig.http;
    this.url = appConfig.url;
    this.httpOptions = appConfig.httpOptions;
  }


  Callback() : Observable<string> {
    return this.http.get(
      this.url + '/extern/api/auth/Callback/', this.httpOptions
    ) as unknown as Observable<string>;
  }

  OAuthEnabled() : Observable<boolean> {
    return this.http.get(
      this.url + '/extern/api/auth/OAuthEnabled/', this.httpOptions
    ) as unknown as Observable<boolean>;
  }

}
