@if (data) {
  <ag-grid-angular
    [class]="'ag-theme-alpine'"
    [gridOptions]="gridOptions"
    [rowData]="data.rowData"
    (cellValueChanged)="cellValueChanged.emit($event)"
    (gridReady)="onGridReady($event)"
    [columnDefs]="data.columnDefs"
    (filterModified)="onColumnFilterChange($event)"
    (sortChanged)="onSortChange($event)"
  >
  </ag-grid-angular>
}
