import {Component, HostListener, ViewChild, ViewEncapsulation} from '@angular/core';
import {ITableVerticalDataRow} from '../../table-vertical-api-adapter';
import {BaseCellEditorComponent} from '../base-cell-editor/base-cell-editor.component';
import {FormControl} from '@angular/forms';
import {EControlActions, IControlObject} from 'frontier/nucleus';
import {
  SelectElementComponent
} from '../../../form-control/dynamic-form/form-element/select-element/select-element.component';
import {
  ISelectFormElement,
  ISelectFormOption
} from '../../../form-control/dynamic-form/form-element/form-data.interface';
import {IApiRow} from 'frontier/browserkit';

@Component({
  selector: 'kpi4me-repository-selection-cell-editor',
  templateUrl: './repository-selection-cell-editor.component.html',
  styleUrls: ['./repository-selection-cell-editor.component.scss'],
  encapsulation: ViewEncapsulation.Emulated,
})
export class RepositorySelectionCellEditorComponent extends BaseCellEditorComponent {
  @ViewChild(SelectElementComponent) selectElement: SelectElementComponent;

  formControl: FormControl<IControlObject>;

  selectElementData: ISelectFormElement;

  @HostListener('keydown.enter', ['$event']) onEnter(event: KeyboardEvent) {
  }

  set row(r: ITableVerticalDataRow) {
    this.selectElementData = {options: r.repository};
    super.row = r;
    // this.initialValue = r.value;
    //
    // this._row = r;
    //
    // this.formControl = new FormControl(
    //   r.value
    // );
  }

  onBlur() {
    return;
  }

  get row(): ITableVerticalDataRow {
    return super.row;
  }

  ngOnInit(): void {
    return null;
  }

  compareWith(o1: any, o2: any) {
    console.log(o1, o2);
    return o1 && o2 && o1.signature === o2.signature;
  }

  getValueForApi = () => {
    return this.formControl.value || null;
  }

  onNewElement(event: string) {
    this.coreService.TableAction.newselectionentry(
      this.row.apiInstance.instanceid,
      event,
      this.row.attribute,
      {type: this.row.apiInstance.type, rowid: this.row.apiInstance.rowid, signature: this.row.apiInstance.signature},
    ).subscribe((repo) => {
        this.selectElement.options = repo.map((repoObject: IControlObject) => {
          let option: ISelectFormOption = {
            value: repoObject,
            name: repoObject.name
          }
          return option;
        });
        this.cdr.detectChanges();
        this.selectionDataChanged.emit({GUID: null, changeType: EControlActions.newSelectionEntry});

        // this.formControl.patchValue(repo.find((o: IControlObject) => o.name === event));
        // this.confirmChange();
      }
    )
  }

  onDeleteElement(event: ISelectFormOption) {
    this.coreService.TableAction.deleteselectionentry(
      this.row.apiInstance.instanceid,
      event.value,
      this.row.attribute,
      {type: this.row.apiInstance.type, rowid: this.row.apiInstance.rowid, signature: this.row.apiInstance.signature},
    ).subscribe(() => {
      this.selectElementData = {
        ...this.selectElementData,
        options: this.selectElementData.options.filter(obj => {
          return obj.signature !== event.value.signature
        })
      };
      this.updateTable.emit();
      this.cdr.detectChanges();
      this.selectionDataChanged.emit({GUID: null, changeType: EControlActions.deleteSelectionEntry});
    })
  }

  onUpdateTable() {
    this.updateTable.emit();
  }

  onChangeElement(event: { oldEntry: { value: ISelectFormOption, name: string }, newName: string }) {
    this.coreService.TableAction.changeselectionentry(
      this.row.apiInstance.instanceid,
      event.newName,
      event.oldEntry.value,
      this.row.attribute,
      {type: this.row.apiInstance.type, rowid: this.row.apiInstance.rowid, signature: this.row.apiInstance.signature},
    ).subscribe((repo) => {
      this.selectElementData = {...this.selectElementData, options: repo as ISelectFormOption[]};
      this.formControl.patchValue(repo.find(o => o.name === event.newName));
      this.confirmChange();
      this.updateTable.emit();
      this.selectionDataChanged.emit({GUID: null, changeType: EControlActions.changeSelectionEntry});

      // TODO: Refresh repository
    })
  }

  getErrorMessage(): string {
    if (this.row.invalidMessage) {
      this.formControl.setErrors({linkedTelFieldsError: true});
      return 'Sie müssen zu einer Telefonnummer auch stets die Art des Anschlusses angeben.';
    }
    return '';
  }
}
