<h2 *ngIf="header">{{header}}</h2>
<!--<mat-form-field>-->
<!--  <mat-label>Selektion</mat-label>-->
<!--  <mat-select>-->
<!--    <mat-option *ngFor="let o of apiRows">-->
<!--      {{o | json}}-->
<!--    </mat-option>-->
<!--  </mat-select>-->
<!--</mat-form-field>-->
<table>
  <!--  <thead>-->
  <!--  <tr>-->
  <!--    <th>Feld</th>-->
  <!--    <th></th>-->
  <!--  </tr>-->
  <!--  </thead>-->

  <tbody>
  <tr *ngIf="hasImports && showImports">
    <td></td>
    <td></td>
    <td class="imported-cell">Importierter Wert</td>
  </tr>
  <tr *ngFor="let r of rows">
    <td>{{r.fieldName}}</td>
    <td>
      <kpi4me-cell-editor
        (cellChanged)="onCellChange($event)"
        (updateTable)="onUpdateTable()"
        (selectionDataChange)="onSelectionDataChange($event)"
        [params]="r"
      ></kpi4me-cell-editor>
    </td>
    <td
      class="imported-cell"
      *ngIf="showImports"
    >
      {{r.imported}}
    </td>
  </tr>
  </tbody>
</table>
