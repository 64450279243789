import {Component, EventEmitter, HostListener, Input, Output, ViewEncapsulation} from '@angular/core';
import {EFormControlType, IFormElement, IFormElementStyle,} from './form-data.interface';
import {AbstractControl, FormControl} from '@angular/forms';

const
  invalidMessageValidator = (control: AbstractControl) => {
    console.log(control)
  }

@Component({
  selector: 'kpi4me-base-form-element',
  encapsulation: ViewEncapsulation.Emulated,
  template: `

  `
})
export class BaseFormElementComponent {
  EFormControlType = EFormControlType;
  _formControlElement: AbstractControl;
  @Output() modelChange = new EventEmitter();

  @Input() set formControlElement(c: AbstractControl) {
    if (c) {
      this._formControlElement = c;
    }
  };

  get formControlElement(): FormControl {
    return this._formControlElement as FormControl;
  }

  @Input() value: any;
  @Input() key: string;
  @Input() label?: string;
  @Input() required?: boolean;
  type?: EFormControlType;
  @Input() styling: IFormElementStyle;


  @Input() set data(options: IFormElement) {
    this.value = options.value;
    this.key = options.key || '';
    this.label = options.label || '';
    this.required = !!options.required;
    this.type = options.formControlType || null;
    this.styling = options.styling || null;
  }

  @Output() focusOutEvent = new EventEmitter<FormControl>();
  @Output() updateTable = new EventEmitter();

  @HostListener('focusout', ['$event']) onFocusOut(evt: FocusEvent) {
    this.focusOutEvent.emit(this._formControlElement as FormControl)
  }

  constructor() {
  }

  getFormControl() {
    return this.formControlElement as FormControl;
  }
}
