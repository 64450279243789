<!--<mat-select style="width: 100%" [compareWith]="compareWith"-->
<!--        [(ngModel)]="row.value" (ngModelChange)="onSelectionChange()"-->
<!--        class="form-select" aria-label="Default select example">-->
<!--  <mat-option [value]="o" *ngFor="let o of row.repository; index as i">-->
<!--    {{o.name}}-->
<!--  </mat-option>-->
<!--</mat-select>-->

<kpi4me-select-element class="form-field-no-padding"
                       [data]="selectElementData"
                       [formControlElement]="formControl"
                       (newElementClick)="onNewElement($event)"
                       (deleteElementClick)="onDeleteElement($event)"
                       (changeElementClick)="onChangeElement($event)"
                       (updateTable)="onUpdateTable()"
                       [createNewEnabled]="true"
                       [emptyOptionAdded]="true"
                       #tooltip="matTooltip"
                       (modelChange)="confirmChange()"
                       [matTooltip]="getErrorMessage()"
                       [matTooltipDisabled]="getErrorMessage() === ''"
></kpi4me-select-element>
