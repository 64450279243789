import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  HostListener,
  OnDestroy,
  Renderer2,
  ViewEncapsulation
} from '@angular/core';
import {BehaviorSubject, of, Subscription} from 'rxjs';
import {debounceTime, map, mergeMap, tap} from 'rxjs/operators';
import {BaseCellEditorComponent} from '../base-cell-editor/base-cell-editor.component';
import {CoreService} from 'frontier/nucleus';
import {ITableVerticalDataRow} from "../../table-vertical-api-adapter";

@Component({
  selector: 'kpi4me-autocomplete-editor',
  templateUrl: './autocomplete-editor.component.html',
  styleUrls: ['./autocomplete-editor.component.scss'],
  encapsulation: ViewEncapsulation.Emulated,
})
export class AutocompleteEditorComponent extends BaseCellEditorComponent implements OnDestroy {
  filteredEntities = new BehaviorSubject<any>([]);
  subs = new Subscription();
  entities: any[] = [];
  displayWith = (value: any) => {
    if (value) {
      return typeof value === 'string' ? value : value.name;
    }
  };
  edited = false;

  @HostListener('keydown.enter', ['$event']) onEnter(event: KeyboardEvent) {
    // if (this.edited) {
    //   this.onOptionSelected()
    // }
  }

  @HostListener('focusout', ['$event']) onBlur() {
    // if (this.edited) {
    //   this.onOptionSelected()
    // }
  }

  constructor(
    protected coreService: CoreService,
    protected renderer: Renderer2,
    protected element: ElementRef,
    protected cdr: ChangeDetectorRef
  ) {
    super(coreService, renderer, element, cdr);

  }

  set row(r: ITableVerticalDataRow) {
    super.row = r;
    this.subs.add(this.formControl.valueChanges.pipe(
      tap(v => console.log(v)),
      debounceTime(0),
      mergeMap(value => {
        return value ? this.getEntities(value) : []
      }),
    ).subscribe((entities) => {
      this.filteredEntities.next(entities);
    }));
  }

  get row(): ITableVerticalDataRow {
    return super.row;
  }

  ngOnInit() {
    super.ngOnInit();
    console.log(this);

  }

  ngOnDestroy() {
    this.subs.unsubscribe();
  }

  onOptionSelected(): void {
    this.edited = false;
    const data: {
      client: any, location?: string, district?: string, postcode?: string, city?: any
    } = {
      client: this.row.apiRow.obj,
    }
    if (typeof this.formControl.value === 'string') {
      data[this.row.attribute as 'location' | 'district' | 'postcode'] = this.formControl.value;
    } else {
      data.city = this.formControl.value;
    }
    this.coreService.Control.ExecuteAction(
      this.row.apiInstance.instanceid,
      'setcity',
      data
    ).subscribe((res) => {
      this.cellChanged.emit({verticalRow: this.row, apiRow: res});
    });
  }

  getEntities(filterValue: string) {
    if (typeof filterValue !== 'string') {
      return of([]);
    }
    return this.coreService.Control.ExecuteAction(
      this.row.apiInstance.instanceid,
      'getcity',
      {
        [this.row.attribute]: filterValue
      }
    ).pipe(map((rows: any[]) => {
      if (typeof rows === 'string') {
        return [];
      } else if (rows) {
        return rows.map(r => {
          return {
            apiRow: r,
            name: r.name,
          }
        })
      }
      return null
    }))
  }

  sendCustomValue(): void {
    //this.onOptionSelected();
  }
}
