import {Observable} from 'rxjs';
import {ApiConfigService} from '../api-config.service';

export class Campaign {
  http;
  url;
  httpOptions;

  constructor(protected appConfig: ApiConfigService) {
    this.http = appConfig.http;
    this.url = appConfig.url;
    this.httpOptions = appConfig.httpOptions;
  }

  CreateCampaign(aSubscriberId: string, CampaignData: any): Observable<any> {
    return this.http.post(
      this.url + '/extern/api/Campaign/CreateCampaign/',
      {_parameters: [aSubscriberId, CampaignData]},
      this.httpOptions
    ) as unknown as Observable<any>;
  }
}
