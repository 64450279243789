import {ChangeDetectorRef, Component, EventEmitter, Input, Output, ViewChild, ViewEncapsulation} from '@angular/core';
import {BaseFormElementComponent} from '../base-form-element.class';
import {FormControl} from '@angular/forms';
import {IFormElement, ISelectFormElement, ISelectFormOption} from '../form-data.interface';
import {Observable, of} from 'rxjs';
import {MatDialog} from "@angular/material/dialog";
import {NewEntryDialogComponent} from "../../../../../dialogs/basics/new-entry-dialog/new-entry-dialog.component";
import {MatSelect, MatSelectChange} from '@angular/material/select';
import {EsvgFiles} from 'frontier/nucleus';
import {
  ConfirmationDialogComponent
} from '../../../../../dialogs/basics/confirmation-dialog/confirmation-dialog.component';
import {MatCheckboxChange} from '@angular/material/checkbox';
import {map, take} from 'rxjs/operators';
import {
  SearchInputComponent
} from 'frontier/browserkit/src/lib/control/form-control/dynamic-form/search-input/search-input.component';

@Component({
  selector: 'kpi4me-select-element',
  templateUrl: './select-element.component.html',
  styleUrls: ['./select-element.component.scss', '../form-element.component.scss'],
  encapsulation: ViewEncapsulation.Emulated,
})

export class SelectElementComponent extends BaseFormElementComponent {
  @ViewChild('selectElement') selector: MatSelect;
  searchForm = new FormControl('');
  selectAllChecked = false;
  filteredOptions: Observable<ISelectFormOption[]>;
  @ViewChild(SearchInputComponent, {static: false}) searchInput: SearchInputComponent;

  @Input() createNewEnabled = false;
  @Input() singleStringArray = true;
  @Input() multiple: boolean = null;
  @Input() arrowPosition: 'left' | 'right';
  @Input() newButtonLabel = 'Neues Element';
  @Input() deleteTooltip = 'Eintrag löschen';
  @Input() editTooltip = 'Eintrag bearbeiten';
  @Input() copyTooltip = 'Eintrag duplizieren';
  @Input() deleteEnabled = true;
  @Input() copyEnabled = false;
  @Input() deleteAlwaysEnabled = false;
  @Input() editEnabled = true;
  @Input() clearEnabled = false;
  @Input() disabled = false;
  @Input() confirmButton = false;
  @Input() emptyOptionAdded = false;
  @Input() icon: string;

  @Output() newElementClick = new EventEmitter<string>();
  @Output() deleteElementClick = new EventEmitter<ISelectFormOption>();
  @Output() changeElementClick = new EventEmitter<{ oldEntry: ISelectFormOption, newName: string }>();
  @Output() copyElementClick = new EventEmitter<ISelectFormOption>();
  @Output() confirm = new EventEmitter();
  @Output() clear = new EventEmitter();
  private _options: ISelectFormOption[];

  @Input() set options(options: ISelectFormOption[]) {
    // Check if options are db objects and if yes map them to selection interface
    if (options && options.length) {
      if (options[0].signature != null) {
        options = options.map(o => ({name: o.name, value: o}))
      }
    }
    this.filteredOptions = of(options);
    this._options = options;
    if (this.multiple) {
      const notEmptyOptions = options.filter((option) => {
        return option.name;
      });
      this.filteredOptions = of(notEmptyOptions);
      this.updateCheckAllCheckbox();
    } else {
      this.filteredOptions = of(this.options);
    }
  }

  get options(): ISelectFormOption[] {
    return this._options;
  }

  @Input() set data(form: ISelectFormElement) {
    if (form == null) return;
    this.filteredOptions = of(form.options);
    this.createNewEnabled = form.createNewEnabled != null ? form.createNewEnabled : this.createNewEnabled;
    this.editEnabled = form.editElementEnabled != null ? form.editElementEnabled : this.editEnabled;
    this.deleteEnabled = form.deleteElementEnabled != null ? form.deleteElementEnabled : this.deleteEnabled;
    this.multiple = form.multiple != null ? form.multiple : false;
    this.arrowPosition = form.arrowPosition;
    this.options = form.options || [];
    this.confirmButton = form.confirmButton;
    this.emptyOptionAdded = form.emptyOptionAdded != null ? form.emptyOptionAdded : this.emptyOptionAdded;
    super.data = form as IFormElement;
  }

  @Input() compareWith = (e1: any, e2: any) => {
    if (e1 != null && e2 != null) {
      if (typeof e1 === "object") {
        return e1.signature === e2.signature;
      }
      return e1 === e2;
    }
    return false;
  };

  constructor(
    protected dialog: MatDialog,
    protected cdr: ChangeDetectorRef,
  ) {
    super();
  }

  onSearchChange(evt: string) {
    this.filteredOptions = of(this.options.filter(o => {
      if (this.multiple) {
        return o.name && o.name.toLowerCase().includes(evt.toLowerCase());
      }
      return o.name.toLowerCase().includes(evt.toLowerCase());
    }));
  }

  focusSearchInput() {
    this.searchInput.focusInput();
  }

  /**
   * Adds a new entry to a dynamic array via a dialog in which the user can name that entry
   * @param categoryLabel: category name to display for user of the dynamic array to which they are about to set a new
   * entry
   */
  createNewOption(categoryLabel?: string): void {
    if (this.createNewEnabled) {
      if (this.singleStringArray) {
        this.dialog.open(
          NewEntryDialogComponent,
          {data: {edit: false, categoryLabel: categoryLabel ? categoryLabel : null}},
        ).afterClosed().subscribe(res => {
          if (res) {
            this.newElementClick.emit(res);
          }
        });
      } else {
        this.newElementClick.emit();
      }
    } else {
      this.newElementClick.emit('');
    }
  }

  /**
   * Deletes an entry from a dynamic array
   * @param evt: click on delete icon
   * @param obj: entry to be deleted from its dynamic array
   */
  delete(evt: MouseEvent, obj: ISelectFormOption): void {
    evt.stopPropagation();
    this.formControlElement.setValue([obj]);
    this.dialog.open(
      ConfirmationDialogComponent,
      {
        data: {
          title: 'Wollen Sie den Eintrag ' + obj.name + ' wirklich löschen?',
          description: 'Die Löschung kann nicht rückgängig gemacht werden.',
          confirmButtonText: 'Ja',
          cancelButtonText: 'Nein',
        }
      }
    ).afterClosed().subscribe(res => {
      if (res) {
        this.deleteElementClick.emit(obj);
      }
    });
  }

  /**
   * Changes an entry in a dynamic array via a dialog in which the user can name that entry
   * @param evt: click on delete icon
   * @param o: the object of the entry to change with its old name
   */
  edit(evt: MouseEvent, o: ISelectFormOption): void {
    evt.stopPropagation();
    this.dialog.open(
      NewEntryDialogComponent,
      {data: {oldValue: o.name}},
    ).afterClosed().subscribe(res => {
      if (res) {
        this.changeElementClick.emit({oldEntry: o, newName: res});
      }
    });
  }

  copy(evt: MouseEvent, o: ISelectFormOption): void {
    evt.stopPropagation();
    this.copyElementClick.emit(o);
  }

  getErrorMessage(): string {
    if (this.formControlElement.hasError('required')) {
      return 'Bitte füllen Sie dieses Feld aus.';
    }
    return '';
  }

  isOptionUnused(o: ISelectFormOption) {
    if (!(o.value)) {
      return false;
    }
    return !o.value.inuse;
  }

  updateInUseValues(evt: MatSelectChange) {
    if (evt.value.signature) {
      this.options = this.options.map((option: ISelectFormOption) => {
        if (evt.value.signature) {
          if (option.value.signature === evt.value.signature) {
            return {...option, value: {...option.value, inuse: true}};
          }
        }
        return option;
      });
      this.filteredOptions = of(this.options);
    }
  }

  clearSelection() {
    this.clear.emit();
  }

  protected readonly EsvgFiles = EsvgFiles;

  onSelectionChange(evt: MatSelectChange): void {
    this.modelChange.emit(evt.value);
    this.updateCheckAllCheckbox();
  }

  updateCheckAllCheckbox() {
    this.filteredOptions.pipe(take(1), map(options => options.length)).subscribe(l => {
      this.selectAllChecked = this._formControlElement?.value?.length === l;
      this.cdr.detectChanges();
    })
  }

  onSelectAll(evt: MatCheckboxChange): void {
    this.filteredOptions.pipe(
      take(1),
    ).subscribe((options) => {
      console.log(this._formControlElement.value)
      let updatedOptions: ISelectFormOption[];
      if (evt.checked) {
        updatedOptions = options.map((o) => o.value);
      } else {
        updatedOptions = [];
      }
      this._formControlElement.setValue(updatedOptions);
      this.modelChange.emit(updatedOptions);
    })
  }
}
