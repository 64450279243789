import {Observable} from 'rxjs';
import {ApiConfigService} from '../api-config.service';

export class Call {
  http;
  url;
  httpOptions;

  constructor(protected appConfig: ApiConfigService) {
    this.http = appConfig.http;
    this.url = appConfig.url;
    this.httpOptions = appConfig.httpOptions;
  }

  CallMeasure(aSubscriberId: string, aCall: any): Observable<any> {
    return this.http.post(
      this.url + '/extern/api/call/CallMeasure/',
      {_parameters: [aSubscriberId, aCall]},
      this.httpOptions
    ) as unknown as Observable<any>;
  }

  Daybutton(aSubscriberId: string, aDate: string): Observable<any> {
    return this.http.post(
      this.url + '/extern/api/call/Daybutton/',
      {_parameters: [aSubscriberId, aDate]},
      this.httpOptions
    ) as unknown as Observable<any>;
  }

  ActivateApp(SubscriberId: string): Observable<any> {
    return this.http.post(
      this.url + '/extern/api/call/ActivateApp/',
      {_parameters: [SubscriberId]},
      this.httpOptions
    ) as unknown as Observable<any>;
  }

  DeactivateApp(SubscriberId: string): Observable<any> {
    return this.http.post(
      this.url + '/extern/api/call/DeactivateApp/',
      {_parameters: [SubscriberId]},
      this.httpOptions
    ) as unknown as Observable<any>;
  }
}
