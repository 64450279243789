import {ChangeDetectorRef, Component, Inject, ViewChild} from '@angular/core';
import {
  CalendarService,
  ControlManagerService,
  DEFAULT_TABLE_ACTIONS,
  GoldenLayoutContentComponent,
  IAction,
  SharedGridOptions,
  TableControlComponent
} from 'frontier/browserkit';
import {CoreService, EcalculusControlGUID, IApiControl} from 'frontier/nucleus';
import {GridOptions} from 'ag-grid-community';
import {GoldenLayoutComponent, GoldenLayoutComponentHost, GoldenLayoutContainer} from '@kpi4me/golden-angular-layout';
import GoldenLayout from '@kpi4me/golden-layout';
import {MatSnackBar} from '@angular/material/snack-bar';
import {combineLatest, debounceTime, Subject, switchMap} from 'rxjs';
import {filter} from 'rxjs/operators';

@Component({
  selector: 'app-alternative-payer',
  templateUrl: './alternative-payer.component.html',
  styleUrl: './alternative-payer.component.scss'
})
export class AlternativePayerComponent  extends GoldenLayoutContentComponent {
  @ViewChild('config') configRef: TableControlComponent;
  @ViewChild(TableControlComponent) tableRef: TableControlComponent;

  apiInstance: IApiControl;
  guid = EcalculusControlGUID.AlternativePayerView;
  gridOptions: GridOptions = {
    ...SharedGridOptions,
  };
  configTableActions: IAction[] = [
    ...DEFAULT_TABLE_ACTIONS,
  ];
  apiInstanceInitialized$: Subject<IApiControl> = new Subject();

  constructor(
    @Inject(GoldenLayoutComponentHost) protected goldenLayout: GoldenLayoutComponent,
    @Inject(GoldenLayoutContainer) protected container: GoldenLayout.Container,
    protected cdr: ChangeDetectorRef,
    protected controlManager: ControlManagerService,
    protected coreService: CoreService,
    public snackbar: MatSnackBar,
    private calendarService: CalendarService
  ) {
    super(goldenLayout, container, cdr, controlManager);

    this.subs.add(
      combineLatest([
        this.calendarService.globalForkedEvents$.pipe(filter(v => v != null)),
        this.apiInstanceInitialized$
      ]).pipe(
        debounceTime(100),
        switchMap(([filter, apiInstance]) => {
          // Update the apiInstance and trigger the fetching of the data with the new filter.
          return this.tableRef.setApiInstanceAndFetch({
            ...apiInstance,
            filter: {
              ...apiInstance?.filter,
              ...filter
            },
          });
        }),
      ).subscribe()
    );

  }

  protected onResize(): void {
  }
}
