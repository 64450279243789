import {ChangeDetectorRef, Component, EventEmitter, inject, Input, OnInit, Output} from '@angular/core';
import {ICheckboxFormElement, ITextFormElement} from 'frontier/browserkit';
import {IEPostConfig} from './epost-config-form.interface';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {EsvgFiles} from 'frontier/nucleus';


@Component({
  selector: 'app-epost-config',
  templateUrl: './epost-config-form.component.html',
  styleUrls: ['./epost-config-form.component.scss']
})
export class EpostConfigFormComponent implements OnInit {
  fb = inject(FormBuilder);
  EsvgFiles = EsvgFiles;

  @Input() set ePostConfig(config:IEPostConfig) {
    if (config == null) return;
    this.form.patchValue(config, {emitEvent: false});
    this.cdr.detectChanges();
  };

  @Output() requestSmsClicked = new EventEmitter();
  @Output() healthCheckClicked = new EventEmitter();

  form: FormGroup = this.fb.group({
    active: this.fb.control(null),
    ekpNumber: this.fb.control(null,[Validators.required]),
    duplex: this.fb.control(null,[Validators.required]),
    color: this.fb.control(null,[Validators.required]),
    senderName: this.fb.control(null,[Validators.required]),
    senderStreet: this.fb.control(null,[Validators.required]),
    senderHouseNumber: this.fb.control(null,[Validators.required]),
    senderPostCode: this.fb.control(null,[Validators.required]),
    senderCity: this.fb.control(null,[Validators.required])
  });

  @Output() formChange = this.form.valueChanges;

  epkText: ITextFormElement = {
    inputType: 'text',
    label: 'Ihre E-Post Kundennummer'
  }
  duplex: ICheckboxFormElement = {
    displayAs: 'toggle',
    label: 'Beidseitiger Druck (Duplex)',
    key: 'duplex',
  };
  color: ICheckboxFormElement = {
    displayAs: 'toggle',
    label: 'Farbig drucken',
    key: 'color'
  };


  constructor(private cdr: ChangeDetectorRef) {
  }

  ngOnInit(): void {
  }

  isActive() {
    return this.form.get('active').value === true;
  }

  onRequestSms() {
    this.requestSmsClicked.emit()
  }

  onHealthCheck() {
    this.healthCheckClicked.emit();
  }
}
