import {Observable} from 'rxjs';
import {ApiConfigService} from '../api-config.service';
import {HttpHeaders} from '@angular/common/http';
import {IControlObject} from '../../interfaces/control-object.interface';

export class Outboxbook {
  http;
  url;
  httpOptions;
  constructor(protected appConfig: ApiConfigService) {
    this.http = appConfig.http;
    this.url = appConfig.url;
    this.httpOptions = appConfig.httpOptions;
  }


  rearrangedta(InstanceId: string, document: IControlObject) : Observable<boolean> {
   return this.http.post(
      this.url + '/intern/api/control/ExecuteAction/' + InstanceId + '/rearrangedta/', {"document": document}, this.httpOptions
    ) as unknown as Observable<boolean>;
  }

  rearrangedocument(InstanceId: string, document: IControlObject, jobtype: number) : Observable<boolean> {
   return this.http.post(
      this.url + '/intern/api/control/ExecuteAction/' + InstanceId + '/rearrangedocument/', {"document": document, "jobtype": jobtype}, this.httpOptions
    ) as unknown as Observable<boolean>;
  }

  arrangeallinvoices(InstanceId: string, ) : Observable<any> {
   return this.http.post(
      this.url + '/intern/api/control/ExecuteAction/' + InstanceId + '/arrangeallinvoices/', {}, {...this.httpOptions, responseType: 'blob'}) as unknown as Observable<any>;
  }

  downloadcurrentinvoice(InstanceId: string, document: IControlObject) : Observable<any> {
   return this.http.post(
      this.url + '/intern/api/control/ExecuteAction/' + InstanceId + '/downloadcurrentinvoice/', {"document": document}, {...this.httpOptions, responseType: 'blob'}) as unknown as Observable<any>;
  }

}
