<div
  *ngIf="form"
  [formGroup]="form"
  class="form-wrapper"
>
  <div class="group-wrapper" *ngFor="let fg of formData?.formGroups">
    <div class="mat-h3" style="margin-bottom: 0; display: flex; align-items: center;">
      <mat-icon
        *ngIf="fg.closable"
        class="centered" style="display: flex; cursor: pointer" svgIcon="arrow_left"
        [style.transform]="fg.closed ? 'rotate(180deg)' : 'rotate(-90deg)'"
        (click)="fg.closed = fg.closed == null ? true : !fg.closed"></mat-icon>
      <span *ngIf="displayHeaders">{{fg.header}}</span>
    </div>

    <mat-divider *ngIf="displayHeaders" class="header-divider"></mat-divider>

    <div
      *ngIf="standardForm; else tableForm"
      [class]=" fg.closed=== true ?'section-wrapper closed' : 'section-wrapper'"
      [style.grid-template-columns]="getTemplateColumns(fg)"
    >
      <ng-container *ngFor="let fc of fg.elements; let i = index;">
        <kpi4me-form-element
          [style.grid-row-start]="'auto'"
          style="grid-column-end: -1; grid-column-start: 1"
          (focusOutEvent)="onFocusOutOnFormControlElement($event, fg)"
          *ngIf="form.controls[fc.key]"
          [formControlElement]="form.controls[fc.key]"
          [formData]="fc"
        ></kpi4me-form-element>
      </ng-container>
    </div>

    <ng-template #tableForm>
      <div
        [class]=" fg.closed === true ?'section-wrapper closed' : 'section-wrapper'"
        [style.grid-template-columns]="getTemplateColumns(fg)"
      >
        <table>
          <tbody style="vertical-align: baseline">
          <tr *ngIf="hasImports && showImports">
            <td></td>

            <td></td>

            <td class="imported-cell">Importierter Wert</td>
          </tr>

          <tr *ngFor="let fc of fg.elements; let i = index;">
            <td class="label-cell">{{fc.tableLabel}}</td>

            <td>
              <kpi4me-form-element
                [style.grid-row-start]="'auto'"
                style="grid-column-end: -1; grid-column-start: 1"
                (focusOutEvent)="onFocusOutOnFormControlElement($event, fg)"
                *ngIf="form.controls[fc.key]"
                [formControlElement]="form.controls[fc.key]"
                [formData]="fc"
              ></kpi4me-form-element>
            </td>

            <td
              class="imported-cell"
              *ngIf="showImports"
            >
              {{fc.imported}}
            </td>
          </tr>
          </tbody>
        </table>
      </div>
    </ng-template>
  </div>

  <button *ngIf="formData?.confirmable" mat-raised-button color="primary" style="max-width: 10rem">Speichern</button>
</div>
