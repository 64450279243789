import {Injectable, NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {
  NgbDateAdapter,
  NgbDateParserFormatter,
  NgbDatepickerConfig,
  NgbDatepickerModule,
  NgbDateStruct,
  NgbTooltipModule,
} from '@ng-bootstrap/ng-bootstrap';
import {NgbDateDEParserFormatter} from './calendar/ngb-date-de-parser-formatter';
import {CalendarComponent} from './calendar/calendar.component';
import {MatTooltipModule} from '@angular/material/tooltip';
import {MatDividerModule} from '@angular/material/divider';
import {MatButtonModule} from '@angular/material/button';
import {MatRippleModule} from '@angular/material/core';
import {MatIconModule} from '@angular/material/icon';
import {MatSnackBarModule} from '@angular/material/snack-bar';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {EasyDatePickerModule} from './easy-date-picker/easy-date-picker.module';

function ngbDateConfiguration() {
  const ngbDateConfig = new NgbDatepickerConfig();
  ngbDateConfig.minDate = {year: 1899, month: 1, day: 1};
  ngbDateConfig.maxDate = {year: 2099, month: 12, day: 31};
  return ngbDateConfig;
}

@Injectable()
class NgbDateAdapterDefault extends NgbDateAdapter<any> {
  toModel(date: NgbDateStruct | null): any {
    return date;
  }

  fromModel(value: any): NgbDateStruct | null {
    return value;
  }
}

@NgModule({
  declarations: [CalendarComponent],
  imports: [
    CommonModule,
    NgbDatepickerModule,
    NgbTooltipModule,
    MatTooltipModule,
    MatDividerModule,
    MatButtonModule,
    MatRippleModule,
    MatIconModule,
    MatSnackBarModule,
    FormsModule,
    EasyDatePickerModule,
    ReactiveFormsModule,
  ],
  exports: [CalendarComponent],
  providers: [
    {provide: NgbDateAdapter, useClass: NgbDateAdapterDefault},
    {
      provide: NgbDateParserFormatter,
      useClass: NgbDateDEParserFormatter,
    },
    {
      provide: NgbDatepickerConfig,
      useFactory: ngbDateConfiguration,
    },
  ],
})
export class CalendarModule {
}
