import {ChangeDetectorRef, Component, Inject, OnDestroy, ViewChild, ViewEncapsulation} from '@angular/core';
import {GridOptions} from 'ag-grid-community';
import {changes, CoreService, EControlActions, EControlGUID} from 'frontier/nucleus';
import {SharedGridOptions} from '../../control/table-control/shared-gridoptions.const';
import {IAction} from "../../control/table-control/interfaces/actions.interface";
import {DEFAULT_TABLE_ACTIONS} from "../../control/table-control/default-table-actions.constant";
import {catchError, filter, tap} from "rxjs/operators";
import {of, Subscription} from "rxjs";
import {TableControlComponent} from "../../control/table-control/table-control.component";
import {DialogService} from "../../dialogs/dialog.service";
import {SetPasswordDialogComponent} from "../../dialogs/basics/set-password-dialog/set-password-dialog.component";
import {GoldenLayoutContentComponent} from "../../golden-layout/golden-layout-content/golden-layout-content.component";
import {GoldenLayoutComponent, GoldenLayoutComponentHost, GoldenLayoutContainer} from '@kpi4me/golden-angular-layout';
import GoldenLayout from '@kpi4me/golden-layout';
import {ControlManagerService} from "../../control-manager/control-manager.service";
import {EventService} from "../../services/event.service";

@Component({
  selector: 'kpi4me-user-list',
  templateUrl: './user-list.component.html',
  styleUrls: ['./user-list.component.scss'],
  encapsulation: ViewEncapsulation.Emulated,
})
export class UserListComponent extends GoldenLayoutContentComponent implements OnDestroy {
  @ViewChild(TableControlComponent, {static: false})
  tableRef: TableControlComponent;

  gridOptions: GridOptions = {
    ...SharedGridOptions,
    rowSelection: 'single',
  };

  actions: IAction[] = [
    ...DEFAULT_TABLE_ACTIONS, {
      displayName: 'Passwort ändern',
      controlAction: EControlActions.resetpassword,
      disabledIfNoSelection: true,
      disabledIfMultiSelection: false,
      color: 'primary',
    },
  ]
  userGUID = EControlGUID.tableUserList;

  constructor(@Inject(GoldenLayoutComponentHost)
              protected goldenLayout: GoldenLayoutComponent,
              @Inject(GoldenLayoutContainer) protected container: GoldenLayout.Container,
              protected cdr: ChangeDetectorRef,
              protected controlManager: ControlManagerService,
              private coreService: CoreService,
              private dialogService: DialogService,
  ) {
    super(goldenLayout, container, cdr, controlManager);

    this.subs.add(
      this.coreService.controlDataChanged.pipe(
        filter(evt => {
          return new Map([
            [EControlGUID.tableUserGroupList, changes],
          ]).get(evt.GUID as EControlGUID)?.has(evt.changeType);
        }),
        tap(() => {
          this.tableRef.refreshInfiniteCache();
        })).subscribe(),
    );
  }

  onCustomTableAction(evt: any) {
    evt = evt as unknown as EControlActions;
    if (evt === 'resetpassword') {
      this.resetPassword();
    } else {
      console.error('There exists no function mapped to ' + evt);
    }
  }

  private resetPassword() {
    const gridApi = this.tableRef.gridApi;
    const selectedRows = gridApi.getSelectedNodes();
    const objectInstances: any[] = selectedRows.map((r) => {
      return r.data.apiRow.obj;
    });
    const dialogRef = this.dialogService.dialog.open(SetPasswordDialogComponent,
      {
        panelClass: 'customDialogContainer',
        data: {username: objectInstances[0].name, type: 'new', title: 'Neues Passwort setzen'}
      }
    ).afterClosed().subscribe(res => {
      if (res) {
        console.log(dialogRef);
        const password = res;
        this.coreService.UserList.changepassword(
          this.tableRef.apiInstance.instanceid,
          objectInstances[0],
          password
        )
          .pipe(
            tap((response) => {
              this.tableRef.resetRowCache();
              gridApi.refreshInfiniteCache();
              gridApi.redrawRows();
            }),
            catchError((err) => {
              // hide the overlay on error
              gridApi.hideOverlay();
              return of(err);
            })
          )
          .subscribe();
      } else {
        gridApi.hideOverlay();
      }
    });
  }

  protected onResize() {
    if (this.tableRef) {
      this.tableRef.fitColumns();
    }
  }
}
