<mat-tab-group
  #tabGroup
  (resized)="updatePagination()"
>
  <mat-tab *ngIf="cartParent">
    <ng-template mat-tab-label class="mat-tab-label">
          <span>
            Auffälligkeiten
          </span>
    </ng-template>

    <ng-template matTabContent>
      <kpi4me-conspicuity-detail
        (clientReplaced)="onClientReplaced($event)"
        [parent]="cartParent"
      ></kpi4me-conspicuity-detail>
    </ng-template>
  </mat-tab>

  <mat-tab *ngIf="cartParent">
    <ng-template mat-tab-label class="mat-tab-label">
          <span>
            Patient
          </span>
    </ng-template>

    <ng-template matTabContent>
      <kpi4me-client-detail
        [parent]="cartParent"
        [showImports]="showImports"
      ></kpi4me-client-detail>
    </ng-template>
  </mat-tab>

  <mat-tab *ngIf="cartParent">
    <ng-template mat-tab-label class="mat-tab-label">
          <span>
            Transport
          </span>
    </ng-template>

    <ng-template matTabContent>
      <kpi4me-transport-detail
        [parent]="cartParent"
        [showImports]="showImports"
      ></kpi4me-transport-detail>
    </ng-template>
  </mat-tab>

  <mat-tab *ngIf="cartParent">
    <ng-template mat-tab-label class="mat-tab-label">
          <span>
            Verordnung
          </span>
    </ng-template>

    <ng-template matTabContent>
      <kpi4me-crew-detail
        [parent]="cartParent"
        [showImports]="showImports"
      ></kpi4me-crew-detail>
    </ng-template>
  </mat-tab>

  <mat-tab *ngIf="cartParent">
    <ng-template mat-tab-label class="mat-tab-label">
          <span>
            Infos
          </span>
    </ng-template>

    <ng-template matTabContent>
      <kpi4me-cart-additional-detail
        [parent]="cartParent"
      ></kpi4me-cart-additional-detail>
    </ng-template>
  </mat-tab>

  <mat-tab *ngIf="cartParent">
    <ng-template mat-tab-label class="mat-tab-label">
          <span>
            Rechnung
          </span>
    </ng-template>

    <ng-template matTabContent>
      <kpi4me-invoice-detail
        [parent]="cartParent"
      ></kpi4me-invoice-detail>
    </ng-template>
  </mat-tab>

  <mat-tab *ngIf="cartParent">
    <ng-template mat-tab-label class="mat-tab-label">
          <span>
            Dokumente
          </span>
    </ng-template>

    <ng-template matTabContent>
      <kpi4me-trapo-detail
        [cartParent]="cartParent"
      ></kpi4me-trapo-detail>
    </ng-template>
  </mat-tab>

  <mat-tab *ngIf="cartParent">
    <ng-template mat-tab-label class="mat-tab-label">
          <span>
            Interne Notizen
          </span>
    </ng-template>

    <ng-template matTabContent>
      <kpi4me-internal-note-detail
        [parent]="cartParent"
      ></kpi4me-internal-note-detail>
    </ng-template>
  </mat-tab>

  <mat-tab *ngIf="cartParent || clientParent">
    <ng-template mat-tab-label class="mat-tab-label">
          <span>
            Dauerverordnung
          </span>
    </ng-template>

    <ng-template matTabContent>
      <kpi4me-permanent-ordinance
        [clientParent]="clientParent"
        [cartParent]="cartParent"
      ></kpi4me-permanent-ordinance>
    </ng-template>
  </mat-tab>
</mat-tab-group>
