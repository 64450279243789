import {Observable} from 'rxjs';
import {ApiConfigService} from '../api-config.service';
import {HttpHeaders} from '@angular/common/http';
import {IControlObject} from '../../interfaces/control-object.interface';

export class Crudcontrol {
  http;
  url;
  httpOptions;
  constructor(protected appConfig: ApiConfigService) {
    this.http = appConfig.http;
    this.url = appConfig.url;
    this.httpOptions = appConfig.httpOptions;
  }


  changeobject(InstanceId: string, ObjectId: IControlObject, ModifiedObject: any) : Observable<any> {
   return this.http.post(
      this.url + '/intern/api/control/ExecuteAction/' + InstanceId + '/changeobject/', {"ObjectId": ObjectId, "ModifiedObject": ModifiedObject}, this.httpOptions
    ) as unknown as Observable<any>;
  }

  deleteobject(InstanceId: string, ObjectId: IControlObject) : Observable<boolean> {
   return this.http.post(
      this.url + '/intern/api/control/ExecuteAction/' + InstanceId + '/deleteobject/', {"ObjectId": ObjectId}, this.httpOptions
    ) as unknown as Observable<boolean>;
  }

  createobject(InstanceId: string, InitializeWith: any | null) : Observable<any> {
   return this.http.post(
      this.url + '/intern/api/control/ExecuteAction/' + InstanceId + '/createobject/', {"InitializeWith": InitializeWith}, this.httpOptions
    ) as unknown as Observable<any>;
  }

  getallobjects(InstanceId: string, ) : Observable<IControlObject[]> {
   return this.http.post(
      this.url + '/intern/api/control/ExecuteAction/' + InstanceId + '/getallobjects/', {}, this.httpOptions
    ) as unknown as Observable<IControlObject[]>;
  }

  getobject(InstanceId: string, ObjectId: IControlObject) : Observable<any> {
   return this.http.post(
      this.url + '/intern/api/control/ExecuteAction/' + InstanceId + '/getobject/', {"ObjectId": ObjectId}, this.httpOptions
    ) as unknown as Observable<any>;
  }

}
