import {Component, Input, OnInit, Output} from '@angular/core';
import {EsvgFiles} from 'frontier/nucleus';
import {ECalculusStateFilter} from '../../config/state-filter.enum';
import {FormControl} from '@angular/forms';
import {ITableToggler} from './table-toggler.interface';

@Component({
  selector: 'app-table-toggler',
  templateUrl: './table-toggler.component.html',
  styleUrls: ['./table-toggler.component.scss']
})
export class TableTogglerComponent implements OnInit {
  _data: ITableToggler;
  @Input() set data(d) {
    this.isOn.patchValue(d.initialValue, {emitEvent: false})
    this._data = d;
  };
  get data() {
    return this._data;
  }

  isOn = new FormControl(false);
  @Output() stateChange = this.isOn.valueChanges;

  protected readonly EsvgFiles = EsvgFiles;
  protected readonly ECalculusStateFilter = ECalculusStateFilter;
  constructor() { }

  ngOnInit(): void {
  }


}
