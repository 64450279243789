export enum EControlActions {
  addNode = 'Knoten hinzufügen',
  arrange = 'Anordnen',
  arrangeAll = 'Alle Anordnen',
  attestation = 'Bescheinigen',
  cancel = 'Stornieren',
  changeLine = 'Zellenwert ändern', // not for custom action
  changeName = 'Knotennamen ändern',
  changeNodePosition = 'Knotenposition ändern',
  changeSelectionEntry = 'Selektion Eintrag ändern',
  check = 'Geprüftstatus togglen',
  confirm = 'Änderungen bestätigen',
  connection = 'Neue Verbindung anlegen',
  contract = 'Vertrag',
  copy = 'Duplizieren', // not for custom action
  create = 'Erstellen', // not for custom action
  customer = 'Kunde',
  delete = 'Löschen', // not for custom action
  deleteSelectionEntry = 'Selektion Eintrag Löschen',
  deprecate = 'Verwerfen',
  detail = 'Verknüpfte Sicht Öffnen',
  device = 'Neues Gerät anlegen',
  dunning = 'Mahnung erstellen',
  edit = 'Ändern',
  editAll = 'Alle ändern',
  etl = 'ETL-Schritt Ausführen',
  execute = 'Erzeugen',
  export = 'Exportieren',
  import = 'Importieren',
  invoice = 'Rechnung erstellen',
  invoicePreview = 'Rechnung anzeigen',
  load = 'Laden',
  loadAll = 'Alle Laden',
  key = 'Neuen Schlüsseltyp anlegen',
  map = 'Zuordnen',
  mail = 'Elektronische Post',
  newNode = 'Neuen Knoten erstellen',
  newOrdinance = 'Neue Dauerverordnung anlegen',
  newReference = 'Neue Referenz setzen',
  newSelectionEntry = 'Selektion Eintrag erstellen',
  partlyCancel = 'Teilstornieren',
  payed = 'Bezahlt',
  phone = 'Neuen Telefontyp erstellen',
  preview = 'Vorschau anzeigen',
  reinvoice = 'Rechnung erneut erzeugen',
  removeNode = 'Knoten löschen',
  resetpassword = 'Passwort ändern',
  resynchronize = 'Resynchronisieren',
  rule = 'ETL-Regeln Öffnen',
  selectAll = 'Alle Selektieren', // not for custom action
  successor = 'Nachfolger',
  synchronize = 'Synchronisieren',
  uncollectible = 'Uneinbringlich',
  undo = 'Aktion zurücknehmen',
  variantcreate = 'Variante Erstellen',
  newMission = 'Neuer Einsatz',
  credit = 'Gutschrift',
  attendance = 'Anwesenheit bescheinigen',
  replaceClientForCart = 'Patienten daten der Fahrt ersetzen',
  tariffChange = 'Tarifänderung',
  tariffOption = 'Tarifoptionen',
  recalculateItemPriceHistory = 'Preise Rückwirkend Anwenden',
  csvExport = "CSV Export",
  resolveClientMessage = 'Patienten Nachrichten auflösen',
}

export const changes = new Set([
  EControlActions.changeLine,
  EControlActions.create,
  EControlActions.delete,
  EControlActions.copy,
])
