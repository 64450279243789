<div
  id="table-container"
>
  <app-table-control
    style="flex: 1"
    #table
    id="carts-table"
    [fixedPageSize]="fixedPageSize"
    [(apiInstance)]="tableApiInstance"
    [apiAdapter]="cartsApiAdapter"
    [GUID]="cartGUID"
    [actions]="tableActions"
    [gridOptions]="gridOptions"
    [pdf]="pdfBlob$ | async"
    (apiInstanceInitialize)="tableApiInstance$.next($event)"
    (rowSelected)="onCartRowSelected($event)"
    (paginationChanged)="paginationChange$.emit($event)"
  ></app-table-control>
</div>
