import {Observable} from 'rxjs';
import {ApiConfigService} from '../api-config.service';
import {HttpHeaders} from '@angular/common/http';

export class Control {
  http;
  url;
  httpOptions;
  constructor(protected appConfig: ApiConfigService) {
    this.http = appConfig.http;
    this.url = appConfig.url;
    this.httpOptions = appConfig.httpOptions;
  }


  NewInstance(controlGuid: string, filter: any, sort: any, custom: any) : Observable<any> {
    return this.http.post(
      this.url + '/intern/api/control/NewInstance/',
      {_parameters: [controlGuid, filter, sort, custom]}, this.httpOptions
    ) as unknown as Observable<any>;
  }

  DropInstance(InstanceID: string) : Observable<boolean> {
    return this.http.post(
      this.url + '/intern/api/control/DropInstance/',
      {_parameters: [InstanceID]}, this.httpOptions
    ) as unknown as Observable<boolean>;
  }

  FetchData(InstanceID: string) : Observable<any> {
    return this.http.post(
      this.url + '/intern/api/control/FetchData/',
      {_parameters: [InstanceID]}, this.httpOptions
    ) as unknown as Observable<any>;
  }

  GetInstance(InstanceID: string) : Observable<any> {
    return this.http.post(
      this.url + '/intern/api/control/GetInstance/',
      {_parameters: [InstanceID]}, this.httpOptions
    ) as unknown as Observable<any>;
  }

  ChangeInstance(InstanceID: string, filter: any, sort: any, custom: any) : Observable<boolean> {
    return this.http.post(
      this.url + '/intern/api/control/ChangeInstance/',
      {_parameters: [InstanceID, filter, sort, custom]}, this.httpOptions
    ) as unknown as Observable<boolean>;
  }

  ChangeInstanceAndFetch(InstanceID: string, filter: any, sort: any, custom: any) : Observable<any> {
    return this.http.post(
      this.url + '/intern/api/control/ChangeInstanceAndFetch/',
      {_parameters: [InstanceID, filter, sort, custom]}, this.httpOptions
    ) as unknown as Observable<any>;
  }

  SaveInstance(InstanceID: string) : Observable<boolean> {
    return this.http.post(
      this.url + '/intern/api/control/SaveInstance/',
      {_parameters: [InstanceID]}, this.httpOptions
    ) as unknown as Observable<boolean>;
  }

  GetInstances(controlGuid: string) : Observable<any[]> {
    return this.http.post(
      this.url + '/intern/api/control/GetInstances/',
      {_parameters: [controlGuid]}, this.httpOptions
    ) as unknown as Observable<any[]>;
  }

  CopyInstance(InstanceID: string) : Observable<any> {
    return this.http.post(
      this.url + '/intern/api/control/CopyInstance/',
      {_parameters: [InstanceID]}, this.httpOptions
    ) as unknown as Observable<any>;
  }

  ExecuteAction(InstanceID: string, Action: string, Data: any) : Observable<any> {
    return this.http.post(
      this.url + '/intern/api/control/ExecuteAction/',
      {_parameters: [InstanceID, Action, Data]}, this.httpOptions
    ) as unknown as Observable<any>;
  }

  GetTooltip(InstanceID: string, Context: any) : Observable<any> {
    return this.http.post(
      this.url + '/intern/api/control/GetTooltip/',
      {_parameters: [InstanceID, Context]}, this.httpOptions
    ) as unknown as Observable<any>;
  }

  GetControls() : Observable<any> {
    return this.http.get(
      this.url + '/intern/api/control/GetControls/', this.httpOptions
    ) as unknown as Observable<any>;
  }

  ControlAllowed(controlGuid: string) : Observable<boolean> {
    return this.http.post(
      this.url + '/intern/api/control/ControlAllowed/',
      {_parameters: [controlGuid]}, this.httpOptions
    ) as unknown as Observable<boolean>;
  }

  ActionAllowed(controlGuid: string, Action: string) : Observable<boolean> {
    return this.http.post(
      this.url + '/intern/api/control/ActionAllowed/',
      {_parameters: [controlGuid, Action]}, this.httpOptions
    ) as unknown as Observable<boolean>;
  }

  UploadDocument(name: string, dataType: string, rowid: string, classType: string, signature: string, propertyname: string, data: any) : Observable<any> {
    return this.http.post(
      this.url + '/intern/api/control/UploadDocument/' + encodeURI(name) + '/' + encodeURI(dataType) + '/' + encodeURI(rowid) + '/' + encodeURI(classType) + '/' + encodeURI(signature) + '/' + encodeURI(propertyname) + '/', data, {headers: new HttpHeaders({Accept: 'application/json','Content-type': 'application/octet-stream'}),}) as unknown as Observable<any>;
  }

  DownloadDocument(InstanceID: string, rowid: string, classType: string, signature: string) : Observable<any> {
    return this.http.get(
      this.url + '/intern/api/control/DownloadDocument/' + encodeURI(InstanceID) + '/' + encodeURI(rowid) + '/' + encodeURI(classType) + '/' + encodeURI(signature) + '/', {...this.httpOptions, responseType: 'blob'}) as unknown as Observable<any>;
  }

}
