<app-dialog-header [title]="'E-Post-Einstellungen'" (close)="dialogRef.close()"></app-dialog-header>
<div mat-dialog-content>
  <app-epost-config *ngIf="gotData | async"
                    [ePostConfig]="data"
                    (requestSmsClicked)="requestSmsClicked$.emit()"
                    (healthCheckClicked)="healthCheckClicked$.emit()"
                    (formChange)="formChangeEvent$.emit($event)"
  >
  </app-epost-config>
</div>

