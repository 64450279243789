import {Observable} from 'rxjs';
import {ApiConfigService} from '../api-config.service';
import {HttpHeaders} from '@angular/common/http';
import {IControlObject} from '../../interfaces/control-object.interface';

export class Cartlist {
  http;
  url;
  httpOptions;
  constructor(protected appConfig: ApiConfigService) {
    this.http = appConfig.http;
    this.url = appConfig.url;
    this.httpOptions = appConfig.httpOptions;
  }


  getbusinessareas(InstanceId: string, ) : Observable<IControlObject[]> {
   return this.http.post(
      this.url + '/intern/api/control/ExecuteAction/' + InstanceId + '/getbusinessareas/', {}, this.httpOptions
    ) as unknown as Observable<IControlObject[]>;
  }

  createalldunning(InstanceId: string, ) : Observable<boolean> {
   return this.http.post(
      this.url + '/intern/api/control/ExecuteAction/' + InstanceId + '/createalldunning/', {}, this.httpOptions
    ) as unknown as Observable<boolean>;
  }

  setchecked(InstanceId: string, cart: IControlObject) : Observable<any[]> {
   return this.http.post(
      this.url + '/intern/api/control/ExecuteAction/' + InstanceId + '/setchecked/', {"cart": cart}, this.httpOptions
    ) as unknown as Observable<any[]>;
  }

  downloadtrapo(InstanceId: string, cart: IControlObject) : Observable<any> {
   return this.http.post(
      this.url + '/intern/api/control/ExecuteAction/' + InstanceId + '/downloadtrapo/', {"cart": cart}, {...this.httpOptions, responseType: 'blob'}) as unknown as Observable<any>;
  }

  getadditionalinvoicedata(InstanceId: string, cart: IControlObject) : Observable<any[]> {
   return this.http.post(
      this.url + '/intern/api/control/ExecuteAction/' + InstanceId + '/getadditionalinvoicedata/', {"cart": cart}, this.httpOptions
    ) as unknown as Observable<any[]>;
  }

  customabrexport(InstanceId: string, ) : Observable<any[]> {
   return this.http.post(
      this.url + '/intern/api/control/ExecuteAction/' + InstanceId + '/customabrexport/', {}, this.httpOptions
    ) as unknown as Observable<any[]>;
  }

  newcart(InstanceId: string, number: string, date: string, businessarea: IControlObject) : Observable<any> {
   return this.http.post(
      this.url + '/intern/api/control/ExecuteAction/' + InstanceId + '/newcart/', {"number": number, "date": date, "businessarea": businessarea}, this.httpOptions
    ) as unknown as Observable<any>;
  }

  setmanualcheckneeded(InstanceId: string, cart: IControlObject, reason: string) : Observable<boolean> {
   return this.http.post(
      this.url + '/intern/api/control/ExecuteAction/' + InstanceId + '/setmanualcheckneeded/', {"cart": cart, "reason": reason}, this.httpOptions
    ) as unknown as Observable<boolean>;
  }

  arrangeallinvoices(InstanceId: string, ) : Observable<any> {
   return this.http.post(
      this.url + '/intern/api/control/ExecuteAction/' + InstanceId + '/arrangeallinvoices/', {}, {...this.httpOptions, responseType: 'blob'}) as unknown as Observable<any>;
  }

  downloadcurrentinvoice(InstanceId: string, cart: IControlObject) : Observable<any> {
   return this.http.post(
      this.url + '/intern/api/control/ExecuteAction/' + InstanceId + '/downloadcurrentinvoice/', {"cart": cart}, {...this.httpOptions, responseType: 'blob'}) as unknown as Observable<any>;
  }

  createallinvoices(InstanceId: string, ) : Observable<boolean> {
   return this.http.post(
      this.url + '/intern/api/control/ExecuteAction/' + InstanceId + '/createallinvoices/', {}, this.httpOptions
    ) as unknown as Observable<boolean>;
  }

  validcartnumber(InstanceId: string, number: string) : Observable<boolean> {
   return this.http.post(
      this.url + '/intern/api/control/ExecuteAction/' + InstanceId + '/validcartnumber/', {"number": number}, this.httpOptions
    ) as unknown as Observable<boolean>;
  }

}
