import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from '@angular/material/dialog';
import {SelectionModel} from '@angular/cdk/collections';
import {IClientsTileApi} from '../clients-tile.interface';
import {ColDef, ColGroupDef, GetRowIdFunc, GridApi, GridOptions, GridReadyEvent} from 'ag-grid-community';
import {ICartControlObj} from './cart-control-obj.interface';

interface Row {
  elsid: string;
  date: string;
  tariff: string;
  state: string;
  price: string;
  payer: string;
}

@Component({
  selector: 'app-cart-selection',
  templateUrl: './cart-selection.component.html',
  styleUrl: './cart-selection.component.scss'
})
export class CartSelectionComponent implements OnInit {
  selectedEntities = new SelectionModel<string>();
  rowData: any[] | null | undefined;
  colDefs: (ColGroupDef<any> | ColDef<any>)[] | null | undefined = [
    {field: 'select', headerName: '', headerCheckboxSelection: true, checkboxSelection: true},
    {
      field: 'elsid', headerName: 'ELSID'
    },
    {field: 'date', headerName: 'Datum'},
    {field: 'tariff', headerName: 'Tarif'},
    {field: 'price', headerName: 'Preis'},
    {field: 'payer', headerName: 'Kostenträger'},
    {field: 'state', headerName: 'Status'},
  ];
  gridOptions: GridOptions<any> = {
    rowHeight: 30,
    rowSelection: 'multiple',
    rowMultiSelectWithClick: true,
    onGridReady: (event) => {
      event.api.sizeColumnsToFit();
      event.columnApi.autoSizeAllColumns();
    }
  };

  gridApi: GridApi<any>;
  getRowId: GetRowIdFunc<any> | undefined = (row => row.data.signature);

  constructor(
    public dialog: MatDialog,
    public dialogRef: MatDialogRef<CartSelectionComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { carts: ICartControlObj[], client: IClientsTileApi, selectedCart: ICartControlObj }
  ) {
  }

  ngOnInit(): void {
    this.rowData = this.data.carts.map(cart => ({
      elsid: cart.number,
      date: cart.cartdate,
      payer: cart.payernamereadonly,
      price: cart.pricetotal,
      state: cart.statereadonly,
      tariff: cart.itemgroupidentifierreadonly,
      signature: cart.signature
    } as Row));
    console.log(this.rowData);
  }

  onConfirm() {
    this.dialogRef.close(this.gridApi.getSelectedNodes().map(node => {
      return this.data.carts.find(cart => node.data.signature === cart.signature);
    }))
  }

  onGridReady(event: GridReadyEvent<any>) {
    this.gridApi = event.api;
    const node = this.gridApi.getRowNode(this.data.selectedCart.signature);
    node.setSelected(true);
    this.gridApi.ensureNodeVisible(node);
  }
}
