import {Component} from '@angular/core';
import {SvgService} from 'frontier/nucleus';
import {ControlManagerService, ThemeService} from 'frontier/browserkit';

@Component({
  selector: 'kpi4me-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent {
  title = 'calculus';

  constructor(
    private svgService: SvgService,
    public themeService: ThemeService,
    private controlManager: ControlManagerService,
  ) {
  }
}
