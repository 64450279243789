import {Observable} from 'rxjs';
import {ApiConfigService} from '../api-config.service';
import {IControlObject} from '../../interfaces/control-object.interface';

export class Contractcollectionlist {
  http;
  url;
  httpOptions;

  constructor(protected appConfig: ApiConfigService) {
    this.http = appConfig.http;
    this.url = appConfig.url;
    this.httpOptions = appConfig.httpOptions;
  }


  sendcontract(InstanceId: string, contractCollection: IControlObject, values: string[], reciver: string[], cc: string[]): Observable<boolean> {
    return this.http.post(
      this.url + '/intern/api/control/ExecuteAction/' + InstanceId + '/sendcontract/', {
        "contractCollection": contractCollection,
        "values": values,
        "reciver": reciver,
        "cc": cc
      }, this.httpOptions
    ) as unknown as Observable<boolean>;
  }

  fillallcontract(InstanceId: string, contractCollection: IControlObject): Observable<boolean> {
    return this.http.post(
      this.url + '/intern/api/control/ExecuteAction/' + InstanceId + '/fillallcontract/', {"contractCollection": contractCollection}, this.httpOptions
    ) as unknown as Observable<boolean>;
  }

}
