<div>
  <!--hide selection when there are no data or the repository is only a dummy repository-->
  <kpi4me-select-element
    #select
    *ngIf="selectionData && (selectionData.options?.length > 1 || deleteInSelectionEnabled || createNewEnabled)"
    [formControlElement]="selectFormControl"
    [data]="selectionData"
    [createNewEnabled]="createNewEnabled"
    [deleteEnabled]="deleteEnabled"
    (newElementClick)="onNewElement($event)"
    (deleteElementClick)="deleteElementClicked($event)"
    [deleteAlwaysEnabled]="deleteInSelectionAlwaysEnabled"
    [editEnabled]="false"
    [deleteEnabled]="deleteInSelectionEnabled"
  ></kpi4me-select-element>
</div>

<kpi4me-dynamic-form-control
  #selectionFormRef
  [style.display]="selectFormControl.value != null && visibleFormFields ? '' : 'none'"
  (formChange)="updateRepo(null).subscribe(); cdr.detectChanges(); formChange.emit()"
  [selectedElement]="selectFormControl.value"
  [GUID]="GUID"
  [(apiInstance)]="apiInstance"
  (apiInstanceInitialize)="onFormApiInit($event)"
  [apiAdapter]="apiAdapter"
></kpi4me-dynamic-form-control>
