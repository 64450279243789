import {Component, Inject, OnDestroy, ViewEncapsulation} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {INewMissionDialog} from './new-mission-dialog.interface';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {IDateFormElement, ISelectFormElement, ITextFormElement} from 'frontier/browserkit';
import {Subscription} from 'rxjs';
import {tap} from 'rxjs/operators';
import {DateTime} from 'luxon';
import {cartNumberValidatorFactory} from './cart-number.validator';
import {CoreService} from 'frontier/nucleus';
import {INewMissionForm} from './new-mission-form.interface';

@Component({
  selector: 'app-new-mission-dialog',
  templateUrl: './new-mission-dialog.component.html',
  styleUrls: ['./new-mission-dialog.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class NewMissionDialogComponent implements OnDestroy {
  form: FormGroup<INewMissionForm> = this.fb.group({
    missionNumber: this.fb.control(String(DateTime.local().year) + '|', [
      Validators.required,
      Validators.pattern(/^[0-9]{4}\|[a-zA-Z0-9][0-9]{1,9}$/),
    ], [
      cartNumberValidatorFactory(this.coreService, this.data.instanceId)
    ]),
    date: this.fb.control(null, [Validators.required]),
    businessAreaSignature: this.fb.control(null, [Validators.required])
  });
  missionNumberData: ITextFormElement = {
    inputType: 'text',
    label: 'Einsatznummer'
  };
  dateData: IDateFormElement = {
    label: 'Datum',
    insertDelimiter: false
  };
  selectBusinessAreaData: ISelectFormElement = {
    label: 'Geschäftsfeld',
    options: [],
    createNewEnabled: false,
    deleteElementEnabled: false,
    editElementEnabled: false,
    emptyOptionAdded: false
  };
  customValidatorErrorMessages = new Map([
    ['invalidCartNumber', 'Die Einsatznummer existiert schon.'],
    ['failedCartNumberCheck', 'Die Einsatznummer konnte nicht überprüft werden.'],
  ])


  subs = new Subscription();

  constructor(@Inject(MAT_DIALOG_DATA) public data: INewMissionDialog,
              public dialogRef: MatDialogRef<NewMissionDialogComponent>,
              private fb: FormBuilder,
              private coreService: CoreService) {
    this.subs.add(
      this.form.controls.missionNumber.valueChanges.pipe(
        tap(value => {
          let numericValue = value.replace(/[^a-zA-Z0-9]/g, '');

          // Format the input according to the specified pattern
          if (numericValue.length >= 4) {
            numericValue = numericValue.substring(0, 4) + '|' + numericValue.substring(4);
          }
          this.form.controls.missionNumber.patchValue(numericValue, {emitEvent: false});
        })
      ).subscribe()
    );

    this.selectBusinessAreaData.options = data.businessAreas || this.selectBusinessAreaData.options;
  }

  ngOnDestroy() {
    this.subs.unsubscribe();
  }

  onBackspace(evt: Event) {
    const value = this.form.value.missionNumber;
    if (value.length !== 5) return;
    const newValue = value.substring(0, value.length - 1);
    this.form.controls.missionNumber.patchValue(newValue, {emitEvent: false});
    evt.stopPropagation();
    evt.preventDefault();
  }
}
