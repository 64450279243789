<mat-dialog-content [formGroup]="form">
  <mat-form-field appearance="fill">
    <mat-label>Name</mat-label>
    <input formControlName="name" matInput/>
  </mat-form-field>
</mat-dialog-content>
<mat-dialog-actions>
  <app-confirm-icon-button [disabled]="form.invalid"
                           (customClick)="onConfirm()"
  ></app-confirm-icon-button>
</mat-dialog-actions>
