import {Component, ViewEncapsulation} from '@angular/core';
import {BaseCellEditorComponent} from '../base-cell-editor/base-cell-editor.component';
import {FormControl} from '@angular/forms';
import {ITextFormElement} from '../../../form-control/dynamic-form/form-element/form-data.interface';

@Component({
  selector: 'kpi4me-long-text-cell-editor',
  templateUrl: './long-text-cell-editor.component.html',
  styleUrls: ['./long-text-cell-editor.component.scss'],
  encapsulation: ViewEncapsulation.Emulated,
})
export class LongTextCellEditorComponent extends BaseCellEditorComponent {

  formControl = new FormControl('')
  textElementData: ITextFormElement = {
    inputType: 'textarea',
  };

}
