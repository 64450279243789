import {Component} from '@angular/core';
import {CustomCellComponent} from '../custom-cell/custom-cell.component';
import {ICellRendererParams} from 'ag-grid-community';
import {EsvgFiles} from 'frontier/nucleus';

@Component({
  selector: 'app-reference-cell-selection-renderer',
  templateUrl: './reference-cell-selection-renderer.component.html',
  styleUrls: ['../custom-cell/custom-cell.component.scss'],
})
export class ReferenceCellSelectionRendererComponent extends CustomCellComponent {
  names: string[] = [];

  constructor() {
    super();
  }

  ngOnInit(): void {
    return;
  }

  agInit(params: ICellRendererParams) {
    this.setNames(params);
    super.agInit(params);
  }

  private setNames(params: ICellRendererParams) {
    if (params.value) {
      this.names = params.value.value.map((v: { name: any }) => v.name);
    }
  }

  protected readonly EsvgFiles = EsvgFiles;
}
