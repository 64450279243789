import {NgModule} from '@angular/core';
import {KeycloakRedirectComponent} from './keycloak-redirect/keycloak-redirect.component';
import {EqualValidator} from './validators/equal.validator';
import {ControlModule} from './control/control.module';
import {CoreService} from './api/core.service';
import {ApiConfigService} from './api/api-config.service';
import {StoreService} from './store/store.service';
import {SvgService} from './svg/svg.service';
import {BrowserModule} from '@angular/platform-browser';
import {CommonModule} from '@angular/common';
import {HttpClientModule} from '@angular/common/http';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {RouterModule} from '@angular/router';
import {CustomMaterialModule} from './styles/material.module';

@NgModule({
  declarations: [
    KeycloakRedirectComponent,
    EqualValidator
  ],
  imports: [
    ControlModule,
    BrowserModule,
    CommonModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
    // FlexModule,
    ControlModule,
    CustomMaterialModule,
  ],
  exports: [
    ControlModule
  ],
  providers: [
    CoreService,
    ApiConfigService,
    StoreService,
    SvgService
  ]
})
export class NucleusModule {
}
