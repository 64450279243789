<mat-form-field appearance="outline" class="form-field-no-padding mat-form-field-appearance-outline">
  <input matInput [type]="'text'"
         placeholder="Suche"
         [formControl]="formControl"
         [matAutocomplete]="auto"
         (keydown)="edited = true"
         (focusout)="sendCustomValue()"
  >
  <mat-autocomplete #auto="matAutocomplete" (optionSelected)="onOptionSelected()" [displayWith]="displayWith">
    <mat-option *ngFor="let entity of filteredEntities | async" [value]="entity.apiRow">
      {{entity.apiRow.name}}
    </mat-option>
  </mat-autocomplete>
</mat-form-field>
