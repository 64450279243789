import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import {
  CoreService,
  DefaultApiAdapter,
  EControlActions,
  IApiAdapter,
  IApiControl,
  IControlObject
} from 'frontier/nucleus';
import {ISelectFormElement, ISelectFormOption} from '../form-element/form-data.interface';
import {mergeMap, Subscription, tap} from 'rxjs';
import {FormControl} from '@angular/forms';
import {DynamicFormControlComponent} from '../dynamic-form-control/dynamic-form-control.component';

@Component({
  selector: 'kpi4me-form-wrapper',
  templateUrl: './form-wrapper.component.html',
  styleUrls: ['./form-wrapper.component.scss'],
  encapsulation: ViewEncapsulation.Emulated,
})
export class FormWrapperComponent implements OnInit, OnDestroy {
  @ViewChild('select') select: ElementRef;
  selectionData: ISelectFormElement;
  selectFormControl = new FormControl(null);
  @ViewChild(DynamicFormControlComponent) formRef: DynamicFormControlComponent;
  @Input() GUID: string;
  @Input() deleteInSelectionAlwaysEnabled = true;
  @Input() deleteInSelectionEnabled = true;
  @Input() createNewEnabled = true;
  @Input() apiAdapter: IApiAdapter = new DefaultApiAdapter();
  @Input() visibleFormFields = true;
  @Input() deleteEnabled = true;
  @Output() selectionChange = new EventEmitter<any>();
  @Output() getFormData = new EventEmitter<any>();
  @Output() formChange = new EventEmitter();

  apiInstance: IApiControl;

  formInit = new EventEmitter();

  subs = new Subscription();

  protected readonly EControlActions = EControlActions;

  constructor(
    private coreService: CoreService,
    protected cdr: ChangeDetectorRef,
  ) {
    this.subs.add(
      this.formInit.pipe(mergeMap(() => {
        return this.updateRepo(null);
      })).subscribe(() => {
      })
    )

    this.subs.add(
      this.selectFormControl.valueChanges.subscribe(v => {
        if (v) {
          this.formRef.apiInstance.filter = {...this.formRef.apiInstance.filter, parent: v};
          this.formRef.changeAndFetch().subscribe((res) => {
            this.getFormData.emit(res);
          });
          this.selectionChange.emit(v);
        }
        console.log(v);
      })
    )
  }

  ngOnInit(): void {
    return;
  }

  ngOnDestroy() {
    this.subs.unsubscribe();
  }

  onFormApiInit(event: IApiControl) {
    this.formInit.emit();
  }

  onNewElement(evt: string) {
    this.coreService.ActionForm.newelement(this.formRef.apiInstance.instanceid, evt, null)
      .subscribe(res => {
        this.updateRepo(EControlActions.create).subscribe(() => {
          this.selectFormControl.patchValue(res);
        })
      })
  }

  deleteElementClicked($event: ISelectFormOption) {
    const dbobject: IControlObject = {
      type: $event.value.type ? $event.value.type : $event.type,
      rowid: $event.value.rowid ? $event.value.rowid : $event.rowid,
      signature: $event.value.signature ? $event.value.signature : $event.signature,
    };
    this.coreService.ActionForm.deleteelement(this.formRef.apiInstance.instanceid, dbobject)
      .subscribe(res => {
        this.selectFormControl.patchValue(null);
        this.updateRepo(EControlActions.delete).subscribe();
      })
  }

  updateRepo(action: EControlActions) {
    return this.coreService.ActionForm.getrepository(this.formRef.apiInstance.instanceid).pipe(
      tap((repository) => {

        const options: ISelectFormOption[] = repository.options.map((o: any) => {
          const mappedO: ISelectFormOption = {
            value: o,
            name: o.name,
          }

          return mappedO;
        });
        // Set value to only possibility in case of dummy repository
        if (options && options.length === 1) {
          this.selectFormControl.patchValue(options[0]);
        }
        this.selectionData = {
          options
        };
        if (action) {
          this.coreService.controlDataChanged.emit({GUID: this.GUID, changeType: action});
        }
      })
    );
  }
}
