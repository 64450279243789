import {Component, Input, OnChanges, OnInit, SimpleChanges, ViewChild} from '@angular/core';
import {
  CoreService,
  dateObjectToISOString,
  EcalculusControlGUID,
  EControlActions,
  EsvgFiles,
  IApiControl,
  IControlObject,
} from "frontier/nucleus";
import {CalendarService, DialogService, IAction, SharedGridOptions, TableControlComponent} from "frontier/browserkit";
import {GridOptions} from "ag-grid-community";
import {combineLatest, concatMap, debounceTime, Observable, of, Subject, Subscription, switchMap} from "rxjs";
import {
  NewPermanentOrdinanceDialogComponent
} from "./new-permanent-ordinance-dialog/new-permanent-ordinance-dialog.component";
import {INewPermanentOrdinanceDialog} from "./new-permanent-ordinance-dialog/new-permanent-ordinance-dialog.interface";
import {filter, finalize} from "rxjs/operators";

@Component({
  selector: 'kpi4me-permanent-ordinance',
  templateUrl: './permanent-ordinance.component.html',
  styleUrl: './permanent-ordinance.component.scss'
})
export class PermanentOrdinanceComponent implements OnInit, OnChanges {
  subs = new Subscription();

  @Input() clientParent: IControlObject;
  @Input() cartParent: IControlObject;
  permanentOrdinanceTableGUID = EcalculusControlGUID.PermanentOrdinanceForClient;

  @ViewChild('table') tableRef: TableControlComponent;
  tableApiInstance: IApiControl;
  tableApiInstance$ = new Subject<IApiControl>();
  tableActions: IAction[] = [
    {
      displayName: 'Neue Dauerverordnung anlegen',
      controlAction: EControlActions.newOrdinance,
      disabledIfNoSelection: false,
      disabledIfMultiSelection: false,
      color: 'accent',
      icon: EsvgFiles.add,
      action: () => this.createNewPermanentOrdinance(),
    },
    {
      displayName: 'Löschen',
      controlAction: EControlActions.delete,
      disabledIfNoSelection: true,
      disabledIfMultiSelection: false,
      color: 'warn',
      icon: EsvgFiles.delete
    }
  ];
  gridOptions: GridOptions = {
    ...SharedGridOptions,
  };

  constructor(
    public dialogService: DialogService,
    protected coreService: CoreService,
    private calendarService: CalendarService,
  ) {
    this.subs.add(
      this.coreService.controlDataChanged.pipe(
        filter(evt => {
          return new Map([
            [EcalculusControlGUID.AllClientList, new Set([EControlActions.attendance])],
          ]).get(evt.GUID as EcalculusControlGUID)?.has(evt.changeType);
        }),
        concatMap(() => this.tableRef.changeAndFetch())).subscribe(),
    );
  }

  ngOnInit(): void {
    this.filterAndFetch(this.tableApiInstance);

    this.subs.add(
      combineLatest([
        this.calendarService.globalForkedEvents$.pipe(filter(v => v != null)),
        this.tableApiInstance$,
      ]).pipe(
        debounceTime(50),
        switchMap(([filter, apiInstance]) => {
          // Update the apiInstance and trigger the fetching of the data with the new filter.
          return this.tableRef.setApiInstanceAndFetch({
            ...apiInstance,
            filter: {
              ...this.tableApiInstance?.filter,
              typename: this.clientParent ? 'client' : this.clientParent ? 'cart' : null,
              parent: this.clientParent || this.cartParent || null,
              ...filter,
            },
          });
        }),
      ).subscribe()
    );
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes && this.tableRef) {
      this.filterAndFetch(this.tableApiInstance);
    }
  }

  filterAndFetch(apiInstance: IApiControl): void {
    if (this.tableRef) {
      if (this.clientParent) {
        this.tableRef.setApiInstanceAndFetch({
          ...apiInstance,
          filter: {
            ...this.tableApiInstance?.filter,
            parent: this.clientParent,
            typename: 'client',
          },
        }).subscribe();
      } else if (this.cartParent) {
        this.tableRef.setApiInstanceAndFetch({
          ...apiInstance,
          filter: {
            ...this.tableApiInstance?.filter,
            parent: this.cartParent,
            typename: 'cart',
          },
        }).subscribe();
      } else {
        console.error('No parent object defined for filter.');
      }
    }
  }

  private createNewPermanentOrdinance(): Observable<any> {
    return this.coreService.Permanentordinanceforclient.getrepository(
      this.tableApiInstance.instanceid,
      'ItemGroup',
      -1,
    ).pipe(
      switchMap((itemGroups) => {
        return this.openCreatePermanentOrdinanceDialog(itemGroups);
      })
    );
  }

  openCreatePermanentOrdinanceDialog(itemGroups: IControlObject[]): Observable<any> {
    let documentData: any;
    const dialogRef = this.dialogService.dialog.open(NewPermanentOrdinanceDialogComponent, {
      data: {
        itemGroups: itemGroups.map(ig => ({
          name: ig.name,
          value: ig.signature,
        })),
      } as INewPermanentOrdinanceDialog,
    });
    return dialogRef.afterClosed().pipe(
      finalize(() => {
        this.tableRef.gridApi.hideOverlay();
      }),
      concatMap((formData: INewPermanentOrdinanceDialog) => {
        if (formData) {
          documentData = formData.document;
          return this.coreService.Permanentordinanceforclient.newpermanentordinance(
            this.tableApiInstance.instanceid,
            itemGroups.find(ig => {
              return ig.signature === formData.itemGroup;
            }),
            dateObjectToISOString(formData.fromDate),
            dateObjectToISOString(formData.toDate),
          );
        }
        return of(null);
      }),
      concatMap((ordinanceObj: any) => {
        // We look for the attribute, that has an object of type 'TDocument' as its value, since this key is needed to
        // call 'UploadDocument'
        let propertyname: string;
        for (let key in ordinanceObj) {
          if (ordinanceObj.hasOwnProperty(key) &&
            typeof ordinanceObj[key] === 'object' &&
            ordinanceObj[key]?.type &&
            ordinanceObj[key]?.type === 'TDocument') {
            propertyname = key;
          }
        }
        return ordinanceObj && propertyname ? this.coreService.Control.UploadDocument(
          documentData.name,
          documentData.fileFormate,
          ordinanceObj.rowid.toString(),
          ordinanceObj.type,
          ordinanceObj.signature,
          propertyname,
          documentData.data,
        ).pipe(
          finalize(() => {
            this.tableRef.changeAndFetch().subscribe();
          }),
        ) : of(null);
      }),
    );
  }
}
