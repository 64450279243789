import {ChangeDetectorRef, Directive, EventEmitter, Input, OnDestroy, OnInit, Output,} from '@angular/core';
import {IControlConfiguration} from '../interfaces/control-config.interface';
import {
  BehaviorSubject,
  concatMap,
  defer,
  EMPTY,
  finalize,
  first,
  Observable,
  Subject,
  Subscription,
  throwError,
} from 'rxjs';
import {ApiAdapter} from '../api-adapter';
import {IApiControl} from '../interfaces/api-control.interface';
import {catchError, debounceTime, filter, mergeMap, tap,} from 'rxjs/operators';
import {StoreService} from '../../store/store.service';
import {CoreService} from '../../api/core.service';

export enum ECalculateImportValue {
  off,
  first,
  last,
  summary,
}

@Directive({})
export abstract class BaseControlComponent implements OnInit, OnDestroy {
  @Input() GUID!: string;

  protected _data: any;
  /**
   * Used to prevent changes or fetches via api, when the instance isn't created already
   * @protected
   */
  public gotData = new BehaviorSubject(null);
  @Input() public sessionExpired = false;
  public loading: boolean;

  @Input()
  public set renewedSession(b: boolean) {
    if (b == true) {
      // this.apiInstance = {
      //   filter: {},
      //   custom: {},
      //   sorting: {}
      // };
      this.createControlInstance();
    }
  }

  @Input() set data(d: any) {
    this._data = this.apiAdapter.from(d);
  }

  get data(): any {
    return this._data;
  }

  protected _config: IControlConfiguration = {
    showFilter: true,
    showNewObject: true,
  };

  get config(): IControlConfiguration {
    return this._config;
  }

  @Input() set config(c: IControlConfiguration) {
    this._config = c;
  }

  // Instance of the API-Control class
  @Input() apiInstance: IApiControl = {
    filter: {},
    custom: {},
    sorting: {},
  };

  @Output() apiInstanceChange = new EventEmitter<IApiControl>();

  @Output() apiInstanceInitialize = new EventEmitter<IApiControl>();
  abstract apiAdapter: ApiAdapter;

  // bouncing time for search of cells
  searchChanged: Subject<string> = new Subject<string>();
  debounceTime = 500;

  subs = new Subscription();

  private changeRequestLock = 0;
  private changeQueue = new BehaviorSubject<number>(0);

  constructor(
    protected coreService: CoreService,
    protected store: StoreService,
    protected cdr: ChangeDetectorRef,
  ) {
  }

  /**
   * Get the control instance from the api and save it in this component.
   */
  ngOnInit(): void {
    if (!this.apiAdapter) {
      console.error(
        'There is no API-Adapter instantiated. Needed to map the data from the API to internal',
        this
      );
      return;
    }
    // debounce search
    this.subs.add(
      this.searchChanged
        .pipe(debounceTime(this.debounceTime))
        .subscribe((filterString) => {
          this.debouncedFilterByString(filterString);
        })
    );

    this.createControlInstance();
  }

  /**
   * Called before closing a tab. Call the api, to drop the instance of this control.
   */
  ngOnDestroy(): void {
    if (this.GUID && this.sessionExpired == false) {
      console.log('Dropping instance of type', this.GUID, this.apiInstance?.instanceid);
      this.drop().subscribe(
        () => {
          // console.log('dropped instance', this);
          // this.gotData.next(null);
          // this.apiInstance = null;
        },
        (error) => {
          console.error(
            'Error occurred at dropping the control instance in the component.',
            this,
            error
          );
        }
      );
    }
    this.gotData.next(null);
    this.apiInstance = null;
    this.subs.unsubscribe();
  }

  /**
   * Creates a new instance of the control via API.
   */
  createControlInstance() {
    if (!this.GUID) {
      console.error(
        'No selectionGUID is set. Cannot create the instance without selectionGUID!',
        this.apiInstance
      );
    } else if (this.sessionExpired == true) {
      console.error('The session has expired. Stopping to create the instance');
    } else {
      this.loading = true;
      this.coreService.Control.NewInstance(
        this.GUID,
        this.apiInstance?.filter,
        this.apiInstance?.sorting,
        this.apiInstance?.custom
      ).pipe(finalize(() => this.loading = false))
        .subscribe(
        (apiInstance: IApiControl) => {
          this.apiInstance = {...this.apiInstance, ...apiInstance};
          this.cdr.detectChanges();
          this.apiInstanceInitialize.emit(this.apiInstance);
          this.apiInstanceChange.emit(this.apiInstance);
        },
        (error) => {
          console.error(
            'Error in creating the instance and fetching the data.',
            this.apiInstance,
            error
          );
        }
      );
    }
  }

  refresh(): Observable<any> {
    return this.gotData.pipe(
      filter((x) => x != null),
      mergeMap(() => {
        return this.fetch().pipe(
          tap((d) => {
            this.data = d;
            this.cdr.detectChanges();
          })
        );
      })
    );
  }

  /**
   * to delete the saved object in customOptions, just send an empty json
   */
  changeAndFetch(): Observable<any> {
    if (
      this.sessionExpired == true ||
      this.apiInstance == null ||
      this.apiInstance.instanceid == null
    ) {
      // console.log(this.apiInstance);
      console.log(
        'Tried change and fetch on invalid control element.',
        this.GUID, this.apiInstance?.instanceid
      );
      return EMPTY;
    } else {
      console.log(
        'change and fetch on valid control element.',
        this.GUID, this.apiInstance?.instanceid
      );
      this.loading = true;
      return this.lockedRequest(this.coreService.Control.ChangeInstanceAndFetch(
        this.apiInstance.instanceid,
        this.apiInstance.filter,
        this.apiInstance.sorting,
        this.apiInstance.custom,
      ).pipe(
        tap((d) => {
          console.log('Changed and fetched', this.GUID, this.apiInstance?.instanceid);
          console.log(d);
          this.data = d;
          this.gotData.next(d);
          return d;
        }),
        catchError((val) => {
          return throwError(val);
        }),
        finalize(() => this.loading = false)
      ))
    }
  }

  /**
   * Method to prevent multiple api requests that trigger database transaction, possibly leading to an "open current transaction error"
   * @param apiRequest
   */
  lockedRequest(apiRequest: Observable<any>): Observable<any> {
    return defer(() => {
      const lock = this.changeRequestLock;
      this.changeRequestLock++;
      return this.changeQueue.pipe(
        filter((i) => i === lock),
        first(),
        concatMap(() => apiRequest),
        finalize(() =>
          this.changeQueue.next(
            this.changeRequestLock - lock > 1 ? this.changeRequestLock - 1 : this.changeRequestLock
          )
        )
      );
    });
  }

  change(): Observable<any> {
    if (
      this.sessionExpired == true ||
      this.apiInstance == null ||
      this.apiInstance.instanceid == null
    ) {
      console.log('Tried change on invalid control element.', this.GUID);
      return EMPTY;
    } else {
      return this.lockedRequest(this.coreService.Control.ChangeInstance(
        this.apiInstance.instanceid,
        this.apiInstance.filter,
        this.apiInstance.sorting,
        this.apiInstance.custom
      ))
    }
  }

  drop(): Observable<boolean> {
    if (
      this.sessionExpired == true ||
      this.apiInstance == null ||
      this.apiInstance.instanceid == null
    ) {
      console.log('Tried drop on invalid control element.', this.GUID);
      return EMPTY;
    } else {
      return this.coreService.Control.DropInstance(this.apiInstance.instanceid);
    }
  }

  /**
   * Pipes the search string to the Subject. The Subject has to be subscribed with a pipe and
   * rxjs/debounceTime
   * @param evt The string of the input field
   */
  onStringFilterChange(evt: string): void {
    this.searchChanged.next(evt);
  }

  /**
   * Function that filters the data by a string.
   * @param filterString The string of the input
   * @private
   */
  protected debouncedFilterByString(filterString: string): void {
    if (filterString != this.apiInstance.filter.searchstring) {
      this.apiInstance.filter = {
        ...this.apiInstance.filter,
        searchstring: filterString,
      };
      this.changeAndFetch().subscribe();
    }
  }

  /**
   * Fetches the data from the api using the instance ID of this control.
   */
  fetch(): Observable<any> {
    if (
      this.sessionExpired == true ||
      this.apiInstance == null ||
      this.apiInstance.instanceid == null
    ) {
      console.log('Tried fetch on invalid control element.', this.GUID, this.apiInstance.instanceid);
      return EMPTY;
    } else {
      this.loading = true;
      console.log('refreshing pivot table', this.apiInstance.instanceid);
      return this.coreService.Control.FetchData(this.apiInstance.instanceid).pipe(finalize(() => this.loading = false));
    }
  }

  setApiInstanceAndFetch(apiInstance: IApiControl): Observable<any> {
    this.apiInstance = apiInstance;
    this.cdr.detectChanges();
    return this.changeAndFetch().pipe(tap(() =>
      this.apiInstanceChange.emit(this.apiInstance))
    );
  }
}
