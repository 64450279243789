import {Component, EventEmitter, Input, OnInit, Output, ViewEncapsulation} from '@angular/core';
import {CalculusStateService} from "../../Services/calculus-state.service";
import {IProcessOverviewState, IProcessState} from '../config/category-state-selection.interface';
import {ECalculusStateFilter} from '../config/state-filter.enum';
import {CartStatuses} from '../config/cart-status-data.const';
import {EsvgFiles} from 'frontier/nucleus';
import {TShownTables} from '../process-overview/shown-tables.type';

@Component({
  selector: 'kpi4me-bread-crumb',
  templateUrl: './bread-crumb.component.html',
  styleUrls: ['./bread-crumb.component.scss'],
  encapsulation: ViewEncapsulation.Emulated
})
export class BreadCrumbComponent implements OnInit {
  CartStatuses = CartStatuses;
  processOverviewState: IProcessOverviewState;
  stateSelection: IProcessState;
  @Input() set activeFilterStatus(processOverviewState: IProcessOverviewState) {
    if (!processOverviewState) return;
    this.processOverviewState = processOverviewState;
    this.stateSelection = processOverviewState.stateSelection;
  }
  @Input() activeTables: Set<TShownTables>;
  @Output() categoryClick = new EventEmitter();
  @Output() stateClick = new EventEmitter();
  @Output() activeCartFilterObjClick = new EventEmitter();

  stateFilterToLabelMap = new Map<ECalculusStateFilter, string>([
    [ECalculusStateFilter.kpiConnectError, 'Rot'],
    [ECalculusStateFilter.ETLnMappingError, 'Rot'],
    [ECalculusStateFilter.checkError, 'Rot'],
    [ECalculusStateFilter.createInvoiceError, 'Rot'],
    [ECalculusStateFilter.arrangeInvoiceError, 'Rot'],
    [ECalculusStateFilter.monitorPaymentError, 'Rot'],
    [ECalculusStateFilter.totalError, 'Rot'],
    [ECalculusStateFilter.kpiConnectWarning, 'Orange'],
    [ECalculusStateFilter.ETLnMappingWarning, 'Orange'],
    [ECalculusStateFilter.checkWarning, 'Orange'],
    [ECalculusStateFilter.createInvoiceWarning, 'Orange'],
    [ECalculusStateFilter.arrangeInvoiceWarning, 'Orange'],
    [ECalculusStateFilter.monitorPaymentWarning, 'Orange'],
    [ECalculusStateFilter.totalWarning, 'Orange'],
    [ECalculusStateFilter.kpiConnectOk, 'Grün'],
    [ECalculusStateFilter.ETLnMappingOk, 'Grün'],
    [ECalculusStateFilter.checkOk, 'Grün'],
    [ECalculusStateFilter.createInvoiceOk, 'Grün'],
    [ECalculusStateFilter.arrangeInvoiceOk, 'Grün'],
    [ECalculusStateFilter.monitorPaymentOk, 'Grün'],
    [ECalculusStateFilter.totalOk, 'Grün'],
  ])
  protected readonly ECalculusStateFilter = ECalculusStateFilter;
  protected readonly EsvgFiles = EsvgFiles;

  constructor(public calculusStateService: CalculusStateService) {
  }

  ngOnInit() {
    // TODO:
    // initialize show carts table toggle
    // const showCartsTable: boolean = JSON.parse(localStorage.getItem('showCartsTable')) || false;
    // this.showCartsTable.setValue(showCartsTable, {emitEvent: true});
  }
}
