import {LOCALE_ID, NgModule} from '@angular/core';
import {BrowserModule} from '@angular/platform-browser';

import {API_CONFIG_TOKEN, CustomMaterialModule, IApiConfig, NucleusModule} from 'frontier/nucleus';
import {DecimalPipe, registerLocaleData} from '@angular/common';
import localeDe from '@angular/common/locales/de';
import {AppComponent} from './app.component';
import {RouterModule} from '@angular/router';
import {HomeComponent} from './home/home.component';
import {routes} from './routes';
import {
  BrowserkitModule,
  ControlManagerModule,
  DynamicFormModule,
  EasyDatePickerModule,
  SharedComponentsModule,
  TokenInterceptor,
} from 'frontier/browserkit';
import {HTTP_INTERCEPTORS, HttpHeaders} from "@angular/common/http";
import {GoldenLayoutModule} from '@kpi4me/golden-angular-layout';
import {MatSidenavModule} from "@angular/material/sidenav";
import {environment} from "../environments/environment";
import {GL_COMPONENTS} from "./home/golden-layout.component";
import {MatToolbarModule} from "@angular/material/toolbar";
import {MatIconModule} from "@angular/material/icon";
import {MatTooltipModule} from "@angular/material/tooltip";
import {MatButtonModule} from "@angular/material/button";
import {ProcessOverviewComponent} from './home/process-overview/process-overview.component';
import {ProcessHeaderComponent} from './home/process-header/process-header.component';
import {EvaluationComponent} from './home/evaluation/evaluation.component';
import {GoToTrapoComponent} from './home/go-to-trapo/go-to-trapo.component';
import {ClientsComponent} from './home/clients/clients.component';
import {CartsComponent} from './home/carts/carts.component';
import {CartDetailsComponent} from './home/cart-details/cart-details.component';
import {MatTabsModule} from "@angular/material/tabs";
import {ClientDetailComponent} from './home/client-detail/client-detail.component';
import {InvoiceDetailComponent} from './home/invoice-detail/invoice-detail.component';
import {TransportDetailComponent} from './home/transport-detail/transport-detail.component';
import {CrewDetailComponent} from './home/crew-detail/crew-detail.component';
import {CartAdditionalDetailComponent} from './home/cart-additional-detail/cart-additional-detail.component';
import {TrapoDetailComponent} from './home/trapo-detail/trapo-detail.component';
import {StatusDialogComponent} from './home/carts/status-dialog/status-dialog.component';
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {BreadCrumbComponent} from './home/bread-crumb/bread-crumb.component';
import {SafePipe} from './home/go-to-trapo/safe.pipe';
import {ConspicuityDetailComponent} from './home/conspicuity-detail/conspicuity-detail.component';
import {SplitterModule} from 'primeng/splitter';
import {InternalNoteDetailComponent} from './home/internal-note-detail/internal-note-detail.component';
import {OpenItemsComponent} from './home/open-items/open-items.component';
import {PageSizeDialogComponent} from './dialogs/page-size-dialog/page-size-dialog.component';
import {DocumentOutboxComponent} from './home/document-outbox/document-outbox.component';
import {EpostConfigFormComponent} from './home/epost-config/epost-config-form.component';
import {EpostConfigWrapperComponent} from './home/epost-config/epost-config-wrapper/epost-config-wrapper.component';
import {TableTogglerComponent} from './home/process-overview/table-toggler/table-toggler.component';
import {NewMissionDialogComponent} from './home/carts/new-mission-dialog/new-mission-dialog.component';
import {DunningConfigComponent} from "./home/dunning-config/dunning-config.component";
import {PermanentOrdinanceComponent} from "./home/permanent-ordinance/permanent-ordinance.component";
import {
  PrescriptionAttendanceDialogComponent
} from './home/clients/prescription-attendance-dialog/prescription-attendance-dialog.component';
import {
  NewPermanentOrdinanceDialogComponent
} from "./home/permanent-ordinance/new-permanent-ordinance-dialog/new-permanent-ordinance-dialog.component";
import {PdfPipe} from "frontier/browserkit/src/lib/pipes/pdf.pipe";
import {ClientsComparatorTableComponent} from './home/clients-comparator-table/clients-comparator-table.component';
import {CustomHeaderComponent} from './home/clients-comparator-table/custom-header/custom-header.component';
import {CartSelectionComponent} from './home/conspicuity-detail/cart-selection/cart-selection.component';
import {AlternativePayerComponent} from './home/alternative-payer/alternative-payer.component';
import {TariffChangesComponent} from './home/tariff-changes/tariff-changes.component';
import {EvaluationOnHoldComponent} from './home/evaluation/evaluation-on-hold/evaluation-on-hold.component';
import {TariffOptionsComponent} from './home/tariff-changes/tariff-options/tariff-options.component';
import {InvoiceTemplatesConfigComponent} from './home/invoice-templates-config/invoice-templates-config.component';
import {ClientAccessComponent} from './home/evaluation/client-access/client-access.component';
import {TemplateEditorComponent} from './home/template-editor/template-editor.component';
import {
  TemplatesControl
} from './home/template-editor/templates-control/templates-control.component';
import {
  ClientMessageDetailsComponent
} from './home/conspicuity-detail/client-message-details/client-message-details.component';

registerLocaleData(localeDe); // de-DE

export const HttpOptions = {
  headers: new HttpHeaders({
    Accept: 'application/json',
    'Content-type': 'text/plain;charset=UTF-8',
  }),
};

export const API_CONFIG: IApiConfig = {
  url:
    environment.env === 'prod'
      ? window.location.origin
      : environment.url,
  httpOptions: HttpOptions,
  environment,
};

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    ProcessOverviewComponent,
    ProcessHeaderComponent,
    EvaluationComponent,
    EvaluationOnHoldComponent,
    ClientAccessComponent,
    GoToTrapoComponent,
    ClientsComponent,
    CartsComponent,
    CartDetailsComponent,
    ClientDetailComponent,
    InvoiceDetailComponent,
    TransportDetailComponent,
    CrewDetailComponent,
    CartAdditionalDetailComponent,
    TrapoDetailComponent,
    StatusDialogComponent,
    BreadCrumbComponent,
    SafePipe,
    ConspicuityDetailComponent,
    ClientMessageDetailsComponent,
    InternalNoteDetailComponent,
    OpenItemsComponent,
    PageSizeDialogComponent,
    DocumentOutboxComponent,
    EpostConfigFormComponent,
    EpostConfigWrapperComponent,
    TableTogglerComponent,
    NewMissionDialogComponent,
    DunningConfigComponent,
    AlternativePayerComponent,
    PermanentOrdinanceComponent,
    TariffChangesComponent,
    TariffOptionsComponent,
    PrescriptionAttendanceDialogComponent,
    NewPermanentOrdinanceDialogComponent,
    ClientsComparatorTableComponent,
    CustomHeaderComponent,
    CartSelectionComponent,
    TemplateEditorComponent,
    TemplatesControl,
    InvoiceTemplatesConfigComponent,
  ],
  imports: [
    BrowserModule,
    RouterModule.forRoot(routes,
      {initialNavigation: 'enabledBlocking', useHash: true}),
    NucleusModule,
    BrowserkitModule,
    GoldenLayoutModule.forRoot(GL_COMPONENTS),
    MatSidenavModule,
    ControlManagerModule,
    DynamicFormModule,
    MatToolbarModule,
    MatIconModule,
    MatTooltipModule,
    MatButtonModule,
    MatTabsModule,
    CustomMaterialModule,
    SharedComponentsModule,
    FormsModule,
    ReactiveFormsModule,
    SplitterModule,
    EasyDatePickerModule,
    PdfPipe,
  ],
  providers: [
    {provide: LOCALE_ID, useValue: 'de-DE'},
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TokenInterceptor,
      multi: true,
    },
    {provide: API_CONFIG_TOKEN, useValue: API_CONFIG},
    DecimalPipe,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {
}
